import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { getFacturesAction, startLoader, requestPaymentAction,
submitBordereauAction, validateBordereauAction } from "../../../store/actions/FacturationAction";
import { currentUser } from "../../../store/selectors/CurrentUserSelector";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { DataTableBase } from "../DataTables/DataTableBase";
import { Modal } from 'react-bootstrap';
import BordereauUpdateModal from "./BordereauUpdateModal";
import { STATUSES } from '../../globals';
import { useTranslation } from "react-i18next";

export const Bordereaux = (props) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [filters, setFilters] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [bordereauUpdateModal, setBordereauUpdateModal] = useState(false);
    const [choosenBordereau, setChoosenBordereau] = useState(null);
    const [typeFactAction, setTypeFactAction] = useState('');

    const closeModal = () => {
        setBordereauUpdateModal(false);
    };

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            montantTotal: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            dateFacturation: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            status: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            statusPaiement: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            datePaiement: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        });
        setGlobalFilterValue("");
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <Button type="button" icon="pi pi-filter-slash" label={t('common.reinit')} outlined onClick={clearFilter} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder={t('common.reinit')} />
                </span>
            </div>
        );
    };

    const clearFilter = () => {
        initFilters();
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const setColumns = () => {
        return [
            {name: 'name', headerName: t('facturation.invoice-number'), filterPlaceholder: t('facturation.filter-by-number'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
            {name: 'montantTotal', dataType: 'numeric', headerName: 'Montant', filterPlaceholder: t('facturation.filter-by-amount'), style: { minWidth: '6rem' }, filter: true, bodyTemplate: null},
            {name: 'dateFacturation', filterField: 'dateFacturation', dataType: 'date', headerName: t('facturation.invoice-date'), filterPlaceholder: t('facturation.filter-by-invoice-date'), style: { minWidth: '6rem' }, filter: true, bodyTemplate: dateFacturationBodyTemplate, filterElement: dateFilterTemplate},
            {name: 'status', headerName: t('facturation.invoice-status'), filterPlaceholder: t('facturation.filter-by-invoice-status'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: statusBodyTemplate, filterElement: statusFilterTemplate},
            {name: 'statusPaiement', headerName: t('facturation.payment-status'), filterPlaceholder: t('facturation.filter-by-payment-status'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: statusPaiementBodyTemplate, filterElement: statusPaiementFilterTemplate},
            {name: 'datePaiement', filterField: 'dateFacturation', dataType: 'date', headerName: t('facturation.payment-date'), filterPlaceholder: t('facturation.filter-by-payment-date'), style: { minWidth: '6rem' }, filter: true, bodyTemplate: datePaiementBodyTemplate, filterElement: dateFilterTemplate},
            {name: 'actions', headerName: 'Actions', filterPlaceholder: '', style: { minWidth: '6rem'}, filter: false, bodyTemplate: getActionsTemplate},
        ]
    };

    const getActionsTemplate = (rowData) => {
        return <div style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
            {hasMenu('MENFACTURE') && canDo('MENFACTURE', 'ACVIEWFACT') && <button className="btn btn-info shadow btn-xs sharp mr-2"
                onClick={e => {
                    e.preventDefault();
                    viewFacture(rowData.id);
                }}
            >
                <i className="fa fa-eye"></i>
            </button> }
            {hasMenu('MENFACTURE') && canDo('MENFACTURE', 'ACVIEWFACT') && props.type === t('common.unsubmit-billboard') && <button className="btn btn-primary shadow btn-xs sharp mr-2"
                onClick={e => {
                    e.preventDefault();
                    setChoosenBordereau(rowData);
                    setTypeFactAction('ajout');
                    setBordereauUpdateModal(true);
                }}
            >
                <i className="fa fa-plus"></i>
            </button> }
            {hasMenu('MENFACTURE') && canDo('MENFACTURE', 'ACVIEWFACT') && props.type === t('common.unsubmit-billboard') && <button className="btn btn-danger shadow btn-xs sharp mr-2"
                onClick={e => {
                    e.preventDefault();
                    setChoosenBordereau(rowData);
                    setTypeFactAction('retrait');
                    setBordereauUpdateModal(true);
                }}
            >
                <i className="fa fa-minus"></i>
            </button> }
            {hasMenu('MENFACTURE') && canDo('MENFACTURE', 'ACREQPAIEM') && props.type === t('common.unsubmit-billboard') && <button className="btn btn-success shadow btn-xs sharp mr-2"
                onClick={e => {
                    e.preventDefault();
                    submitBordereau(rowData.id);
                }}
            >
                <i className="fa fa-calculator"></i>
            </button> }
            {hasMenu('MENFACTURE') && canDo('MENFACTURE', 'ACREQPAIEM') && props.type === t('common.pending-billboard') && rowData.status === STATUSES.invalidation && <button className="btn btn-success shadow btn-xs sharp mr-2"
                onClick={e => {
                    e.preventDefault();
                    validateReglement(rowData.id);
                }}
            >
                <i className="fa fa-handshake"></i>
            </button> }
        </div>
    }

    const viewFacture = (id) => {
        props.history.push('/facturation-details/'+id);
    }

    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={props.factures.map(facture => {
            return facture.status ? facture.status : 'non payé'
        }).reduce(function (acc, curr) {
            if (!acc.includes(curr))
                acc.push(curr);
            return acc;
        }, [])} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={statusItemTemplate} placeholder="Choisir" className="p-column-filter" showClear />;
    };

    const statusPaiementFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={props.factures.map(facture => {
            return facture.statusPaiement ? facture.statusPaiement : 'non payé'
        }).reduce(function (acc, curr) {
            if (!acc.includes(curr))
                acc.push(curr);
            return acc;
        }, [])} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={statusItemTemplate} placeholder="Choisir" className="p-column-filter" showClear />;
    };

    const statusBordFilterTemplate = (options) => {
        return (
            <div className="flex align-items-center gap-2">
                <label htmlFor="isClose-filter" className="font-bold">
                {t('facturation.closed')}
                </label>
                <TriStateCheckbox inputId="isClose-filter" value={options.value} onChange={(e) => options.filterCallback(e.value)} />
            </div>
        );
    };

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" mask="99/99/9999" />;
    };

    const datePaiementBodyTemplate = (rowData) => {
        return formatDate(rowData.datePaiement);
    };

    const dateFacturationBodyTemplate = (rowData) => {
        return formatDate(rowData.dateFacturation);
    };

    const statusBodyTemplate = (rowData) => {
        return <Tag value={t('statuses.'+rowData.status)} severity={getSeverity(rowData.status)} />;
    };

    const statusPaiementBodyTemplate = (rowData) => {
        return <Tag value={t('statuses.'+rowData.statusPaiement)} severity={getSeverity(rowData.statusPaiement)} />;
    };

    const statusBordBodyTemplate = (rowData) => {
        return <Tag value={rowData.isClose ? 'clôturé' : "ouvert"} severity={getSeverity(rowData.isClose)} />;
    };

    const statusItemTemplate = (option) => {
        return <Tag value={option} severity={getSeverity(option)} />;
    };

    const getSeverity = (status) => {
        switch (status) {
            case STATUSES.pending || STATUSES.increation:
                return 'primary';
            
            case STATUSES.nonPaye:
                return 'danger';

            case STATUSES.invalidation:
                return 'secondary';

            case STATUSES.paye || STATUSES.inpayment:
                return 'success';

            case 'clôturé':
                return 'success';

            case 'ouvert':
                return 'warning';

            default:
                return 'warning';
        }
    };

    /*const formatDate = (value) => {
        return format(new Date(value), 'dd/MM/yyyy')
    };*/

    const formatDate = (value) => {
        return value.toLocaleDateString('fr-FR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
          return false;
        }else{
          return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
      }
    
     const hasMenu = (menuCode) => {
          return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
      }

    /*useEffect(() => {
        console.log("the useEffect is called");
        if(hasMenu('MENFACTURE') && canDo('MENFACTURE', 'ACVIEWFACT')){
            console.log("About to call the factures");
            dispatch(startLoader());
            dispatch(getFacturesAction(props.currentUser.providerId));
            initFilters();
        }else{
            props.history.push('/access-denied');
        }
    }, []);*/

    /*useEffect(() => {
        console.log("Here are the factures");
        console.log(props.factures);
    }, [props.factures]);*/


    const submitBordereau = (id) => {
        dispatch(startLoader());
        dispatch(submitBordereauAction(id, props.currentUser.providerId));
    }

    const validateReglement = (id) => {
        dispatch(startLoader());
        dispatch(validateBordereauAction(id, props.currentUser.providerId));
    }

    return (
        <>
            <DataTableBase 
                data={props.bordereaux.map(facture => {
                    facture.dateFacturation = new Date(facture.dateFacturation);
                    facture.datePaiement = new Date(facture.datePaiement);
                    return facture;
                })}
                emptyMessage={t('facturation.no-invoice-found')}
                filters={filters}
                globalFiltersFields={['name', 'montantTotal', 'dateFacturation', 'status', 'statusPaiement', 'datePaiement']}
                header={renderHeader()}
                columns={setColumns()}
                rows={10} 
                loading={props.showLoading}
                rowClass={(data) => {
                    return {
                        'bg-info': data.status === STATUSES.invalidation
                    };
                }}
            />
            
            <Modal
                className="fade bd-example-modal-lg"
                show={bordereauUpdateModal}
                size="lg"
            >
                <BordereauUpdateModal 
                    showModal={bordereauUpdateModal}
                    bordereau={choosenBordereau}
                    type={typeFactAction}
                    closeModal={closeModal}
                />
            </Modal>
        </>
    )
};

const mapStateToProps = (state) => {
    return {
        factures: state.facturation.factures,
        showLoading: state.facturation.showLoading,
        currentUser: currentUser(state)
    };
};

export default connect(mapStateToProps)(Bordereaux);