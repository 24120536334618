import React, { Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from 'react-redux';
import { useParams, withRouter } from "react-router-dom";
import Select from 'react-select'
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { makeAppointmentRequest, startLoader as startConsultLoader, getAppointmentDataAction } from "../../../store/actions/ConsultationActions";
import { startLoader, listProvidersAction, getProviderDataAction } from "../../../store/actions/ProviderActions";
import { currentUser } from "../../../store/selectors/CurrentUserSelector";
import { Button, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export const AppointmentRequest = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { id } = useParams();
    const { history, currentUser, appointShowLoading, appointment } = props;
    const [date, setDate] = useState(new Date(appointment.dateRdv));
    

     const requestAppointment = (e) => {
        e.preventDefault();
        dispatch(startConsultLoader());
        let data = {
            providerId: appointment.providerId,
            providerName: appointment.providerName,
            patientId: appointment.patientId,
            patientName: appointment.patientName,
            dateRdv: date,
            doctorId: appointment.doctorId,
            doctorName: appointment.doctorName,
            motifRdv: appointment.motifRdv
        }

        dispatch(makeAppointmentRequest(id, data, history));
     }


     useEffect(() => {
        dispatch(startConsultLoader());
        dispatch(getAppointmentDataAction(id));
    }, []);

    return (
        <Fragment>
            <div className="row">
                <div className="col-xl-12 col-xxl-12">
                    <div className="card">
                        <div className="card-header">
                        <h4 className="card-title">{t('appointment.confirm-appointment')}</h4>
                        </div>
                        <div className="card-body">
                        <form
                            onSubmit={(e) => requestAppointment(e)}
                        >
                                <section>
                                    <div className="row">
                                        <div className="col-lg-4 mb-2">
                                            <div className="form-group">
                                                <label className='mb-1 '>
                                                    <strong>{t('appointment.clinic-hospital')}</strong>
                                                </label><br/>
                                                <span>{appointment.providerName}</span>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 mb-2">
                                            <div className="form-group">
                                                <label className='mb-1 '>
                                                    <strong>{t('common.doctor')}</strong>
                                                </label><br/>
                                                <span>{appointment.doctorName}</span>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 mb-2">
                                            <div className="form-group">
                                                <label className='mb-1 '>
                                                    <strong>Type de consultation</strong>
                                                </label><br/>
                                                <span>{appointment.acteName}</span>
                                            </div>
                                        </div>
                                    </div>
                                        
                                    
                                    
                                    <div className="row">
                                        <div className="col-lg-6 mb-2">
                                            <div className="form-group">
                                                <label className='mb-1 '>
                                                    <strong>{t('appointment.appointment-reason')}</strong>
                                                </label><br/>
                                                <span>{appointment.motifRdv}</span>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 mb-2">
                                            <div className="form-group">
                                                <label className='mb-1 '>
                                                    <strong>{t('appointment.date-time')}</strong>
                                                </label>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <DateTimePicker
                                                        autoOk
                                                        label=""
                                                        clearable
                                                        format="dd/MM/yyyy hh:mm"
                                                        value={date}
                                                        disabled={appointShowLoading}
                                                        required
                                                        onChange={setDate}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                
                                <div className='row justify-content-center mt-4'>
                                    { appointShowLoading && 
                                        <Button variant="primary" disabled>
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />{' '}
                                            <span className="visually-hidden">{t('appointment.await-text')}</span>
                                        </Button> }
                                    { !appointShowLoading &&
                                        <div className='col-4'>
                                            <input type='submit' value={t('common.confirm')} className='btn btn-primary btn-block'/>
                                        </div> }
                                </div>
                        </form>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
};

const mapStateToProps = (state) => {
    return {
        appointment: state.consultation.appointment,
        appointShowLoading: state.consultation.showLoading,
        currentUser: currentUser(state)
    };
};

export default withRouter(connect(mapStateToProps)(AppointmentRequest));