import {
    START_LOADER_ACTION,
    GET_BON_CONFIRMED_ACTION,
    GET_BON_FAILED_ACTION,
    MARK_DONE_CONFIRMED_ACTION,
    MARK_DONE_FAILED_ACTION
} from '../actions/ActesSpecialisesAction';

const initialState = {
    bon: {},
    errorMessage: '',
    successMessage: '',
    showLoading: false,
};

export const ActesSpecialisesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_BON_CONFIRMED_ACTION:
            return {
                ...state,
                bon: action.payload,
                errorMessage: '',
                successMessage: 'Bon gotten Successfully Completed',
                showLoading: false,
            };
        case GET_BON_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case MARK_DONE_CONFIRMED_ACTION:
            return {
                ...state,
                errorMessage: '',
                successMessage: 'Mark act as done Successfully Completed',
                showLoading: false,
            };
        case MARK_DONE_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case START_LOADER_ACTION:
            return {
                ...state,
                showLoading: true,
            };
        default:
            return state;
    };
};