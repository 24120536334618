import { sendNotificationRequest } from '../../services/NotificationService';
import { errorToastr, successToastr } from '../../Utils/ToastrMessage';

export const MESSAGE_SENT_CONFIRMED_ACTION = '[messageSent action] confirmed messageSent';
export const MESSAGE_SENT_FAILED_ACTION = '[messageSent action] failed messageSent';
export const LOADING_TOGGLE_ACTION = '[toggleLoader action] failed toggleLoader';

var I18n = require('react-redux-i18n').I18n;

export function sentEmailMessage(data) {
    return (dispatch) => {
        sendNotificationRequest(data)
        .then((response) => {
            successToastr(I18n.t('help.success'));
            dispatch(confirmedMessageSentAction(response.data));
        })
        .catch((error) => {
            console.log(error.response);
            errorToastr(I18n.t('help.error')+error.response?.data?.message)
            dispatch(messageSentFailedAction(error.response?.data?.message));
        });
    };
}

export function messageSentFailedAction(data) {
    return {
        type: MESSAGE_SENT_FAILED_ACTION,
        payload: data,
    };
}

export function confirmedMessageSentAction(data) {
    return {
        type: MESSAGE_SENT_CONFIRMED_ACTION,
        payload: data,
    };
}

export function loadingToggleAction(status) {
    console.log('this fucntion is called with value '+status);
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}