import {
    MESSAGE_SENT_CONFIRMED_ACTION,
    MESSAGE_SENT_FAILED_ACTION,
    LOADING_TOGGLE_ACTION,
} from '../actions/HelpActions';

const initialState = {
    errorMessage: '',
    successMessage: '',
    showLoading: false,
};

export function HelpReducer(state = initialState, action) {
    if (action.type === MESSAGE_SENT_CONFIRMED_ACTION) {
        return {
            ...state,
            errorMessage: '',
            successMessage: 'Signup Successfully Completed',
            showLoading: false,
        };
    }
    if (action.type === MESSAGE_SENT_FAILED_ACTION) {
        return {
            ...state,
            errorMessage: action.payload,
            successMessage: '',
            showLoading: false,
        };
    }

    if (action.type === LOADING_TOGGLE_ACTION) {
        return {
            ...state,
            showLoading: action.payload,
        };
    }
    return state;
}

    
