import React, { useState, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import TextInput from 'react-autocomplete-input';
import { getSuggestionsDataAction } from '../../../../../store/actions/ConsultationActions';
import 'react-autocomplete-input/dist/bundle.css';
import './ConsultationStyle.css';
import { Badge, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const ConsultationForm = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const consult = JSON.parse(localStorage.getItem("consultation"));

    const [motif, setMotif] = useState((consult && consult.questions) ? consult.questions.motif : "");
    const [allerg, setAllerg] = useState("");
    const [habit, setHabit] = useState("");
    const [persAnt, setPersAnt] = useState("");
    const [famAnt, setFamAnt] = useState("");
    const [identity, setIdentity] = useState("");
    const [observations, setObservations] = useState((consult && consult.questions) ? consult.questions.observations : "");

    const [inspection, setInspection] = useState("");
    const [palpation, setPalpation] = useState("");
    const [oscultation, setOscultation] = useState("");
    const [percussion, setPercussion] = useState("");

    const [allergies, setAllergies] = useState((consult && consult.questions) ? consult.questions.allergies : []);
    const [habits, setHabits] = useState((consult && consult.questions) ? consult.questions.habits : []);
    const [persAnts, setPersAnts] = useState((consult && consult.questions) ? consult.questions.persAnts : []);
    const [famAnts, setFamAnts] = useState((consult && consult.questions) ? consult.questions.famAnts : []);
    const [identities, setIdentities] = useState((consult && consult.questions) ? consult.questions.identities : []);
    const [inspections, setInspections] = useState((consult && consult.questions) ? consult.questions.inspections : []);
    const [palpations, setPalpations] = useState((consult && consult.questions) ? consult.questions.palpations : []);
    const [oscultations, setOscultations] = useState((consult && consult.questions) ? consult.questions.oscultations : []);
    const [percussions, setPercussions] = useState((consult && consult.questions) ? consult.questions.percussions : []);

    const updateMotif = (val) => {
        setMotif(val);
        saveUpdates({
            motif: val,
            allergies: [...allergies, allerg],
            habits,
            persAnts,
            famAnts,
            identities,
            inspections,
            palpations,
            oscultations,
            percussions,
            observations
        });
    }

    const updateObservations = (val) => {
        setObservations(val);
        saveUpdates({
            motif,
            allergies: [...allergies, allerg],
            habits,
            persAnts,
            famAnts,
            identities,
            inspections,
            palpations,
            oscultations,
            percussions,
            observations: val
        });
    }


    const handleKeyDown = (e, type) => {
        if(e.key === "Enter") {
            if(type === "allergy" && !allergies.includes(allerg)){
                setAllergies([...allergies, allerg]);
                saveUpdates({
                    motif,
                    allergies: [...allergies, allerg],
                    habits,
                    persAnts,
                    famAnts,
                    identities,
                    inspections,
                    palpations,
                    oscultations,
                    percussions,
                    observations
                });
                setAllerg("");
            }
            if(type === "habit" && !habits.includes(habit)){
                setHabits([...habits, habit]);
                saveUpdates({
                    motif,
                    allergies,
                    habits: [...habits, habit],
                    persAnts,
                    famAnts,
                    identities,
                    inspections,
                    palpations,
                    oscultations,
                    percussions,
                    observations
                });
                setHabit("");
            }
            if(type === "persAnt" && !persAnts.includes(persAnt)){
                setPersAnts([...persAnts, persAnt]);
                saveUpdates({
                    motif,
                    allergies,
                    habits,
                    persAnts: [...persAnts, persAnt],
                    famAnts,
                    identities,
                    inspections,
                    palpations,
                    oscultations,
                    percussions,
                    observations
                });
                setPersAnt("");
            }
            if(type === "famAnt" && !famAnts.includes(famAnt)){
                setFamAnts([...famAnts, famAnt]);
                saveUpdates({
                    motif,
                    allergies,
                    habits,
                    persAnts,
                    famAnts: [...famAnts, famAnt],
                    identities,
                    inspections,
                    palpations,
                    oscultations,
                    percussions,
                    observations
                });
                setFamAnt("");
            }
            if(type === "identity" && !identities.includes(identity)){
                setIdentities([...identities, identity]);
                saveUpdates({
                    motif,
                    allergies,
                    habits,
                    persAnts,
                    famAnts,
                    identities: [...identities, identity],
                    inspections,
                    palpations,
                    oscultations,
                    percussions,
                    observations
                });
                setIdentity("");
            }
            if(type === "inspection" && !inspections.includes(inspection)){
                setInspections([...inspections, inspection]);
                saveUpdates({
                    motif,
                    allergies,
                    habits,
                    persAnts,
                    famAnts,
                    identities,
                    inspections: [...inspections, inspection],
                    palpations,
                    oscultations,
                    percussions,
                    observations
                });
                setInspection("");
            }
            if(type === "palpation" && !palpations.includes(palpation)){
                setPalpations([...palpations, palpation]);
                saveUpdates({
                    motif,
                    allergies,
                    habits,
                    persAnts,
                    famAnts,
                    identities,
                    inspections,
                    palpations: [...palpations, palpation],
                    oscultations,
                    percussions,
                    observations
                });
                setPalpation("");
            }
            if(type === "oscultation" && !oscultations.includes(oscultation)){
                setOscultations([...oscultations, oscultation]);
                saveUpdates({
                    motif,
                    allergies,
                    habits,
                    persAnts,
                    famAnts,
                    identities,
                    inspections,
                    palpations,
                    oscultations: [...oscultations, oscultation],
                    percussions,
                    observations
                });
                setOscultation("");
            }
            if(type === "percussion" && !percussions.includes(percussion)){
                setPercussions([...percussions, percussion]);
                saveUpdates({
                    motif,
                    allergies,
                    habits,
                    persAnts,
                    famAnts,
                    identities,
                    inspections,
                    palpations,
                    oscultations,
                    percussions: [...percussions, percussion],
                    observations
                });
                setPercussion("");
            }
        }
    }

    const removeElt = (val, e, type) => {
        if(e.type === "click") {
            if(type === "allergy"){
                setAllergies(allergies.filter(allr => {return allr !== val}));
                saveUpdates({
                    motif,
                    allergies: allergies.filter(allr => {return allr !== val}),
                    habits,
                    persAnts,
                    famAnts,
                    identities,
                    inspections,
                    palpations,
                    oscultations,
                    percussions,
                    observations
                });
            }
            else if(type === "habit"){
                setHabits(habits.filter(habit => {return habit !== val}));
                saveUpdates({
                    motif,
                    allergies,
                    habits: habits.filter(habit => {return habit !== val}),
                    persAnts,
                    famAnts,
                    identities,
                    inspections,
                    palpations,
                    oscultations,
                    percussions,
                    observations
                });
            }
            else if(type === "persAnt"){
                setPersAnts(persAnts.filter(persAnt => {return persAnt !== val}));
                saveUpdates({
                    motif,
                    allergies,
                    habits,
                    persAnts: persAnts.filter(persAnt => {return persAnt !== val}),
                    famAnts,
                    identities,
                    inspections,
                    palpations,
                    oscultations,
                    percussions,
                    observations
                });
            }
            else if(type === "famAnt"){
                setFamAnts(famAnts.filter(famAnt => {return famAnt !== val}));
                saveUpdates({
                    motif,
                    allergies,
                    habits,
                    persAnts,
                    famAnts: famAnts.filter(famAnt => {return famAnt !== val}),
                    identities,
                    inspections,
                    palpations,
                    oscultations,
                    percussions,
                    observations
                });
            }
            else if(type === "identity"){
                setIdentities(identities.filter(ident => {return ident !== val}));
                saveUpdates({
                    motif,
                    allergies,
                    habits,
                    persAnts,
                    famAnts,
                    identities: identities.filter(ident => {return ident !== val}),
                    inspections,
                    palpations,
                    oscultations,
                    percussions,
                    observations
                });
            }
            else if(type === "inspection"){
                setInspections(inspections.filter(ident => {return ident !== val}));
                saveUpdates({
                    motif,
                    allergies,
                    habits,
                    persAnts,
                    famAnts,
                    identities,
                    inspections: inspections.filter(ident => {return ident !== val}),
                    palpations,
                    oscultations,
                    percussions,
                    observations
                });
            }
            else if(type === "palpation"){
                setPalpations(palpations.filter(ident => {return ident !== val}));
                saveUpdates({
                    motif,
                    allergies,
                    habits,
                    persAnts,
                    famAnts,
                    identities,
                    inspections,
                    palpations: palpations.filter(ident => {return ident !== val}),
                    oscultations,
                    percussions,
                    observations
                });
            }
            else if(type === "oscultation"){
                setOscultations(oscultations.filter(ident => {return ident !== val}));
                saveUpdates({
                    motif,
                    allergies,
                    habits,
                    persAnts,
                    famAnts,
                    identities,
                    inspections,
                    palpations,
                    oscultations: oscultations.filter(ident => {return ident !== val}),
                    percussions,
                    observations
                });
            }
            else if(type === "percussion"){
                setPercussions(percussions.filter(ident => {return ident !== val}));
                saveUpdates({
                    motif,
                    allergies,
                    habits,
                    persAnts,
                    famAnts,
                    identities,
                    inspections,
                    palpations,
                    oscultations,
                    percussions: percussions.filter(ident => {return ident !== val}),
                    observations
                });
            }
            
        }
    }

    const saveUpdates = (data) => {
        let consultation = JSON.parse(localStorage.getItem("consultation"));
        consultation.questions = data;
        localStorage.setItem("consultation", JSON.stringify(consultation));
    }

    useEffect(() => {
        dispatch(getSuggestionsDataAction('allergy'));
        dispatch(getSuggestionsDataAction('habitude'));
        dispatch(getSuggestionsDataAction('antecedent-familial'));
        dispatch(getSuggestionsDataAction('antecedent-personnel'));
        dispatch(getSuggestionsDataAction('identite'));
        dispatch(getSuggestionsDataAction('inspection'));
        dispatch(getSuggestionsDataAction('palpation'));
        dispatch(getSuggestionsDataAction('auscultation'));
        dispatch(getSuggestionsDataAction('percussion'));
    }, []);

    return (
      <>
        <div className='row'>
            <div className='col-xl-6 col-lg-6'>
                <div className='card'>
                    <div className='card-header'>
                        <h4 className='card-title'>{t('consultation.interrogatory')}</h4>
                    </div>
                    <div className='card-body'>
                        <div className='basic-form'>
                            <form onSubmit={(e) => e.preventDefault()}>
                                <div className='form-group'>
                                    <label className='mb-1 '>
                                        <strong>{t('consultation.consultation-reason')}<span className='text-danger'>*</span></strong>
                                    </label>
                                    <input
                                    value={motif}
                                    type='text'
                                    className='form-control input-default '
                                    onChange={e => updateMotif(e.target.value)}
                                    />
                                </div>
                                <div className='form-group'>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <label className='mb-1 '>
                                                <strong>Allergies</strong>
                                            </label>
                                            <div className='d-flex flex-wrap border rounded p-1'>
                                                {allergies.map((allergy, i) => {
                                                    return (
                                                        <Badge variant="dark light mr-1 mb-1" key={i}>
                                                            <span>{allergy}</span>
                                                            <button type="button" className="btn btn-dark shadow btn-xs sharp mx-1"
                                                                onClick={(e) => removeElt(allergy, e, "allergy")}
                                                            >
                                                                <i className="fa fa-close"></i>
                                                            </button>
                                                        </Badge>
                                                    )
                                                })}
                                                <TextInput
                                                    className='form-control custom-text-input'
                                                    Component="input"
                                                    trigger={""}
                                                    value={allerg}
                                                    placeholder={t('consultation.enter-allergies')}
                                                    onChange={setAllerg}
                                                    onKeyDown={(e) => handleKeyDown(e, 'allergy')}
                                                    options={props.allergiesList.map(all => {return all.nameFr})}
                                                />
                                            </div>
                                        </div>
                                        
                                        <div className='col-6'>
                                            <label className='mb-1 '>
                                                <strong>{t('consultation.habits')}</strong>
                                            </label>
                                            <div className='d-flex flex-wrap border rounded p-1'>
                                                {habits.map((habit, i) => {
                                                    return (
                                                        <Badge variant="dark light mr-1 mb-1" key={i}>
                                                            <span>{habit}</span>
                                                            <button type="button" className="btn btn-dark shadow btn-xs sharp mx-1"
                                                                onClick={(e) => removeElt(habit, e, "habit")}
                                                            >
                                                                <i className="fa fa-close"></i>
                                                            </button>
                                                        </Badge>
                                                    )
                                                })}
                                                <TextInput
                                                    className='form-control custom-text-input'
                                                    Component="input"
                                                    trigger={""}
                                                    value={habit}
                                                    placeholder={t('consultation.enter-habits')}
                                                    onChange={setHabit}
                                                    onKeyDown={(e) => handleKeyDown(e, 'habit')}
                                                    options={props.habitudesList.map(all => {return all.nameFr})}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='form-group'>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <label className='mb-1 '>
                                                <strong>{t('consultation.personal-history')}</strong>
                                            </label>
                                            <div className='d-flex flex-wrap border rounded p-1'>
                                                {persAnts.map((persAnt, i) => {
                                                    return (
                                                        <Badge variant="dark light mr-1 mb-1" key={i}>
                                                            <span>{persAnt}</span>
                                                            <button type="button" className="btn btn-dark shadow btn-xs sharp mx-1"
                                                                onClick={(e) => removeElt(persAnt, e, "persAnt")}
                                                            >
                                                                <i className="fa fa-close"></i>
                                                            </button>
                                                        </Badge>
                                                    )
                                                })}
                                                <TextInput
                                                    className='form-control custom-text-input'
                                                    Component="input"
                                                    trigger={""}
                                                    value={persAnt}
                                                    placeholder={t('consultation.enter-history')}
                                                    onChange={setPersAnt}
                                                    onKeyDown={(e) => handleKeyDown(e, 'persAnt')}
                                                    options={props.antecedentsPersList.map(all => {return all.nameFr})}
                                                />
                                            </div>
                                        </div>
                                        
                                        <div className='col-6'>
                                            <label className='mb-1 '>
                                                <strong>{t('consultation.family-history')}</strong>
                                            </label>
                                            <div className='d-flex flex-wrap border rounded p-1'>
                                                {famAnts.map((famAnt, i) => {
                                                    return (
                                                        <Badge variant="dark light mr-1 mb-1" key={i}>
                                                            <span>{famAnt}</span>
                                                            <button type="button" className="btn btn-dark shadow btn-xs sharp mx-1"
                                                                onClick={(e) => removeElt(famAnt, e, "famAnt")}
                                                            >
                                                                <i className="fa fa-close"></i>
                                                            </button>
                                                        </Badge>
                                                    )
                                                })}
                                                <TextInput
                                                    className='form-control custom-text-input'
                                                    Component="input"
                                                    trigger={""}
                                                    value={famAnt}
                                                    placeholder={t('consultation.enter-history')}
                                                    onChange={setFamAnt}
                                                    onKeyDown={(e) => handleKeyDown(e, 'famAnt')}
                                                    options={props.antecedentsFamList.map(all => {return all.nameFr})}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='form-group'>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <label className='mb-1 '>
                                                <strong>Profession</strong>
                                            </label>
                                            <div className='d-flex flex-wrap border rounded p-1'>
                                                {identities.map((identity, i) => {
                                                    return (
                                                        <Badge variant="dark light mr-1 mb-1" key={i}>
                                                            <span>{identity}</span>
                                                            <button type="button" className="btn btn-dark shadow btn-xs sharp mx-1"
                                                                onClick={(e) => removeElt(identity, e, "identity")}
                                                            >
                                                                <i className="fa fa-close"></i>
                                                            </button>
                                                        </Badge>
                                                    )
                                                })}
                                                <TextInput
                                                    className='form-control custom-text-input'
                                                    Component="input"
                                                    trigger={""}
                                                    value={identity}
                                                    placeholder= {t('consultation.enter-identity')}
                                                    onChange={setIdentity}
                                                    onKeyDown={(e) => handleKeyDown(e, 'identity')}
                                                    options={props.identitesList.map(all => {return all.nameFr})}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='form-group'>
                                    <label className='mb-1 '>
                                        <strong>Observations<span className='text-danger'>*</span></strong>
                                    </label>
                                    <textarea
                                        value={observations}
                                        className='form-control'
                                        rows='4'
                                        id='Observations'
                                        onChange={e => updateObservations(e.target.value)}
                                    ></textarea>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            
            <div className='col-xl-6 col-lg-6'>
                <div className='card'>
                    <div className='card-header'>
                        <h4 className='card-title'>{t('consultation.physical-exam')}</h4>
                    </div>
                    <div className='card-body'>
                        <div className='basic-form'>
                            <form onSubmit={(e) => e.preventDefault()}>
                                <div className='form-group'>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <label className='mb-1 '>
                                                <strong>Inspection</strong>
                                            </label>
                                            <div className='d-flex flex-wrap border rounded p-1'>
                                                {inspections.map((inspection, i) => {
                                                    return (
                                                        <Badge variant="dark light mr-1 mb-1" key={i}>
                                                            <span>{inspection}</span>
                                                            <button type="button" className="btn btn-dark shadow btn-xs sharp mx-1"
                                                                onClick={(e) => removeElt(inspection, e, "inspection")}
                                                            >
                                                                <i className="fa fa-close"></i>
                                                            </button>
                                                        </Badge>
                                                    )
                                                })}
                                                <TextInput
                                                    className='form-control custom-text-input'
                                                    Component="textarea"
                                                    trigger={""}
                                                    value={inspection}
                                                    placeholder={t('consultation.enter-allergies')}
                                                    onChange={setInspection}
                                                    onKeyDown={(e) => handleKeyDown(e, 'inspection')}
                                                    options={props.inspectionsList.map(all => {return all.nameFr})}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='form-group'>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <label className='mb-1 '>
                                                <strong>Palpation</strong>
                                            </label>
                                            <div className='d-flex flex-wrap border rounded p-1'>
                                                {palpations.map((palpation, i) => {
                                                    return (
                                                        <Badge variant="dark light mr-1 mb-1" key={i}>
                                                            <span>{palpation}</span>
                                                            <button type="button" className="btn btn-dark shadow btn-xs sharp mx-1"
                                                                onClick={(e) => removeElt(palpation, e, "palpation")}
                                                            >
                                                                <i className="fa fa-close"></i>
                                                            </button>
                                                        </Badge>
                                                    )
                                                })}
                                                <TextInput
                                                    className='form-control custom-text-input'
                                                    Component="textarea"
                                                    trigger={""}
                                                    value={palpation}
                                                    placeholder={t('consultation.enter-history')}
                                                    onChange={setPalpation}
                                                    onKeyDown={(e) => handleKeyDown(e, 'palpation')}
                                                    options={props.palpationsList.map(all => {return all.nameFr})}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='form-group'>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <label className='mb-1 '>
                                                <strong>Auscultation</strong>
                                            </label>
                                            <div className='d-flex flex-wrap border rounded p-1'>
                                                {oscultations.map((oscultation, i) => {
                                                    return (
                                                        <Badge variant="dark light mr-1 mb-1" key={i}>
                                                            <span>{oscultation}</span>
                                                            <button type="button" className="btn btn-dark shadow btn-xs sharp mx-1"
                                                                onClick={(e) => removeElt(oscultation, e, "oscultation")}
                                                            >
                                                                <i className="fa fa-close"></i>
                                                            </button>
                                                        </Badge>
                                                    )
                                                })}
                                                <TextInput
                                                    className='form-control custom-text-input'
                                                    Component="textarea"
                                                    cols="4"
                                                    trigger={""}
                                                    value={oscultation}
                                                    placeholder={t('consultation.enter-identity')}
                                                    onChange={setOscultation}
                                                    onKeyDown={(e) => handleKeyDown(e, 'oscultation')}
                                                    options={props.auscultationsList.map(all => {return all.nameFr})}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='form-group'>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <label className='mb-1 '>
                                                <strong>Percussion</strong>
                                            </label>
                                            <div className='d-flex flex-wrap border rounded p-1'>
                                                {percussions.map((percussion, i) => {
                                                    return (
                                                        <Badge variant="dark light mr-1 mb-1" key={i}>
                                                            <span>{percussion}</span>
                                                            <button type="button" className="btn btn-dark shadow btn-xs sharp mx-1"
                                                                onClick={(e) => removeElt(percussion, e, "percussion")}
                                                            >
                                                                <i className="fa fa-close"></i>
                                                            </button>
                                                        </Badge>
                                                    )
                                                })}
                                                <TextInput
                                                    className='form-control custom-text-input'
                                                    Component="textarea"
                                                    cols="4"
                                                    trigger={""}
                                                    value={percussion}
                                                    placeholder={t('consultation.enter-identity')}
                                                    onChange={setPercussion}
                                                    onKeyDown={(e) => handleKeyDown(e, 'percussion')}
                                                    options={props.percussionsList.map(all => {return all.nameFr})}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </>
    )
}

const mapStateToProps = (state) => {
    return {
        allergiesList: state.consultation.allergies,
        habitudesList: state.consultation.habitudes,
        antecedentsFamList: state.consultation.antecedentsFam,
        antecedentsPersList: state.consultation.antecedentsPers,
        identitesList: state.consultation.identites,
        inspectionsList: state.consultation.inspections,
        palpationsList: state.consultation.palpations,
        auscultationsList: state.consultation.auscultations,
        percussionsList: state.consultation.percussions,
    };
};

export default connect(mapStateToProps)(ConsultationForm);
