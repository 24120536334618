import axios from 'axios';
const headers = { 'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('userDetails'))?.token, 'Accept-Language': localStorage.getItem('currentLanguage')};

const FacturationServiceRequest = axios.create({
  baseURL: process.env.REACT_APP_BASEURL_CONSULTATION_BACKEND,
  headers: headers
});

const ParamServiceRequest = axios.create({
  baseURL: process.env.REACT_APP_BASEURL_ADMIN_BACKEND,
  headers: headers 
});

export const getFactures = (providerId) => FacturationServiceRequest.get('/factures/prestataire/impaye/'+providerId);
export const getFacture = (id) => FacturationServiceRequest.get('/factures/'+id);
//export const getBordereauDetail = (id) => FacturationServiceRequest.get('/facture/borderaux/'+id);
export const getBordereauDetail = (id) => FacturationServiceRequest.get('/facture/bordereau/'+id);
export const requestPayment = (providerId) => FacturationServiceRequest.get('/factures/prestataire/'+providerId+'/close');

export const getFactureImpayes = (providerId, dateDebut, dateFin) => FacturationServiceRequest.get('/facture/provider/'+providerId, {
  params: {
    dateDebut,
    dateFin
  }
});
export const getBodereauxPrestataires = (providerId) => FacturationServiceRequest.get('/bordereau/provider/'+providerId);
export const createBordereau = (data) => FacturationServiceRequest.post('/facture/bordereau', data);
export const submitBordereau = (id) => FacturationServiceRequest.put('/bordereau/'+id+'/send-to-reglement');
export const validateBordereau = (id) => FacturationServiceRequest.put('/bordereau/'+id+'/send-to-paiement');
export const paidBordereau = (providerId) => FacturationServiceRequest.get('/bordereau/paye', {
  params: {
    providerId
  }
});
//export const unpaidBordereau = (providerId) => FacturationServiceRequest.get('/bordereau/provider/'+providerId);
export const unpaidBordereau = (providerId) => FacturationServiceRequest.get('/bordereau/ouvert', {
  params: {
    providerId
  }
});
export const onTreatmentBordereau = (providerId) => FacturationServiceRequest.get('/bordereau/en-reglement', {
  params: {
    providerId
  }
});
export const addFactureBordereau = (id, data) => FacturationServiceRequest.post('/bordereau/'+id+'/add-facture', data);
export const removeFactureBordereau = (id, data) => FacturationServiceRequest.post('/bordereau/'+id+'/remove-facture', data);

export const calculationRembBase = (providerId, patientId, acteId) => ParamServiceRequest.get('/parameter/Tarif/tarif-acte/'+providerId+'/'+patientId+'/'+acteId);
export const calculationRembBaseHospi = (providerId, patientId, acteId, montant) => ParamServiceRequest.get('/parameter/Tarif/base-remboursement/'+providerId+'/'+patientId+'/'+acteId+'/'+montant);
export const calculationTM = (patientId) => ParamServiceRequest.get('/parameter/Tarif/pharmacie/tm/'+patientId);
