import {
    formatError,
    verify,
    getClientData,
    IdentifyAssure,
    validateCode
} from '../../services/PatientService';
import { errorToastr, successToastr } from '../../Utils/ToastrMessage';

export const IDENTITY_CONFIRMED_ACTION = '[Verify action] confirmed identity';
export const IDENTIFY_FAILED_ACTION = '[Failed action] failed identity';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';

var I18n = require('react-redux-i18n').I18n;

export function identificationAction(img, history) {
    return (dispatch) => {
        dispatch(loadingToggleAction(true));
        verify(img)
        .then((response) => {
            let data = response.data;
            successToastr(I18n.t('patient.identification.success_one'));

            getClientData(data.username)
            .then((response) => {
                let clientData = response.data;
                clientData["img"] = img;
                successToastr(I18n.t('patient.identification.success_two'));
                
                dispatch(confirmedIdentifyAction(clientData));
                history.push('/patient-details');
            })
            .catch((error) => {
                console.log(error);
                errorToastr(I18n.t('patient.identification.error_one')+error.response?.data?.message);
                dispatch(identificationFailedAction(error.response?.data?.message));
            });
        })
        .catch((error) => {
            console.log(JSON.stringify(error));
            console.log(error.response?.data?.message);
            errorToastr(I18n.t('patient.identification.error_two')+error.response?.data?.message);
            dispatch(identificationFailedAction(error.response?.data?.message));
        });
    };
}

export function identificationAssuAction(data, cardIdent, history) {
    return (dispatch) => {
        dispatch(loadingToggleAction(true));
        if(cardIdent) {
            getClientData(data.id)
            .then((response) => {
                let clientData = response.data;
                successToastr(I18n.t('patient.identification.success_two'));
                
                dispatch(confirmedIdentifyCodeAssuAction({...clientData, from_card: cardIdent}));
                history.push('/patient-details');
            })
            .catch((error) => {
                errorToastr(I18n.t('patient.identification.error_one')+error.response?.data?.message);
                dispatch(identificationCodeAssuFailedAction(error.response?.data?.message));
            });
        }else{
            IdentifyAssure(data)
            .then((response) => {
                let resp = response.data;
                dispatch(confirmedIdentificationAssuAction(resp))
            })
            .catch((error) => {
                errorToastr("Echec d'identification du patient: "+error.response?.data?.message);
                dispatch(identificationAssuFailedAction(error.response?.data?.message));
            });
        }
    };
}

export function identificationCodeAssuAction(data, history) {
    return (dispatch) => {
        dispatch(loadingToggleAction(true));
        validateCode(data)
        .then((response) => {
            successToastr(I18n.t('patient.identification.success_one'));

            getClientData(data.clientId)
            .then((response) => {
                let clientData = response.data;
                successToastr(I18n.t('patient.identification.success_two'));
                
                dispatch(confirmedIdentifyCodeAssuAction({...clientData, from_card: false}));
                history.push('/patient-details');
            })
            .catch((error) => {
                errorToastr(I18n.t('patient.identification.error_one')+error.response?.data?.message);
                dispatch(identificationCodeAssuFailedAction(error.response?.data?.message));
            });
        })
        .catch((error) => {
            errorToastr(I18n.t('patient.identification.error_two')+error.response?.data?.message);
            dispatch(identificationCodeAssuFailedAction(error.response?.data?.message));
        });
    };
}

export function identificationCodeAssuFailedAction(data) {
    return {
        type: "IDENTIFY_CODE_ASSU_FAILED_ACTION",
        payload: data,
    };
}

export function confirmedIdentifyCodeAssuAction(data) {
    return {
        type: "IDENTITY_CODE_ASSU_CONFIRMED_ACTION",
        payload: data,
    };
}

export function identificationAssuFailedAction(data) {
    return {
        type: "IDENTIFY_ASSU_FAILED_ACTION",
        payload: data,
    };
}

export function confirmedIdentificationAssuAction(data) {
    return {
        type: "IDENTITY_ASSU_CONFIRMED_ACTION",
        payload: data,
    };
}


export function identificationFailedAction(data) {
    return {
        type: "IDENTIFY_FAILED_ACTION",
        payload: data,
    };
}

export function confirmedIdentifyAction(data) {
    return {
        type: "IDENTITY_CONFIRMED_ACTION",
        payload: data,
    };
}

export function reInitAuthAction() {
    return {
        type: "RE_INIT_STATE_ACTION"
    };
}

export function loadingToggleAction(status) {
    return {
        type: "LOADING_TOGGLE_ACTION",
        payload: status,
    };
}

export function resendSMSTriggerAction() {
    return {
        type: "RESEND_SMS_TRIGGER_ACTION"
    };
}
