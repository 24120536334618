import {
    getAllAccordRequest,
    validateAccord,
    sendAccordMessage,
    getAccordDetails,
    rejectAccord
} from '../../services/AccordPreaService';
import blobFileUpload from '../../services/BlobStorageService';
import { errorToastr, successToastr } from '../../Utils/ToastrMessage';

export const LIST_CONFIRMED_ACTION = '[listAccords action] confirmed listAccords';
export const LIST_FAILED_ACTION = '[listAccords action] failed listAccords';


export const VALIDATE_ACCORD_CONFIRMED_ACTION = '[validateAccord action] confirmed validateAccord';
export const VALIDATE_ACCORD_FAILED_ACTION = '[validateAccord action] failed validateAccord';


export const REJECT_ACCORD_CONFIRMED_ACTION = '[rejectAccord action] confirmed rejectAccord';
export const REJECT_ACCORD_FAILED_ACTION = '[rejectAccord action] failed rejectAccord';


export const SEND_MESSAGE_CONFIRMED_ACTION = '[sendMessage action] confirmed sendMessage';
export const SEND_MESSAGE_NOTIFS_FAILED_ACTION = '[sendMessage action] failed sendMessage';


export const GET_ACCORD_CONFIRMED_ACTION = '[getAccord action] confirmed getAccord';
export const GET_ACCORD_NOTIFS_FAILED_ACTION = '[getAccord action] failed getAccord';

export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';

var I18n = require('react-redux-i18n').I18n;

export function listAllAccordRequestAction() {
    return (dispatch) => {
        getAllAccordRequest()
        .then((response) => {
            let data = response.data;
            dispatch(confirmedListAction(data));
        })
        .catch((error) => {
            errorToastr(I18n.t('accord.list.error')+error.response?.data?.message);
            dispatch(listFailedAction(error.response?.data?.message));
        });
    };
}

export function validateAccordAction(data, history) {
    return (dispatch) => {
        validateAccord(data)
        .then((response) => {
            let data = response.data;
            dispatch(confirmedAccordValidateAction(data));
            history.push("/accords-list")
            successToastr(I18n.t('accord.validate.success'));
        })
        .catch((error) => {
            errorToastr(I18n.t('accord.validate.error')+error.response?.data?.message);
            dispatch(AccordValidationFailedAction(error.response?.data?.message));
        });
    };
}

export function rejectAccordAction(accordId, data, history) {
    return (dispatch) => {
        rejectAccord(accordId, data)
        .then((response) => {
            let data = response.data;
            dispatch(confirmedAccordRejectionAction(data));
            history.push("/accords-list");
            successToastr(I18n.t('accord.reject.success'));
        })
        .catch((error) => {
            errorToastr(I18n.t('accord.reject.error')+error.response?.data?.message);
            dispatch(AccordRejectionFailedAction(error.response?.data?.message));
        });
    };
}

export function sendAccordMessageAction(data, history) {
    return async (dispatch) => {
        if (data.fileSrc) {
            let resImg = await blobFileUpload([data.fileSrc], 'amp-providers-container');
            data['docUrl'] = resImg[0];
        }

        sendAccordMessage(data)
        .then((response) => {
            let data = response.data;
            dispatch(confirmedSendAccordMessageAction(data));
            //history.push("/consultation-list")
            successToastr(I18n.t('accord.sendMessage.success'));
        })
        .catch((error) => {
            errorToastr(I18n.t('accord.sendMessage.error')+error.response?.data?.message);
            dispatch(SendAccordMessagelFailedAction(error.response?.data?.message));
        });
    };
}

export function getAccordAction(accordId) {
    return (dispatch) => {
        getAccordDetails(accordId)
        .then((response) => {
            let data = response.data;
            dispatch(confirmedGetAccordAction(data));
        })
        .catch((error) => {
            errorToastr(I18n.t('accord.sendMessage.error')+error.response?.data?.message);
            dispatch(getAccordFailedAction(error.response?.data?.message));
        });
    };
}


export function confirmedListAction(data) {
    return {
        type: LIST_CONFIRMED_ACTION,
        payload: data,
    };
}

export function listFailedAction(data) {
    return {
        type: LIST_FAILED_ACTION,
        payload: data,
    };
}

export function confirmedAccordValidateAction(data) {
    return {
        type: VALIDATE_ACCORD_CONFIRMED_ACTION,
        payload: data,
    };
}

export function AccordValidationFailedAction(data) {
    return {
        type: VALIDATE_ACCORD_FAILED_ACTION,
        payload: data,
    };
}

export function confirmedAccordRejectionAction(data) {
    return {
        type: REJECT_ACCORD_CONFIRMED_ACTION,
        payload: data,
    };
}

export function AccordRejectionFailedAction(data) {
    return {
        type: REJECT_ACCORD_FAILED_ACTION,
        payload: data,
    };
}

export function confirmedSendAccordMessageAction(data) {
    return {
        type: SEND_MESSAGE_CONFIRMED_ACTION,
        payload: data,
    };
}

export function SendAccordMessagelFailedAction(data) {
    return {
        type: SEND_MESSAGE_NOTIFS_FAILED_ACTION,
        payload: data,
    };
}

export function confirmedGetAccordAction(data) {
    return {
        type: GET_ACCORD_CONFIRMED_ACTION,
        payload: data,
    };
}

export function getAccordFailedAction(data) {
    return {
        type: GET_ACCORD_NOTIFS_FAILED_ACTION,
        payload: data,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}
