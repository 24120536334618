
const initialData = {
	administration : {},
    errorMessage: '',
    successMessage: '',
    showLoading: false,
}

export function AdministrationReducer(state = initialData, action) {
    if (action.type === "ADD_CONFIRMED_ACTION") {
        return {
            ...state,
            errorMessage: '',
            successMessage: 'Enregistrement réussi!',
            showLoading: false,
        };
    }
    if (action.type === "GET_CONFIRMED_ACTION") {
        return {
            ...state,
            administration: action.payload,
            errorMessage: '',
            successMessage: 'Enregistrement réussi!',
            showLoading: false,
        };
    }
    if (action.type === "ADD_FAILED_ACTION") {
        return {
            ...state,
            administration:{},
            errorMessage: 'Une erreur est survenu',
            successMessage: '',
            showLoading: false,
        };
    }
    if (action.type === "GET_FAILED_ACTION") {
        return {
            ...state,
            errorMessage: 'Une erreur est survenu',
            successMessage: '',
            showLoading: false,
        };
    }
    if (action.type === "LOADING_TOGGLE_ACTION") {
        let data = {
            ...state,
            showLoading: action.payload,
        };
        console.log(data)
        return data;
    }
    return state;
}

    
