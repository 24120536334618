import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Col, Row } from "react-bootstrap";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import Alert from "sweetalert2";

class AppointmentsCalendar extends Component {
   state = {
      calendarEvents: [
         {
            title: "Atlanta Monster",
            start: new Date("2022-12-04 00:00"),
            id: "99999998",
         },
         {
            title: "My Favorite Murder",
            start: new Date("2022-12-05 00:00"),
            id: "99999999",
         },
      ],
   };

   eventClick = (eventClick) => {
      Alert.fire({
         title: 'Rendez-vous',
         html:
            `<div className="table-responsive">
            <table className="table">
            <tbody>
            <tr >
            <td>Motif</td>
            <td><strong>` +
                  eventClick.event.title +
                  `</strong></td>
            </tr>
            <tr >
            <td>Heure</td>
            <td><strong>
            ` +
                  eventClick.event.start +
                  `
            </strong></td>
            </tr>
            </tbody>
            </table>
            </div>`,

         showCancelButton: true,
         confirmButtonColor: "#d33",
         cancelButtonColor: "#3085d6",
         cancelButtonText: "Close",
      }).then((result) => {
         if (result.value) {
            eventClick.event.remove(); // It will remove event from the calendar
            Alert.fire("Deleted!", "Your Event has been deleted.", "success");
         }
      });
   };


   render() {
      return (
         <div className="animated fadeIn p-4 demo-app" style={{width: "100%"}}>
            <div className="demo-app-calendar p-5" id="mycalendartest">
               <FullCalendar
                  initialView="timeGridDay"
                  headerToolbar={{
                     left: "prev,next today",
                     center: "title",
                     right: "dayGridMonth,timeGridWeek,timeGridDay",
                  }}
                  rerenderDelay={10}
                  eventDurationEditable={false}
                  editable={true}
                  droppable={true}
                  plugins={[
                     dayGridPlugin,
                     timeGridPlugin,
                     interactionPlugin,
                  ]}
                  ref={this.calendarComponentRef}
                  weekends={this.state.calendarWeekends}
                  events={this.props.appointments.map(appointment => {
                     return {
                        title: "Consultation avec M./Mme "+appointment.patientName+" pour"+appointment.motifRdv,
                        start: new Date(appointment.dateRdv),
                        id: appointment.id,
                     }
                  })}
                  eventDrop={this.drop}
                  // drop={this.drop}
                  eventReceive={this.eventReceive}
                  eventClick={this.eventClick}
                  // selectable={true}
               />
            </div>
         </div>
      );
   }
}

export default AppointmentsCalendar;
