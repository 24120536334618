import { paidBordereau, unpaidBordereau } from '../../services/FacturationService';
import {
    START_LOADER_ACTION,
    GET_FACTURES_CONFIRMED_ACTION,
    GET_FACTURES_FAILED_ACTION,
    GET_FACTURE_CONFIRMED_ACTION,
    GET_FACTURE_FAILED_ACTION,
    REQUEST_PAYMENT_CONFIRMED_ACTION,
    REQUEST_PAYMENT_FAILED_ACTION,
    CALCULATE_REMB_BASE_CONFIRMED_ACTION,
    CALCULATE_REMB_BASE_FAILED_ACTION,
    CALCULATE_TM_CONFIRMED_ACTION,
    CALCULATE_TM_FAILED_ACTION,
    GET_FACTURES_IMPAYES_CONFIRMED_ACTION,
    GET_BORDEREAUX_CONFIRMED_ACTION,
    PAID_BORDEREAU_CONFIRMED_ACTION,
    UNPAID_BORDEREAU_CONFIRMED_ACTION,
    ON_TREATMENT_BORDEREAU_CONFIRMED_ACTION
} from '../actions/FacturationAction';

const initialState = {
    factures: [],
    facture: {},
    factureImpayees: [],
    bordereaux: [],
    paidBordereaux: [],
    unpaidBordereaux: [],
    onTreatmentBordereaux: [],
    rembCalculDatas: [],
    ticketModDatas: null,
    errorMessage: '',
    successMessage: '',
    showLoading: false,
    dateDebut: new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1),
    dateFin: new Date()
};

export const FacturationReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_FACTURES_CONFIRMED_ACTION:
            return {
                ...state,
                factures: action.payload,
                errorMessage: '',
                successMessage: 'Factures gotten Successfully Completed',
                showLoading: false,
            };
        case GET_FACTURES_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case GET_FACTURE_CONFIRMED_ACTION:
            return {
                ...state,
                facture: action.payload,
                errorMessage: '',
                successMessage: 'Facture gotten Successfully Completed',
                showLoading: false,
            };
        case GET_FACTURE_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        
        case REQUEST_PAYMENT_CONFIRMED_ACTION:
            return {
                ...state,
                errorMessage: '',
                successMessage: 'Facture Request Successfully Completed',
                showLoading: false,
            };
        case REQUEST_PAYMENT_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case CALCULATE_REMB_BASE_CONFIRMED_ACTION:
            return {
                ...state,
                rembCalculDatas: state.rembCalculDatas.find(elt => {return elt.index === action.payload.index}) ? state.rembCalculDatas.map((elt, index) => {
                    if(index === action.payload.index) {
                        elt.data = action.payload.data
                    }
                    return elt;
                }) : [...state.rembCalculDatas, action.payload],
                errorMessage: '',
                successMessage: 'Calculation Successfully Completed',
                showLoading: false,
            };
        case CALCULATE_REMB_BASE_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case CALCULATE_TM_CONFIRMED_ACTION:
            return {
                ...state,
                ticketModDatas: action.payload,
                errorMessage: '',
                successMessage: 'Tickets Successfully Completed',
                showLoading: false,
            };
        case CALCULATE_TM_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case GET_FACTURES_IMPAYES_CONFIRMED_ACTION:
            return {
                ...state,
                factureImpayees: action.payload.data,
                dateDebut: action.payload.dateDebut,
                dateFin: action.payload.dateFin,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case GET_BORDEREAUX_CONFIRMED_ACTION:
            return {
                ...state,
                bordereaux: action.payload,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case PAID_BORDEREAU_CONFIRMED_ACTION:
            return {
                ...state,
                paidBordereaux: action.payload,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case UNPAID_BORDEREAU_CONFIRMED_ACTION:
            return {
                ...state,
                unpaidBordereaux: action.payload,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case ON_TREATMENT_BORDEREAU_CONFIRMED_ACTION:
            return {
                ...state,
                onTreatmentBordereaux: action.payload,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case START_LOADER_ACTION:
            return {
                ...state,
                showLoading: true,
            };
        default:
            return state;
    };
};