import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch } from 'react-redux';
import { getAppointmentsAction, startLoader } from "../../../../store/actions/ConsultationActions";
import { currentUser } from "../../../../store/selectors/CurrentUserSelector";
import { Link } from "react-router-dom";
import {
  Dropdown
} from 'react-bootstrap'
import AppointmentsCalendar from "./AppointmentsCalendar";

const AppointmentView = (props) => {

   const dispatch = useDispatch();

   useEffect(() => {
      console.log("About to call all the providers");
      dispatch(startLoader());
      dispatch(getAppointmentsAction(props.currentUser.id, 'doctor'));
  }, []); 

   return (
      <>
      { props.showLoading && 
      <div id="preloader">
            <div className="sk-three-bounce">
               <div className="sk-child sk-bounce1"></div>
               <div className="sk-child sk-bounce2"></div>
               <div className="sk-child sk-bounce3"></div>
            </div>
      </div> }
      { !props.showLoading && 
      <div className="h-100 bg-white">
         <div className="row">
            <AppointmentsCalendar appointments={props.appointments} />
         </div>
      </div>}
      </>
   );
};

const mapStateToProps = (state) => {
    return {
        appointments: state.consultation.appointments,
        showLoading: state.consultation.showLoading,
        currentUser: currentUser(state)
    };
};

export default connect(mapStateToProps)(AppointmentView);