import axios from 'axios';
const headers = { 'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('userDetails'))?.token, 'Accept-Language': localStorage.getItem('currentLanguage')};

const consultationServiceRequest = axios.create({
  baseURL: process.env.REACT_APP_BASEURL_CONSULTATION_BACKEND,
  headers: headers
});

const parameterServiceRequest = axios.create({
  baseURL: process.env.REACT_APP_BASEURL_ADMIN_BACKEND,
  headers: headers
});

export const getConsultations = (providerId) => consultationServiceRequest.get('/consultations/bon/'+providerId);
export const getConsultation = (id) => consultationServiceRequest.get('/consultation/'+id);
export const getConsultationByCode = (code) => consultationServiceRequest.get('/all-bon/consultation/bon/'+code);
export const requestConsultation = (data) => consultationServiceRequest.post('/consultation/bon', data);
export const updateConsultation = (data) => consultationServiceRequest.post('/consultation', data);
export const searchDrugs = (keyword) => consultationServiceRequest.get('/medicament/search?keyword='+keyword);
export const searchDiseases = (keyword) => consultationServiceRequest.get('/affection/search?keyword='+keyword);
export const getSuggestion = (type) => consultationServiceRequest.get('/dictionaires/'+type);
export const getClientConsultations = (id) => consultationServiceRequest.get('/consultations/beneficiary/'+id);
export const getClientMedicalFile = (id) => consultationServiceRequest.get('/dossier/beneficiary/'+id);
export const getParameterHistory = (id, startDate, endDate) => consultationServiceRequest.get('/dossier/healht-parameter/'+id+'/'+startDate+'/'+endDate);
export const updateParameters = (id, data) => consultationServiceRequest.put('/consultation/bon/'+id, data);
export const getActesConsultation = () => parameterServiceRequest.get('/Parameter/acte/consultation');
export const getActesHospitalisation = (providerId) => parameterServiceRequest.get('/parameter/Tarif/provider/'+providerId+'/tarif-chambre');
export const addActeSpecDetail = (data) => consultationServiceRequest.post('/ActeSpecialise/acte-specialise/detail', data);
export const addExamenDetail = (data) => consultationServiceRequest.post('/Examen/detail', data);
export const addPharmacieDetail = (data) => consultationServiceRequest.post('/Pharmacie/pharmacie-bon/detail', data);
export const addHospitalisationDetail = (data) => consultationServiceRequest.post('/Hospitalisation/Hospitalisation-bon/detail', data);
export const requestProlongHospi = (id, data, extra) => consultationServiceRequest.post('/Hospitalisation/Hospitalisation-bon/'+id+'/add-day/'+data+'?MotifClinique='+extra);
export const changeHospiRoom = (data) => consultationServiceRequest.post('/Hospitalisation/Hospitalisation-bon/change-room', data);
export const endHospitalisation = (data) => consultationServiceRequest.put('/Hospitalisation/Hospitalisation-bon/close/', data);
export const getActByType = (type) => parameterServiceRequest.get('/Parameter/Acte/type/'+type);
export const getOptiqueParams = (type) => parameterServiceRequest.get('/Parameter/dictionaires/'+type);

//appointment endpoints
export const requestAppointment = (id, data) => consultationServiceRequest.put('/RendezVous/'+id, data);
export const listAppointments = (id) => consultationServiceRequest.get('/RendezVous/provider/'+id);
export const listDoctorAppointments = (id) => consultationServiceRequest.get('/RendezVous/doctor/'+id);
export const getAppointment = (id) => consultationServiceRequest.get('/RendezVous/'+id);
