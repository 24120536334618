import axios from 'axios';
const headers = { 'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('userDetails'))?.token, 'Accept-Language': localStorage.getItem('currentLanguage')};

const consultationServiceRequest = axios.create({
  baseURL: process.env.REACT_APP_BASEURL_CONSULTATION_BACKEND,
  headers: headers
});

export const getBonByCode = (code, providerId) => consultationServiceRequest.get('/Kine/bon/'+code+'/'+providerId);
export const sendProforma = (data) => consultationServiceRequest.post('/Kine/send-proformat/bon', data);
export const executeBon = (data) => consultationServiceRequest.post('/Kine/provider/execute-seance', data);
export const getBonDataByCode = (code, providerId) => consultationServiceRequest.get('/Kine/'+code+'/'+providerId+'/seance');
export const addActeBonKine = (data) => consultationServiceRequest.post('/Kine/detail', data);