import React, { useState } from "react";
import { connect, useDispatch } from 'react-redux';
import { startLoader, endHospitalisationAction } from '../../../../store/actions/ConsultationActions';
import { Button, Modal} from "react-bootstrap";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

export const EndHospiModal = (props) => {
    const dispatch = useDispatch();
    const {bonId, manageEndHospiModal, clientName, dateEntree } = props;
    const [error, setError] = useState(null);
    const [dateFin, setDateFin] = useState(new Date());

    

    const submitHospiEndDate = () => {
        if(!dateFin) {
            setError("Vous devez choisir une date");
            return;
        }

        const data = {
            bonId: bonId,
            date: dateFin
        };

        dispatch(startLoader());
        dispatch(endHospitalisationAction(data));
        manageEndHospiModal(false, true);
    }


    return (
            <>
                    <Modal.Header>
                        <Modal.Title className="text-center" >
                                 Clôturer l'hospitalisation{' '}<br/>
                                <b className="text-primary" >{clientName}</b>
                        </Modal.Title>
                        <Button
                            variant=""
                            className="close"
                            onClick={() => manageEndHospiModal(false)}
                        >
                            <span>&times;</span>
                        </Button>
                    </Modal.Header>
                    <Modal.Body>
                        
                        { error && <small className="text-danger">{{error}}</small> }

                        <form onSubmit={(e) => e.preventDefault()}>
                            <div className='form-group col-12'>
                                <label className='mb-1 '>
                                    <strong>Date de sortie du malade</strong>
                                </label>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        autoOk
                                        label=""
                                        clearable
                                        minDate={new Date(dateEntree)}
                                        format="dd/MM/yyyy"
                                        disableFuture
                                        value={dateFin}
                                        onChange={setDateFin}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        </form>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="danger light"
                            onClick={() => manageEndHospiModal(false, false)}
                        >
                            Annuler
                        </Button>
                        <Button
                            variant=""
                            type="button"
                            className="btn btn-primary"
                            //disabled={!dateFin}
                            onClick={() => submitHospiEndDate()}
                        >
                            Soumettre
                        </Button>
                    </Modal.Footer>
            </>
    )
};


export default EndHospiModal;