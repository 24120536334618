import {
    formatError,
    getDateError,
    addActivite,
    addCity,
    addCountry,
    addExclusion,
    addProfession,
    saveSuccess,
    getParameter
} from '../../services/AdministrationService';


export function addActiviteAction(data) {
    return (dispatch) => {
        dispatch(loadingToggleAction(true));
        addActivite(data)
        .then((response) => {
            dispatch(confirmedAddAction(response.data));
            saveSuccess()
        })
        .catch((error) => {
            const errorMessage = formatError(error);
            dispatch(addFailedAction(errorMessage));
        });
    };
}
export function addCityAction(data) {
    return (dispatch) => {
        dispatch(loadingToggleAction(true));
        addCity(data)
        .then((response) => {
            dispatch(confirmedAddAction(response.data));
            saveSuccess()
        })
        .catch((error) => {
            const errorMessage = formatError(error);
            dispatch(addFailedAction(errorMessage));
        });
    };
}
export function addCountryAction(data) {
    return (dispatch) => {
        dispatch(loadingToggleAction(true));
        addCountry(data)
        .then((response) => {
            dispatch(confirmedAddAction(response.data));
            saveSuccess()
        })
        .catch((error) => {
            const errorMessage = formatError(error);
            dispatch(addFailedAction(errorMessage));
        });
    };
}
export function addExclusionAction(data) {
    return (dispatch) => {
        dispatch(loadingToggleAction(true));
        addExclusion(data)
        .then((response) => {
            dispatch(confirmedAddAction(response.data));
            saveSuccess()
        })
        .catch((error) => {
            const errorMessage = formatError(error);
            dispatch(addFailedAction(errorMessage));
        });
    };
}
export function addProfessionAction(data) {
    return (dispatch) => {
        dispatch(loadingToggleAction(true));
        addProfession(data)
        .then((response) => {
            dispatch(confirmedAddAction(response.data));
            saveSuccess()
        })
        .catch((error) => {
            const errorMessage = formatError(error);
            dispatch(getFailedAction(errorMessage));
        });
    };
}

export function getParameterAction() {
    return (dispatch) => {
        dispatch(loadingToggleAction(true));
        getParameter()
        .then((response) => {
            dispatch(confirmedGetAction(response.data));
        })
        .catch((error) => {
            const errorMessage = getDateError(error);
            dispatch(getFailedAction(errorMessage));
        });
    };
}

export function addFailedAction(data) {
    return {
        type: "ADD_FAILED_ACTION",
        payload: data,
    };
}
export function getFailedAction(data) {
    return {
        type: "GET_FAILED_ACTION",
        payload: data,
    };
}
export function confirmedAddAction(data) {
    return {
        type: "ADD_CONFIRMED_ACTION",
        payload: data,
    };
}
export function confirmedGetAction(data) {
    return {
        type: "GET_CONFIRMED_ACTION",
        payload: data,
    };
}
export function loadingToggleAction(status) {
    return {
        type: "LOADING_TOGGLE_ACTION",
        payload: status,
    };
}
