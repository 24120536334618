import React, { useState, useEffect, useRef, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect, useDispatch } from 'react-redux';
import { getSubstitutionRequestsHistoryAction, startLoader } from "../../../store/actions/PharmacieActions";
import { listAllAccordRequestAction, loadingToggleAction, sendAccordMessageAction } from "../../../store/actions/AccordPreaActions";
import { currentUser } from "../../../store/selectors/CurrentUserSelector";
import { useTranslation } from "react-i18next";
import { DataTable } from 'primereact/datatable';
import { STATUSES } from '../../globals';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';

const Dashboard = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {subsRequestsHistory, accordPreas, showLoading, currentUser} = props;
 
    /*useEffect(() => {
        if(hasMenu('MENPHARM') && canDo('MENPHARM', 'ACVIEWPHARMBON')){
            dispatch(startLoader());
            dispatch(getSubstitutionRequestsHistoryAction(currentUser.provider.id));
            dispatch(loadingToggleAction());
            dispatch(listAllAccordRequestAction());
        }else{
            props.history.push('/access-denied');
        }
    }, []); */

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
          return false;
        }else{
          return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
    }
    
    const hasMenu = (menuCode) => {
        return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
    }

    const dateBodyTemplate = (rowData) => {
        return formatDate(rowData.createdAt);
    };

    const statusBodyTemplate = (rowData) => {
        return <Tag value={t('statuses.'+rowData.status)} severity={getSeverity(rowData.status)} />;
    };

    const getSeverity = (status) => {
        switch (status) {
            case STATUSES.exectuted:
                return 'success';

            case STATUSES.rejeted:
                return 'success';

            default:
                return 'warning';
        }
    };

    const formatDate = (value) => {
        return value ? value.toLocaleDateString('fr-FR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        }) : '';
    };

    return (
        <div>
            <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
                <div className="mr-auto d-none d-lg-block">
                    <h3 className="text-black font-w600" >{t('dashboard.welcome')}</h3>
                    <p className="mb-0 fs-18">Activa Care</p>
                </div>
                <div className="input-group search-area ml-auto d-inline-flex">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Search here"
                    />
                    <div className="input-group-append">
                        <button type="button" className="input-group-text">
                            <i className="flaticon-381-search-2" />
                        </button>
                    </div>
                </div>
                <Link to="/" className="settings-icon  ml-3">
                    <i className="flaticon-381-settings-2 mr-0" />
                </Link>
            </div>

            <div className="col-xl-12 col-xxl-12 col-lg-12">
                <div className="card">
                    <div className="card-header border-0 pb-0">
                        <h3 className="fs-20 mb-0 text-black">
                            Demandes de substitution
                        </h3>
                    </div>

                    <div className="card-body">
                        <div className="assigned-doctor owl-carousel">
                            <DataTable 
                                value={subsRequestsHistory?.map(bon => {
                                    bon.createdAt = new Date(bon.createdAt);
                                    return bon;
                                })} 
                                showGridlines 
                                tableStyle={{ minWidth: '50rem' }}
                            >
                                <Column field="oldMedicament.name" header="Médicament Prescrit" style={{ minWidth: '200px' }}></Column>
                                <Column field="newMedicament.name" header="Substitut proposé" style={{ minWidth: '200px' }}></Column>
                                <Column field="status" header="Statut de la demande" style={{ minWidth: '150px' }} body={statusBodyTemplate}></Column>
                                <Column field="createdAt" header="Date" style={{ minWidth: '150px' }} body={dateBodyTemplate}></Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>

            
            <div className="col-xl-12 col-xxl-12 col-lg-12">
                <div className="card">
                    <div className="card-header border-0 pb-0">
                        <h3 className="fs-20 mb-0 text-black">
                            Demandes d'accord préalables
                        </h3>
                    </div>

                    <div className="card-body">
                        <div className="assigned-doctor owl-carousel">
                            <DataTable 
                                value={accordPreas?.filter(elt => elt.providerId === currentUser.providerId)?.map(bon => {
                                    bon.createdAt = new Date(bon.createdAt);
                                    return bon;
                                })} 
                                showGridlines 
                                tableStyle={{ minWidth: '50rem' }}
                            >
                                <Column field="acteName" header="Acte" style={{ minWidth: '200px' }}></Column>
                                <Column field="dossierConsultation.consultationBon.beneficiaryName" header="Patient" style={{ minWidth: '200px' }}></Column>
                                <Column field="dossierConsultation.consultationBon.doctorName" header="Prescripteur" style={{ minWidth: '200px' }}></Column>
                                <Column field="status" header="Statut de la demande" style={{ minWidth: '150px' }} body={statusBodyTemplate}></Column>
                                <Column field="createdAt" header="Date" style={{ minWidth: '150px' }} body={dateBodyTemplate}></Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        subsRequestsHistory: state.pharmacie.subsRequestsHistory,
        accordPreas: state.accordPrea.accordPreas,
        successMessage: state.pharmacie.successMessage,
        errorMessage: state.pharmacie.errorMessage,
        showLoading: state.pharmacie.showLoading,
        currentUser: currentUser(state),
    };
 };
 
 export default connect(mapStateToProps)(Dashboard);