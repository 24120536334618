import React, { Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from 'react-redux';
import { useParams, withRouter } from "react-router-dom";
import Select from 'react-select'
// import SelectSearch from "react-select-search";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { makeConsultationRequest, getActesConsultationAction, startLoader } from "../../../../store/actions/ConsultationActions";
import { getProviderDataAction, listProvidersAction } from "../../../../store/actions/ProviderActions";
import { currentUser } from "../../../../store/selectors/CurrentUserSelector";
import { Button, Spinner, Modal } from "react-bootstrap";
import { calculationRembBaseAction, startLoader as startFactLoader } from "../../../../store/actions/FacturationAction";
import { getPatients } from "../../../../store/selectors/PatientSelector";
import '../../Styles/selectStyle.css';
import swal from 'sweetalert';
import PrestationTabModal from './PrestationTabModal';
import { useTranslation } from "react-i18next";


export const ConsultationRequest = (props) => {
    const { t } = useTranslation();
    const { patientId, patientName } = useParams();
    const dispatch = useDispatch();
    const { history, currentUser, provider, showLoading, actesConsultation, rembCalculDatas, providerDoctors } = props;
    const [acteId, setActeId] = useState("");
    const [acteCode, setActeCode] = useState("");
    const [acteName, setActeName] = useState("");
    const [doctorId, setDoctorId] = useState("");
    const [doctorName, setDoctorName] = useState("");
    const [date, setDate] = useState(new Date());
    //const [time, setTime] = useState("");
    //const acteOptions = [{name: "Consultation généraliste", value: 1}, {name: "Consultation gynécologique", value: 2}];
    const doctors = [{label: "Celestin Djeumi", value: 1245}, {label: "Ngando Carole", value: 2535}];
    const motives = [{label: "Maternité", value: 'maternity'}, {label: "Accident", value: "accident"}, {label: "Maladie naturelle", value: "sickness"}];
    const [motif, setMotif] = useState(motives[motives.length - 1].value);
    
    const [height, setHeight] = useState(0);
    const [weight, setWeight] = useState(0);
    const [imc, setImc] = useState(0);
    const [temperature, setTemperature] = useState(0);
    const [bloodPressionS, setBloodPressionS] = useState(0);
    const [bloodPressionD, setBloodPressionD] = useState(0);
    const [frequenceCard, setFreqCard] = useState(0);
    const [frequenceResp, setFreqResp] = useState(0);
    const [showPrestationTab, setShowPrestationTab] = useState(false);
    
    const getDoctorName = () => {
        console.log(provider.providerUsers);
        console.log(doctorId);
        console.log(provider.providerUsers.filter(user => {return user.userId === doctorId}));
        let res = provider.providerUsers.filter(user => {return user.userId === doctorId})[0].user.username;
        return res;
    }

     const requestConsultation = (e) => {
        e.preventDefault();
        dispatch(startLoader());
        
        let data = {
            providerId: currentUser.providerId,
            providerName: currentUser.provider.providerName,
            acteId,
            acteCode,
            acteName,
            patientId,
            patientName,
            doctorId,
            doctorName,
            date,
            motif,
            healhtParameter: {
                height,
                weight,
                imc,
                temperature,
                bloodPressionS,
                bloodPressionD,
                executedBy: props.currentUser.id,
                frequenceCard,
                frequenceResp
            }
        }

        console.log(data);

        dispatch(makeConsultationRequest(data, history));
     }

     const manageHealthParamsModal = () => {
        setShowPrestationTab(false);
     }

     /*const loadDoctors = () => {
        
        console.log(currentUser.providerId);
        let res = provider.providerUsers.map(provUser => {
            let profiles = provUser.user.userProfil.map(elt => {return elt.profil.code});
            if(profiles.includes('DOC001')){
                return {label: provUser.user.userName, value: provUser.userId}
            }else{
                return;
            }
        }).filter(el => { return el});
        console.log(res);
        return res;

     }*/

     const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
          return false;
        }else{
          return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
      }
    
     const hasMenu = (menuCode) => {
          return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
      }

     useEffect(() => {
        if(hasMenu('MENCONSULT') && canDo('MENCONSULT', 'ACREQCONSULT')){
            //dispatch(getProviderDataAction(currentUser.providerId, history));
            dispatch(listProvidersAction());
            dispatch(getActesConsultationAction());
        }else{
            history.push('/access-denied');
        }
    }, []);

    return (
        <Fragment>
           <div className="row">
              <div className="col-xl-12 col-xxl-12">
                 <div className="card">
                    <div className="card-header">
                       <h4 className="card-title">{t('consultation.start-consultation')}</h4>
                       {props.patients.contrat && <button className='btn btn-info' onClick={e => setShowPrestationTab(true)}>
                         {t('consultation.consult-table')}
                       </button>}
                    </div>
                    <div className="card-body">
                        { props.showFactLoading && <div className='row justify-content-center my-5'>
                            <span className='text-warning'>...Calculs en cours</span>
                        </div>}
                        { !props.showFactLoading && <div className='row justify-content-between my-5'>
                            <h6 className='text-danger'>{rembCalculDatas.filter(fact => {return fact.index === 'consultation'})[0]?.data.message}</h6><br/>
                            <span className="font-weight-bold text-primary">{t('consultation.consultation-price')} {Math.min(rembCalculDatas.filter(fact => {return fact.index === 'consultation'})[0]?.data.montantConvention, rembCalculDatas.filter(fact => {return fact.index === 'consultation'})[0]?.data.montantPolice) || 0}</span><br/>
                            <span className="font-weight-bold text-primary">{t('consultation.moderator-ticket')} {(100 - rembCalculDatas.filter(fact => {return fact.index === 'consultation'})[0]?.data.tm) || 0} %</span><br/>
                            <span className="font-weight-bold text-primary">{t('consultation.patient-amount-to-pay')} {rembCalculDatas.filter(fact => {return fact.index === 'consultation'})[0]?.data.montantPaye}</span><br/>
                        </div>}
                       <form
                          onSubmit={(e) => {
                            e.preventDefault();
                            if(rembCalculDatas.filter(fact => {return fact.index === 'consultation'})[0]?.data.montantPaye > 0) {
                                swal({
                                title: "Confirmation de paiement",
                                text:"Confirmez-vous que le patient "+
                                    patientName
                                    +" a bien payé la somme de "+
                                    rembCalculDatas.filter(fact => {return fact.index === 'consultation'})[0]?.data.montantPaye
                                    +" due pour cette consultation ?",
                                icon: "warning",
                                buttons: true,
                                dangerMode: true,
                                }).then((hasPaid) => {
                                    if (hasPaid) {
                                        requestConsultation(e)
                                    }
                                })
                            }else{
                                requestConsultation(e);
                            }
                        }}
                       >
                            <section>
        
                                <h5 className='mt-4 text-primary'>Consultation</h5>
                                <div className="border-top mt-2 mb-4"></div>
                                <div className="row">
                                <div className="col-lg-3 mb-2">
                                    <div className="form-group">
                                        <label className='mb-1 '>
                                            <strong>{t('consultation.consultation-type')}</strong>
                                        </label>
                                        
                                        <Select
                                            className="select-search"
                                            options={actesConsultation.map(act => {
                                                return {label: act.nameFr, value: act.id, code: act.code}
                                            })}
                                            name="Type"
                                            placeholder="Choisir un type"
                                            disabled={showLoading}
                                            search
                                            isRequired
                                            onChange={(val) => {
                                                setActeId(val.value);
                                                setActeName(val.label);
                                                setActeCode(val.code);
        
                                                let data = {
                                                    providerId: currentUser.providerId,
                                                    patientId,
                                                    acteId: val.value
                                                };
                                                
                                                dispatch(startFactLoader());
                                                dispatch(calculationRembBaseAction(data, 'consultation'));
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 mb-2">
                                    <div className="form-group">
                                        <label className='mb-1 '>
                                            <strong>{t('common.doctor')}</strong>
                                        </label>
                                        <Select
                                            className="select-search"
                                            options={providerDoctors?.find(doc => doc.id+"" === currentUser.providerId+"")?.user?.map(provUser => {
                                                return {label: provUser.username, value: provUser.id}
                                            })}
                                            name="Doctor"
                                            placeholder={t('common.choose-doctor')}
                                            disabled={showLoading}
                                            search
                                            isRequired
                                            onChange={(val) => {
                                                setDoctorId(val.value);
                                                setDoctorName(val.label);
                                            }}
                                        />
                                    </div>
                                </div>
                                
                                <div className="col-lg-3 mb-2">
                                    <div className="form-group">
                                        <label className='mb-1 '>
                                            <strong>{t('common.date-and-time')}</strong>
                                        </label>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <DateTimePicker
                                                autoOk
                                                label=""
                                                clearable
                                                format="dd/MM/yyyy hh:mm"
                                                value={date}
                                                disablePast
                                                disableFuture
                                                disabled={showLoading}
                                                required
                                                onChange={setDate}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                </div>
                                
                                <div className="col-lg-3 mb-2">
                                    <div className="form-group">
                                        <label className='mb-1 '>
                                            <strong>{t('consultation.consultation-reason')}</strong>
                                        </label>
                                        <Select
                                            className="select-search"
                                            options={motives}
                                            value={motives.filter(elt => elt.value === motif).map(res => {return {label: res.label, value: res.value}})}
                                            name="Type"
                                            placeholder={t('client.chooseCountry')}
                                            disabled={showLoading}
                                            search
                                            isRequired
                                            onChange={(val) => {
                                                setMotif(val.value);
                                            }}
                                        />
                                    </div>
                                </div>
                                </div>
                            </section>

                            {/*<section>
                                <h5 className='mt-4 text-primary'>Paramètres du patient</h5>
                                <div className="border-top mt-2 mb-4"></div>
                                <div className="row">
                                    <div className="col-lg-4 mb-2">
                                        <div className="form-group">
                                            <label className='mb-1 '>
                                                <strong>Taille(en cm)</strong>
                                            </label>
                                            <input type='number' className='form-control'
                                                value={height}
                                                disabled={showLoading}
                                                onChange={(e) => {
                                                    setHeight(e.target.value)
                                                    setImc(weight / ((e.target.value / 100) ** 2))
                                                }}
                                                />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 mb-2">
                                        <div className="form-group">
                                            <label className='mb-1 '>
                                                <strong>Poids(en kg)</strong>
                                            </label>
                                            <input type='number' className='form-control'
                                                value={weight}
                                                disabled={showLoading}
                                                onChange={(e) => {
                                                    setWeight(e.target.value)
                                                    setImc(e.target.value / ((height/100) ** 2))
                                                }}
                                                />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 mb-2">
                                        <div className="form-group">
                                            <label className='mb-1 '>
                                                <strong>IMC(en kg/m)</strong>
                                            </label>
                                            <input type='number' className='form-control'
                                                value={imc}
                                                disabled={showLoading}
                                                onChange={(e) => setImc(e.target.value)}
                                                />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 mb-2">
                                        <div className="form-group">
                                            <label className='mb-1 '>
                                                <strong>Température(en °C)</strong>
                                            </label>
                                            <input type='number' className='form-control'
                                                value={temperature}
                                                disabled={showLoading}
                                                onChange={(e) => setTemperature(e.target.value)}
                                                />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 mb-2">
                                        <div className="form-group">
                                            <label className='mb-1 '>
                                                <strong>Tension systolique(en mm/Hg)</strong>
                                            </label>
                                            <input type='number' className='form-control'
                                                value={bloodPressionS}
                                                disabled={showLoading}
                                                onChange={(e) => setBloodPressionS(e.target.value)}
                                                />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 mb-2">
                                        <div className="form-group">
                                            <label className='mb-1 '>
                                                <strong>Tension diastolique(en mm/Hg)</strong>
                                            </label>
                                            <input type='number' className='form-control'
                                                value={bloodPressionD}
                                                disabled={showLoading}
                                                onChange={(e) => setBloodPressionD(e.target.value)}
                                                />
                                        </div>
                                    </div>
                                
                                </div>
                            </section>*/}
                            
                            <div className='row justify-content-center mt-4'>
                                { (props.showLoading || props.showFactLoading) && 
                                    <Button variant="primary" disabled>
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />{' '}
                                        <span className="visually-hidden">{t('common.await-text')}</span>
                                    </Button> }
                                { !props.showLoading && !props.showFactLoading &&
                                    <div className='col-4'>
                                        <input type='submit' className='btn btn-primary btn-block'/>
                                    </div> }
                            </div>
                       </form>
                    </div>
                 </div>
              </div>
           </div>
           
           <Modal
                className="fade bd-example-modal-lg"
                show={showPrestationTab}
                size="lg"
            >
                <PrestationTabModal 
                    showModal={showPrestationTab}
                    data={props.patients?.contrat?.couvertureColleges}
                    patientName={patientName}
                    manageHealthParamsModal={manageHealthParamsModal}
                />
            </Modal>
        </Fragment>
    )
};

const mapStateToProps = (state) => {
    return {
        provider: state.provider.provider,
        providerDoctors: state.provider.providers,
        actesConsultation: state.consultation.actesConsultation,
        showLoading: state.consultation.showLoading,
        currentUser: currentUser(state),
        patients: getPatients(state),
        rembCalculDatas: state.facturation.rembCalculDatas,
        showFactLoading: state.facturation.showLoading
    };
};

export default withRouter(connect(mapStateToProps)(ConsultationRequest));