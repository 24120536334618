import React, { Fragment, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { connect } from 'react-redux';
import { Link, useParams, withRouter } from "react-router-dom";
import { ListGroup } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import map from "../../../../images/svg/map.svg";
import widget8 from "../../../../images/avatar/5.png";
import task6 from "../../../../images/task/img6.jpg";
import { getConsultationDataAction, startLoader } from "../../../../store/actions/ConsultationActions";
import { getSubstitutionRequestsAction, substituteDrugAction } from "../../../../store/actions/PharmacieActions";
import { listAllAccordRequestAction, loadingToggleAction, sendAccordMessageAction } from "../../../../store/actions/AccordPreaActions";
import { currentUser } from "../../../../store/selectors/CurrentUserSelector";
import { Row, Col, Card, Accordion, Button, Modal, Badge, Dropdown } from 'react-bootstrap';
import CommentaireModal from './CommentaireModal';
import AddActModal from './AddActModal';
import ProlongHospiModal from "./ProlongHospiModal";
import EndHospiModal from "./EndHospiModal";
import {format} from 'date-fns';
import avatar1 from '../../../../images/avatar/5.png';
import avatar2 from '../../../../images/avatar/2.png';
import './AccordPreaView.css';
import './DetailsConsultation.css';
import { TabView, TabPanel } from 'primereact/tabview';
import { ExamenBonsTable } from './ExamenBonsTable';
import { ActesSpecialisesTable } from './ActesSpecialisesTable';
import { MedicamentsTable } from './MedicamentsTable';
import { OptiqueBonsTable } from './OptiqueBonsTable';
import { HospiBonTable } from './HospiBonTable';
import { KineBonsTable } from './KineBonTable';
import { STATUSES } from '../../../globals';
import { useTranslation } from "react-i18next";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export const DetailsConsultation = (props) => {
    
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { id } = useParams();
    const { currentUser, consultation, history, subsRequests, accordPreas,
         showLoading, showAccordLoading, updateActDone, prolongHospiDone, endHospiDone } = props;
         
    const [activeBordered, setActiveBordered] = useState(0);
    const [currentKey, setCurrentKey] = useState('');
    const [message, setMessage] = useState('');
    const [fileSrc, setFileSrc] = useState(null);
    const [showDiscuss, setShowDiscuss] = useState(true);
    const [addActModal, setAddActModal] = useState(false);
    const [type, setType] = useState("");
    const [bonId, setBonId] = useState("");
    const [prolongationModal, setProlongationModal] = useState(false);
    const [endHospiModal, setEndHospiModal] = useState(false);
    const [maxDays, setMaxDays] = useState(0);
    const [isChange, setIsChange] = useState(false);

    const suggestions = [
        "Voici les résultats d'examens demandés",
        "Voici les précisions demandée sur le patient",
        "Je vous relance pour la validation de cette demande"
    ];

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
          return false;
        }else{
          return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
      }
    
     const hasMenu = (menuCode) => {
          return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
      }

     useEffect(() => {
        if(hasMenu('MENCONSULT') && canDo('MENCONSULT', 'ACVIEWCONSULT')){
            dispatch(startLoader());
            dispatch(getSubstitutionRequestsAction());
            dispatch(getConsultationDataAction(id));
            dispatch(loadingToggleAction());
            dispatch(listAllAccordRequestAction());
        }else{
            props.history.push('/access-denied');
        }
    }, []);

    useEffect(() => {
        dispatch(startLoader());
        dispatch(getConsultationDataAction(id));
        dispatch(loadingToggleAction());
        dispatch(listAllAccordRequestAction());
    }, [updateActDone, prolongHospiDone, endHospiDone]);

    const defaultAccordion = [
        {
          title: 'Examens',
          bg: 'primary',
        },
        {
          title: 'Pharmacie',
          bg: 'info',
        },
        {
          title: 'Actes Spécialisés',
          bg: 'success',
        },
        {
          title: 'Hospitalisation',
          bg: 'success',
        },
        {
          title: 'Optique',
          bg: 'secondary',
        },
      ]

    const manageCommentModal = (val, reload) => {
        //setSubsModal(val);
        setCurrentKey('');
        /*if(reload){
            window.location.reload(false);
        };*/
    }

    const manageAddActModal = (val, reload) => {
        setAddActModal(val);
        setType("");
        setBonId("");
        setIsChange(false);
    }

    const manageProlongationModal = (val, reload) => {
        setProlongationModal(val);
    }

    const manageEndHospiModal = (val, reload) => {
        setEndHospiModal(val);
    }

    const respondDemande = (status, demandeId) => {
        dispatch(substituteDrugAction(status, demandeId));
    }

    const loadFile = (e) => {
        let upFiles = e.target.files;
        const keys = Object.keys(upFiles);
        console.log(upFiles[keys[0]]);
        setFileSrc(upFiles[keys[0]]);
    }

    const sendMessage = (e, accordId) => {
        e.preventDefault();
        let data = {
            senderId: currentUser.id,
            accordPreableId: accordId,
            message,
            fileSrc,
        };
        dispatch(loadingToggleAction());
        dispatch(sendAccordMessageAction(data, history));
        dispatch(listAllAccordRequestAction(id));
    }

    const canAddActs = () => {
        if(consultation.isHospi && !consultation.isclose) {
            return true
        }

        if(new Date(consultation.closeDate) <= new Date()){
            return true
        }

        return false
    }

    const dayHospiString = () => {
        let str = "";
        let diff = Math.abs(new Date() - new Date(consultation.dateDebutHospi));
        let diff2 = Math.abs(new Date(consultation.dateFinHospi) - new Date());

        let days = Math.ceil(diff / (1000*60*60*24));
        let remainDays = Math.ceil(diff2 / (1000*60*60*24));
        
        if(days <= 0){
            str = "1er jour d'hospitalisation, ";
        }else{
            str = days + "e jour d'hospitalisation, ";
        }

        if(remainDays < 0){
            str += "patient sorti d'hospitalisation.";
        }else if(remainDays === 0){
            str += "sortie prévue aujourd'hui.";
        }else{
            str += "sortie prévue dans "+(remainDays - 1)+" jour(s)"
        }

        return str;

    }

    return (
        <>
            { showLoading && 
            <div id="preloader">
                <div className="sk-three-bounce">
                    <div className="sk-child sk-bounce1"></div>
                    <div className="sk-child sk-bounce2"></div>
                    <div className="sk-child sk-bounce3"></div>
                </div>
            </div> }

            { !showLoading &&
            <>
                <div className='d-md-flex d-block mb-3 align-items-center justify-content-between no-print'>
                    <div className="d-flex flex-column">
                        <div className="widget-timeline-icon py-3 py-md-2 px-1 overflow-auto">
                            <ul className="timeline">
                                <li>
                                    <div className="icon bg-warning" />
                                    <Link
                                        className="timeline-panel text-muted"
                                        to="#"
                                    >
                                        <h4 className="mb-2 mt-0 text-warning fs-16 font-w600">
                                        En Consultation le
                                        </h4>
                                        {consultation.openDate && <p className="fs-14 mb-0 ">
                                            {format(new Date(consultation.openDate), 'dd/MM/yyyy hh:mm')}
                                        </p>}
                                    </Link>
                                </li>
                                {consultation.isHospi && 
                                <>
                                    <li className="border-info">
                                        <div className="icon bg-info" />
                                        <Link
                                            className="timeline-panel text-muted"
                                            to="#"
                                        >
                                            <h4 className="mb-2 mt-0 text-info fs-16 font-w600">
                                            En Hospitalisation le
                                            </h4>
                                            {consultation.dateDebutHospi && <p className="fs-14 mb-0 ">
                                                {format(new Date(consultation.dateDebutHospi), 'dd/MM/yyyy hh:mm')}
                                            </p>}
                                        </Link>
                                    </li>
                                    <li>
                                        <div className="icon bg-primary" />
                                        <Link
                                            className="timeline-panel text-muted"
                                            to="#"
                                        >
                                            <h4 className="mb-2 text-primary mt-0 fs-16 font-w600">
                                            Sorti le
                                            </h4>
                                            {consultation.dateFinHospi && <p className="fs-14 mb-0 ">
                                                {format(new Date(consultation.dateFinHospi), 'dd/MM/yyyy hh:mm')}
                                            </p>}
                                        </Link>
                                    </li>
                                </>
                                }
                            </ul>
                        </div>
                        {consultation.isHospi === true && consultation.isclose === false && <div className="my-2">
                            <span className="text-info font-weight-bold">{dayHospiString()}</span>
                        </div>}
                    </div>
                    {consultation.isHospi === true && consultation.isclose === false && <>
                        {hasMenu('MENCONSULT') && canDo('MENCONSULT', 'ACENDLHOSPI') && <Dropdown className="dropdown d-inline-block ml-auto mr-2"  onClick={e => setEndHospiModal(true)}>
                            <Dropdown.Toggle
                                variant=""
                                type="button"
                                className="btn btn-outline-danger btn-rounded dropdown-toggle font-w600"
                                data-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <i className="las la-check-circle scale5 mr-3" />
                                Sortie d'hospi
                            </Dropdown.Toggle>
                        </Dropdown>}
                        {hasMenu('MENCONSULT') && canDo('MENCONSULT', 'ACENDLHOSPI') && <Modal
                            className="fade bd-example-modal-lg"
                            show={endHospiModal}
                            size="lg"
                        >
                            <EndHospiModal 
                                showModal={endHospiModal}
                                bonId={consultation.consultationBon?.prescription.hospitalisationBon[0]?.id}
                                clientName={consultation.consultationBon.beneficiaryName}
                                manageEndHospiModal={manageEndHospiModal}
                                dateEntree={consultation.openDate}
                            />
                        </Modal>}
                        {hasMenu('MENCONSULT') && canDo('MENCONSULT', 'ACPROLHOSPI') && (new Date(consultation.dateFinHospi) >= new Date()) && <>
                            <Dropdown className="dropdown d-inline-block ml-auto mr-2" onClick={e => setProlongationModal(true)}>
                                <Dropdown.Toggle
                                    variant=""
                                    type="button"
                                    className="btn btn-outline-info btn-rounded dropdown-toggle font-w600"
                                    data-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <i className="las la-check-circle scale5 mr-3" />
                                    Prolongation
                                </Dropdown.Toggle>
                            </Dropdown>
                            <Modal
                                className="fade bd-example-modal-lg"
                                show={prolongationModal}
                                size="lg"
                            >
                                <ProlongHospiModal 
                                    showModal={prolongationModal}
                                    currentUser={currentUser}
                                    consultationId={consultation.id}
                                    bonId={consultation.consultationBon?.prescription.hospitalisationBon[0].id} 
                                    clientId={consultation.consultationBon.beneficiaryId}
                                    clientName={consultation.consultationBon.beneficiaryName}
                                    manageProlongationModal={manageProlongationModal}
                                />
                            </Modal>
                        </>}
                    </>}
                    <div>
                        {hasMenu('MENCONSULT') && canDo('MENCONSULT', 'ACVIEWMEDFOLD') && <Link
                        to={"/dossier-medical/"+consultation.consultationBon?.beneficiaryId}
                        className="btn btn-success btn-rounded wspace-no mx-2"
                        >
                        <i className="las scale5 la-folder-plus mr-2" /> Dossier Médical
                        </Link>}
                    </div>
                </div>
                


                <div className="row">
                    <div className="col-12">
                        <div className="row no-print">
                            <div className="col-xl-12 col-xxl-12 col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="media d-sm-flex d-block text-center text-sm-left pb-4 mb-4 border-bottom">
                                            <img
                                            alt="widget"
                                            className="rounded mr-sm-4 mr-0"
                                            width={130}
                                            src={task6}
                                            />
                                            <div className="media-body align-items-center">
                                                <div className="d-sm-flex d-block justify-content-between my-3 my-sm-0">
                                                    <div>
                                                        <h3 className="fs-22 text-black font-w600 mb-0">
                                                            {consultation.consultationBon?.beneficiaryName}
                                                        </h3>
                                                        <h5 className="my-2 my-sm-2 text-danger">
                                                            <b>Motif </b>: {consultation.motif}
                                                        </h5>
                                                        <small className="mb-2 mb-sm-2 text-info">
                                                            <b>Date de consultation</b>: {' '} 
                                                                {consultation.consultationBon?.date}
                                                        </small>
                                                    </div>
                                                    <span>{t('statuses.'+consultation.consultationBon?.status)}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-4 mb-3">
                                                <div className="media">
                                                    <span className="p-2 border border-primary-light rounded-circle mr-3">
                                                        <i className="las la-temperature-high text-primary" style={{fontSize: '30px'}}></i>
                                                    </span>
                                                    <div className="media-body">
                                                        <span className="d-block text-primary mb-2">
                                                            Température
                                                        </span>
                                                        <p className="fs-16 text-dark">
                                                            <strong>{consultation.healhtParameter?.temperature}° C</strong>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mb-3">
                                                <div className="media">
                                                    <span className="p-2 border border-primary-light rounded-circle mr-3">
                                                        <i className="las la-text-height text-primary" style={{fontSize: '30px'}}></i>
                                                    </span>
                                                    <div className="media-body">
                                                        <span className="d-block text-primary mb-2">
                                                            Taille
                                                        </span>
                                                        <p className="fs-16 text-dark">
                                                            <strong>{consultation.healhtParameter?.height} Cm</strong>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mb-3">
                                                <div className="media">
                                                    <span className="p-2 border border-primary-light rounded-circle mr-3">
                                                        <i className="las la-weight text-primary" style={{fontSize: '30px'}}></i>
                                                    </span>
                                                    <div className="media-body">
                                                        <span className="d-block text-primary mb-2">
                                                            Poids
                                                        </span>
                                                        <p className="fs-16 text-dark">
                                                            <strong>{consultation.healhtParameter?.weight} Kg</strong>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mb-3">
                                                <div className="media">
                                                    <span className="p-2 border border-primary-light rounded-circle mr-3">
                                                        <i className="las la-heartbeat text-primary" style={{fontSize: '30px'}}></i>
                                                    </span>
                                                    <div className="media-body">
                                                        <span className="d-block text-primary mb-2">
                                                            Tension Systolique
                                                        </span>
                                                        <p className="fs-16 text-dark">
                                                            <strong>{consultation.healhtParameter?.bloodpressionS} mmHg</strong>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mb-3">
                                                <div className="media">
                                                    <span className="p-2 border border-primary-light rounded-circle mr-3">
                                                        <i className="las la-heartbeat text-primary" style={{fontSize: '30px'}}></i>
                                                    </span>
                                                    <div className="media-body">
                                                        <span className="d-block text-primary mb-2">
                                                            Tension diastolique
                                                        </span>
                                                        <p className="fs-16 text-dark">
                                                            <strong>{consultation.healhtParameter?.bloodPressionD} mmHg</strong>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mb-3">
                                                <div className="media">
                                                    <span className="p-2 border border-primary-light rounded-circle mr-3">
                                                        <i className="las la-balance-scale text-primary" style={{fontSize: '30px'}}></i>
                                                    </span>
                                                    <div className="media-body">
                                                        <span className="d-block text-primary mb-2">
                                                            IMC
                                                        </span>
                                                        <p className="fs-16 text-dark">
                                                            <strong>{consultation.healhtParameter?.imc}</strong>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mb-3">
                                                <div className="media">
                                                    <span className="p-2 border border-primary-light rounded-circle mr-3">
                                                        <i className="las la-heart text-primary" style={{fontSize: '30px'}}></i>
                                                    </span>
                                                    <div className="media-body">
                                                        <span className="d-block text-primary mb-2">
                                                            Fréquence Cardiaque
                                                        </span>
                                                        <p className="fs-16 text-dark">
                                                            <strong>{consultation.healhtParameter?.frequenceCard} bmp</strong>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mb-3">
                                                <div className="media">
                                                    <span className="p-2 border border-primary-light rounded-circle mr-3">
                                                        <i className="las la-wind text-primary" style={{fontSize: '30px'}}></i>
                                                    </span>
                                                    <div className="media-body">
                                                        <span className="d-block text-primary mb-2">
                                                            Fréquence Respiratoire
                                                        </span>
                                                        <p className="fs-16 text-dark">
                                                            <strong>{consultation.healhtParameter?.frequenceResp} cycle/min</strong>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row no-print">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">Prescriptions</h4>
                                        <Button
                                            as='a'
                                            variant='success light'
                                            href='#'
                                            className='btn-card btn-success'
                                            onClick={e => window.print()}
                                        >
                                            Imprimer l'ordonance
                                        </Button>
                                    </div>
                                    <div className="card-body">
                                        <TabView>
                                            <TabPanel header={"Laboratoire("+consultation.consultationBon?.prescription.examensBon?.reduce((sum, elt) => {return sum + elt.examenBonDetail.length}, 0)+')'}>
                                                {consultation.consultationBon?.prescription.examensBon?.map(bon => {
                                                    return <>
                                                        <h3 className='mt-2 text-dark text-start'>Numéro Bon: {bon.code}</h3>
                                                        <div className="border-top my-2"></div>
                                                        <ExamenBonsTable data={bon.examenBonDetail} history={history} />
                                                        <div>
                                                            {hasMenu('MENCONSULT') && canDo('MENCONSULT', 'ACADDPRESCRIP') && canAddActs() && <Button 
                                                                variant='primary light' 
                                                                className='btn-card mt-3'
                                                                onClick={e => {
                                                                    if(!addActModal) {
                                                                        setAddActModal(true);
                                                                        setType("examens");
                                                                        setBonId(bon.id);
                                                                    }
                                                                }}
                                                            >
                                                                Ajouter un examen
                                                            </Button>}
                                                        </div>
                                                    </>
                                                })}
                                                
                                                {hasMenu('MENCONSULT') && canDo('MENCONSULT', 'ACADDPRESCRIP') && canAddActs() && consultation.consultationBon?.prescription.examensBon?.length === 0 && <Button 
                                                    variant='secondary light' 
                                                    className='btn-card mt-3'
                                                    onClick={e => {
                                                        setAddActModal(true);
                                                        setType("examens");
                                                    }}
                                                >
                                                    Prescrire un examen
                                                </Button>}
                                            </TabPanel>
                                            <TabPanel header={"Pharmacie("+consultation.consultationBon?.prescription.pharmacieBon?.reduce((sum, elt) => {return sum + elt.pharmacieBonDetails.length}, 0)+')'}>
                                                {consultation.consultationBon?.prescription.pharmacieBon?.map(bon => {
                                                    return <>
                                                        <h3 className='mt-2 text-dark text-start'>Numéro Bon: {bon.code}</h3>
                                                        <div className="border-top my-2"></div>
                                                        <MedicamentsTable data={bon.pharmacieBonDetails} respondDemande={respondDemande} />
                                                        <div>
                                                            {hasMenu('MENCONSULT') && canDo('MENCONSULT', 'ACADDPRESCRIP') && canAddActs() && <Button 
                                                                variant='primary light' 
                                                                className='btn-card mt-3'
                                                                onClick={e => {
                                                                    if(!addActModal) {
                                                                        setAddActModal(true);
                                                                        setType("medicament");
                                                                        setBonId(bon.id);
                                                                    }
                                                                }}
                                                            >
                                                                Ajouter un médicament
                                                            </Button>}
                                                        </div>
                                                    </>
                                                })}
                                                
                                                {hasMenu('MENCONSULT') && canDo('MENCONSULT', 'ACADDPRESCRIP') && canAddActs() && consultation.consultationBon?.prescription.pharmacieBon?.length === 0 && <Button 
                                                    variant='secondary light' 
                                                    className='btn-card mt-3'
                                                    onClick={e => {
                                                        setAddActModal(true);
                                                        setType("medicament");
                                                    }}
                                                >
                                                    Prescrire un médicament
                                                </Button>}
                                            </TabPanel>
                                            <TabPanel header={"Actes Spécialisés("+consultation.consultationBon?.prescription.acteSpecialiseBon?.reduce((sum, elt) => {return sum + elt.specialiseBonDetails.length}, 0)+')'}>
                                                {consultation.consultationBon?.prescription.acteSpecialiseBon?.map(bon => {
                                                    return <>
                                                        <h3 className='mt-2 text-dark text-start'>Numéro Bon: {bon.code}</h3>
                                                        <div className="border-top my-2"></div>
                                                        <ActesSpecialisesTable data={bon.specialiseBonDetails} />
                                                        <div>
                                                            {hasMenu('MENCONSULT') && canDo('MENCONSULT', 'ACADDPRESCRIP') && canAddActs() && <Button 
                                                                variant='primary light' 
                                                                className='btn-card mt-3'
                                                                onClick={e => {
                                                                    if(!addActModal) {
                                                                        setAddActModal(true);
                                                                        setType("actes spécialisés");
                                                                        setBonId(bon.id);
                                                                    }
                                                                }}
                                                            >
                                                                Ajouter un acte spécialisé
                                                            </Button>}
                                                        </div>
                                                    </>
                                                })}
                                                
                                                {hasMenu('MENCONSULT') && canDo('MENCONSULT', 'ACADDPRESCRIP') && canAddActs() && consultation.consultationBon?.prescription.acteSpecialiseBon?.length === 0 && <Button 
                                                    variant='secondary light' 
                                                    className='btn-card mt-3'
                                                    onClick={e => {
                                                        setAddActModal(true);
                                                        setType("actes spécialisés");
                                                    }}
                                                >
                                                    Prescrire un acte spécialisé
                                                </Button>}
                                            </TabPanel>
                                            <TabPanel header={"Optique("+consultation.consultationBon?.prescription.opticBon?.reduce((sum, elt) => {return sum + elt.opticBonDetails.length}, 0)+')'}>
                                                {consultation.consultationBon?.prescription.opticBon?.map(bon => {
                                                    return <>
                                                        <h3 className='mt-2 text-dark text-start'>Numéro Bon: {bon.code}</h3>
                                                        <div className="border-top my-2"></div>
                                                        <OptiqueBonsTable data={bon.opticBonDetails} />
                                                        <div>
                                                            {hasMenu('MENCONSULT') && canDo('MENCONSULT', 'ACADDPRESCRIP') && canAddActs() && <Button 
                                                                variant='primary light' 
                                                                className='btn-card mt-3'
                                                                onClick={e => {
                                                                    if(!addActModal) {
                                                                        setAddActModal(true);
                                                                        setType("optique");
                                                                        setBonId(bon.id);
                                                                    }
                                                                }}
                                                            >
                                                                Ajouter une prescription optique
                                                            </Button>}
                                                        </div>
                                                    </>
                                                })}
                                                
                                                {hasMenu('MENCONSULT') && canDo('MENCONSULT', 'ACADDPRESCRIP') && canAddActs() && consultation.consultationBon?.prescription.opticBon?.length === 0 && <Button 
                                                    variant='secondary light' 
                                                    className='btn-card mt-3'
                                                    onClick={e => {
                                                        setAddActModal(true);
                                                        setType("optique");
                                                    }}
                                                >
                                                    Faire une prescription optique
                                                </Button>}
                                            </TabPanel>
                                            <TabPanel header={"Hospitalisation("+consultation.consultationBon?.prescription.hospitalisationBon?.reduce((sum, elt) => {return sum + elt.hospitalisationBonDetail.length}, 0)+')'}>
                                                {consultation.consultationBon?.prescription.hospitalisationBon?.map(bon => {
                                                    return <>
                                                        <h3 className='mt-2 text-dark text-start'>Numéro Bon: {bon.code}</h3>
                                                        <div className="border-top my-2"></div>
                                                        <HospiBonTable data={bon.hospitalisationBonDetail} />
                                                        <div>
                                                            {hasMenu('MENCONSULT') && canDo('MENCONSULT', 'ACADDPRESCRIP') && canAddActs() && <Button 
                                                                variant='primary light' 
                                                                className='btn-card mt-3'
                                                                onClick={e => {
                                                                    setAddActModal(true);
                                                                    setType("hospitalisation");
                                                                    setBonId(bon.id);
                                                                    let diff2 = Math.abs(new Date(consultation.dateFinHospi) - new Date());

                                                                    let remainDays = Math.ceil(diff2 / (1000*60*60*24));
                                                                    
                                                                    let idx = bon.hospitalisationBonDetail.length - 1;
                                                                    //setMaxDays(bon.hospitalisationBonDetail[idx].quantite - 1);
                                                                    setMaxDays(remainDays - 1);
                                                                    setIsChange(true);
                                                                }}
                                                            >
                                                                Changer de chambre
                                                            </Button>}
                                                        </div>
                                                    </>
                                                })}
                                                
                                                {hasMenu('MENCONSULT') && canDo('MENCONSULT', 'ACADDPRESCRIP') && canAddActs() && consultation.consultationBon?.prescription.hospitalisationBon?.length === 0 && <Button 
                                                    variant='secondary light' 
                                                    className='btn-card mt-3'
                                                    onClick={e => {
                                                        setAddActModal(true);
                                                        setType("hospitalisation");
                                                        setMaxDays(365);
                                                        setIsChange(false);
                                                    }}
                                                >
                                                    Hospitaliser le patient
                                                </Button>}
                                            </TabPanel>
                                            <TabPanel header={"Kinesithérapie("+consultation.consultationBon?.prescription.kinesitherapieBons?.reduce((sum, elt) => {return sum + elt.kinesitherapieBonDetails.length}, 0)+')'}>
                                                {consultation.consultationBon?.prescription.kinesitherapieBons?.map(bon => {
                                                    return <>
                                                        <h3 className='mt-2 text-dark text-start'>Numéro Bon: {bon.code}</h3>
                                                        <div className="border-top my-2"></div>
                                                        <KineBonsTable data={bon.kinesitherapieBonDetails} />
                                                        {/*<div>
                                                            {hasMenu('MENCONSULT') && canDo('MENCONSULT', 'ACADDPRESCRIP') && canAddActs() && <Button 
                                                                variant='primary light' 
                                                                className='btn-card mt-3'
                                                                onClick={e => {
                                                                    if(!addActModal) {
                                                                        setAddActModal(true);
                                                                        setType("kine");
                                                                        setBonId(bon.id);
                                                                    }
                                                                }}
                                                            >
                                                                Ajouter une prescription kiné
                                                            </Button>}
                                                        </div>*/}
                                                    </>
                                                })}
                                                
                                                {/*{hasMenu('MENCONSULT') && canDo('MENCONSULT', 'ACADDPRESCRIP') && canAddActs() && consultation.consultationBon?.prescription.kinesitherapieBons?.length === 0 && <Button 
                                                    variant='secondary light' 
                                                    className='btn-card mt-3'
                                                    onClick={e => {
                                                        setAddActModal(true);
                                                        setType("kine");
                                                    }}
                                                >
                                                    Faire une prescription kiné
                                                </Button>}*/}
                                            </TabPanel>
                                            <TabPanel header={"Accords Préalables("+accordPreas?.filter(acc => {return acc.dossierConsultationId === consultation.id}).length+')'}>
                                                { showAccordLoading && 
                                                    <div id="preloader">
                                                        <div className="sk-three-bounce">
                                                            <div className="sk-child sk-bounce1"></div>
                                                            <div className="sk-child sk-bounce2"></div>
                                                            <div className="sk-child sk-bounce3"></div>
                                                        </div>
                                                    </div> 
                                                }
                                                {accordPreas?.filter(acc => {return acc.dossierConsultationId === consultation.id}).length > 0 && !showAccordLoading &&
                                                    <div className="row custom-chatbox">
                                                        {accordPreas?.filter(acc => {return acc.dossierConsultationId === consultation.id})?.map(accordBlock => {
                                                            return <div className="col-4 mb-4">    
                                                                <div className="card chat dz-chat-history-box">
                                                                    <div className="card-header chat-list-header justify-content-center text-center">
                                                                        <div>
                                                                            <h6 className="mb-1">{accordBlock?.dossierConsultation?.consultationBon?.doctorName}</h6>
                                                                            <p className="mb-0 text-success">{accordBlock?.acteName}</p>
                                                                        </div>
                                                                    </div>
                                                                    <PerfectScrollbar
                                                                        className="card-body msg_card_body dz-scroll ps ps--active-y height370"
                                                                        id="DZ_W_Contacts_Body3"
                                                                        scrollTop="100"
                                                                    >
                                                                        { accordBlock?.accordPreableDisccusions?.map(messag => {
                                                                            if(messag.senderId === currentUser.id){
                                                                                return <div className="d-flex justify-content-end mb-4">
                                                                                            <div className="msg_cotainer_send">
                                                                                                {messag.message}
                                                                                                {messag.docUrl && <a
                                                                                                    href={messag.docUrl}
                                                                                                    target="_blank"
                                                                                                    className="btn btn-light btn-rounded py-1"
                                                                                                    >
                                                                                                    <i className="las la-file-download mr-2"></i>
                                                                                                    <small>Document</small>
                                                                                                </a>}
                                                                                                <span className="msg_time_send">{messag.createdAt}</span>
                                                                                            </div>
                                                                                            <div className="img_cont_msg">
                                                                                                <img
                                                                                                    src={avatar2}
                                                                                                    className="rounded-circle user_img_msg"
                                                                                                    alt=""
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                            }else{
                                                                                return <div className="d-flex justify-content-start mb-4">
                                                                                <div className="img_cont_msg">
                                                                                    <img
                                                                                        src={avatar1}
                                                                                        className="rounded-circle user_img_msg"
                                                                                        alt=""
                                                                                    />
                                                                                </div>
                                                                                <div className="msg_cotainer">
                                                                                    {messag.message}
                                                                                    {messag.docUrl && <a
                                                                                        href={messag.docUrl}
                                                                                        target="_blank"
                                                                                        className="btn btn-light btn-rounded py-1"
                                                                                        >
                                                                                        <i className="las la-file-download mr-2"></i>
                                                                                        <small>Document</small>
                                                                                    </a>}
                                                                                    <span className="msg_time">{messag.createdAt}</span>
                                                                                </div>
                                                                            </div>

                                                                            }
                                                                        })}
                                                                        {accordPreas?.filter(acc => {return acc.dossierConsultationId === consultation.id})[0]?.status === STATUSES.resused && <div className="d-flex justify-content-start mb-4">
                                                                                <div className="img_cont_msg">
                                                                                    <img
                                                                                        src={avatar1}
                                                                                        className="rounded-circle user_img_msg"
                                                                                        alt=""
                                                                                    />
                                                                                </div>
                                                                                <div className="msg_cotainer bg-danger">
                                                                                    Rejeté pour: {accordPreas?.filter(acc => {return acc.dossierConsultationId === consultation.id})[0]?.motifRejet}
                                                                                    <span className="msg_time">{accordPreas?.filter(acc => {return acc.dossierConsultationId === consultation.id})[0]?.updatedAt}</span>
                                                                                </div>
                                                                            </div>}
                                                                        {accordPreas?.filter(acc => {return acc.dossierConsultationId === consultation.id})[0]?.status === STATUSES.accepted  &&  <div className="d-flex justify-content-start mb-4">
                                                                                <div className="img_cont_msg">
                                                                                    <img
                                                                                        src={avatar1}
                                                                                        className="rounded-circle user_img_msg"
                                                                                        alt=""
                                                                                    />
                                                                                </div>
                                                                                <div className="msg_cotainer bg-success">
                                                                                    Accord Validé
                                                                                    <span className="msg_time">{accordPreas?.filter(acc => {return acc.dossierConsultationId === consultation.id})[0]?.updatedAt}</span>
                                                                                </div>
                                                                        </div>}
                                                                    </PerfectScrollbar>
                                                                    {accordPreas?.filter(acc => {return acc.dossierConsultationId === consultation.id})[0]?.status === STATUSES.pending && <div className="card-footer">
                                                                        <div className="mb-2">
                                                                            {suggestions.map((suggestion, i) => {
                                                                                return (
                                                                                    <Badge variant="dark light mr-1 mb-1" key={i} onClick={(e) => setMessage(suggestion)}>
                                                                                        <span>{suggestion}</span>
                                                                                    </Badge>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                        <form onSubmit={e => sendMessage(e, accordPreas?.filter(acc => {return acc.dossierConsultationId === consultation.id})[0]?.id)}>
                                                                            <div className="row">
                                                                                <textarea
                                                                                    value={message}
                                                                                    onChange={(e) => setMessage(e.target.value)}
                                                                                    className="form-control mb-2"
                                                                                    cols={20}
                                                                                    required
                                                                                    placeholder="Type your message..."
                                                                                ></textarea>
                                                                            </div>
                                                                            <div className='row mb-3'>
                                                                                <div className='custom-file'>
                                                                                    <input type="file" className='custom-file-input' onChange={(e) => loadFile(e)} />
                                                                                    <label className='custom-file-label'>{fileSrc ? <small>{fileSrc.name}</small> : 'Choisir'}</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className=" row input-group-append d-flex justify-content-end">
                                                                                <button type="submit" className="btn btn-primary">
                                                                                    <i className="fa fa-location-arrow"></i>
                                                                                </button>
                                                                            </div>
                                                                        </form>
                                                                    </div>}
                                                                </div>
                                                            </div>
                                                        })}
                                                    </div>
                                                }
                                            </TabPanel>
                                        </TabView>
                                        {canAddActs() && <Modal
                                            className="fade bd-example-modal-lg"
                                            show={addActModal}
                                            size="lg"
                                        >
                                            <AddActModal 
                                                showModal={addActModal}
                                                type={type}
                                                bonId={bonId}
                                                consultationId={consultation.id} 
                                                currentUser={currentUser}
                                                clientId={consultation.consultationBon.beneficiaryId}
                                                maxDays={maxDays}
                                                isChange={isChange}
                                                manageAddActModal={manageAddActModal}
                                                t={t}
                                            />
                                        </Modal>}
                                    </div>
                                </div>
                            </div>
                        </div>

                        
                        
                        <div className="row no-print">
                            <div className="col-xl-4 col-xxl-4 col-lg-4">
                                <div className="card">
                                    <div className="card-header border-0 pb-0 d-flex justify-content-between">
                                        <h4 className="fs-20 font-w600 mb-0">
                                            Intervenants
                                        </h4>
                                    </div>
                                    <div className="card-body pt-4">
                                        <PerfectScrollbar
                                            id="DZ_W_Todo2"
                                            className="widget-media dz-scroll ps ps--active-y height370"
                                        >
                                            <ul className="timeline">
                                                <li>
                                                    <div className="timeline-panel bgl-dark flex-wrap border-0 p-3 rounded">
                                                        <div className="media bg-transparent mr-2">
                                                            <img
                                                            className="rounded-circle"
                                                            alt="widget"
                                                            width={48}
                                                            src={widget8}
                                                            />
                                                        </div>
                                                        <div className="media-body">
                                                            <h5 className="mb-1 fs-18">{consultation.consultationBon?.doctorName}</h5>
                                                            <span>Medecin Généraliste</span>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </PerfectScrollbar>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-8 col-xxl-8 col-lg-8">
                                <div className="card">
                                    <div className="card-header border-0 pb-0 d-flex justify-content-between">
                                        <h4 className="fs-20 font-w600 mb-0">
                                            Données de consultation
                                        </h4>
                                    </div>
                                    <div className="card-body pt-4">
                                        <PerfectScrollbar
                                            id="DZ_W_Todo2"
                                            className="widget-media dz-scroll ps ps--active-y height370"
                                        >
                                            <div className="row">
                                            
                                                <div className="col-lg-6 mb-3">
                                                    <div className="media">
                                                        <span className="p-2 border border-primary-light rounded-circle mr-3">
                                                            <i class="las la-allergies" style={{fontSize: '30px'}}></i>
                                                        </span>
                                                        <div className="media-body">
                                                            <span className="d-block text-primary mb-2">
                                                                Allergies
                                                            </span>
                                                            <p className="fs-14 text-dark">
                                                                <strong>{consultation.consultationQuestion?.allergies.toString()}</strong>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 mb-3">
                                                    <div className="media">
                                                        <span className="p-2 border border-primary-light rounded-circle mr-3">
                                                            <i class="las la-users" style={{fontSize: '30px'}}></i>
                                                        </span>
                                                        <div className="media-body">
                                                            <span className="d-block text-primary mb-2">
                                                                Antécédents Familiaux
                                                            </span>
                                                            <p className="fs-14 text-dark">
                                                                <strong>{consultation.consultationQuestion?.famAnts.toString()}</strong>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 mb-3">
                                                    <div className="media">
                                                        <span className="p-2 border border-primary-light rounded-circle mr-3">
                                                            <i class="las la-user" style={{fontSize: '30px'}}></i>
                                                        </span>
                                                        <div className="media-body">
                                                            <span className="d-block text-primary mb-2">
                                                                Antécédents personnels
                                                            </span>
                                                            <p className="fs-14 text-dark">
                                                                <strong>{consultation.consultationQuestion?.persAnts.toString()}</strong>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 mb-3">
                                                    <div className="media">
                                                        <span className="p-2 border border-primary-light rounded-circle mr-3">
                                                            <i class="las la-smoking" style={{fontSize: '30px'}}></i>
                                                        </span>
                                                        <div className="media-body">
                                                            <span className="d-block text-primary mb-2">
                                                                Habitudes
                                                            </span>
                                                            <p className="fs-14 text-dark">
                                                                <strong>{consultation.consultationQuestion?.habits.toString()}</strong>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 mb-3">
                                                    <div className="media">
                                                        <span className="p-2 border border-primary-light rounded-circle mr-3">
                                                            <i class="las la-briefcase" style={{fontSize: '30px'}}></i>
                                                        </span>
                                                        <div className="media-body">
                                                            <span className="d-block text-primary mb-2">
                                                                Profession
                                                            </span>
                                                            <p className="fs-14 text-dark">
                                                                <strong>{consultation.consultationQuestion?.identities.toString()}</strong>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 mb-3">
                                                    <div className="media">
                                                        <span className="p-2 border border-primary-light rounded-circle mr-3">
                                                            <i class="las la-search" style={{fontSize: '30px'}}></i>
                                                        </span>
                                                        <div className="media-body">
                                                            <span className="d-block text-primary mb-2">
                                                                Inspection
                                                            </span>
                                                            <p className="fs-14 text-dark">
                                                                <strong>{consultation.consultationQuestion?.inspections.toString()}</strong>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 mb-3">
                                                    <div className="media">
                                                        <span className="p-2 border border-primary-light rounded-circle mr-3">
                                                            <i class="las la-hand-paper" style={{fontSize: '30px'}}></i>
                                                        </span>
                                                        <div className="media-body">
                                                            <span className="d-block text-primary mb-2">
                                                                Palpation
                                                            </span>
                                                            <p className="fs-14 text-dark">
                                                                <strong>{consultation.consultationQuestion?.palpations.toString()}</strong>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 mb-3">
                                                    <div className="media">
                                                        <span className="p-2 border border-primary-light rounded-circle mr-3">
                                                            <i class="las la-stethoscope" style={{fontSize: '30px'}}></i>
                                                        </span>
                                                        <div className="media-body">
                                                            <span className="d-block text-primary mb-2">
                                                                Auscultation
                                                            </span>
                                                            <p className="fs-14 text-dark">
                                                                <strong>{consultation.consultationQuestion?.oscultations.toString()}</strong>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 mb-3">
                                                    <div className="media">
                                                        <span className="p-2 border border-primary-light rounded-circle mr-3">
                                                            <i class="las la-gavel" style={{fontSize: '30px'}}></i>
                                                        </span>
                                                        <div className="media-body">
                                                            <span className="d-block text-primary mb-2">
                                                                Percussion
                                                            </span>
                                                            <p className="fs-14 text-dark">
                                                                <strong>{consultation.consultationQuestion?.percussions.toString()}</strong>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 mb-3">
                                                    <div className="media">
                                                        <span className="p-2 border border-primary-light rounded-circle mr-3">
                                                            <i class="las la-gavel" style={{fontSize: '30px'}}></i>
                                                        </span>
                                                        <div className="media-body">
                                                            <span className="d-block text-primary mb-2">
                                                                Observaions
                                                            </span>
                                                            <p className="fs-14 text-dark">
                                                                {consultation.consultationQuestion?.observations}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </PerfectScrollbar>
                                    </div>
                                </div>
                            </div>
                        </div>

                        
                        <div className='bon-print col'>
                            <h1>Ordonance A-Care</h1>
                            
                            <h4 className='mt-2 text-dark text-start'>
                                <span style={{textDecoration: 'underline'}}>Patient:</span>
                                <span className='text-bold text-primary ml-2'>{consultation.consultationBon?.beneficiaryName}</span>
                            </h4>
                            <h4 className='mt-2 text-dark text-start'>
                                <span style={{textDecoration: 'underline'}}>{t('laboratory.prescriber')}:</span>
                                <span className='text-bold text-primary ml-2'>{currentUser.provider.providerName}</span>
                            </h4>

                            <div className="border-top my-2"></div>

                            {consultation.consultationBon?.prescription.pharmacieBon?.map(bon => {
                                return <div className="row my-4">
                                    <h2 className='text-center mb-5'>Bon de pharmacie n°{bon.code}</h2>
                                    <div className="card">
                                        <DataTable 
                                            value={bon.pharmacieBonDetails} 
                                            showGridlines 
                                            tableStyle={{ minWidth: '50rem' }}
                                        >
                                            <Column field="medicament.name" header="Médicament" style={{ minWidth: '200px' }}></Column>
                                            <Column field="quantite" header="Quantite" style={{ minWidth: '200px' }}></Column>
                                            <Column field="medicament.unit" header="Unité" style={{ minWidth: '150px' }}></Column>
                                        </DataTable>
                                    </div>
                                </div>})
                            }

                            <div className="border-top my-2"></div>

                            {consultation.consultationBon?.prescription.examensBon?.map(bon => {
                                return <div className="row my-4">
                                    <h2 className='text-center mb-5'>Bon d'examens n°{bon.code}</h2>
                                    <div className="card">
                                        <DataTable 
                                            value={bon.examenBonDetail} 
                                            showGridlines 
                                            tableStyle={{ minWidth: '50rem' }}
                                        >
                                            <Column field="acteName" header="Examen" style={{ minWidth: '200px' }}></Column>
                                        </DataTable>
                                    </div>
                                </div>})
                            }

                            <div className="border-top my-2"></div>

                            {consultation.consultationBon?.prescription.acteSpecialiseBon?.map(bon => {
                                return <div className="row my-4">
                                    <h2 className='text-center mb-5'>Bon d'actes spécialisés n°{bon.code}</h2>
                                    <div className="card">
                                        <DataTable 
                                            value={bon.specialiseBonDetails} 
                                            showGridlines 
                                            tableStyle={{ minWidth: '50rem' }}
                                        >
                                            <Column field="acteName" header="Acte" style={{ minWidth: '200px' }}></Column>
                                        </DataTable>
                                    </div>
                                </div>})
                            }

                            <div className="border-top my-2"></div>

                            {consultation.consultationBon?.prescription.opticBon?.map(bon => {
                                return <div className="row my-4">
                                    <h2 className='text-center mb-5'>Bon d'optique n°{bon.code}</h2>
                                    <div className="card">
                                        <DataTable 
                                            value={bon.specialiseBonDetails} 
                                            showGridlines 
                                            tableStyle={{ minWidth: '50rem' }}
                                        >
                                            <Column field="acteName" header="Acte" style={{ minWidth: '200px' }}></Column>
                                        </DataTable>
                                    </div>
                                </div>})
                            }

                            <div className="border-top my-2"></div>
                            
                            {consultation.consultationBon?.prescription.kinesitherapieBons?.map(bon => {
                                return <div className="row my-4">
                                    <h2 className='text-center mb-5'>Bon de kinésithérapie n°{bon.code}</h2>
                                    <div className="card">
                                        <DataTable 
                                            value={bon.kinesitherapieBonDetails} 
                                            showGridlines 
                                            tableStyle={{ minWidth: '50rem' }}
                                        >
                                            <Column field="acteName" header="Acte" style={{ minWidth: '200px' }}></Column>
                                            <Column field="acteName" header="Description" style={{ minWidth: '200px' }}></Column>
                                        </DataTable>
                                    </div>
                                </div>})
                            }
                        </div>
                    </div>
                    {/*<>
                        { showAccordLoading && 
                        <div id="preloader">
                            <div className="sk-three-bounce">
                                <div className="sk-child sk-bounce1"></div>
                                <div className="sk-child sk-bounce2"></div>
                                <div className="sk-child sk-bounce3"></div>
                            </div>
                        </div> }
                        {accordPreas?.filter(acc => {return acc.dossierConsultationId === consultation.id}).length > 0 && showDiscuss && !showAccordLoading &&
                            <div className="col-4">
                                {accordPreas?.filter(acc => {return acc.dossierConsultationId === consultation.id})?.map(accordBlock => {
                                    return <div className="row mb-4">    
                                        <div className="card chat dz-chat-history-box">
                                            <div className="card-header chat-list-header justify-content-center text-center">
                                                <div>
                                                    <h6 className="mb-1">{accordBlock?.dossierConsultation?.consultationBon?.doctorName}</h6>
                                                    <p className="mb-0 text-success">{accordBlock?.acteName}</p>
                                                </div>
                                            </div>
                                            <PerfectScrollbar
                                                className="card-body msg_card_body dz-scroll ps ps--active-y height370"
                                                id="DZ_W_Contacts_Body3"
                                                scrollTop="100"
                                            >
                                                { accordBlock?.accordPreableDisccusions?.map(messag => {
                                                    if(messag.senderId === currentUser.id){
                                                        return <div className="d-flex justify-content-end mb-4">
                                                                    <div className="msg_cotainer_send">
                                                                        {messag.message}
                                                                        {messag.docUrl && <a
                                                                            href={messag.docUrl}
                                                                            target="_blank"
                                                                            className="btn btn-light btn-rounded py-1"
                                                                            >
                                                                            <i className="las la-file-download mr-2"></i>
                                                                            <small>Document</small>
                                                                        </a>}
                                                                        <span className="msg_time_send">{messag.createdAt}</span>
                                                                    </div>
                                                                    <div className="img_cont_msg">
                                                                        <img
                                                                            src={avatar2}
                                                                            className="rounded-circle user_img_msg"
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                </div>
                                                    }else{
                                                        return <div className="d-flex justify-content-start mb-4">
                                                        <div className="img_cont_msg">
                                                            <img
                                                                src={avatar1}
                                                                className="rounded-circle user_img_msg"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="msg_cotainer">
                                                            {messag.message}
                                                            {messag.docUrl && <a
                                                                href={messag.docUrl}
                                                                target="_blank"
                                                                className="btn btn-light btn-rounded py-1"
                                                                >
                                                                <i className="las la-file-download mr-2"></i>
                                                                <small>Document</small>
                                                            </a>}
                                                            <span className="msg_time">{messag.createdAt}</span>
                                                        </div>
                                                    </div>

                                                    }
                                                })}
                                                {accordPreas?.filter(acc => {return acc.dossierConsultationId === consultation.id})[0]?.status === "REFUSED"  && <div className="d-flex justify-content-start mb-4">
                                                        <div className="img_cont_msg">
                                                            <img
                                                                src={avatar1}
                                                                className="rounded-circle user_img_msg"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="msg_cotainer bg-danger">
                                                            Rejeté pour: {accordPreas?.filter(acc => {return acc.dossierConsultationId === consultation.id})[0]?.motifRejet}
                                                            <span className="msg_time">{accordPreas?.filter(acc => {return acc.dossierConsultationId === consultation.id})[0]?.updatedAt}</span>
                                                        </div>
                                                    </div>}
                                                {accordPreas?.filter(acc => {return acc.dossierConsultationId === consultation.id})[0]?.status === "ACCEPT"  &&  <div className="d-flex justify-content-start mb-4">
                                                        <div className="img_cont_msg">
                                                            <img
                                                                src={avatar1}
                                                                className="rounded-circle user_img_msg"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="msg_cotainer bg-success">
                                                            Accord Validé
                                                            <span className="msg_time">{accordPreas?.filter(acc => {return acc.dossierConsultationId === consultation.id})[0]?.updatedAt}</span>
                                                        </div>
                                                </div>}
                                            </PerfectScrollbar>
                                            {accordPreas?.filter(acc => {return acc.dossierConsultationId === consultation.id})[0]?.status === "Pending" && <div className="card-footer">
                                                <div className="mb-2">
                                                    {suggestions.map((suggestion, i) => {
                                                        return (
                                                            <Badge variant="dark light mr-1 mb-1" key={i} onClick={(e) => setMessage(suggestion)}>
                                                                <span>{suggestion}</span>
                                                            </Badge>
                                                        )
                                                    })}
                                                </div>
                                                <form onSubmit={e => sendMessage(e, accordPreas?.filter(acc => {return acc.dossierConsultationId === consultation.id})[0]?.id)}>
                                                    <div className="row">
                                                        <textarea
                                                            value={message}
                                                            onChange={(e) => setMessage(e.target.value)}
                                                            className="form-control mb-2"
                                                            cols={20}
                                                            required
                                                            placeholder="Type your message..."
                                                        ></textarea>
                                                    </div>
                                                    <div className='row mb-3'>
                                                        <div className='custom-file'>
                                                            <input type="file" className='custom-file-input' onChange={(e) => loadFile(e)} />
                                                            <label className='custom-file-label'>{fileSrc ? <small>{fileSrc.name}</small> : 'Choisir'}</label>
                                                        </div>
                                                    </div>
                                                    <div className=" row input-group-append d-flex justify-content-end">
                                                        <button type="submit" className="btn btn-primary">
                                                            <i className="fa fa-location-arrow"></i>
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>}
                                        </div>
                                    </div>
                                })}
                            </div>
                        }
                    </>*/}
                </div>

            </>
            }
        </>
    )
};

const mapStateToProps = (state) => {
    return {
        consultation: state.consultation.consultation,
        subsRequests: state.pharmacie.subsRequests,
        accordPreas: state.accordPrea.accordPreas,
        currentUser: currentUser(state),
        showLoading: state.consultation.showLoading,
        showAccordLoading: state.accordPrea.showLoading,
        updateActDone: state.consultation.updateActDone,
        prolongHospiDone: state.consultation.prolongHospiDone,
        endHospiDone: state.consultation.endHospiDone,
    };
};

export default withRouter(connect(mapStateToProps)(DetailsConsultation));