import React, {useState} from "react";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { DataTableBase } from '../../DataTables/DataTableBase';
import { STATUSES } from '../../../globals';
import { useTranslation } from "react-i18next";
        

export const OptiqueBonsTable = (props) => {
    
    const { t } = useTranslation();
    const [filters, setFilters] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const {data} = props;

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            acteName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            executedBy: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            executeDate: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            status: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        });
        setGlobalFilterValue("");
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <Button type="button" icon="pi pi-filter-slash" label="Réinitialiser" outlined onClick={clearFilter} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Rechercher" />
                </span>
            </div>
        );
    };

    const clearFilter = () => {
        initFilters();
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const setColumns = () => {
        return [
            {name: 'acteName', headerName: "Examen", filterPlaceholder: 'Filtrer par examen', style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
            {name: 'executedBy', headerName: "Centre exécutant", filterPlaceholder: 'Filtrer par centre exécutant', style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
            {name: 'executeDate', filterField: 'date', dataType: 'date', headerName: 'Exécuté le', filterPlaceholder: 'Filtrer par date d\'exécution', style: { minWidth: '6rem' }, filter: true, bodyTemplate: dateBodyTemplate, filterElement: dateFilterTemplate},
            {name: 'status', headerName: 'Statut', filterPlaceholder: 'Filtrer par statut', style: { minWidth: '12rem' }, filter: true, bodyTemplate: statusBodyTemplate, filterElement: statusFilterTemplate},
        ]
    };

    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={data.map(exam => {
            return exam.status
        }).reduce(function (acc, curr) {
            if (!acc.includes(curr))
                acc.push(curr);
            return acc;
        }, [])} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={statusItemTemplate} placeholder="Choisir" className="p-column-filter" showClear />;
    };

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" mask="99/99/9999" />;
    };

    const dateBodyTemplate = (rowData) => {
        return formatDate(rowData.date);
    };

    const statusBodyTemplate = (rowData) => {
        return <Tag value={t('statuses.'+rowData.status)} severity={getSeverity(rowData.status)} />;
    };

    const statusItemTemplate = (option) => {
        return <Tag value={option} severity={getSeverity(option)} />;
    };

    const getSeverity = (status) => {
        switch (status) {
            case STATUSES.rejeted:
                return 'danger';
            
            case STATUSES.pending:
                return 'warning';

            default:
                return 'success';
        }
    };

    const formatDate = (value) => {
        return value ? value.toLocaleDateString('fr-FR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        }) : '';
    };

    return (
        <>
            <DataTableBase 
                data={data.map(bon => {
                    bon.executeDate = new Date(bon.executeDate);
                    return bon;
                })}
                size="small"
                emptyMessage="Aucun prescription optique trouvée" 
                filters={filters}
                globalFiltersFields={['acteName', 'status', 'executeDate', 'executedBy']}
                header={renderHeader()}
                columns={setColumns()}
                rows={10} 
                loading={props.showLoading}
            />
        </>
    )
};

export default OptiqueBonsTable;