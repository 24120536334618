import axios from 'axios';
const headers = { 'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('userDetails'))?.token, 'Accept-Language': localStorage.getItem('currentLanguage')};

const consultationServiceRequest = axios.create({
  baseURL: process.env.REACT_APP_BASEURL_CONSULTATION_BACKEND,
  headers: headers
});

export const getAllAccordRequest = () => consultationServiceRequest.get('/accords-preables');
export const validateAccord = (id) => consultationServiceRequest.put('/accord-preable/'+id+'/accept');
export const rejectAccord = (id, data) => consultationServiceRequest.put('/accord-preable/'+id+'/refuse', data);
export const sendAccordMessage = (data) => consultationServiceRequest.post('/accord-preable/discussion', data);
export const getAccordDetails = (accordId) => consultationServiceRequest.get('/accords-preable/'+accordId);
