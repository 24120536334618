import axios from 'axios';
const headers = { 'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('userDetails'))?.token, 'Accept-Language': localStorage.getItem('currentLanguage')};

const consultationServiceRequest = axios.create({
  baseURL: process.env.REACT_APP_BASEURL_CONSULTATION_BACKEND,
  headers: headers
});

export const getBonByCode = (code, providerId) => consultationServiceRequest.get('/Optic/bon/'+code+'/'+providerId);
export const sendProforma = (data) => consultationServiceRequest.post('/Optic/send-proformat/bon', data);
export const executeBon = (data) => consultationServiceRequest.post('/Optic/execute/bon/'+data);
