import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { Row, Col, Card, Tab, Nav } from 'react-bootstrap'
import { getFacturesAction, startLoader, requestPaymentAction, getFacturesImpayesAction, 
getBodereauxAction, getPaidBodereauxAction, getUnpaidBodereauxAction, getOnTreatmentBodereauxAction } from "../../../store/actions/FacturationAction";
import { currentUser } from "../../../store/selectors/CurrentUserSelector";
import { useTranslation } from "react-i18next";
import { Bordereaux } from "./Bordereaux";
import { BordereauForm } from "./BordereauForm";
import { paidBordereau } from "../../../services/FacturationService";

export const FacturationList = (props) => {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const unpaidStatuses = ["102", "103", "104"];
    const paidStatuses = ["205"];

    const date = new Date();

    const [tabData, setTabData] = useState([
        {
          name: t('common.pending-bills'),
          icon: 'file-invoice',
          component: BordereauForm,
        },
        {
          name: t('common.unsubmit-billboard'),
          icon: 'user-clock',
          component: Bordereaux,
        },
        {
          name: t('common.pending-billboard'),
          icon: 'business-time',
          component: Bordereaux,
        },
        {
          name: t('common.paid-billboard'),
          icon: 'history',
          component: Bordereaux,
        }
    ]);

    useEffect(() => {
        if(hasMenu('MENFACTURE') && canDo('MENFACTURE', 'ACVIEWFACT')){
            dispatch(startLoader());
            var date = new Date();
            let dateDebut = new Date(date.getFullYear(), date.getMonth(), 1);
            let dateFin = new Date();
            dispatch(getFacturesImpayesAction(props.currentUser.providerId, dateDebut, dateFin));
            dispatch(getPaidBodereauxAction(props.currentUser.providerId));
            dispatch(getUnpaidBodereauxAction(props.currentUser.providerId));
            dispatch(getOnTreatmentBodereauxAction(props.currentUser.providerId));
        }else{
            props.history.push('/access-denied');
        }
    }, []);


    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
          return false;
        }else{
          return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
      }
    
     const hasMenu = (menuCode) => {
          return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
      }

    return (
        <Fragment>
            
            { props.showLoading && 
                <div id="preloader">
                    <div className="sk-three-bounce">
                        <div className="sk-child sk-bounce1"></div>
                        <div className="sk-child sk-bounce2"></div>
                        <div className="sk-child sk-bounce3"></div>
                    </div>
                </div> 
            }

            { !props.showLoading &&
                <Row>
                    <Col xl={12}>
                        <Card>
                            <Card.Header>
                                <Card.Title>{t('common.billing')}</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                {/* <!-- Nav tabs --> */}
                                <div className='custom-tab-1'>
                                    <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                                        <Nav as='ul' className='nav-tabs'>
                                            {tabData.map((data, i) => (
                                                <Nav.Item as='li' key={i}>
                                                    <Nav.Link eventKey={data.name.toLowerCase()}>
                                                        <i className={`la la-${data.icon} mr-2`} />
                                                        {data.name}
                                                    </Nav.Link>
                                                </Nav.Item>
                                            ))}
                                        </Nav>
                                        <Tab.Content className='pt-4'>
                                            {tabData.map((data, i) => (
                                                <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                                                    <data.component 
                                                        history={props.history} 
                                                        currentUser={props.currentUser} 
                                                        factures={props.factureImpayees}
                                                        type={data.name}
                                                        dateMin={props.dateDebut}
                                                        dateMax={props.dateFin}
                                                        bordereaux={data.name === t('common.paid-billboard') ? 
                                                                    props.paidBordereaux :
                                                                    data.name === t('common.unsubmit-billboard') ?
                                                                        props.unpaidBordereaux :
                                                                        props.onTreatmentBordereaux
                                                                }
                                                    />
                                                </Tab.Pane>
                                            ))}
                                        </Tab.Content>
                                    </Tab.Container>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            }
        </Fragment>
    )
};

const mapStateToProps = (state) => {
    return {
        factures: state.facturation.factures,
        factureImpayees: state.facturation.factureImpayees,
        paidBordereaux: state.facturation.paidBordereaux,
        unpaidBordereaux: state.facturation.unpaidBordereaux,
        onTreatmentBordereaux: state.facturation.onTreatmentBordereaux,
        showLoading: state.facturation.showLoading,
        dateDebut: state.facturation.dateDebut,
        dateFin: state.facturation.dateFin,
        currentUser: currentUser(state)
    };
};

export default withRouter(connect(mapStateToProps)(FacturationList));