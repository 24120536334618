import {getCountries, getWorkingFields, getParamIdent} from '../../services/ParameterService';
import { successToastr, errorToastr } from '../../Utils/ToastrMessage';

export const LIST_COUNTRIES_CONFIRMED_ACTION = '[listCountries action] confirmed listCountries';
export const LIST_COUNTRIES_FAILED_ACTION = '[listCountries action] failed listCountries';
export const LIST_WORKINGFIELDS_CONFIRMED_ACTION = '[listWorkingFields client action] confirmed listWorkingFields';
export const LIST_WORKINGFIELDS_FAILED_ACTION = '[listWorkingFields client action] failed listWorkingFields';

export const LIST_PARAM_IDENT_CONFIRMED_ACTION = '[listParamIdent client action] confirmed listParamIdent';
export const LIST_PARAM_IDENT_FAILED_ACTION = '[listParamIdent client action] failed listParamIdent';

var I18n = require('react-redux-i18n').I18n;

export const listCountriesAction = () => {
    return (dispatch) => {

        getCountries()
        .then((response) => {
            dispatch(confirmedCountriesListingAction([response.data]));
        })
        .catch((error) => {
            console.log(error);
            dispatch(countriesListingFailedAction(error.response?.data?.message));
        });
    };
};

export const listWorkingFieldsAction = () => {
    return (dispatch) => {

        getWorkingFields()
        .then((response) => {
            dispatch(confirmedWorkingFieldsListingAction(response.data));
        })
        .catch((error) => {
            console.log(error);
            dispatch(workingFieldsListingFailedAction(error.response?.data?.message));
        });
    };
};

export const listParamIdentAction = () => {
    return (dispatch) => {

        getParamIdent()
        .then((response) => {
            dispatch(confirmedParamIdentListingAction(response.data));
        })
        .catch((error) => {
            console.log(error);
            errorToastr(I18n.t('parameter.globalAct.error')+error.response?.data?.message);
            dispatch(paramIdentListingFailedAction(error.response?.data?.message));
        });
    };
};

export function paramIdentListingFailedAction(payload) {
    return {
        type: LIST_PARAM_IDENT_FAILED_ACTION,
        payload,
    };
};

export function confirmedParamIdentListingAction(payload) {
    return {
        type: LIST_PARAM_IDENT_CONFIRMED_ACTION,
        payload,
    };
};

export function confirmedCountriesListingAction(payload) {
    return {
        type: LIST_COUNTRIES_CONFIRMED_ACTION,
        payload,
    };
};

export function countriesListingFailedAction(payload) {
    return {
        type: LIST_COUNTRIES_FAILED_ACTION,
        payload,
    };
};

export function confirmedWorkingFieldsListingAction(payload) {
    return {
        type: LIST_WORKINGFIELDS_CONFIRMED_ACTION,
        payload,
    };
};

export function workingFieldsListingFailedAction(payload) {
    return {
        type: LIST_WORKINGFIELDS_FAILED_ACTION,
        payload,
    };
};