import { getBonByCode, markActeDone } from '../../services/ActesSpecialisesService';
import { errorToastr, successToastr } from '../../Utils/ToastrMessage';


export const GET_BON_CONFIRMED_ACTION = '[getAcSpecBon action] confirmed getAcSpecBon';
export const GET_BON_FAILED_ACTION = '[getAcSpecBon action] failed getAcSpecBon';

export const MARK_DONE_CONFIRMED_ACTION = '[makeActe action] confirmed makeActe';
export const MARK_DONE_FAILED_ACTION = '[makeActe action] failed makeActe';

export const START_LOADER_ACTION = '[actesSpecLoader action] started actesSpecLoader';

var I18n = require('react-redux-i18n').I18n;

export const getBonByCodeAction = (code) => {
    console.log("Action is called");
    return (dispatch) => {
        getBonByCode(code)
        .then((response) => {
            console.log(response.data);
            dispatch(confirmedGetBonAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('actSpec.getBon.error')+error.response?.data?.message);
            dispatch(getBonFailedAction(error.response?.data?.message));
        });
    };
};

export const markActeAsDoneAction = (data) => {
    console.log("Action is called");
    return async (dispatch) => {
        markActeDone(data)
        .then((response) => {
            console.log(response.data);
            successToastr(I18n.t('actSpec.markAct.success'));
            dispatch(confirmedMarkDoneAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('actSpec.markAct.error')+error.response?.data?.message);
            dispatch(markDoneFailedAction(error.response?.data?.message));
        });
    };
};

export function confirmedGetBonAction(payload) {
    return {
        type: GET_BON_CONFIRMED_ACTION,
        payload,
    };
};

export function getBonFailedAction(payload) {
    return {
        type: GET_BON_FAILED_ACTION,
        payload,
    };
};

export function confirmedMarkDoneAction(payload) {
    return {
        type: MARK_DONE_CONFIRMED_ACTION,
        payload,
    };
};

export function markDoneFailedAction(payload) {
    return {
        type: MARK_DONE_FAILED_ACTION,
        payload,
    };
};

export function startLoader() {
    return {
        type: START_LOADER_ACTION
    };
};