import axios from 'axios';
const headers = { 'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('userDetails'))?.token, 'Accept-Language': localStorage.getItem('currentLanguage')};

const ContratServiceRequest = axios.create({
    baseURL: process.env.REACT_APP_BASEURL_CONTRACT_BACKEND,
    headers: headers, 
});


//Contrat
export const getBeneficiaires = (nomBenef, numPolice, numSouscrip) => ContratServiceRequest.get('/Contrat/search',{
    params: {
      beneficiaire: nomBenef,
      police: numPolice,
      souscripteur: numSouscrip
    }
  });
export const getAssuresBeneficiaires = (numContrat) => ContratServiceRequest.get('/Contrat/beneficiaire/police/'+numContrat);
export const getSouscripteurs = () => ContratServiceRequest.get('/Contrat/souscripteurs');
export const getBeneficiaire = (id) => ContratServiceRequest.get('/Contrat/Beneficiaire/'+id); 