import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Badge, Dropdown, Button, ListGroup } from "react-bootstrap";
import DropdownMultiselect from 'react-multiselect-dropdown-bootstrap';
import PerfectScrollbar from "react-perfect-scrollbar";
import { connect, useDispatch } from 'react-redux';
import {getActesByTypeAction, getOpticParamsAction } from '../../../../../store/actions/ConsultationActions';
import { currentUser } from '../../../../../store/selectors/CurrentUserSelector';
import Select from 'react-select';
import './ConsultationStyle.css';
import { useTranslation } from "react-i18next";

const OptiqueForm = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const consult = JSON.parse(localStorage.getItem("consultation"));
    const [addOptique, setAddOptique] = useState(consult && consult.optique);
    const [acteId, setActeId] = useState((consult && consult.optique) ? consult.optique.acteId : "");
    const [acteName, setActeName] = useState((consult && consult.optique) ? consult.optique.acteName : "");
    const [ecartPupillaire, setEcartPupillaire] = useState((consult && consult.optique && consult.optique.details && consult.optique.details.createOpticBonCaracts) ? 
    consult.optique.details.createOpticBonCaracts.find(elt => elt.typeCaracteristique === "ecartPupillaire")?.valeurCaracteristique || 0 : 
    0);
    const [vlod, setVlod] = useState((consult && consult.optique) ? consult.optique.vlod : "");
    const [vlog, setVlog] = useState((consult && consult.optique) ? consult.optique.vlog : "");
    const [vpod, setVpod] = useState((consult && consult.optique) ? consult.optique.vpod : "");
    const [vpog, setVpog] = useState((consult && consult.optique) ? consult.optique.vpog : "");
    const [typeVerre, setTypeVerre] = useState((consult && consult.optique && consult.optique.details && consult.optique.details.length > 0 && consult.optique.details.createOpticBonCaracts) ? 
    consult.optique.details[0].createOpticBonCaracts.find(elt => elt.typeCaracteristique === "typeVerre")?.valeurCaracteristique || "" : 
    "");
    const [baseVerre, setBaseVerre] = useState((consult && consult.optique && consult.optique.details && consult.optique.details.length > 0 && consult.optique.details.createOpticBonCaracts) ? 
    consult.optique.details[0].createOpticBonCaracts.find(elt => elt.typeCaracteristique === "baseVerre")?.valeurCaracteristique || "" : 
    "");
    const [teinteVerre, setTeinteVerre] = useState((consult && consult.optique && consult.optique.details && consult.optique.details.length > 0 && consult.optique.details.createOpticBonCaracts) ? 
    consult.optique.details[0].createOpticBonCaracts.find(elt => elt.typeCaracteristique === "teinteVerre")?.valeurCaracteristique || "" : 
    "");
    const [traimentVerre, setTraimentVerre] = useState((consult && consult.optique && consult.optique.details && consult.optique.details.length > 0 && consult.optique.details.createOpticBonCaracts) ? 
    consult.optique.details[0].createOpticBonCaracts.find(elt => elt.typeCaracteristique === "traimentVerre")?.valeurCaracteristique || "" : 
    "");
    const [portVerre, setPortVerre] = useState((consult && consult.optique && consult.optique.details && consult.optique.details.length > 0 && consult.optique.details.createOpticBonCaracts) ? 
    consult.optique.details[0].createOpticBonCaracts.find(elt => elt.typeCaracteristique === "portVerre")?.valeurCaracteristique || "" : 
    "");
    const [details, setDetails] = useState((consult && consult.optique) ? consult.optique.details : []);
    const [vpValue, setVpValue] = useState((consult && consult.optique && consult.optique.vpod) ? consult.optique.vpod.sphère : "");
    const [opticDatas, setOpticDatas] = useState((consult && consult.optique) ?
    [
        {category: 'Voir de loin', acte: 'Oeil Droit', key: 'vlod', sphère: consult.optique.vlod?.sphère, cylindre: consult.optique.vlod?.cylindre, axe: consult.optique.vlod?.axe, prisme: consult.optique.vlod?.prisme, base: consult.optique.vlod?.base},
        {category: 'Voir de loin', acte: 'Oeil Gauche', key: 'vlog', sphère: consult.optique.vlog?.sphère, cylindre: consult.optique.vlog?.cylindre, axe: consult.optique.vlog?.axe, prisme: consult.optique.vlog?.prisme, base: consult.optique.vlog?.base}
    ] :
    [
        {category: 'Voir de loin', acte: 'Oeil Droit', key: 'vlod', sphère: 0, cylindre: 0, axe: 0, prisme: 0, base: 0},
        {category: 'Voir de loin', acte: 'Oeil Gauche', key: 'vlog', sphère: 0, cylindre: 0, axe: 0, prisme: 0, base: 0}
    ]);

    const [typesVerre, setTypesVerre] = useState([]);
    const [basesVerre, setBasesVerre] = useState([]);
    const [teintesVerre, setTeintesVerre] = useState([]);
    const [traimentsVerre, setTraitementsVerre] = useState([]);
    const [portsVerre, setPortsVerre] = useState([]);

    const {actesByType, accessoireVerre, paramsOptic} = props;

    const sphereBodyTemplate = (rowData) => {
        return <input
            value={rowData.sphère}
            type='number'
            min='0'
            width={"50px"}
            className='form-control input-group-prepend'
            disabled={!addOptique}
            onChange={e => {
                setOpticDatas(opticDatas.map(elt => {
                    if(elt.acte === rowData.acte) {
                        elt.sphère = e.target.value;
                    }
                    return elt;
                }));
                saveUpdates(e.target.value, rowData.key+" sphère");
            }}
        />
    }
    const cylindreBodyTemplate = (rowData) => {
        return <input
            value={rowData.cylindre}
            type='number'
            min='-2'
            width={"50px"}
            className='form-control input-group-prepend'
            disabled={!addOptique}
            onChange={e => {
                setOpticDatas(opticDatas.map(elt => {
                    if(elt.acte === rowData.acte) {
                        elt.cylindre = e.target.value;
                    }
                    return elt;
                }));
                saveUpdates(e.target.value, rowData.key+" cylindre");
            }}
        />
    }
    const axeBodyTemplate = (rowData) => {
        return <input
            value={rowData.axe}
            type='number'
            min='0'
            max='360'
            width={"50px"}
            className='form-control input-group-prepend'
            disabled={!addOptique}
            onChange={e => {
                setOpticDatas(opticDatas.map(elt => {
                    if(elt.acte === rowData.acte) {
                        elt.axe = e.target.value;
                    }
                    return elt;
                }));
                saveUpdates(e.target.value, rowData.key+" axe");
            }}
        />
    }
    const prismeBodyTemplate = (rowData) => {
        return <input
            value={rowData.prisme}
            type='number'
            min='-2'
            width={"50px"}
            className='form-control input-group-prepend'
            disabled={!addOptique}
            onChange={e => {
                setOpticDatas(opticDatas.map(elt => {
                    if(elt.acte === rowData.acte) {
                        elt.prisme = e.target.value;
                    }
                    return elt;
                }));
                saveUpdates(e.target.value, rowData.key+" prisme");
            }}
        />
    }
    const baseBodyTemplate = (rowData) => {
        return <input
            value={rowData.base}
            type='number'
            min='-2'
            width={"50px"}
            className='form-control input-group-prepend'    
            disabled={!addOptique}
            onChange={e => {
                setOpticDatas(opticDatas.map(elt => {
                    if(elt.acte === rowData.acte) {
                        elt.base = e.target.value;
                    }
                    return elt;
                }));
                saveUpdates(e.target.value, rowData.key+" base");
            }}
        />
    }
    const vpBodyTemplate = () => {
        return <input
            value={vpValue}
            type='text'
            width={"50px"}
            className='form-control input-group-prepend'
            disabled={!addOptique}
            onChange={e => {
                setVpValue(e.target.value);
                saveUpdates({
                    sphère: parseFloat(e.target.value),
                    cylindre: 0,
                    axe: 0,
                    prisme: 0,
                    base: 0
                }, "vpod");
                saveUpdates({
                    sphère: parseFloat(e.target.value),
                    cylindre: 0,
                    axe: 0,
                    prisme: 0,
                    base: 0
                }, "vpog");
            }}
        />
    }

    useEffect(() => {
        dispatch(getActesByTypeAction('optique'));
        //dispatch(getActesByTypeAction('Accessoires verres'));
        dispatch(getOpticParamsAction('paramOptique'));
    }, []);

    useEffect(() => {
        setTeintesVerre(paramsOptic.filter(elt => elt.code === 'teinte_verre').map(val =>{ 
            return {label: val.nameFr, value: val.nameFr}
        }))
        setTraitementsVerre(paramsOptic.filter(elt => elt.code === 'traitement_verre').map(val =>{ 
            return {label: val.nameFr, value: val.nameFr}
        }))
        setTypesVerre(paramsOptic.filter(elt => elt.code === 'type_verre').map(val =>{ 
            return {label: val.nameFr, value: val.nameFr}
        }))
        setBasesVerre(paramsOptic.filter(elt => elt.code === 'matiere').map(val =>{ 
            return {label: val.nameFr, value: val.nameFr}
        }))
        setPortsVerre(paramsOptic.filter(elt => elt.code === 'port_verre').map(val =>{ 
            return {label: val.nameFr, value: val.nameFr}
        }))
    }, [paramsOptic]);

    const saveUpdates = (value, type) => {
        console.log(value);
        let consultation = JSON.parse(localStorage.getItem("consultation"));
        if(type === "addOptique") {
            if(value) {
                console.log("adding the optique key");
                consultation['optique'] = {
                    acteId: actesByType[0].id,
                    acteName: actesByType[0].nameFr,
                    vlod: {
                        sphère: 0,
                        cylindre: 0,
                        axe: 0,
                        prisme: 0,
                        base: 0
                    },
                    vlog: {
                        sphère: 0,
                        cylindre: 0,
                        axe: 0,
                        prisme: 0,
                        base: 0
                    },
                    vpog: {
                        sphère: 0,
                        cylindre: 0,
                        axe: 0,
                        prisme: 0,
                        base: 0
                    },
                    vpod: {
                        sphère: 0,
                        cylindre: 0,
                        axe: 0,
                        prisme: 0,
                        base: 0
                    },
                    details: [{
                        acteId: actesByType[0].id,
                        acteName: actesByType[0].nameFr,
                        createOpticBonCaracts: []
                    }, {
                        acteId: actesByType[1].id,
                        acteName: actesByType[1].nameFr,
                        createOpticBonCaracts: []
                    }]
                }
            }else{
                delete consultation['optique'];
            }
            console.log(consultation);
            localStorage.setItem("consultation", JSON.stringify(consultation));
            return;
        }

        let temp = consultation.optique;
        if(type.split(' ').includes('vlog') || type.split(' ').includes('vlod')) {
            let keys = Object.keys(temp);
            if(keys.includes(type.split(' ')[0])) {
                temp[type.split(' ')[0]][type.split(' ')[1]] = parseFloat(value);
                console.log(temp)
            }else{
                temp[type.split(' ')[0]] = {};
                temp[type.split(' ')[0]][type.split(' ')[1]] = parseFloat(value);
            }
        }else if(type === 'vpod' || type === 'vpog') {
            temp[type] = value;
        }else{
            let existingCaract = temp.details[0].createOpticBonCaracts.find(elt => elt.typeCaracteristique === type);
            if(existingCaract) {
                temp.details[0].createOpticBonCaracts = temp.details[0].createOpticBonCaracts.map(elt => {
                    if(elt.typeCaracteristique === type) {
                        elt.valeurCaracteristique = value
                    }
                    return elt
                });
            }else{
                temp.details[0].createOpticBonCaracts.push({
                    typeCaracteristique: type,
                    valeurCaracteristique: value
                });
            }
        }

        consultation.optique = temp;
        
        localStorage.setItem("consultation", JSON.stringify(consultation));
    }

    const footerGroup = (
        <ColumnGroup>
            <Row>
                <Column footer="Voir de près"/>
                <Column footer="AddODG" />
                <Column footer={vpBodyTemplate} colSpan={5}/>
            </Row>
        </ColumnGroup>
    );


    return (
      <>
        <div className='row'>
            <div className='col-xl-12 col-lg-12'>
                <div className='card'>
                    <div className='card-header'>
                        <h4 className='card-title'>Prescription Optique</h4>
                    </div>
                    <div className='card-body'>
                        <div className='basic-form'>
                            <form onSubmit={(e) => e.preventDefault()}>
                                <div className='form-group'>
                                    <div className='d-flex justify-content-start'>
                                        <div className='custom-control custom-checkbox mb-3 checkbox-danger'>
                                            <input
                                                type='checkbox'
                                                checked={addOptique}
                                                onChange={(e) => {
                                                    setAddOptique(e.target.checked);
                                                    saveUpdates(e.target.checked, "addOptique");
                                                }}
                                                className='custom-control-input'
                                                id='customCheckBoxOptique1'
                                                required
                                            />
                                            <label
                                            className='custom-control-label'
                                            htmlFor='customCheckBoxOptique1'
                                            >
                                                Prescrire des lunettes
                                            </label>
                                        </div>
                                    </div>
                                    <div className='row '>
                                        <div className='col-12'>
                                            <DataTable value={opticDatas} showGridlines rowGroupMode="rowspan" groupRowsBy="category" footerColumnGroup={footerGroup} tableStyle={{ minWidth: '50rem' }}>
                                                <Column field="category" header=""></Column>
                                                <Column field="acte" header=""></Column>
                                                <Column field="sphère" header="sphère" body={sphereBodyTemplate}></Column>
                                                <Column field="cylindre" header="cylindre" body={cylindreBodyTemplate}></Column>
                                                <Column field="axe" header="axe" body={axeBodyTemplate}></Column>
                                                <Column field="prisme" header="prisme" body={prismeBodyTemplate}></Column>
                                                <Column field="base" header="base" body={baseBodyTemplate}></Column>
                                            </DataTable>
                                            <div className='row mt-4'>
                                                <div className='form-group col-4'>
                                                    <label className='mb-1 '>
                                                        <strong>Ecart Pupillaire</strong>
                                                    </label>
                                                    <input
                                                        value={ecartPupillaire}
                                                        type='number'
                                                        min='1'
                                                        width={"50px"}
                                                        className='form-control input-group-prepend'
                                                        disabled={!addOptique}
                                                        onChange={e => {
                                                            setEcartPupillaire(e.target.value);
                                                            saveUpdates(e.target.value, "ecartPupillaire");
                                                        }}
                                                    />
                                                </div>
                                                <div className='form-group col-4'>
                                                    <label className='mb-1 '>
                                                        <strong>Type</strong>
                                                    </label>
                                                    <Select
                                                        className="select-search"
                                                        options={typesVerre}
                                                        value={typesVerre.find(elt => elt.value === typeVerre)}
                                                        placeholder="Choisir le type de verre"
                                                        isDisabled={!addOptique}
                                                        search
                                                        onChange={(val) => {
                                                            setTypeVerre(val.value);
                                                            saveUpdates(val.value, "typeVerre");
                                                        }}
                                                    />
                                                </div>
                                                <div className='form-group col-4'>
                                                    <label className='mb-1 '>
                                                        <strong>Base</strong>
                                                    </label>
                                                    <Select
                                                        className="select-search"
                                                        options={basesVerre}
                                                        value={basesVerre.find(elt => elt.value === baseVerre)}
                                                        placeholder="Choisir la base du verre"
                                                        isDisabled={!addOptique}
                                                        search
                                                        onChange={(val) => {
                                                            setBaseVerre(val.value);
                                                            saveUpdates(val.value, "baseVerre");
                                                        }}
                                                    />
                                                </div>
                                                <div className='form-group col-4'>
                                                    <label className='mb-1 '>
                                                        <strong>Teinte</strong>
                                                    </label>
                                                    <Select
                                                        className="select-search"
                                                        options={teintesVerre}
                                                        value={teintesVerre.find(elt => elt.value === teinteVerre)}
                                                        placeholder="Choisir la teinte du verre"
                                                        isDisabled={!addOptique}
                                                        search
                                                        onChange={(val) => {
                                                            setTeinteVerre(val.value);
                                                            saveUpdates(val.value, "teinteVerre");
                                                        }}
                                                    />
                                                </div>
                                                <div className='form-group col-4'>
                                                    <label className='mb-1 '>
                                                        <strong>Traitement</strong>
                                                    </label>
                                                    <Select
                                                        className="select-search"
                                                        options={traimentsVerre}
                                                        value={traimentsVerre.find(elt => elt.value === traimentVerre)}
                                                        placeholder="Choisir le traitement du verre"
                                                        isDisabled={!addOptique}
                                                        search
                                                        onChange={(val) => {
                                                            setTraimentVerre(val.value);
                                                            saveUpdates(val.value, "traimentVerre");
                                                        }}
                                                    />
                                                </div>
                                                <div className='form-group col-4'>
                                                    <label className='mb-1 '>
                                                        <strong>Port</strong>
                                                    </label>
                                                    <Select
                                                        className="select-search"
                                                        options={portsVerre}
                                                        value={portsVerre.find(elt => elt.value === portVerre)}
                                                        placeholder="Choisir le port du verre"
                                                        isDisabled={!addOptique}
                                                        search
                                                        onChange={(val) => {
                                                            setPortVerre(val.value);
                                                            saveUpdates(val.value, "portVerre");
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </>
    )
}
const mapStateToProps = (state) => {
    return {
        actesByType: state.consultation.actesByType,
        rembCalculDatas: state.facturation.rembCalculDatas,
        currentUser: currentUser(state),
        accessoireVerre: state.consultation.accessoireVerre,
        paramsOptic: state.consultation.paramsOptic
    };
};

export default connect(mapStateToProps)(OptiqueForm);
