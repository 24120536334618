import React, { useState, useEffect } from "react";
import { connect, useDispatch } from 'react-redux';
import { askSubstitutionAction, startLoader } from "../../../store/actions/PharmacieActions";
import { searchDrugsAction } from "../../../store/actions/ConsultationActions";
import { Button, Modal, ListGroup } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";

export const SubstitutionModal = (props) => {
    const dispatch = useDispatch();
    const { medicament, manageSubsModal, history, bonDetailId, currentUser } = props;
    const [error, setError] = useState(null);
    const [keyword, setKeyword] = useState("");
    const [drug, setDrug] = useState('');

    

    const saveSubstitutionRequest = () => {
        if(drug === '') {
            setError("Vous devez choisir un médicament");
            return;
        }

        const data = {
            newMedicamentId: drug.id,
            providerId: currentUser.provider.id,
            bonDetailId
        };
        
        dispatch(startLoader());
        dispatch(askSubstitutionAction(data, history));
        manageSubsModal(false, true);

    }

    useEffect(() => {
        if(keyword.trim().length >= 3){
            dispatch(searchDrugsAction(keyword));
        }else{
            dispatch(searchDrugsAction('@@@'));
        }
    }, [keyword]);

    return (
            <>
                    <Modal.Header>
                        <Modal.Title  >
                                <h4 className="text-center" >DEMANDE DE SUBSTITUTION</h4>{' '}<br/>
                                <small>Nom: </small><b className="text-primary" >{medicament.name}</b><br/>
                                {/*<small>Prix: </small><b>{medicament.amount}</b><br/>
                                <small className="text-danger">** Veuillez choisir un médicament d'un prix inférieur ou égal à celui-ci</small>*/}
                        </Modal.Title>
                        <Button
                            variant=""
                            className="close"
                            onClick={() => manageSubsModal(false)}
                        >
                            <span>&times;</span>
                        </Button>
                    </Modal.Header>
                    <Modal.Body>
                        <h5>Veuillez choisir le medicament</h5>
                        
                        { error && <small className="text-danger">{{error}}</small> }

                        {drug !== '' && <div className="basic-list-group my-2">
                            <ListGroup>
                                <ListGroup.Item
                                    action
                                    active
                                    className="flex-column align-items-start"
                                    >
                                    <div className="d-flex w-100 justify-content-between">
                                        <h5 className="text-white" >
                                            {drug.name}
                                            {' '}
                                            <small className={'text-' + (!drug.isExclu ? 'success' : 'danger')}>{!drug.isExclu ? '(Couvert)' : '(Non Couvert)'}</small>
                                        </h5>
                                        
                                        <button type="button" className="btn btn-danger shadow btn-xs sharp mx-1"
                                            onClick={(e) => setDrug('')}
                                        >
                                            <i className="fa fa-close"></i>
                                        </button>
                                    </div>
                                </ListGroup.Item>
                            </ListGroup>
                        </div>}

                        <div className='row justify-content-between align-items-center'>
                            <div className='col-12 form-group'>
                                
                                <input type="search" value={keyword} onChange={e => setKeyword(e.target.value)} className='form-control input-default mb-2' /> 

                                <PerfectScrollbar
                                    id="DZ_W_Todo2"
                                    className={"widget-media dz-scroll ps ps--active-y "+ (props.drugList.length > 0 ? "height300" : "height0")}
                                >
                                    <div className="basic-list-group">
                                        <ListGroup>
                                            {props.drugList.map((disease, j) => {
                                                return <ListGroup.Item
                                                    action
                                                    active={drug === disease}
                                                    //disabled={medicament.amount < disease.amount}
                                                    key={j}
                                                    onClick={e => setDrug(disease)}
                                                    className="flex-column align-items-start"
                                                    >
                                                    <div className="d-flex w-100 justify-content-between">
                                                        <span className="text-dark" >
                                                            {disease.name}{' '}<small className={'text-' + (!disease.isExclu ? 'success' : 'danger')}>{!disease.isExclu ? '(Couvert)' : '(Non Couvert)'}</small>
                                                        </span>
                                                        
                                                        <span>[ {disease.code} ]</span>
                                                    </div>
                                                    </ListGroup.Item>
                                                })
                                            }
                                        </ListGroup>
                                    </div>
                                </PerfectScrollbar>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="danger light"
                            onClick={() => manageSubsModal(false, false)}
                        >
                            Annuler
                        </Button>
                        <Button
                            variant=""
                            type="button"
                            className="btn btn-primary"
                            disabled={drug === ''}
                            onClick={() => saveSubstitutionRequest()}
                        >
                            Soumettre
                        </Button>
                    </Modal.Footer>
            </>
    )
};

const mapStateToProps = (state) => {
    return {
        drugList: state.consultation.drugs
    };
};

export default connect(mapStateToProps)(SubstitutionModal);