import React, { Fragment, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import { Tab, Nav } from "react-bootstrap";
import { currentUser } from '../../../../store/selectors/CurrentUserSelector';

const Finances = (props) => {
    const dispatch = useDispatch();

    return (
        <Fragment>
            <p>Welcome</p>
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        provider: state.provider.provider,
        currentUser: currentUser(state)
    };
};

export default connect(mapStateToProps)(Finances);