import axios from 'axios';
const headers = { 'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('userDetails'))?.token, 'Accept-Language': localStorage.getItem('currentLanguage')};

const parameterServiceRequest = axios.create({
  baseURL: process.env.REACT_APP_BASEURL_ADMIN_BACKEND+"/parameter",
  headers: headers
});


export const getCountries = () => parameterServiceRequest.get('/Countries');

export const getWorkingFields = () => parameterServiceRequest.get('/WorkingFields');

export const getParamIdent = () => parameterServiceRequest.get('/acte-standard');
