import {
    START_LOADER_ACTION,
    GET_BON_CONFIRMED_ACTION,
    GET_BON_FAILED_ACTION,
    DELIEVER_EXAMS_CONFIRMED_ACTION,
    DELIEVER_EXAMS_FAILED_ACTION,
    COMMENT_EXAMS_CONFIRMED_ACTION,
    COMMENT_EXAMS_FAILED_ACTION,
    GET_PATIENT_RESULTS_CONFIRMED_ACTION,
    GET_PATIENT_RESULTS_FAILED_ACTION
} from '../actions/LaboratoireActions';

const initialState = {
    bon: {},
    patientResults: [],
    errorMessage: '',
    successMessage: '',
    showLoading: false,
};

export const LaboratoireReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_BON_CONFIRMED_ACTION:
            return {
                ...state,
                bon: action.payload,
                errorMessage: '',
                successMessage: 'Bon gotten Successfully Completed',
                showLoading: false,
            };
        case GET_BON_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case DELIEVER_EXAMS_CONFIRMED_ACTION:
            return {
                ...state,
                errorMessage: '',
                successMessage: 'Delievry Successfully Completed',
                showLoading: false,
            };
        case DELIEVER_EXAMS_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case COMMENT_EXAMS_CONFIRMED_ACTION:
            return {
                ...state,
                errorMessage: '',
                successMessage: 'Comment Successfully Completed',
                showLoading: false,
            };
        case COMMENT_EXAMS_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case GET_PATIENT_RESULTS_CONFIRMED_ACTION:
            return {
                ...state,
                patientResults: action.payload,
                errorMessage: '',
                successMessage: 'Patient Results gotten Successfully Completed',
                showLoading: false,
            };
        case GET_PATIENT_RESULTS_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case START_LOADER_ACTION:
            return {
                ...state,
                showLoading: true,
            };
        default:
            return state;
    };
};