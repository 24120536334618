import React, { useState, useEffect, useRef } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import Webcam from 'react-webcam';
import { useFaceDetection } from 'react-use-face-detection';
import FaceDetection from '@mediapipe/face_detection';
import { Camera } from '@mediapipe/camera_utils';
import {
    startLoader,
    registerClientAction,
    listClientsAction,
    getClientAction
} from '../../../store/actions/ClientAuthActions';
import { getParameterAction } from '../../../store/actions/AdministrationAction';
import { getBeneficiaireAction } from '../../../store/actions/ContratActions';
import { Button, Spinner } from 'react-bootstrap';
import SelectSearch from "react-select-search";
import Select from 'react-select';
import { DatePicker, DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { currentUser } from '../../../store/selectors/CurrentUserSelector';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './Enroll.css';
import { useTranslation } from "react-i18next";

function Enroll(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { id } = useParams();
    const { clients, admin, client, history } = props;
    //const client = id ? clients.filter((clt) => {return clt.id === parseInt(id)})[0] : null;
    
    const [choosenCountry, setChoosenCountry] = useState([]);
    const [choosenActivity, setChoosenActivity] = useState({});

    const [imgSrc, setImgSrc] = useState(null);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [whatsappPhone, setWhatsappPhone] = useState("");
    const [lon, setLon] = useState(0);
    const [lat, setLat] = useState(0);
    const [postal, setPostal] = useState("");
    const [idCity, setIdCity] = useState(0);
    const [idCountry, setIdCountry] = useState(0);
    const [email, setEmail] = useState("");
    const [sexe, setSexe] = useState("");
    const [birthdate, setBirthdate] = useState(new Date());
    const [idProfession, setIdProfession] = useState(0);
    const [idWorkingField, setIdWorkingField] = useState(0);
    //const [niu, setNiu] = useState("");
    const [maritalSituation, setMaritalSituation] = useState("");
    const [childNumber, setChildNumber] = useState("0");

    
    const [type, setType] = useState("");
    const [idNumber, setIdNumber] = useState("");
    const [deleveryDate, setDeleveryDate] = useState(new Date());
    const [expirationDate, setExpirationDate] = useState(new Date());

    const [weight, setWeight] = useState(0);
    const [height, setHeight] = useState(0);
    const [bloodPressionD, setBloodPressionD] = useState(0);
    const [bloodPressionS, setBloodPressionS] = useState(0);

    const [phoneNumbers, setPhoneNumbers] = useState([]);

    let errorsObj = { image: '', firstName: ''};
    const [errors, setErrors] = useState(errorsObj);

    const phoneCountries = process.env.REACT_APP_BASEURL_ADMIN_BACKEND.includes('civ') ? 
    ['ci'] :  
    process.env.REACT_APP_BASEURL_CONSULTATION_BACKEND.includes('rdc') ? 
    ['cd'] : 
    ['cm','ci','cd'];

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
          return false;
        }else{
          return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
      }
    
     const hasMenu = (menuCode) => {
          return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
      }

      useEffect(() => {
        if(hasMenu('MENENROLL') && canDo('MENENROLL', 'ACENRRF')){
            dispatch(getBeneficiaireAction(id, history));
            //dispatch(listClientsAction());
            dispatch(getParameterAction());
        }else{
            props.history.push("/provider-view/"+props.currentUser.providerId);
        }
    }, []);

    useEffect(() => {
        console.log(client);
        setFirstName(client.nom);
        setLastName(client.prenoms ? client.prenoms : "");
        setPhoneNumber(client.telephone);
        setWhatsappPhone(client.telephone);
        setEmail(client.email);
        setSexe(client.sexe);
        setBirthdate(new Date(client.dateNaissance));
        setType(client.cni ? "cni" : "");
        setIdNumber(client.cni ? client.cni : "");
    }, [client]);

    const { webcamRef, facesDetected } = useFaceDetection({
        faceDetectionOptions: {
          model: 'short',
        },
        faceDetection: new FaceDetection.FaceDetection({
          locateFile: (file) => `https://cdn.jsdelivr.net/npm/@mediapipe/face_detection/${file}`,
        }),
        camera: ({
          mediaSrc, onFrame, width, height,
        }) => new Camera(mediaSrc, {
          onFrame,
          width,
          height,
        })
      });
    
      const capture = () => {
        const errorObj = { ...errorsObj };
        errorObj.image = "";
        console.log(facesDetected);
        if(facesDetected === 1) {
            console.log(webcamRef.current);
            const imageSrc = webcamRef.current.getScreenshot();
            console.log(imageSrc);
            setImgSrc(imageSrc);
        }else {
            errorObj.image = "Faites face à la camera";
            console.log("Please face the camera");
        }
        setErrors(errorObj);
      };

      const recapture = () => {
        setImgSrc(null);
      }

    const base64MimeType = (encoded) =>{
        var result = null;
        
        if (typeof encoded !== 'string') {
            return result;
        }
        
        var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).,./);
        
        if (mime && mime.length) {
            result = mime[1];
        }
        
        return result;
    }

    const urlToFile = (url, filename, mimeType) =>{
        return (fetch(url)
            .then(function(res){return res.arrayBuffer();})
            .then(function(buf){return new File([buf], filename,{type:mimeType});})
        );
    }

    async function onSignUp(e) {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        console.log("About to save the user");
        console.log(firstName);
        if (!imgSrc) {
            errorObj.image = 'Ajoutez une image';
            error = true;
        }

        if (firstName === '') {
            errorObj.email = 'Le nom requis';
            error = true;
        }

        setErrors(errorObj);

        if (error) return;
            
        const mimeTypeImg = base64MimeType(imgSrc);

        const filename = ''+client.id+'.jpg';

        const imageFile = await urlToFile(imgSrc, filename, mimeTypeImg);

        console.log(imageFile);

        let clientDatas = {
            firstName,
            lastName,
            phoneNumber,
            whatsappPhone,
            lon: lon.toString(),
            lat: lat.toString(),
            postal,
            idCity,
            idCountry,
            email,
            sexe,
            birthdate,
            idProfession,
            idWorkingField,
            //niu,
            maritalSituation,
            childNumber,
            numeroPolice: client.numeroPolice,
            numeroBeneficiaire: client.numBeneficiaire,
            numeroBeneficiairePrin: client.numBeneficiairePrincipal,
            nomBeneficiairePrin: client.nomBeneficiairePrinc,
            telBeneficiairePrin: client.telBeneficiairePrinc,
            numeroAssurer: client.numBeneficiairePrincipal,
            souscripteur: client.souscripteur?.nom_Assu,
            assureur: client.police?.codeIntermediaire,
            numeroAssureur: client.police?.codeIntermediaire,
            phoneNumbers: phoneNumbers.map(elt => {
                return {phoneNumber: elt}
            }),
            clientIdDetails: {
                type,
                idNumber,
                deleveryDate,
                expirationDate
            },
            healhtParameters: {
                height,
                weight,
                bloodPressionD,
                bloodPressionS
            }
        };

        console.log(clientDatas);
        dispatch(startLoader());
        dispatch(registerClientAction(imageFile, client.id, clientDatas, props.history, imgSrc));
    }
    return (
        <div>
            <div className="form-head d-flex mb-3 mb-md-4 align-items-start justify-content-center bg-white">
                <div className='auth-form'>
                    <div className='row justify-content-start'>
                        <h2 className='text-center mb-4 '>Enrôlement <b>{client.nom}</b></h2>
                    </div>
                    <div className='bg-red-300 text-danger p-1 my-2'>
                    {t('patient.required-fields')}
                    </div>
                    <form onSubmit={onSignUp}>
                        <div className='form-group row justify-content-end align-items-center'>
                            <div className='col-8'>
                                <h5 className='mt-4 text-primary'>{t('client.identity')}</h5>
                                <div className="border-top mt-2 mb-4"></div>
                                <div className='row justify-content-between align-items-center'>
                                    <div className='col-3 form-group'>
                                        <label className='mb-1 '>
                                            <strong>{t('common.name')}<span className='text-danger'>*</span></strong>
                                        </label>
                                        <input type='text' className='form-control bg-light'
                                            disabled="true"
                                            value={firstName}
                                            onChange={(e) => setFirstName(e.target.value)}
                                            />
                                    
                                        {errors.firstName && <div className="text-danger fs-12">{errors.firstName}</div>}
                                    </div>
                                    <div className='col-3 form-group'>
                                        <label className='mb-1 '>
                                            <strong>{t('common.firstname')}<span className='text-danger'>*</span></strong>
                                        </label>
                                        <input type='text' className='form-control bg-light'
                                            disabled="true"
                                            value={lastName}
                                            onChange={(e) => setLastName(e.target.value)}
                                            />
                                    </div>
    
                                    <div className='col-3 form-group'>
                                        <label className='mb-1 '>
                                            <strong>{t('common.gender')}<span className='text-danger'>*</span></strong>
                                        </label>
                                        <select
                                            value={sexe}
                                            disabled="true"
                                            className='form-control form-control-lg bg-light'
                                            onChange={(e) => setSexe(e.target.value)}
                                        >
                                            <option value='option'>
                                            {t('common.choose')}
                                            </option>
                                            <option value='F'>{t('common.female')}</option>
                                            <option value='M'>{t('common.male')}</option>
                                        </select>
                                    </div>
    
                                    <div className='col-3 form-group'>
                                        <label className='mb-1 '>
                                            <strong>{t('common.birthdate')}<span className='text-danger'>*</span></strong>
                                        </label>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <DatePicker
                                                autoOk
                                                label=""
                                                clearable
                                                disabled="true"
                                                format="dd/MM/yyyy"
                                                disableFuture
                                                value={birthdate}
                                                onChange={setBirthdate}
                                                className="bg-light"
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    
                                </div> 
    
                                <div className='row justify-content-between align-items-center'>
                                    <div className='col-4 form-group'>
                                        <label className='mb-1 '>
                                            <strong>Email<span className='text-danger'>*</span></strong>
                                        </label>
                                        <div className='input-group mb-3 input-success-o'>
                                            <div className='input-group-prepend'>
                                                <span className='input-group-text'>
                                                    <i className='fa fa-at' />
                                                </span>
                                            </div>
                                            <input type='email' className='form-control'
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                />
                                        </div>
                                    </div>
    
                                    <div className='col-4 form-group'>
                                        <label className='mb-1 '>
                                            <strong>Whatsapp<span className='text-danger'>*</span></strong>
                                        </label>
                                        <PhoneInput
                                            country='cm'
                                            onlyCountries={phoneCountries}
                                            localization='fr'
                                            value={whatsappPhone}
                                            autoFormat={whatsappPhone ? !whatsappPhone.startsWith('225') : true}
                                            onChange={phone => setWhatsappPhone(phone)}
                                        />
                                        
                                    </div>
                                    <div className='col-4 form-group'>
                                        <label className='mb-1 '>
                                            <strong>{t('common.phone')}<span className='text-danger'>*</span></strong>
                                        </label>
                                        <PhoneInput
                                            country='cm'
                                            onlyCountries={phoneCountries}
                                            localization='fr'
                                            value={phoneNumber}
                                            autoFormat={phoneNumber ? !phoneNumber.startsWith('225') : true}
                                            onChange={phone => setPhoneNumber(phone)}
                                        />
                                    </div>
                                </div>
                                <div className='row justify-content-between align-items-center'>
                                    {phoneNumbers.map((phone, i) => (
                                        <div className='col-4 form-group' style={{position: "relative"}} key={i}>
                                            <label className='mb-1 '>
                                                <strong>{t('common.additional-phone')}{' '+(i+1)}</strong>
                                            </label>
                                            <div className='input-group mb-3 input-success-o'>
                                                
                                                <PhoneInput
                                                    country='cm'
                                                    onlyCountries={phoneCountries}
                                                    localization='fr'
                                                    value={phone}
                                                    autoFormat={phone ? !phone.startsWith('225') : true}
                                                    onChange={val => {
                                                        let temp = [...phoneNumbers];
                                                        temp[i] = val;
                                                        setPhoneNumbers(temp);
                                                    }}
                                                />
                                                <a
                                                    type="button"
                                                    className='icons-sm text-danger'
                                                    style={{
                                                        position: "absolute",
                                                        top: '-35px',
                                                        right: '-15px',
                                                    }}
                                                    onClick={() => {
                                                        const temp = [...phoneNumbers];
                                                        temp.splice(i, 1);
                                                        setPhoneNumbers(temp);
                                                    }}
                                                >
                                                    <i class="fa fa-close"></i>
                                                </a>
                                            </div>
                                        </div>
                                    ))}
                                    
                                    <div className='col-4 form-group'>
                                        <Button 
                                            label={t('common.add-phone')}
                                            icon="pi pi-plus"
                                            severity="info"
                                            onClick={(e) =>{ 
                                                console.log("the action is called");
                                                e.preventDefault();
                                                setPhoneNumbers(prevPhoneNumbers => [...prevPhoneNumbers, ""]);
                                            }}
                                        />
                                    </div>
                                </div>
    
                                
    
                                <div className='row justify-content-between align-items-center'>
                                    <div className='col-3 form-group'>
                                        <label className='mb-1 '>
                                            <strong>Type<span className='text-danger'>*</span></strong>
                                        </label>
                                        <select
                                            defaultValue={type}
                                            className='form-control form-control-lg'
                                            onChange={(e) => setType(e.target.value)}
                                        >
                                            <option value='option'>
                                            {t('common.choose')}
                                            </option>
                                            <option value='cni'>CNI</option>
                                            <option value='passeport'>Passeport</option>
                                            <option value='voter-card'>Carte d'électeur</option>
                                            <option value='birth-certificate'>Extrait de naissance</option>
                                        </select>
                                    </div>
                                    <div className='col-3 form-group'>
                                        <label className='mb-1 '>
                                            <strong>Numéro<span className='text-danger'>*</span></strong>
                                        </label>
                                        <input type='text' className='form-control'
                                            value={idNumber}
                                            onChange={(e) => setIdNumber(e.target.value)}
                                            />
                                    </div>
    
                                    <div className='col-3 form-group'>
                                        <label className='mb-1 '>
                                            <strong>{t('common.delivery-date')}<span className='text-danger'>*</span></strong>
                                        </label>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <DatePicker
                                                autoOk
                                                label=""
                                                clearable
                                                format="dd/MM/yyyy"
                                                disableFuture
                                                value={deleveryDate}
                                                onChange={setDeleveryDate}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
    
                                    <div className='col-3 form-group'>
                                        <label className='mb-1 '>
                                            <strong>{t('common.expiration-date')}<span className='text-danger'>*</span></strong>
                                        </label>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <DatePicker
                                                autoOk
                                                label=""
                                                clearable
                                                format="dd/MM/yyyy"
                                                disablePast
                                                value={expirationDate}
                                                onChange={setExpirationDate}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                </div>
                            </div>
                            <div className='col-4'>
                                <div className='picture-frame'>
                                    {!imgSrc && (
                                        <Webcam
                                        audio={false}
                                        mirrored
                                        ref={webcamRef}
                                        screenshotFormat="image/jpeg"
                                        style={{
                                            height: '100%',
                                            width: '100%',
                                            // objectFit: 'cover',
                                        }}
                                        />
                                    )}
                                    {imgSrc && (
                                        <img
                                        src={imgSrc}
                                        alt="camimg"
                                        />
                                    )}
                                </div>
                                {errors.image && <div className="text-danger fs-12 my-1">{errors.image}</div>}
                                <div className='capture-controls'>
                                    <Button onClick={() => capture()} className='mr-2' variant='outline-primary' size='sm'>
                                        {t('common.capture')}
                                    </Button>
                                    <Button onClick={() => recapture()} className='mr-2' variant='outline-warning' size='sm'>
                                    {t('common.retake')}
                                    </Button>
                                </div>
                            </div>
                        </div>
                        
                        <h5 className='mt-4 text-primary'> {t('common.health-parameter')}
                        </h5>
                        <div className="border-top mt-2 mb-4"></div>
                        <div className='row justify-content-between align-items-center'>
                            <div className='col-3 form-group'>
                                <label className='mb-1 '>
                                    <strong>{t('common.height')}</strong>
                                </label>
                                <input type='text' className='form-control'
                                    value={height}
                                    onChange={(e) => setHeight(e.target.value)}
                                    />
                            
                                {errors.firstName && <div className="text-danger fs-12">{errors.firstName}</div>}
                            </div>
                            <div className='col-3 form-group'>
                                <label className='mb-1 '>
                                    <strong>{t('common.weight')}</strong>
                                </label>
                                <input type='text' className='form-control'
                                    value={weight}
                                    onChange={(e) => setWeight(e.target.value)}
                                    />
                            </div>
                            <div className='col-3 form-group'>
                                <label className='mb-1 '>
                                    <strong>{t('client.diastolicPressure')}</strong>
                                </label>
                                <input type='text' className='form-control'
                                value={bloodPressionS}
                                onChange={(e) => setBloodPressionS(e.target.value)}
                                />
                                
                            </div>
                            <div className='col-3 form-group'>
                                <label className='mb-1 '>
                                    <strong>{t('client.systolicPressure')}</strong>
                                </label>
                                <input type='text' className='form-control'
                                    value={bloodPressionD}
                                    onChange={(e) => setBloodPressionD(e.target.value)}
                                    />
                            </div>
                        </div>
                        
                        <h5 className='mt-4 text-primary'>Localisation</h5>
                        <div className="border-top mt-2 mb-4"></div>
                        <div className='row justify-content-between align-items-center'>
                            <div className='col-3 form-group'>
                                <label className='mb-1 '>
                                    <strong>{t('common.country')}<span className='text-danger'>*</span></strong>
                                </label>
                                <Select
                                    className="select-search"
                                    options={admin.countries?.map((opt)=>{return {label:opt.name,value:opt.id}})}
                                    placeholder={t('client.chooseCountry')}
                                    search
                                    onChange={(val) => {
                                        setChoosenCountry(admin.countries?.filter((country) =>{return country.id === val.value}))
                                        setIdCountry(val.value);
                                    }}
                                />
                            </div>
                            <div className='col-3 form-group'>
                                <label className='mb-1 '>
                                    <strong>{t('common.city')}<span className='text-danger'>*</span></strong>
                                </label>
                                <Select
                                    className="select-search"
                                    options={choosenCountry[0]?.city.map((opt)=>{return {label:opt.name,value:opt.id}})}
                                    placeholder={t('client.chooseCity')}
                                    search
                                    onChange={(val) => {
                                        setIdCity(val.value);
                                    }}
                                />
                            </div>
    
                            
                            <div className='col-3 form-group'>
                                <label className='mb-1 '>
                                    <strong>{t('client.postalCode')}</strong>
                                </label>
                                <input type='text' className='form-control'
                                    value={postal}
                                    onChange={(e) => setPostal(e.target.value)}
                                    />
                            </div>
    
                            <div className='col-1 form-group'>
                                <label className='mb-1 '>
                                    <strong>Latitude</strong>
                                </label>
                                <input type='number' className='form-control'
                                    value={lat}
                                    onChange={(e) => setLat(e.target.value)}
                                    />
                            </div>
                            <div className='col-1 form-group'>
                                <label className='mb-1 '>
                                    <strong>Longitude</strong>
                                </label>
                                <input type='number' className='form-control'
                                    value={lon}
                                    onChange={(e) => setLon(e.target.value)}
                                    />
                            </div>
                        </div>
                        
                        <h5 className='mt-4 text-primary'>{t('client.additionalInfo')}</h5>
                        <div className="border-top mt-2 mb-4"></div>
                        <div className='row justify-content-between align-items-center'>
                            <div className='col-3 form-group'>
                                <label className='mb-1 '>
                                    <strong>Matrimonie<span className='text-danger'>*</span></strong>
                                </label>
                                <select
                                    value={maritalSituation}
                                    className='form-control form-control-lg'
                                    onChange={(e) => setMaritalSituation(e.target.value)}
                                >
                                    <option value='option'>
                                    {t('common.choose')}
                                    </option>
                                    <option value='maried'>{t('maritalStatus.maried')}</option>
                                    <option value='single'>{t('maritalStatus.single')}</option>
                                    <option value='divorced'>{t('maritalStatus.divorced')}</option>
                                    <option value='widow'>{t('maritalStatus.widow')}</option>
                                    <option value='concubinage'>Concubinage</option>
                                </select>
                            </div>
                            <div className='col-2 form-group'>
                                <label className='mb-1 '>
                                    <strong>{t('client.numberOfChildren')}<span className='text-danger'>*</span></strong>
                                </label>
                                <input type='number' className='form-control'
                                    value={childNumber}
                                    onChange={(e) => setChildNumber(e.target.value)}
                                    />
                            </div>
                            <div className='col-3 form-group'>
                                <label className='mb-1 '>
                                    <strong>{t('client.workingField')}</strong>
                                </label>
                                <Select
                                    className="select-search"
                                    options={admin.activities?.map((opt)=>{return {label:opt.nameFr,value:opt.id}})}
                                    placeholder={t('client.chooseWorkingField')}
                                    search
                                    onChange={(val) => {
                                        setChoosenActivity(admin.activities?.find((activity) =>{return activity.id === val.value}))
                                        setIdWorkingField(val.value);
                                    }}
                                />
                            </div>  
                            <div className='col-3 form-group'>
                                <label className='mb-1 '>
                                    <strong>Profession</strong>
                                </label>
                                
                                <Select
                                    className="select-search"
                                    options={choosenActivity?.prefession?.map((opt)=>{return {label:opt.nameFr,value:opt.id}})}
                                    placeholder={t('client.chooseProfession')}
                                    search
                                    onChange={(val) => {
                                        setIdProfession(val.value);
                                    }}
                                />
                            </div>
                        </div>
                        { props.showLoading && 
                            <div className='row justify-content-center mt-4'>
                                <div className='col-4'>
                                    <Button variant="primary" disabled>
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />{' '}
                                        <span className="visually-hidden">{t('common.await-text')}</span>
                                    </Button> 
                                </div>
                            </div>}
                        { !props.showLoading &&
                            <div className='row justify-content-center mt-4'>
                                <div className='col-4'>
                                    <input type='submit' className='btn btn-primary btn-block'/>
                                </div>
                            </div>}
                    </form>
                </div>
            </div>
        </div>
      )
}

const mapStateToProps = (state) => {
    return {
        clients: state.clientAuth.clients,
        client: state.contrat.beneficiaire,
        admin: state.admin.administration,
        errorMessage: state.clientAuth.errorMessage,
        successMessage: state.clientAuth.successMessage,
        showLoading: state.clientAuth.showLoading,
        currentUser: currentUser(state)
    };
};

export default withRouter(connect(mapStateToProps)(Enroll));