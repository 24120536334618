import axios from 'axios';

const notificationServiceRequest = axios.create({
  baseURL: process.env.REACT_APP_BASEURL_NOTIFICATION_BACKEND,
});

export const sendNotificationRequest = (data) => notificationServiceRequest.get('/notification/send', {
    params: {
        email: data.email,
        subject: data.subject,
        message: data.message
    }
  });
