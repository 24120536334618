import {
    START_LOADER_ACTION,
    GET_BON_CONFIRMED_ACTION,
    GET_BON_FAILED_ACTION,
    DELIEVER_CONFIRMED_ACTION,
    DELIEVER_FAILED_ACTION,
    SUBSTITUTE_CONFIRMED_ACTION,
    SUBSTITUTE_FAILED_ACTION,
    REQUEST_SUBSTITUTE_CONFIRMED_ACTION,
    REQUEST_SUBSTITUTE_FAILED_ACTION,
    GET_SUBS_REQUEST_CONFIRMED_ACTION,
    GET_SUBS_REQUEST_FAILED_ACTION,
    GET_SUBS_REQUEST_HIST_FAILED_ACTION,
    GET_SUBS_REQUEST_HIST_CONFIRMED_ACTION
} from '../actions/PharmacieActions';

const initialState = {
    bon: {},
    subsRequests: [],
    subsRequestsHistory: [],
    errorMessage: '',
    successMessage: '',
    showLoading: false,
};

export const PharmacieReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_BON_CONFIRMED_ACTION:
            return {
                ...state,
                bon: action.payload,
                errorMessage: '',
                successMessage: 'Bon gotten Successfully Completed',
                showLoading: false,
            };
        case GET_BON_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case GET_SUBS_REQUEST_CONFIRMED_ACTION:
            return {
                ...state,
                subsRequests: action.payload,
                errorMessage: '',
                successMessage: 'Subs requests gotten Successfully Completed',
                showLoading: false,
            };
        case GET_SUBS_REQUEST_HIST_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        
        case GET_SUBS_REQUEST_HIST_CONFIRMED_ACTION:
            return {
                ...state,
                subsRequestsHistory: action.payload,
                errorMessage: '',
                successMessage: 'Subs requests gotten Successfully Completed',
                showLoading: false,
            };
        case GET_SUBS_REQUEST_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case DELIEVER_CONFIRMED_ACTION:
            return {
                ...state,
                errorMessage: '',
                successMessage: 'Delievry Successfully Completed',
                showLoading: false,
            };
        case DELIEVER_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case SUBSTITUTE_CONFIRMED_ACTION:
            return {
                ...state,
                errorMessage: '',
                successMessage: 'Subtitution Successfully Completed',
                showLoading: false,
            };
        case SUBSTITUTE_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case REQUEST_SUBSTITUTE_CONFIRMED_ACTION:
            return {
                ...state,
                errorMessage: '',
                successMessage: 'Substitution request Successfully Completed',
                showLoading: false,
            };
        case REQUEST_SUBSTITUTE_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case START_LOADER_ACTION:
            return {
                ...state,
                showLoading: true,
            };
        default:
            return state;
    };
};