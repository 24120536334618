/* eslint-disable  no-restricted-syntax */
import { BlobServiceClient } from '@azure/storage-blob';

// const blobSasUrl = ''https://facialstorage.blob.core.windows.net/?sv=2021-06-08&ss=bfq&srt=sco&sp=rwdlacuptfx&se=2022-11-01T00:35:41Z&st=2022-10-10T16:35:41Z&spr=https&sig=%2FrKYrSlcM5f%2BLzqeKZL2UVMLJmhMOht8qDBE6vfSxrw%3D'';

const blobSasUrl = process.env.REACT_APP_BLOB_SAS_URL;
// Create a new BlobServiceClient
// const blobServiceClient = new BlobServiceClient(blobSasUrl);
const blobServiceClient = new BlobServiceClient(blobSasUrl);

// Create a unique name for the container by
// appending the current time to the file name

// const containerName = 'amp-container';
const containerName = process.env.REACT_APP_AMP_CONTAINER;

// Get a container client from the BlobServiceClient
const containerClient = blobServiceClient.getContainerClient(containerName);

const reportStatus = (message) => {
  console.log(message);
};

const createContainer = async () => {
  let result = true;
  try {
    reportStatus(`Creating container "${containerName}"...`);
    await containerClient.create();
    reportStatus(`Done. URL:${containerClient.url}`);
  } catch (error) {
    result = false;
    reportStatus(error.response?.data?.message);
  }

  return result;
};

const checkContainerExistence = async () => {

  try {
    const exists = await containerClient.exists();
    return exists;
  } catch (error) {
    console.error("Error occurred:", error);
    return false;
  }
  /*let res = false;

  const options = {
    includeDeleted: false,
    includeMetadata: true,
    includeSystem: true,
    prefix: 'amp',
  };

  for await (const containerItem of blobServiceClient.listContainers(options)) {
    // ContainerItem
    console.log(`For-await list: ${containerItem.name}`);

    if (containerItem.name === containerName) {
      res = true;
      break;
    }
  }

  return res;*/
};

const uploadFiles = async (files) => {
  let blobUrls = [];

  const containerExist = await checkContainerExistence();
  if (!containerExist) {
    await createContainer();
  }

  try {
    reportStatus('Uploading files...');
    let uploadedBlobs = [];
    const promises = [];
    for (let i = 0; i < files.length; i += 1) {
      const file = files[i];
      const blockBlobClient = containerClient.getBlockBlobClient(file.name);
      //blockBlobClient.setMetadata({ username: file.name.split('.')[0] });
      uploadedBlobs.push(file.name);
      console.log(blockBlobClient.url);
      promises.push(blockBlobClient.uploadData(file));
    }
    await Promise.all(promises);
    reportStatus('Done.');
    uploadedBlobs.forEach(blobName => {
      blobUrls.push(getImageUrl(blobName));
    });
    // blobUrls = uploadedBlobs;
  } catch (error) {
    console.log('error popped from here');
    reportStatus(error.response?.data?.message);
  }
  return blobUrls;
};

const getImageUrl = (blobName) => {
  const blobClient = containerClient.getBlobClient(blobName);
  return blobClient.url;
};


export default uploadFiles;