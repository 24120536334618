import { applyMiddleware, combineReducers, compose,createStore,} from 'redux';
import thunk from 'redux-thunk';
import { AuthReducer } from './reducers/AuthReducer';
import { PatientReducer } from './reducers/PatientReducer';
import { ProviderReducer } from './reducers/ProviderReducer';
import { ConsultationReducer } from './reducers/ConsultationReducer';
import { PharmacieReducer } from './reducers/PharmacieReducer';
import { LaboratoireReducer } from './reducers/LaboratoireReducer';
import { AccordPreaReducer } from './reducers/AccordPreaReducer';
import { ActesSpecialisesReducer } from './reducers/ActesSpecialisesReducer';
import { AdministrationReducer } from './reducers/AdministrationReducer';
import { ClientAuthReducer } from './reducers/ClientAuthReducer';
import { FacturationReducer } from './reducers/FacturationReducer';
import { ContratReducer } from './reducers/ContratReducer';
import { OptiqueReducer } from './reducers/OptiqueReducer';
import { KineReducer } from './reducers/KineReducer';
import { HelpReducer } from './reducers/HelpReducer';
import { ParameterReducer } from './reducers/ParameterReducer';
import {
    setLocale,
    loadTranslations,
    syncTranslationWithStore,
    i18nReducer
} from "react-redux-i18n";
import translations from '../i18n/translations';

const middleware = applyMiddleware(thunk);

const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
    
    auth: AuthReducer,
    patient: PatientReducer,
    provider: ProviderReducer,
    consultation: ConsultationReducer,
    pharmacie: PharmacieReducer,
    laboratoire: LaboratoireReducer,
    accordPrea: AccordPreaReducer,
    actesSpecialise: ActesSpecialisesReducer,
    admin: AdministrationReducer,
    clientAuth: ClientAuthReducer,
    facturation: FacturationReducer,
    contrat: ContratReducer,
    optique: OptiqueReducer,
    kine: KineReducer,
    help: HelpReducer,
    parameters: ParameterReducer,
    i18n: i18nReducer
});

//const store = createStore(rootReducers);

const store = createStore(reducers,  composeEnhancers(middleware));

syncTranslationWithStore(store);
store.dispatch(loadTranslations(translations));
store.dispatch(setLocale("fr"));

export default store;
