import React, { Fragment, useState } from "react";
import { connect, useDispatch } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
//** Import Image */
import profile from "../../images/avatar-default.jpg";
import { Button, Spinner } from "react-bootstrap";
import { currentUser } from "../../store/selectors/CurrentUserSelector";
import coverImage from "../../images/profile/cover.jpg";
import './Profile.css';
import { updatePasswordAction, loadingToggleAction } from "../../store/actions/AuthActions";

const Profile = (props) => {
   const dispatch = useDispatch();
   const { hash } = window.location;
   const [activeToggle, setActiveToggle] = useState(hash.length > 0 && hash.includes("setting") ? "setting" : "aboutMe");
   const [prevPassword, setPrevPassword] = useState('');
   const [newPassword, setNewPassword] = useState('');
   const [confPassword, setConfPassword] = useState('');
   const [errMess, setErrMess] = useState('');
   const {currentUser, history} = props;

    const updatePassword = (e) => {
         e.preventDefault(); 
         console.log("the submission is called");
        if(prevPassword.length === 0 || newPassword.length === 0 || confPassword === 0) {
            setErrMess('Veuillez remplir tous les champs');
            return;
        }
        if(newPassword.length < 8){
            setErrMess('Le mot de passe doit avoir au moins 6 caractères');
            return;
        }
        if(!(/^(?=.*\d+)(?=.*[a-z]*)(?=.*[A-Z]+)(?=.*[a-zA-Z]*).{8,}$/.test(newPassword))){
            setErrMess('Le mot de passe doit comporter au moins une majuscule, un chiffre et un caractère spécial');
            return;
        }
        if(prevPassword === newPassword){
            setErrMess('Le nouveau mot de passe doit être différent de l\'ancien');
            return;
        }
        if(newPassword !== confPassword){
            setErrMess('La confirmation du mot de passe doit être identique au nouveau mot de passe');
            return;
        }
        dispatch(loadingToggleAction(true));
        let data = {
            email: currentUser.email,
            password: newPassword,
            confirmPassword: confPassword,
            currentPassword: prevPassword
        }
        dispatch(updatePasswordAction(data, history));
    }

   return (
      <Fragment>

         <div className="row">
            <div className="col-lg-12">
               <div className="profile card card-body px-3 pt-3 pb-0">
                  <div className="profile-head">
                     <div className="photo-content">
                        <div className="cover-photo"></div>
                     </div>
                     <div className="profile-info">
                        <div className="profile-photo">
                           <img
                              src={profile}
                              className="img-fluid rounded-circle"
                              alt="profile"
                           />
                        </div>
                        <div className="profile-details">
                           <div className="profile-name px-3 pt-2">
                              <h4 className="text-primary mb-0">
                                 {currentUser.username}
                              </h4>
                              <p>{currentUser.profils[0]?.nameFr}</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className="row">
            <div className="col-xl-12">
               <div className="card">
                  <div className="card-body">
                     <div className="profile-tab">
                        <div className="custom-tab-1">
                           <ul className="nav nav-tabs">
                              <li
                                 className="nav-item"
                                 onClick={() => setActiveToggle("aboutMe")}
                              >
                                 <Link
                                    to="#about-me"
                                    data-toggle="tab"
                                    className={`nav-link ${
                                       activeToggle === "aboutMe"
                                          ? "active show"
                                          : ""
                                    }`}
                                 >
                                    Informations
                                 </Link>
                              </li>
                              <li className="nav-item">
                                 <Link
                                    to="#profile-settings"
                                    data-toggle="tab"
                                    onClick={() => setActiveToggle("setting")}
                                    className={`nav-link ${
                                       activeToggle === "setting"
                                          ? "active show"
                                          : ""
                                    }`}
                                 >
                                    Sécurité et accès
                                 </Link>
                              </li>
                           </ul>
                           <div className="tab-content">
                              <div
                                 id="about-me"
                                 className={`tab-pane fade ${
                                    activeToggle === "aboutMe"
                                       ? "active show"
                                       : ""
                                 }`}
                              >
                                 <div className="profile-personal-info">
                                    <div className="row mb-2">
                                       <div className="col-3">
                                          <h5 className="f-w-500">
                                             Nom
                                             <span className="pull-right">
                                                :
                                             </span>
                                          </h5>
                                       </div>
                                       <div className="col-9">
                                          <span>{currentUser.username}</span>
                                       </div>
                                    </div>
                                    <div className="row mb-2">
                                       <div className="col-3">
                                          <h5 className="f-w-500">
                                             Email
                                             <span className="pull-right">
                                                :
                                             </span>
                                          </h5>
                                       </div>
                                       <div className="col-9">
                                          <span>{currentUser.email}</span>
                                       </div>
                                    </div>
                                    <div className="row mb-2">
                                       <div className="col-3">
                                          <h5 className="f-w-500">
                                             Prestataire
                                             <span className="pull-right">
                                                :
                                             </span>
                                          </h5>
                                       </div>
                                       <div className="col-9">
                                          <span>{currentUser.provider.providerName}</span>
                                       </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-3">
                                            <h5 className="f-w-500">
                                                Profils
                                                <span className="pull-right">
                                                    :
                                                </span>
                                            </h5>
                                        </div>
                                        <div className="col-9">
                                            <span>{currentUser.profils?.reduce((res, profil) => {
                                                if(res.length > 0) {
                                                    return res+', '+profil.nameFr
                                                }else{
                                                    return profil.nameFr
                                                }
                                            }, '')}</span>
                                        </div>
                                    </div>
                                 </div>
                              </div>
                              <div
                                 id="profile-settings"
                                 className={`tab-pane fade ${
                                    activeToggle === "setting"
                                       ? "active show"
                                       : ""
                                 }`}
                              >
                                 <div className="pt-3">
                                    <div className="settings-form">
                                        <h6 className="text-primary">Modifier mon Mot de passe</h6>
                                        {errMess.length > 0 &&
                                            <span className="text-danger">{errMess}</span> 
                                         }
                                        <form className="comment-form" onSubmit={(e) => updatePassword(e)}>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label htmlFor="author" className="text-black font-w600">Mot de passe actuel <span className="required">*</span> </label>
                                                        <input type="password" className="form-control" value={prevPassword} onChange={(e) => {setPrevPassword(e.target.value)}} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label htmlFor="author" className="text-black font-w600">Nouveau mot de passe <span className="required">*</span> </label>
                                                        <input type="password" className="form-control" value={newPassword} onChange={(e) => {setNewPassword(e.target.value); setErrMess('')}} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label htmlFor="author" className="text-black font-w600">Confirmer le mot de passe <span className="required">*</span> </label>
                                                        <input type="password" className="form-control" value={confPassword} onChange={(e) => {setConfPassword(e.target.value); setErrMess('')}} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    { props.showLoading && 
                                                        <Button variant="primary" disabled>
                                                            <Spinner
                                                                as="span"
                                                                animation="grow"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            />{' '}
                                                            <span className="visually-hidden">Un instant...</span>
                                                        </Button> }
                                                    { !props.showLoading &&
                                                        <div className="form-group">
                                                            <input type="submit" value="Soumettre" className="submit btn btn-primary" name="Modifier mon mot de passe"/>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </Fragment>
   );
};
const mapStateToProps = (state) => {
    return {
       currentUser: currentUser(state),
       showLoading: state.auth.showLoading,
    };
};

export default withRouter(connect(mapStateToProps)(Profile));
//export default connect(mapStateToProps)(Profile);
