import {faceApiForUpload, localClientMicroserviceApi, ParametreMicroserviceApi} from './AxiosInstance';
import { errorToastr } from '../Utils/ToastrMessage';

export async function  verify(img)  {
    var convertedFiles = await urltoFile(img,'image.jpeg',base64MimeType(img));
    var data = new FormData();
    data.append('image', convertedFiles);

    return faceApiForUpload.post(
        `/face/verify`,
        data,
    );
}

export async function getClientData(id)  {
    return localClientMicroserviceApi.get(
        '/Client/'+id
    );
}

export function formatPatient(patientData) {
    let patient = [];
    for (let key in patientData) {
        patient.push({ ...patientData[key], id: key });
    }

    return patient;
}

export function formatError(errorResponse) {
    switch (errorResponse.error.response?.data?.message) {
        case 'EMAIL_EXISTS':
            //return 'Email already exists';
            errorToastr("Email already exists");
            break;
        case 'EMAIL_NOT_FOUND':
            //return 'Email not found';
            errorToastr("Email not found");
           break;
        case 'INVALID_PASSWORD':
            //return 'Invalid Password';
            errorToastr("Invalid Password");
            break;
        case 'USER_DISABLED':
            errorToastr("User Disabled");
            return 'User Disabled';

        default:
            return '';
    }
}

export const base64MimeType = (encoded) =>{
    var result = null;
  
    if (typeof encoded !== 'string') {
      return result;
    }
  
    var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
  
    if (mime && mime.length) {
      result = mime[1];
    }
  
    return result;
}
export const urltoFile = (url, filename, mimeType) =>{
    return (fetch(url)
        .then(function(res){return res.arrayBuffer();})
        .then(function(buf){return new File([buf], filename,{type:mimeType});})
    );
}

export async function IdentifyAssure(data)  {
    return localClientMicroserviceApi.post(
        '/Client/request-code/',
        data
    );
}

export async function validateCode(data)  {
    return localClientMicroserviceApi.get(
        '/Client/'+data.clientId+'/verify-code/'+data.code
    );
}

