const translations = {

    en: {
        accord: {
          list: {
              error: "Error retrieving agreement requests: "
          },
          validate: {
            error: "Error validating the agreement: ",
            success: "Agreement validated successfully"
          },
          validate: {
            error: "Error rejecting the agreement: ",
            success: "Agreement rejected successfully"
          },
          sendMessage: {
            error: "Error sending message: ",
            success: "Message sent successfully"
          },
          getAgreement: {
            error: "Error retrieving the agreement request: "
          },
          getProformas: {
            error: "Error retrieving invoices: "
          },
          getProforma: {
            error: "Error retrieving invoice: "
          },
          validateProforma: {
            error: "Error validating invoice: ",
            error_line: "Error validating invoice line: "
          },
          rejectProforma: {
            error: "Error rejecting invoice: ",
            error_line: "Error rejecting invoice line: "
          },
          restoreProforma: {
            error: "Error restoring invoice: "
          }
        },
        actSpec: {
            getBon: {
                error: "Error retrieving provider: "
            },
            markAct: {
                error: "Error recording the action taken: ",
                success: "Act marked completed successfully recorded"
            }
        },
        auth: {
          error: "Authentication Failure: ",
          welcome: "Welcome ",
          updatePass: {
            success: "Your password has been successfully updated, please log in again using your new password",
            error: "Failed to update password: "
          }
        },
        consultation: {
            getConsult: {
                error: "Error retrieving consultations: "
            },
            getConsultData: {
                error: "Error retrieving the consultation: "
            },
            makeConsult: {
                error: "Error during consultation request: "
            },
            searchDrug: {
                error: "Error retrieving drugs: "
            },
            searchDisease: {
                error: "Error retrieving conditions: "
            },
            getSuggest: {
                error: "Error retrieving suggestions: "
            },
            updateConsult: {
                error: "Error updating the consultation: "
            },
            getClientConsult: {
                error: "Error retrieving consultations for this patient: "
            },
            medicalFile: {
                error_one: "Error retrieving medical record: ",
                error_two: "Error retrieving medical records: "
            },
            getPatientParam: {
                error: "Error retrieving parameter histories for this patient: "
            },
            makeAppointment: {
                error: "Error requesting an appointment: "
            },
            getPatientAppointment: {
                error: "Error retrieving appointments: ",
                error_data: "Error retrieving the appointment: "
            },
            updateQuantity: {
                success: "The prescribed quantity has been successfully updated",
                error: "Error updating the prescribed quantity: "
            },
            getActesConsult: {
                error: "Error retrieving consultation acts: "
            },
            getActeVerre: {
                error: "Error retrieving hospitalization acts: "
            },
            getActeHospi: {
                error: "Error retrieving hospitalization acts: "
            },
            addActeDetail: {
                error_one: "Error adding the exam to the voucher: ",
                error_two: "Error adding the specialized act to the voucher: ",
                error_three: "Error adding the act to the voucher: ",
                error_four: "Error adding the medication to the voucher: "
            },
            examDel: {
                error: "Error executing exams: "
            },
            acteDel: {
                error: "Error saving the performed act: "
            },
            drugDel: {
                error: "Error delivering medications: "
            },
            hospiDel: {
                error: "Error closing hospitalization: ",
                error_prolong: "Error extending hospitalization: ",
                error_change: "Error changing room: "
            },
            updateparams: {
                success: "Parameters successfully updated",
                error: "Error updating parameters: "
            },
            getOpticParams: {
                error: "Error retrieving lens parameters: "
            }
          
        },
        contrat: {
            getBenef: {
                error: "Error retrieving beneficiaries: ",
                by_assu_error: "Error retrieving beneficiaries: "
            },
            getSouscript: {
                error: "Error retrieving subscribers: "
            },
            getGaranties: {
                error: "Error retrieving collateral: "
            },
            getActes: {
                error: "Error retrieving records: "
            },
            contractSearch: {
                error: "Error searching for contract: "
            }
        },
        factures: {
            getFactures: {
                error: "Error retrieving invoices: ",
                error_single: "Error retrieving invoice: "
            },
            getMotifs: {
                error: "Error retrieving exclusion reasons: "
            },
            rejet: {
                save_success: "Exclusion saved",
                save_error: "Error rejecting: ",
                restore_success: "Exclusion canceled",
                restore_error: "Error restoring: "
            },
            rembours: {
                request_success: "Request saved",
                request_error: "Error requesting refund: ",
                gets_error: "Error retrieving refunds: ",
                get_error: "Error retrieving refund: ",
                update_success: "Refund request saved",
                update_error: "Error saving refund request: "
            },
            requestPayment: {
                success: "Invoice sent for technical payment",
                error: "Error during billing request: "
            },
            calculate: {
                error_base_remb: "Error calculating reimbursement base: ",
                error_tm: "Error returning ticket modérateur: "
            }
        },
        help: {
            success: "Your message has been sent successfully, support will contact you as soon as possible.",
            error: "Error sending message: "
        },
        labo: {
            get_bon_error: "Error retrieving provider: ",
            delievery_exam_error: "Delivery successfully recorded",
            comment_success: "Comment successfully recorded",
            comment_error: "Error while commenting: ",
            getPatientResult: "Error retrieving test results: "
        },
        patient: {
            identification: {
              success_one: "Patient identified",
              success_two: "Patient data retrieved",
              error_one: "Patient identification failed: ",
              error_two: "Patient identification failed: "
            }
        },
        pharmacie: {
            get_bon_error: "Error retrieving provider: ",
            get_subs_error: "Error retrieving substitution requests: ",
            livraison_success: "Delivery successfully recorded",
            livraison_error: "Error during delivery: ",
            subs_req_success: "Substitution request successfully recorded",
            subs_req_error: "Error during substitution request: ",
            subs_validated_success: "Substitution successfully validated",
            subs_validated_error: "Error during substitution validation: ",
            subs_rejected_success: "Substitution successfully rejected",
            subs_rejected_error: "Error during substitution rejection: "
        },
        provider: {
            get_error: "Error retrieving provider: ",
            add_prov_bank_error: "Error saving provider payment information: ",
            search_act_error: "Error retrieving drugs: ",
            list_providers_error: "Error listing providers: ",
            list_provider_error: "Error listing providers: ",
            search_spec_act_error: "Error searching for specialized acts: ",
            calculate_facture_error: "Error calculating invoice: "
        },
        client: {
            register: {
              error_face_enrolled: "Warning, this face has already been enrolled for another patient",
              error_no_face: "The insured's face could not be verified.",
              face_enroll_success: "Client successfully enrolled",
              face_enroll_error: "The insured's face could not be registered. They will therefore not be able to use biometrics to identify themselves. However, they will receive their insured number by SMS in order to benefit from the alternative identification option via SMS",
              client_enroll_error: "Error updating client data: ",
              face_verif_error: "Error verifying face: ",
              client_listing_error: "Error retrieving clients: ",
              client_already_enrolled_error: "This client has already been enrolled",
              client_enroll_verif_error: "Error verifying client enrollment, please try again later!",
              enroll_start: "Enrollment started",
              get_client_error: "Error retrieving client: ",
              login_hist_error: "Error retrieving login history: "
            }
        },
        optic: {
            success_proforma_send: "Proforma submitted for validation by the medical advisor",
            error_proforma_send: "Error sending proforma: ",
            success_exec_bon: "Voucher marked as executed",
            error_exec_bon: "Error executing voucher: "
        },
        kine: {
            success_add_kine_acte: "The act has successfully been added",
            error_add_kine_acte: "Error while adding the act"
        }  
    },
  
    fr: {
      accord: {
        list: {
            error: "Erreur lors de la récupération des demandes d'accord: "
        },
        validate: {
          error: "Erreur lors de la validation de l'acte: ",
          success: "Accord validé avec succès"
        },
        validate: {
          error: "Erreur lors du rejet de l'acte: ",
          success: "Accord rejeté avec succès"
        },
        sendMessage: {
          error: "Erreur lors de l'envoie du message: ",
          success: "Message envoyé avec succès"
        },
        getAccord: {
          error: "Erreur lors de a récupération de la demande d'accord': "
        },
        getProformas: {
            error: "Erreur lors des proformas: "
        },
        getProforma: {
            error: "Erreur lors de la proforma: "
        },
        validateProforma: {
            error: "Erreur lors de la validation de la proforma: ",
            error_line: "Erreur lors de la validation de la proforma: "
        },
        rejectProforma: {
            error: "Erreur lors du rejet de la proforma: ",
            error_line: "Erreur lors du rejet de la proforma: "
        },
        restoreProforma: {
            error: "Erreur lors de la restauration de la proforma: "
        }
      },
      actSpec: {
        getBon: {
            error: "Erreur lors de la récupération du provider: "
        },
        markAct: {
            error: "Erreur lors de l'enregistrement de l'acte posé: ",
            success: "Acte marqué éffectué enregistrée avec succès"
        }
      },
      auth: {
        error: "Echec d'authentification: ",
        welcome: "Bienvenue ",
        updatePass: {
          success: "Votre mot de passe a bien été mis à jour, veuilez vous reconnecter en utilisant votre nouveau mot de passe",
          error: "Echec de mise à jour du mot de passe: "
        }
      },
      consultation: {
        getConsult: {
            error: "Erreur lors de la récupération des consultation: "
        },
        getConsultData: {
            error: "Erreur lors de la récupération de la consultation: "
        },
        makeConsult: {
            error: "Erreur lors de la requete de consultation: "
        },
        searchDrug: {
            error: "Erreur lors de la récupération du drugs: "
        },
        searchDisease: {
            error: "Erreur lors de la récupération des affections: "
        },
        getSuggest: {
            error: "Erreur lors de la récupération des suggestions: "
        },
        updateConsult: {
            error: "Erreur lors de la mise à jour de la consultation: "
        },
        getClientConsult: {
            error: "Erreur lors de la récupération des consultations pour ce patient: "
        },
        medicalFile: {
            error_one: "Erreur lors de la récupération du dossier médical: ",
            error_two: "Erreur lors de la récupération du dossier médical: "
        },
        getPatientParam: {
            error: "Erreur lors de la récupération des historiques des paramètre pour ce patient: "
        },
        makeAppointment: {
            error: "Erreur lors de la requête de rendez-vous: "
        },
        getPatientAppointment: {
            error: "Erreur lors de la récupération des rendez-vous: ",
            error_data: "Erreur lors de la récupération du rendez-vous: "
        },
        updateQuantity: {
            success: "La quantité prescrite a bien été mise à jour",
            error: "Erreur lors de la mise à jour de la quantité prescrite: "
        },
        getActesConsult: {
            error: "Erreur lors de la récupération des actes de consultation: "
        },
        getActeVerre: {
            error: "Erreur lors de la récupération des actes d'hospitalisation: "
        },
        getActeHospi: {
            error: "Erreur lors de la récupération des actes d'hospitalisation: "
        },
        addActeDetail: {
            error_one: "Erreur lors de l'ajout de l'examen dans le bon: ",
            error_two: "Erreur lors de l'ajout de l'acte spécialisé dans le bon: ",
            error_three: "Erreur lors de l'ajout de l'acte dans le bon: ",
            error_four: "Erreur lors de l'ajout du medicament dans le bon: "
        },
        examDel: {
            error: "Erreur lors de l'exécution des examens: "
        },
        acteDel: {
            error: "Erreur lors de l'enregistrement de l'acte posé: "
        },
        drugDel: {
            error: "Erreur lors de la livraison des médicaments: "
        },
        hospiDel: {
            error: "Erreur lors de la clôture de l'hospitalisation: ",
            error_prolong: "Erreur lors du prolongement de l'hospitalisation: ",
            error_change: "Erreur lors du changement de la chambre: "
        },
        updateparams: {
            success: "Paramètres mis à jour avec succès",
            error: "Erreur lors de la mise à jour des paramètres: "
        },
        getOpticParams: {
            error: "Erreur lors de la récupération des paramètres de verres: "
        }
      },
      contrat: {
        getBenef: {
            error: "Erreur lors de la récupération des bénéficiaires: ",
            by_assu_error: "Erreur lors de la récupération des bénéficiaires: "
        },
        getSouscript: {
            error: "Erreur lors de la récupération des souscripteurs: "
        },
        getGaranties: {
            error: "Erreur lors de la récupération des garanties: "
        },
        getActes: {
            error: "Erreur lors de la récupération des actes: "
        },
        contratSearch: {
            error: "Erreur lors de la recherche du contrat: "
        }
      },
      factures: {
        getFactures: {
            error: "Erreur lors de la récupération des factures: ",
            error_single: "Erreur lors de la récupération de la facture: "
        },
        getMotifs: {
            error: "Erreur lors de la récupération des motifs d'exclusions: "
        },
        rejet: {
            save_success: "Exclusion enregistrée",
            save_error: "Erreur lors du rejet: ",
            restore_success: "Exclusion annulée",
            restore_error: "Erreur lors de la restauration: "
        },
        rembours: {
            request_success: "Demande enregistrée",
            request_error: "Erreur lors de la demande de remboursement: ",
            gets_error: "Erreur lors de la récupération des remboursements: ",
            get_error: "Erreur lors de la récupération du remboursement: ",
            update_success: "Demande de remboursement enregistrée",
            update_error: "Erreur lors de l'enregistrement de la demande de remboursement: "
        },
        requestPayment: {
            success: "Facture envoyées en règlement technique",
            error: "Erreur lors de la requete de facturation: "
        },
        calculate: {
            error_base_remb: "Erreur lors du calcul de la base de remboursement: ",
            error_tm: "Erreur lors du renvoie du ticket modérateur: "
        }
      },
      help: {
        success: "Votre message a bien été envoyé, le support vous contactera dans les plus brefs délais.",
        error: "Erreur lors de l'envoie du message: "
      },
      labo: {
        get_bon_error: "Erreur lors de la récupération du provider: ",
        deliever_exam_error: "Livraison enregistrée avec succès",
        comment_success: "Commentaire enregistrée avec succès",
        comment_error: "Erreur lors du commentaire: ",
        getPatientResult: "Erreur lors de récupération des résultats d'analyse: "
      },
      patient: {
        identification: {
            success_one: "Patient identifié",
            success_two: "Données Patient retrouvées",
            error_one: "Echec d'identification du patient: ",
            error_two: "Echec d'identification du patient: "
        }
      },
      pharmacie: {
        get_bon_error: "Erreur lors de la récupération du provider: ",
        get_subs_error: "Erreur lors de la récupération des demandes de substitution: ",
        livraison_success: "Livraison enregistrée avec succès",
        livraison_error: "Erreur lors de la livraison: ",
        subs_req_success: "Demande de substitution enregistrée avec succès",
        subs_req_error: "Erreur lors de la demande de substitution: ",
        subs_validated_success: "Substitution validée avec succès",
        subs_validated_error: "Erreur lors de la validation substitution: ",
        subs_rejected_success: "Substitution rejetée avec succès",
        subs_rejected_error: "Erreur lors du rejet de la substitution: "
      },
      provider: {
        get_error: "Erreur lors de la récupération du provider: ",
        add_prov_bank_error: "Erreur lors de l'enregistrement des infos de paiement du prestataire: ",
        search_act_error: "Erreur lors de la récupération du drugs: ",
        list_providers_error: "Erreur lors du listing des prestataire: ",
        list_provider_error: "Erreur lors du listing des prestataire: ",
        search_spec_act_error: "Erreur lors de la recherche des actes spécialisés: ",
        calculate_facture_error: "Erreur lors du calcul de la facture: "
      },
      client: {
        register: {
            error_face_enrolled: "Attention, ce visage a déjà été enrôlé pour une autre patient",
            error_no_face: "Le visage de l'assuré n'a pas pu être vérifié.",
            face_enroll_success: "Client enrollé avec succès",
            face_enroll_error: "Le visage de l'assuré n'a pas pu être enregistré. Il ne pourra par conséquent pas utiliser la biométrie pour s'identifier. Il recevra cependant par sms son numéro d'assuré afin de bénéficier de l'option d'identification alternative via sms",
            client_enroll_error: "Erreur lors de la mise à jour des données du client: ",
            face_verif_error: "Erreur lors de la vérification du visage: ",
            client_listing_error: "Erreur lors de récupération des clients: ",
            client_alread_enrolled_error: "Ce client a déjà été enrollé",
            client_enroll_verif_error: "Erreur lors de la vérification de l'enrollement du client, veuillez réessayer plus tard!",
            enroll_start: "Début de l'enrôlement",
            get_client_error: "Erreur lors de récupération du client: ",
            login_hist_error: "Erreur lors de récupération de l'historique de connexion: "
        }
      },
      optic: {
        success_proforma_send: "Proforma soumise à validation du médecin conseil",
        error_proforma_send: "Erreur lors de l'envoie de la proforma: ",
        success_exec_bon: "Bon marqué comme exécuté",
        error_exec_bon: "Erreur lors de l'exécution du bon: "
      },
      kine: {
          success_add_kine_acte: "Le bon de kinésithérapie a bien étémis à jour",
          error_add_kine_acte: "Erreur lors de la mise à jour du bon"
      }  
    },
  
  };
  
  export default translations;