import React, { useState, useEffect } from 'react';
import { Badge, ListGroup } from "react-bootstrap";
import { connect, useDispatch } from 'react-redux';
import PerfectScrollbar from "react-perfect-scrollbar";
import Select from 'react-select';
import { currentUser } from '../../../../../store/selectors/CurrentUserSelector';
import { searchActesSpecAction } from '../../../../../store/actions/ProviderActions';
import incisiveTooth from '../../../../../images/tooth/incisors-tooth.svg';
import canineTooth from '../../../../../images/tooth/canine-tooth.svg';
import premolarTooth from '../../../../../images/tooth/tooth.svg';
import molarTooth from '../../../../../images/tooth/molar-tooth.svg';
import './ConsultationStyle.css';
import { useTranslation } from "react-i18next";

const SpecialActsForm = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const consult = JSON.parse(localStorage.getItem("consultation"));

    const [actes, setActes] = useState((consult && consult.specialActs) ? consult.specialActs : []);
    const [keyword, setKeyword] = useState("");
    const [isKid, setIsKid] = useState(false);
    const [selectedTooth, setSelectedTooth] = useState([]);
    const [focusExam, setFocusExam] = useState(null);

    const adultTopDentiton = [
        {code: "18", name: molarTooth},
        {code: "17", name: molarTooth},
        {code: "16", name: molarTooth},
        {code: "15", name: premolarTooth},
        {code: "14", name: premolarTooth},
        {code: "13", name: canineTooth},
        {code: "12", name: incisiveTooth},
        {code: "11", name: incisiveTooth},
        {code: "21", name: incisiveTooth},
        {code: "22", name: incisiveTooth},
        {code: "23", name: canineTooth},
        {code: "24", name: premolarTooth},
        {code: "25", name: premolarTooth},
        {code: "26", name: molarTooth},
        {code: "27", name: molarTooth},
        {code: "28", name: molarTooth}
    ];

    const adultBottomDentiton = [
        {code: "48", name: molarTooth},
        {code: "47", name: molarTooth},
        {code: "46", name: molarTooth},
        {code: "45", name: premolarTooth},
        {code: "44", name: premolarTooth},
        {code: "43", name: canineTooth},
        {code: "42", name: incisiveTooth},
        {code: "41", name: incisiveTooth},
        {code: "31", name: incisiveTooth},
        {code: "32", name: incisiveTooth},
        {code: "33", name: canineTooth},
        {code: "34", name: premolarTooth},
        {code: "35", name: premolarTooth},
        {code: "36", name: molarTooth},
        {code: "37", name: molarTooth},
        {code: "38", name: molarTooth}
    ];

    const kidTopDentiton = [
        {code: "55", name: molarTooth},
        {code: "54", name: premolarTooth},
        {code: "53", name: canineTooth},
        {code: "52", name: incisiveTooth},
        {code: "51", name: incisiveTooth},
        {code: "61", name: incisiveTooth},
        {code: "62", name: incisiveTooth},
        {code: "63", name: canineTooth},
        {code: "64", name: premolarTooth},
        {code: "65", name: molarTooth},
    ];

    const kidBottomDentiton = [
        {code: "85", name: molarTooth},
        {code: "84", name: premolarTooth},
        {code: "83", name: canineTooth},
        {code: "82", name: incisiveTooth},
        {code: "81", name: incisiveTooth},
        {code: "71", name: incisiveTooth},
        {code: "72", name: incisiveTooth},
        {code: "73", name: canineTooth},
        {code: "74", name: premolarTooth},
        {code: "75", name: molarTooth},
    ];

    const isDentiste = () => {
        return props.currentUser.profils.filter(profil => {return profil.code.includes("DEN")}).length > 0;
    }

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
            return false;
        }else{
            return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
    }
    
    const hasMenu = (menuCode) => {
        return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
    }
    

    const selectExam = (e, val) => {
        e.preventDefault();
        let selectedAct = props.examensList.filter(ex => {return ex.code === val.code})[0];
        if(actes.filter(act => {return act.code === selectedAct.code}).length === 0) {
            if(hasMenu('MENCONSULT') && canDo('MENCONSULT', 'ACPRESCDENT')){
                selectedAct.dents = [];
            }
            setActes([...actes, selectedAct]);
            setFocusExam(selectedAct);
            saveUpdates([...actes, selectedAct]);
        };
    }

    const removeElt = (val, e) => {
        if(e.type === "click") {
            setActes(actes.filter(act => {return act.code !== val}));
            saveUpdates(actes.filter(act => {return act.code !== val}));
        }
    }

    const addDent = (dentCode) => {
        if(focusExam){
            setActes(actes.map(ex => {
                if((ex.code === focusExam.code) && (!ex.dents.includes(dentCode))){
                    ex.dents.push(dentCode);
                    setFocusExam(ex);
                }
                return ex;
            }));
            saveUpdates(actes);
        }
    }

    const removeDent = (dentCode) => {
        if(focusExam) {
            setActes(actes.map(ex => {
                if((ex.code === focusExam.code) && (ex.dents.includes(dentCode))){
                    ex.dents = ex.dents.filter(de => {return de !== dentCode});
                    setFocusExam(ex);
                }
                return ex;
            }));
            saveUpdates(actes);
        }
    }

    const saveUpdates = (data) => {
        let consultation = JSON.parse(localStorage.getItem("consultation"));
        consultation.specialActs = data;
        localStorage.setItem("consultation", JSON.stringify(consultation));
    }

    useEffect(() => {
        if(keyword.trim().length >= 1){
            dispatch(searchActesSpecAction(keyword, props.currentUser.providerId, 'Acte spécialisé'));
        }else{
            dispatch(searchActesSpecAction('@@@'));
        }
    }, [keyword]);


    return (
      <>
        <div className='row'>
            <div className='col-xl-12 col-lg-12'>
                <div className='card'>
                    <div className='card-header'>
                        <h4 className='card-title'>Prescription Actes Spécialisés</h4>
                    </div>
                    <div className='card-body'>
                        <div className='basic-form'>
                            <form onSubmit={(e) => e.preventDefault()}>
                                <div className='form-group'>
                                    {hasMenu('MENCONSULT') && canDo('MENCONSULT', 'ACPRESCDENT') && <div className='row'>
                                        <div className='col'>
                                            <h6 className='text-center'>{t('consultation.choose-tooth')}</h6>
                                            <div className='row justify-content-center my-4'>
                                                <div className='form-group mb-0'>
                                                    <label className='radio-inline mr-5'>
                                                        <input type='radio' name='isKid' value="adulte" checked={!isKid} onChange={e => {setIsKid(!e.target.checked)}}/> {t('consultation.adult')}
                                                    </label>
                                                    <label className='radio-inline'>
                                                        <input type='radio' name='isKid' value="enfant" checked={isKid} onChange={e => {setIsKid(e.target.checked)}}/> {t('consultation.child')}
                                                    </label>
                                                </div>
                                            </div>
                                            {/* Schéma Dentition */}
                                            <div className='d-flex flex-column align-items-center'>
                                                {/* Dentition supérieure */}
                                                <div className='d-flex sup-dental'>
                                                    {isKid &&
                                                        <>
                                                            {kidTopDentiton.map(tooth => {
                                                                return <button className={focusExam?.dents?.includes(tooth.code) ? "btn btn-default d-flex flex-column align-items-center p-1 m-0 border border-warning" :  "btn btn-default d-flex flex-column align-items-center p-1 m-0"}
                                                                        onClick={(e) => addDent(tooth.code)}
                                                                        >
                                                                                <small className='font-weight-bold'>{tooth.code}</small>
                                                                                <img src={tooth.name} width="20" style={{transform: "rotate(180deg)"}} />
                                                                        </button>
                                                            })}
                                                        </> 
                                                    }
                                                    {!isKid &&
                                                        <>
                                                            {adultTopDentiton.map(tooth => {
                                                                return <button className={focusExam?.dents?.includes(tooth.code) ? "btn btn-default d-flex flex-column align-items-center p-1 m-0 border border-warning" :  "btn btn-default d-flex flex-column align-items-center p-1 m-0"}
                                                                        onClick={(e) => addDent(tooth.code)}
                                                                        >
                                                                                <small className='font-weight-bold'>{tooth.code}</small>
                                                                                <img src={tooth.name} width="20" style={{transform: "rotate(180deg)"}}/>
                                                                        </button>
                                                            })}
                                                        </> 
                                                    }
                                                </div>
                                                {/* Dentition inférieure */}
                                                <div className='d-flex inf-dental'>
                                                    {isKid &&
                                                        <>
                                                            {kidBottomDentiton.map(tooth => {
                                                                return <button className={focusExam?.dents?.includes(tooth.code) ? "btn btn-default d-flex flex-column align-items-center p-1 m-0 border border-warning" :  "btn btn-default d-flex flex-column align-items-center p-1 m-0"}
                                                                        onClick={(e) => addDent(tooth.code)}
                                                                        >
                                                                                <img src={tooth.name} width="20" />
                                                                                <small className='font-weight-bold'>{tooth.code}</small>
                                                                        </button>
                                                            })}
                                                        </> 
                                                    }
                                                    {!isKid &&
                                                        <>
                                                            {adultBottomDentiton.map(tooth => {
                                                                return <button className={focusExam?.dents?.includes(tooth.code) ? "btn btn-default d-flex flex-column align-items-center p-1 m-0 border border-warning" :  "btn btn-default d-flex flex-column align-items-center p-1 m-0"}
                                                                        onClick={(e) => addDent(tooth.code)}
                                                                        >
                                                                                <img src={tooth.name} width="20" />
                                                                                <small className='font-weight-bold'>{tooth.code}</small>
                                                                        </button>
                                                            })}
                                                        </> 
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                                    <div className='row'>
                                        <div className='col-6'>
                                            <div className='form-group'>
                                                <label className='mb-1 '>
                                                    <strong>{t('consultation.choose-tooth')}</strong>
                                                </label>
                                                <input type="search" value={keyword} onChange={e => setKeyword(e.target.value)} className='form-control input-default mb-4' /> 

                                                <PerfectScrollbar
                                                    id="DZ_W_Todo2"
                                                    className="widget-media dz-scroll ps ps--active-y height300"
                                                >
                                                    <div className="basic-list-group">
                                                        <ListGroup>
                                                            {props.examensList.map((disease, j) => {
                                                                return <ListGroup.Item
                                                                    action
                                                                    active={actes.includes(disease)}
                                                                    key={j}
                                                                    onClick={e => selectExam(e, disease)}
                                                                    className="flex-column align-items-start"
                                                                    >
                                                                    <div className="d-flex w-100 justify-content-between">
                                                                        <h6 className={actes.includes(disease) ? "text-white" : "text-dark"} >
                                                                            {disease.nameFr}
                                                                            {disease.isAccord && <small className="text-danger">(Accord Préalable)</small>}
                                                                        </h6>
                                                                        
                                                                        <span>[ {disease.code} ]</span>
                                                                    </div>
                                                                    </ListGroup.Item>
                                                                })
                                                            }
                                                        </ListGroup>
                                                    </div>
                                                </PerfectScrollbar>
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <h5 className='mt-4 text-primary'>Acte choisis</h5>
                                            <div className="border-top mt-2 mb-4"></div>
                                            {actes.map((diag, i) => {
                                                return (
                                                    <p className="text-dark bg-light mr-1 mb-1 p-2 rounded" key={i} onClick={e => setFocusExam(diag)}>
                                                        <button type="button" className="btn btn-danger close-button shadow btn-xs sharp mr-1"
                                                            onClick={(e) => removeElt(diag.code, e)}
                                                        >
                                                            <i className="fa fa-close"></i>
                                                        </button><br/>
                                                        <span>[{diag.code}] {' - '} {diag.nameFr} </span><br/>
                                                        {hasMenu('MENCONSULT') && canDo('MENCONSULT', 'ACPRESCDENT') && <>
                                                            {diag.dents?.map((dent, j) => {
                                                                return <Badge variant="warning mr-1 mb-1" key={j}>
                                                                    <span>{dent}</span>
                                                                    <button type="button" className="btn btn-dark shadow btn-xs sharp mx-1"
                                                                        onClick={(e) => removeDent(dent)}
                                                                    >
                                                                        <i className="fa fa-close"></i>
                                                                    </button>
                                                                </Badge>
                                                            })}
                                                        </>}
                                                    </p>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </>
    )
}
const mapStateToProps = (state) => {
    return {
        examensList: state.provider.actesSpec,
        currentUser: currentUser(state)
    };
};

export default connect(mapStateToProps)(SpecialActsForm);
