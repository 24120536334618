import React, { ChangeEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import { setLocale } from "react-redux-i18n";
import { Language } from '../../../Language';
 
const Lang = () => {
    const { i18n } = useTranslation();
    const dispatch = useDispatch();
    const [lang, setLang] = useState(i18n.language);
 
    let changeLanguage = (event) => {
        let language = event.target.value;
 
        switch (language) {
            case Language.EN:
                setLang(Language.EN);
                i18n.changeLanguage(Language.EN);
                dispatch(setLocale(Language.EN));
                localStorage.setItem('currentLanguage', Language.EN);
                break;
            case Language.FR:
            default:
                setLang(Language.FR);
                i18n.changeLanguage(Language.FR);
                dispatch(setLocale(Language.FR));
                localStorage.setItem('currentLanguage', Language.FR);
                break;
        }
    }
 
    return (

                <select value={lang} name="language" onChange={changeLanguage}>
                    <option value={Language.FR}>FR</option>
                    <option value={Language.EN}>EN</option>
                </select>

    )
}
 
export default Lang;