import React, { Fragment, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link, useParams } from "react-router-dom";
import { Tab, Nav, ListGroup } from "react-bootstrap";
import { currentUser } from '../../../../store/selectors/CurrentUserSelector';
import { getClientConsultationsAction } from '../../../../store/actions/ConsultationActions';
import {format} from 'date-fns';
import { useTranslation } from "react-i18next";

const Dashboard = (props) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const {datas, consultations} = props;
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(getClientConsultationsAction(id));
    }, []);


    return (
        <Fragment>
            <div className="row">
                <div className='col-6 h300'>
                    <div className='card'>
                        <div className='card-header border-0 bg-primary'>
                            <h4 className='text-white d-flex align-items-center'>
                                <i className='la la-list-alt mx-1' style={{fontSize: "25px"}}></i>
                                {t('consultation.consult-history')}
                            </h4>
                        </div>
                        <div className='card-body'>
                        <PerfectScrollbar
                            style={{ height: '500px' }}
                            id='DZ_W_TimeLine1'
                            className='widget-timeline dz-scroll style-1 height500 ps ps--active-y'
                        >
                            <ul className='timeline'>
                                {consultations.map(consultation => {
                                return <li>
                                    <div className={consultation.id % 2 ? 'timeline-badge info' :  (
                                        consultation.id % 3 ? 'timeline-badge success' : (
                                            consultation.id % 5 ? 'timeline-badge warning' :(
                                                consultation.id % 7 ? 'timeline-badge danger' : 'timeline-badge primary'
                                            )
                                        )
                                    )}></div>
                                    <Link
                                        className='timeline-panel text-muted'
                                        to={"/consultation-details/"+consultation.id}
                                    >
                                        <span>{format(new Date(consultation.openDate), 'dd/MM/yyyy hh:mm')}</span>
                                        <h6 className='mb-0'>
                                            {consultation.motif}
                                        </h6>
                                    </Link>
                                </li>})
                                }
                            </ul>
                        </PerfectScrollbar>
                        </div>
                    </div>
                </div>
                <div className='col-6'>
                    <div className='row w100'>
                        <div className='card' style={{width: "100%" }}>
                            <div className='card-header border-0 bg-primary'>
                                <h4 className='text-white d-flex align-items-center'>
                                    <i className='la la-pills mx-1' style={{fontSize: "25px"}}></i>
                                    {t('common.in-progress')}
                                </h4>
                            </div>
                            <div className='card-body'>
                            <PerfectScrollbar
                                style={{ height: '185px', width: "100% !important" }}
                                id='DZ_W_TimeLine1'
                                className='widget-timeline dz-scroll style-1 height185 ps ps--active-y'
                            >
                                <ListGroup as="ul" variant="flush">
                                    {datas.traitements?.map((treat, i) => {
                                        return <ListGroup.Item
                                            action
                                            className="flex-column align-items-start"
                                            key={i}
                                        >
                                            <div className="d-flex w-100 justify-content-between">
                                                <h5 className="mb-1 text-dark">
                                                    {treat.traitement?.medicament?.name}
                                                </h5>
                                            </div>
                                            <i>Démarré le {format(new Date(treat.startDate), 'dd/MM/yyyy')} et se termine le {format(new Date(treat.endDate), 'dd/MM/yyyy')}</i>
                                        </ListGroup.Item>})
                                    }
                                </ListGroup>
                            </PerfectScrollbar>
                            </div>
                        </div>
                    </div>
                    
                    <div className='row w100 justify-content-space-evenly'>
                        <div className='col-6 p-0 pr-2 m-0'>
                            <div className='card' style={{width: "100%" }}>
                                <div className='card-header border-0 bg-primary'>
                                    <h4 className='text-white d-flex align-items-center'>
                                        <i className='la la-chart-bar mx-1' style={{fontSize: "25px"}}></i>
                                        Mesures
                                    </h4>
                                </div>
                                <div className='card-body'>
                                {datas.listParametres?.length > 0 && <PerfectScrollbar
                                    style={{ height: '185px', width: "100% !important" }}
                                    id='DZ_W_TimeLine1'
                                    className='widget-timeline dz-scroll style-1 height185 ps ps--active-y'
                                >
                                    <div className='d-flex flex-wrap'>
                                        {datas.listParametres[4] && <div className='col-6 p-0 m-0 pr-2 mb-2 align-items-start'>
                                            <div className='p-1 d-flex flex-column border-bottom'>
                                                <h4 className='text-primary mb-2'>{t('common.height')}</h4>
                                                <b>{datas.listParametres[4]?.value} m</b>
                                            </div>
                                            <small className='text-dark'>{format(new Date(datas.listParametres[4]?.date), 'dd/MM/yyyy')} </small>
                                        </div>}
                                        {datas.listParametres[5] && <div className='col-6 p-0 m-0 mb-2 align-items-start'>
                                            <div className='p-1 d-flex flex-column border-bottom'>
                                                <h4 className='text-primary mb-2'>{t('common.weight')}</h4>
                                                <b>{datas.listParametres[5]?.value}  Kg</b>
                                            </div>
                                            <small className='text-dark'>{format(new Date(datas.listParametres[5]?.date), 'dd/MM/yyyy')} </small>
                                        </div>}
                                        {datas.listParametres[0] && <div className='col-6 p-0 m-0 pr-2 mb-2 align-items-start'>
                                            <div className='p-1 d-flex flex-column border-bottom'>
                                                <h4 className='text-primary mb-2'>IMC</h4>
                                                <b>{datas.listParametres[0]?.value}  kg/m</b>
                                            </div>
                                            <small className='text-dark'>{format(new Date(datas.listParametres[0]?.date), 'dd/MM/yyyy')} </small>
                                        </div>}
                                        {datas.listParametres[3] && <div className='col-6 p-0 m-0 mb-2 align-items-start'>
                                            <div className='p-1 d-flex flex-column border-bottom'>
                                                <h4 className='text-primary mb-2'>Temp</h4>
                                                <b>{datas.listParametres[3]?.value}  °C</b>
                                            </div>
                                            <small className='text-dark'>{format(new Date(datas.listParametres[3]?.date), 'dd/MM/yyyy')} </small>
                                        </div>}
                                        {datas.listParametres[2] && <div className='col-6 p-0 m-0 pr-2 mb-2 align-items-start'>
                                            <div className='p-1 d-flex flex-column border-bottom'>
                                                <h4 className='text-primary mb-2'>{t('consultation.blood-pressure')} S</h4>
                                                <b>{datas.listParametres[2]?.value}  mm/Hg</b>
                                            </div>
                                            <small className='text-dark'>{format(new Date(datas.listParametres[2]?.date), 'dd/MM/yyyy')}  </small>
                                        </div>}
                                        {datas.listParametres[1] && <div className='col-6 p-0 m-0 mb-2 align-items-start'>
                                            <div className='p-1 d-flex flex-column border-bottom'>
                                                <h4 className='text-primary mb-2'>{t('consultation.blood-pressure')} D</h4>
                                                <b>{datas.listParametres[1]?.value}  mm/Hg</b>
                                            </div>
                                            <small className='text-dark'>{format(new Date(datas.listParametres[1]?.date), 'dd/MM/yyyy')} </small>
                                        </div>}
                                        {datas.listParametres[6] && <div className='col-6 p-0 m-0 mb-2 align-items-start'>
                                            <div className='p-1 d-flex flex-column border-bottom'>
                                                <h4 className='text-primary mb-2'>{t('consultation.heart-rate')}</h4>
                                                <b>{datas.listParametres[6]?.value}  bpm</b>
                                            </div>
                                            <small className='text-dark'>{format(new Date(datas.listParametres[6]?.date), 'dd/MM/yyyy')} </small>
                                        </div>}
                                        {datas.listParametres[7] && <div className='col-6 p-0 m-0 mb-2 align-items-start'>
                                            <div className='p-1 d-flex flex-column border-bottom'>
                                                <h4 className='text-primary mb-2'>{t('consultation.respiration-rate')}</h4>
                                                <b>{datas.listParametres[7]?.value}  cycle/min</b>
                                            </div>
                                            <small className='text-dark'>{format(new Date(datas.listParametres[7]?.date), 'dd/MM/yyyy')} </small>
                                        </div>}
                                    </div>
                                </PerfectScrollbar>}
                                </div>
                            </div>
                        </div>

                        <div className='col-6 p-0 m-0'>
                            <div className='card' style={{width: "100%" }}>
                                <div className='card-header border-0 bg-primary'>
                                    <h4 className='text-white d-flex align-items-center'>
                                        <i className='la la-user-cog mx-1' style={{fontSize: "25px"}}></i>
                                        Allergies
                                    </h4>
                                </div>
                                <div className='card-body'>
                                <PerfectScrollbar
                                    style={{ height: '185px', width: "100% !important" }}
                                    id='DZ_W_TimeLine1'
                                    className='widget-timeline dz-scroll style-1 height185 ps ps--active-y'
                                >
                                    <div>
                                        <h5>Allergies</h5>
                                        <ul className='pl-3'>
                                            {datas.allergies?.allergies?.map((allergy, i) => {return <li key={i} className="my-2">{allergy}</li>})}
                                        </ul>
                                    </div>
                                    <div>
                                        <h5>{t('consultation.personal-history')}</h5>
                                        <ul className='pl-3'>
                                            {datas.allergies?.persAnts?.map((allergy, i) => {return <li key={i} className="my-2">{allergy}</li>})}
                                        </ul>
                                    </div><div>
                                        <h5>{t('consultation.family-history')}</h5>
                                        <ul className='pl-3'>
                                            {datas.allergies?.famAnts?.map((allergy, i) => {return <li key={i} className="my-2">{allergy}</li>})}
                                        </ul>
                                    </div><div>
                                        <h5>{t('consultation.habits')}</h5>
                                        <ul className='pl-3'>
                                            {datas.allergies?.habits?.map((allergy, i) => {return <li key={i} className="my-2">{allergy}</li>})}
                                        </ul>
                                    </div><div>
                                        <h5>Profession</h5>
                                        <ul className='pl-3'>
                                            {datas.allergies?.Identities?.map((allergy, i) => {return <li key={i} className="my-2">{allergy}</li>})}
                                        </ul>
                                    </div>
                                </PerfectScrollbar>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        provider: state.provider.provider,
        consultations: state.consultation.clientConsultations,
        currentUser: currentUser(state)
    };
};

export default connect(mapStateToProps)(Dashboard);