import { connect, useDispatch } from 'react-redux';
import React, {Fragment, useEffect, useState} from "react";
import swal from "sweetalert";
import {
    loadingToggleAction, identificationAction,
} from '../../../store/actions/PatientAction';
import { DataTable } from 'primereact/datatable';
import { InputSwitch } from 'primereact/inputswitch';
import { Column } from 'primereact/column';
import { Link, withRouter } from "react-router-dom";
import { Row, Col, Card, Spinner, Badge, Modal } from 'react-bootstrap'
import { getBonByCodeAction, delieverDrugsAction, startLoader } from '../../../store/actions/PharmacieActions';
import { searchDiseasesAction, searchDrugsAction, addActeDetailAction, updateConsultationAction, getConsultationByCodeDataAction, startLoader as startConsultationLoader } from '../../../store/actions/ConsultationActions';
import { currentUser } from '../../../store/selectors/CurrentUserSelector';
import { calculationTMAction } from '../../../store/actions/FacturationAction';
import SubstitutionModal from './SubstitutionModal';
import {format} from 'date-fns';
import { DataTableBase } from '../DataTables/DataTableBase';
import Select from 'react-select';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { ListGroup, Button as ButtonBootstrap } from 'react-bootstrap';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { Dialog } from 'primereact/dialog';

import { DatePicker, DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import PerfectScrollbar from "react-perfect-scrollbar";
import { TabView, TabPanel } from 'primereact/tabview';
import { InputNumber } from 'primereact/inputnumber';
import { Button as PButton } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { STATUSES } from '../../globals';
import { useTranslation } from "react-i18next";
import { Card as PRCard } from 'primereact/card';
import './BonSearchForm.css';

const BonSearchForm = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [search, setSearch] = useState("");
    const [drugs, setDrugs] = useState([]);
    const [prixPharmacie, setPrixPharmacie] = useState([]);
    //const [subsModal, setSubsModal] = useState(false);
    const [currentKey, setCurrentKey] = useState('');
    const [filters, setFilters] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    
    const [visible, setVisible] = useState(false);
    const [multiBonVisible, setMultiBonVisible] = useState(false);
    const [numBon, setNumBon] = useState("");
    const [consultation, setConsultation] = useState({});
    const [fileSrc, setFileSrc] = useState(null);
    const [focusBon, setFocusBon] = useState({});

    
    const motives = [{label: t('common.motherhood'), value: 'maternity'}, {label: t('common.accident'), value: "accident"}, {label: t('common.natural-illness'), value: "sickness"}];
    const { bon, consultationData, consultSuccessMessage, beneficiaires, diseases, drugList, actesConsult, showConsultLoading, currentUser, history, errorMessage, successMessage, showLoading, ticketModDatas } = props;

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            quantite: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            unit: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            deliveryName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            deliveryDate: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            isDelivery: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        });
        setGlobalFilterValue("");
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <Button type="button" icon="pi pi-filter-slash" label={t('common.reinit')} outlined onClick={clearFilter} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder={t('common.search')} />
                </span>
            </div>
        );
    };

    const footerContent = (
        <div>
            <PButton label="Fermer" icon="pi pi-times" onClick={() => setVisible(false)} className="p-button-text" />
        </div>
    );

    const clearFilter = () => {
        initFilters();
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    

    const setColumns = () => {
        return [
            {selectionMode: "multiple", style: { width: '3rem' }},
            {name: 'name', headerName: t('common.drug'), filterPlaceholder: t('common.filter-drug'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: medicamentNameBodyTemplate},
            {name: 'prix', dataType: 'numeric', headerName: 'P.U.', filterPlaceholder: t('common.filter-unit-price'), style: { minWidth: '6rem' }, filter: true, bodyTemplate: getAmountTemplate},
            {name: 'qte', dataType: 'numeric', headerName: 'Qté', filterPlaceholder:  t('common.filter-qte'), style: { minWidth: '2rem' }, filter: true, bodyTemplate: null},
            {name: 'prixTotal', dataType: 'numeric', headerName: 'P.T.', filterPlaceholder:  t('common.filter-total-price'), style: { minWidth: '6rem' }, filter: true, bodyTemplate: null},
            {name: 'tm', dataType: 'numeric', headerName: 'T.M.', filterPlaceholder: t('common.filter-moderator'), style: { minWidth: '6rem' }, filter: true, bodyTemplate: null},
            {name: 'prixPatient', dataType: 'numeric', headerName: 'T.P.', filterPlaceholder: t('common.filter-tier'), style: { minWidth: '6rem' }, filter: true, bodyTemplate: null},
            {name: 'unit', headerName: "Unité", filterPlaceholder: t('common.filter-unit'), style: { minWidth: '5rem' }, filter: true, bodyTemplate: null},
            {name: 'deliveryName', headerName: t('common.executed-center'), filterPlaceholder: t('common.filter-center'), style: { minWidth: '8rem' }, filter: true, bodyTemplate: null},
            {name: 'deliveryDate', filterField: 'date', dataType: 'date', headerName: t('common.executed-on'), filterPlaceholder: 'Filtrer par date d\'exécution', style: { minWidth: '5rem' }, filter: true, bodyTemplate: dateBodyTemplate, filterElement: dateFilterTemplate},
            {name: 'isDelivery', headerName: 'Statut', dataType: 'boolean', filterPlaceholder: t('common.filter-status'), style: { minWidth: '4rem' }, filter: true, bodyTemplate: statusDelBodyTemplate, filterElement: statusDelFilterTemplate},
            {name: 'actions', headerName: 'Actions', filterPlaceholder: '', style: { minWidth: '4rem'}, filter: false, bodyTemplate: getActionsTemplate},
        ]
    };

    const getActionsTemplate = (rowData) => {
        return <div style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
            {hasMenu('MENPHARM') && canDo('MENPHARM', 'ACREQDRGSUBS') && 
            !rowData.isDelivery && (rowData.drugSubtitution.length === 0 || 
            (rowData.drugSubtitution.length > 0 && 
            rowData.drugSubtitution[rowData.drugSubtitution.length - 1]?.status !== STATUSES.pending)) && <ButtonBootstrap
                as='a'
                variant='warning light'
                href='#'
                className='btn-card'
                onClick={(e) => {
                    e.preventDefault();
                    console.log("clicked on :"+rowData.id)
                    setCurrentKey(rowData.id);
                }}
            >
                {t('common.substitute')}
            </ButtonBootstrap>}
            
            <Modal
                className="fade bd-example-modal-lg"
                show={currentKey === rowData.id}
                size="lg"
            >
                <SubstitutionModal 
                    showModal={currentKey === rowData.id}
                    bonDetailId={rowData.id} 
                    medicament={rowData.medicament} 
                    manageSubsModal={manageSubsModal}
                    history={history}
                    currentUser={currentUser}
                />
            </Modal>
        </div>
    };

    const getAmountTemplate = (rowData) => {
        return rowData.isDelivery ? 
        <span>{rowData.prix}</span> :
        <input type='number' 
            className='form-control'
            min="0" 
            disabled={rowData.isDelivery}
            onChange={e => {
                setPrixTotal(rowData.id, e);
            }} 
            value={prixPharmacie?.find(elt => elt.id === rowData.id)?.prix}
        />
    }

    const statusDelFilterTemplate = (options) => {
        return (
            <div className="flex align-items-center gap-2">
                <label htmlFor="isClose-filter" className="font-bold">
                    {t('common.delivered')}
                </label>
                <TriStateCheckbox inputId="isClose-filter" value={options.value} onChange={(e) => options.filterCallback(e.value)} />
            </div>
        );
    };

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" mask="99/99/9999" />;
    };

    const dateBodyTemplate = (rowData) => {
        return formatDate(rowData.deliveryDate);
    };

    const medicamentNameBodyTemplate = (rowData) => {
        return <>
            <span>{rowData.name}</span><br/>
            {rowData.drugSubtitution.length > 0 && rowData.drugSubtitution[rowData.drugSubtitution.length - 1]?.status === STATUSES.pending && 
                <>
                    <small className='text-warning'>
                        {t('common.await-substitution')} {' '}
                        {rowData.drugSubtitution[rowData.drugSubtitution.length - 1]?.newMedicament?.name}
                    </small>
                </>
            }
        </>
    };

    const statusDelBodyTemplate = (rowData) => {
        return <Tag value={rowData.isDelivery ? 'Livré' : "Non Livré"} severity={getSeverity(rowData.isDelivery)} />;
    };

    const getSeverity = (status) => {
        return status ? 'success' : 'warning'
    };

    const formatDate = (value) => {
        return value ? value.toLocaleDateString('fr-FR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        }) : '';
    };

    const isDrugSelectable = (rowData) => {
        return !rowData.isDelivery && 
                (rowData.drugSubtitution.length === 0 || 
                    (rowData.drugSubtitution.length > 0 && 
                        rowData.drugSubtitution[rowData.drugSubtitution.length - 1]?.status !== STATUSES.pending)
                );
    }

    
    const onSave = () => {

    }

    const searchBon = (e) => {
        e.preventDefault();
        if(search.trim().length > 0) {
            console.log(search);
            dispatch(startLoader());
            dispatch(getBonByCodeAction(search));
        }
    }

    const sumMedicament = (data) => {
        let total = 0;
        data.forEach(medoc => {
            total += (medoc.medicament.prix * medoc.quantite);
        });
        return total;
    }

    const delieverDrugs = () => {
        let data = {};
        data.medicaments = drugs.map(drug => {
            return {
                pharmacieBonDetailId: drug.id,
                MedicamentCode: drug.medicament.code, 
                MedicamentName: drug.medicament.name, 
                Quantite: drug.qte, 
                Amount: prixPharmacie.filter(drg => drg.id === drug.id)[0]?.prix,
                MontantPolice: ticketModDatas?.plafond,
                MontantTM: prixPharmacie.filter(drg => drg.id === drug.id)[0]?.tm,
            }
        });
        data.deliveryName = currentUser.provider.providerName;
        data.deliveryBy = currentUser.provider.id;
        console.log(data);
        dispatch(startLoader());
        dispatch(delieverDrugsAction(data));
    }

    const manageSubsModal = (val, reload) => {
        //setSubsModal(val);
        setCurrentKey('');
        /*if(reload){
            window.location.reload(false);
        };*/
        if(search.trim().length > 0) {
            dispatch(startLoader());
            dispatch(getBonByCodeAction(search));
        }
    }

    const setPrixTotal = (detailId, e) => {
        setPrixPharmacie(prixPharmacie.map(elt => {
            if(elt.id === detailId) {
                elt.prix = e.target.value;
                elt.prixTotal = (e.target.value * elt.qte).toFixed(2);
                elt.tm = Math.min(elt.prix, ticketModDatas?.plafond) * elt.qte * ticketModDatas?.tm / 100;
                elt.prixPatient = (elt.prix * elt.quantite) - Math.min(elt.prix, ticketModDatas?.plafond) * elt.quantite * ticketModDatas?.tm / 100;
            }
            return elt;
        }))
    }

    const saveConsultation = (e) => {
        e.preventDefault();
        if(consultation.dossierConsultationId) {
            dispatch(startConsultationLoader());
            if(consultation.drugs) {
                consultation.drugs.forEach(drug => {
                    let data = {
                        pharmacieBonId: consultation.pharmacieBonId ? consultation.pharmacieBonId : -1,
                        consultationId: consultation.dossierConsultationId,
                        medicamentId: drug.id,
                        medicamentCode: drug.code,
                        medicamentName: drug.name,
                        quantite: drug.quantity,
                        montant: drug.amount,
                        posologie: [],
                        posologieString: "",
                        fileSrc
                        //facturePrestataireTMPId: consultation.facturePrestataireTMPId
                    };
                    dispatch(addActeDetailAction('pharmacie', data, numBon));
                });
            }
        }else{
            let data = {
                bonId: consultation.bonId,
                //facturePrestataireTMPId: consultation.facturePrestataireTMPId ? consultation.facturePrestataireTMPId : id,
                diagnostics: {
                    final: true,
                    diagnostic: consultation.diagnostic
                },
                questions: {
                    motif: consultation.motif,
                    allergies: [],
                    habits: [],
                    persAnts: [],
                    famAnts: [],
                    identities: [],
                    inspections: [],
                    palpations: [],
                    oscultations: [],
                    percussions: [],
                    observations: "",
                },
                optique: consultation.optique ? consultation.optique : {},
                fileSrc
            }
            if(consultation.drugs) {
                data = {
                    ...data,
                    medocs: consultation.drugs.map(drug => {
                        let res = {...drug, posologieString: ""};
                        delete res['active'];
                        return res;
                    })
                }
            }
            dispatch(startConsultationLoader());
            dispatch(updateConsultationAction(data, numBon));
        }
    }

    const noChange = () => {
        return (!consultation.drugs || consultation.drugs.length === 0) && !consultation.diagnostic && !fileSrc;
    }

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
          return false;
        }else{
          return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
    }
    
    const hasMenu = (menuCode) => {
        return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
    }

    const addOrdonance = (e) => {
        let upFiles = e.target.files;
        const keys = Object.keys(upFiles);
        console.log(upFiles[keys[0]]);
        setFileSrc(upFiles[keys[0]]);
    }

    const chooseBon = (choosenBon) => {
        console.log(choosenBon);
        if(choosenBon.patientId) {
            dispatch(calculationTMAction(choosenBon.patientId));
        }
        setNumBon(choosenBon.consultationCode);
        setFocusBon(choosenBon);
        setPrixPharmacie(choosenBon.pharmacieBonDetails?.map(elt => {
            return {
                id: elt.id,
                name: elt.medicament.name,
                prix: (elt.isDelivery ? elt.montant : elt.medicament.amount),
                qte: elt.quantite,
                prixTotal: ((elt.isDelivery ? elt.montant : elt.medicament.amount) * elt.quantite).toFixed(2),
                unit: elt.medicament.unit,
                deliveryName: elt.deliveryName,
                deliveryDate: elt.deliveryDate,
                isDelivery: elt.isDelivery,
                tm: 0,
                prixPatient: ((elt.isDelivery ? elt.montant : elt.medicament.amount) * elt.quantite).toFixed(2),
                drugSubtitution: elt.drugSubtitution,
                medicament: elt.medicament
            }
        }));
        setMultiBonVisible(false);
    }

    useEffect(() => {
        if(hasMenu('MENPHARM') && canDo('MENPHARM', 'ACVIEWPHARMBON')){
            console.log("About to call all the consultations");
        }else{
            props.history.push('/access-denied');
        }
    }, []);

    useEffect(() => {
        if(search.trim().length > 0) {
            dispatch(startLoader());
            dispatch(getBonByCodeAction(search));
        }
    }, [successMessage, errorMessage, consultSuccessMessage]);

    useEffect(() => {
        if(consultSuccessMessage === 'Consultation Updated Successfully Completed' || consultSuccessMessage === 'Add acte Successfully Completed') {
            setVisible(false);
            dispatch(startLoader());
            dispatch(getBonByCodeAction(numBon));
        }
    }, [consultSuccessMessage]);

    useEffect(() => {
        if(Array.isArray(bon)){
            if(bon.length > 0) {
                setMultiBonVisible(true);
            }
        }else{
            if(bon.patientId) {
                dispatch(calculationTMAction(bon.patientId));
            }
            setNumBon(bon.consultationCode);
            setFocusBon(bon);
            setPrixPharmacie(bon.pharmacieBonDetails?.map(elt => {
                return {
                    id: elt.id,
                    name: elt.medicament.name,
                    prix: (elt.isDelivery ? elt.montant : elt.medicament.amount),
                    qte: elt.quantite,
                    prixTotal: ((elt.isDelivery ? elt.montant : elt.medicament.amount) * elt.quantite).toFixed(2),
                    unit: elt.medicament.unit,
                    deliveryName: elt.deliveryName,
                    deliveryDate: elt.deliveryDate,
                    isDelivery: elt.isDelivery,
                    tm: 0,
                    prixPatient: ((elt.isDelivery ? elt.montant : elt.medicament.amount) * elt.quantite).toFixed(2),
                    drugSubtitution: elt.drugSubtitution,
                    medicament: elt.medicament
                }
            }))
        }
    }, [bon]);

    useEffect(() => {
        if(visible && numBon) {
            dispatch(startConsultationLoader());
            dispatch(getConsultationByCodeDataAction(numBon));
        }
    }, [visible]);

    useEffect(() => {
        setPrixPharmacie(focusBon.pharmacieBonDetails?.map(elt => {
            return {
                id: elt.id,
                name: elt.medicament.name,
                prix: (elt.isDelivery ? elt.montant : elt.medicament.amount),
                qte: elt.quantite,
                prixTotal: ((elt.isDelivery ? elt.montant : elt.medicament.amount) * elt.quantite).toFixed(2),
                unit: elt.medicament.unit,
                deliveryName: elt.deliveryName,
                deliveryDate: elt.deliveryDate,
                isDelivery: elt.isDelivery,
                drugSubtitution: elt.drugSubtitution,
                tm: Math.min((elt.isDelivery ? elt.montant : elt.medicament.amount), ticketModDatas?.plafond) * elt.quantite * ticketModDatas?.tm / 100,
                prixPatient: ((elt.isDelivery ? elt.montant : elt.medicament.amount) * elt.quantite) - Math.min(elt.medicament.amount, ticketModDatas?.plafond) * elt.quantite * ticketModDatas?.tm / 100,
                medicament: elt.medicament
            }
        }))
    }, [ticketModDatas]);

    useEffect(() => {
        if(props.consultationData) {
            setConsultation({
                bonId: props.consultationData.id,
                facturePrestataireTMPId: props.consultationData.facturePrestataireTMPId,
                beneficiaryId: props.consultationData.beneficiaryId,
                beneficiaryName: props.consultationData.beneficiaryName,
                doctorId: props.consultationData.doctorId,
                doctorName: props.consultationData.doctorName,
                acteId: props.consultationData.acteId,
                acteName: props.consultationData.acteName,
                acteCode: props.consultationData.acteCode,
                date: props.consultationData.date,
                motif: props.consultationData.motif,
                dossierConsultationId: props.consultationData.dossierConsultation?.id,
                pharmacieBonId: props.consultationData.prescription?.examensBon[0]?.id,
                prixPharmacie: props.consultationData.prescription?.pharmacieBon[0]?.pharmacieBonDetails?.map(elt => {
                    return {
                        id: elt.id,
                        name: elt.medicament.name,
                        prix: elt.isDelivery ? elt.montant : elt.medicament.amount,
                        qte: elt.quantite,
                        prixTotal: ((elt.isDelivery ? elt.montant : elt.medicament.amount) * elt.quantite).toFixed(2),
                        unit: elt.medicament.unit,
                        deliveryName: elt.deliveryName,
                        deliveryDate: elt.deliveryDate,
                        isDelivery: elt.isDelivery,
                        medicament: elt.medicament
                    }
                })
            })
        }
        //dispatch(getPrestationAction(id));
    }, [props.consultationData]);
    

    return (
        <Fragment>
            <div className="bg-white p-5">
                <div className='row justify-content-center mt-4'>
                    <form onSubmit={(e) => searchBon(e)} className="d-flex">
                        <input type="search" placeholder= {t('common.bon-search-placeholder')} required value={search} onChange={e => setSearch(e.target.value)} className='form-control input-default mb-2' /> 
                        
                        { showLoading && 
                            <div className='col-6'>
                                <Button variant="primary" disabled>
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />{' '}
                                    <span className="visually-hidden">{t('common.await-text')}</span>
                                </Button>
                            </div> }
                        { !showLoading &&
                            <div className='col-4'>
                                <button type='submit' className='btn btn-primary btn-block'>
                                    <i className='la la-search'></i>
                                </button>
                            </div> }
                    </form>
                </div>
                {Array.isArray(bon) && <Dialog header="Choisir le bon à traiter" visible={multiBonVisible} maximizable style={{ width: '50vw'}} onHide={() => setMultiBonVisible(false)} footer={footerContent}>
                    <div className='row'>
                        {bon.map(elt => {
                                return <div className="card col-4" onClick={() => chooseBon(elt)}>
                                        <PRCard title={"Bon n°"+elt.code}>
                                            <ul className="m-0">
                                                <li><b>Prescripteur:</b> {elt.providerName}</li>
                                                <li><b>Date de prescription:</b> {formatDate(new Date(elt.createdAt))}</li>
                                                <li><b>Total médicaments:</b> {elt.pharmacieBonDetails.length}</li>
                                            </ul>
                                        </PRCard>
                                    </div>
                            })}
                    </div>
                </Dialog>}
                <div className='row mt-5 justify-content-center align-items-center h500'>
                    {Object.keys(bon).length === 0 && showLoading && <small>{t('laboratory.await-text-ordonance')}</small>}
                    {Object.keys(bon).length === 0 && !showLoading && <small>{t('laboratory.no-prescription-found')}</small>}
                    {Object.keys(focusBon).length > 0 && <>
                        <div className='no-print col'>
                            <h4 className='mt-2 text-dark text-start'>
                                <span style={{textDecoration: 'underline'}}>Patient:</span>
                                <span className='text-bold text-primary ml-2'>{focusBon.patientName}</span>
                            </h4>
                            <h4 className='mt-2 text-dark text-start'>
                                <span style={{textDecoration: 'underline'}}>{t('laboratory.prescriber')}:</span>
                                <span className='text-bold text-primary ml-2'>{focusBon.providerName}</span>
                            </h4>
                            <h4 className='mt-2 text-dark text-start'>
                                <span style={{textDecoration: 'underline'}}>Affection:</span>
                                <span className='text-bold text-primary ml-2'>{focusBon.codeAffection}</span>
                            </h4>
                            <div className="border-top my-2"></div>
                            <div className='row justify-content-between my-5'>
                                <span>{t('common.total-price')}: <b className='text-primary'>{drugs.reduce((sum, drug) => {
                                    let prix = prixPharmacie.find(drg => drg.id === drug.id)?.prix;
                                    sum = sum + (prix * drug.qte);
                                    return sum;
                                }, 0)}</b></span>
                                <span>{t('common.amount-to-pay')}: <b className='text-primary'>{drugs.reduce((sum, drug) => {
                                    let tm = prixPharmacie.find(drg => drg.id === drug.id)?.tm;
                                    sum = sum + tm;
                                    return sum;
                                }, 0)}</b></span>
                            </div>
                            <div className="row">
                                <DataTableBase 
                                    data={prixPharmacie?.map(bon => {
                                        bon.deliveryDate = new Date(bon.deliveryDate);
                                        return bon;
                                    })}
                                    size="small"
                                    emptyMessage={t('common.no-drug')} 
                                    filters={filters}
                                    globalFiltersFields={['name', 'prix', 'qte', 'prixTotal', 'tm', 'prixPatient', 'unit', 'deliveryName', 'deliveryDate', 'isDelivery']}
                                    header={renderHeader()}
                                    columns={setColumns()}
                                    rows={10} 
                                    loading={props.showLoading}
                                    rowClick={true}
                                    selectedData={drugs}
                                    setSelectedDatas={(val) => setDrugs(val)}
                                    isRowSelectable={val => isDrugSelectable(val)}
                                />
                            </div>
                            <div className='row p-4 justify-content-between'>
                                {hasMenu('MENPHARM') && canDo('MENPHARM', 'ACDELDRUGS') && prixPharmacie && prixPharmacie.find(elt => !elt.isDelivery) && <div className='row justify-content-end my-4'>
                                        { showLoading && 
                                            <Button variant="primary" disabled>
                                                <Spinner
                                                    as="span"
                                                    animation="grow"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />{' '}
                                                <span className="visually-hidden">{t('common.await-text')}</span>
                                            </Button> }
                                        { !showLoading &&
                                            <Button
                                                as='a'
                                                variant='primary light'
                                                href='#'
                                                className='btn-card'
                                                onClick={() => delieverDrugs()}
                                                >
                                                {t('laboratory.validate-button')}
                                            </Button> }
                                </div>}
                                <div className='row justify-content-end my-4'>
                                    <Button
                                        as='a'
                                        variant='success light'
                                        href='#'
                                        className='btn-card btn-success'
                                        onClick={e => window.print()}
                                    >
                                        {t('laboratory.print-button')}
                                    </Button>
                                </div>
                                <div className='row justify-content-end my-4'>
                                    <Button
                                        as='a'
                                        href='#'
                                        onClick={() => setVisible(true)}
                                        variant='info light'
                                        className='btn-card btn-info'
                                    >
                                        + Compléter la prescription
                                    </Button>
                                </div>

                                <Dialog header="Compléter la prescription" visible={visible} maximizable style={{ width: '50vw'}} onHide={() => setVisible(false)} footer={footerContent}>
                                    <form onSubmit={onSave}>
                                        <div className='row my-2 align-items-center'>
                                            <div className="col-4">
                                                <div className="form-group">
                                                    <label className='mb-1 '>
                                                        <strong>{t('common.code')} du bon</strong>
                                                        <span className='text-danger ml-1'>*</span>
                                                    </label>
                                                    <input
                                                        value={numBon}
                                                        type='text'
                                                        width={"50px"}
                                                        className='form-control input-group-prepend'
                                                        onChange={e => {
                                                            setNumBon(e.target.value);
                                                        }}
                                                        disabled={props.showConsultLoading}
                                                        onKeyDown={(e) => {
                                                            if(e.key === "Enter") {
                                                                e.preventDefault();
                                                                dispatch(startConsultationLoader());
                                                                dispatch(getConsultationByCodeDataAction(numBon));
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            {props.showConsultLoading && <div className="col-1">
                                                <ProgressSpinner style={{width: '25px', height: '25'}} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                                            </div>}
                                            <div className={props.showConsultLoading ? "col-7" : "col-8"}>
                                                <div className="form-group">
                                                    <label className='mb-1 '>
                                                        <strong>Assuré</strong>
                                                        <span className='text-danger ml-1'>*</span>
                                                    </label>
                                                    <input
                                                        value={consultation.beneficiaryId+" - "+consultation.beneficiaryName}
                                                        type='text'
                                                        width={"50px"}
                                                        className='form-control input-group-prepend'
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <TabView>

                                            <TabPanel header="Consultation">
                                                <div className='px-1 pt-1'>
                                                    <div className='row align-items-center'>
                                                        <div className="col-3">
                                                            <div className="form-group">
                                                                <label className='mb-1 '>
                                                                    <strong>{t('client.type')}</strong>
                                                                    <span className='text-danger ml-1'>*</span>
                                                                </label>
                                                                <Select
                                                                    className="select-search"
                                                                    value={{label: consultation.acteName, value: consultation.acteId, code: consultation.acteCode}}
                                                                    options={props.actesConsultation?.map(act => {
                                                                        return {label: act.nameFr, value: act.id, code: act.code}
                                                                    })}
                                                                    name="Type"
                                                                    placeholder="Choisir un type"
                                                                    search
                                                                    isRequired
                                                                    isDisabled={true}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-3">
                                                            <div className="form-group">
                                                                <label className='mb-1 '>
                                                                    <strong>{t('common.doctor')}</strong>
                                                                    <span className='text-danger ml-1'>*</span>
                                                                </label>
                                                                <Select
                                                                    className="select-search"
                                                                    value={{label: consultation.doctorName, value: consultation.doctorId}}
                                                                    options={props.provider?.providerUsers?.map(provUser => {
                                                                        return {label: provUser.user.username, value: provUser.userId}
                                                                    })}
                                                                    name="Doctor"
                                                                    placeholder="Choisir un médecin"
                                                                    disabled={props.showLoading}
                                                                    search
                                                                    isRequired
                                                                    isDisabled={true}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-3">
                                                            <div className="form-group">
                                                                <label className='mb-1 '>
                                                                    <strong>{t('common.hourdate')}</strong>
                                                                    <span className='text-danger ml-1'>*</span>
                                                                </label>
                                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                    <DateTimePicker
                                                                        autoOk
                                                                        label=""
                                                                        clearable
                                                                        value={consultation.date}
                                                                        format="dd/MM/yyyy"
                                                                        required
                                                                        disabled
                                                                    />
                                                                </MuiPickersUtilsProvider>
                                                            </div>
                                                        </div>
                                                        <div className="col-3">
                                                            <div className="form-group">
                                                                <label className='mb-1 '>
                                                                    <strong>{t('appointment.reason')}</strong>
                                                                    <span className='text-danger ml-1'>*</span>
                                                                </label>
                                                                <Select
                                                                    className="select-search"
                                                                    options={motives}
                                                                    value={motives.filter(elt => elt.value === consultation.motif).map(res => {return {label: res.label, value: res.value}})}
                                                                    name="Type"
                                                                    placeholder="Choisir un type"
                                                                    isDisabled={true}
                                                                    search
                                                                    isRequired
                                                                />
                                                            </div>
                                                        </div>
                                                        {!consultation.dossierConsultationId && <div className='col-12'>
                                                            <div className='form-group'>
                                                                <label className='mb-1 '>
                                                                    <strong>{t('common.diagnose')}</strong>
                                                                    <span className='text-danger ml-1'>*</span>
                                                                </label>
                                                                <Select
                                                                    className="select-search"
                                                                    options={props.diseases?.map(elt => {return {label: elt.name, value: elt.code}})}
                                                                    placeholder="Saisir un mot clé pour trouver l'affection"
                                                                    search
                                                                    isMulti
                                                                    onInputChange={(val) => {
                                                                        console.log(val);
                                                                        if(val.length >= 1){
                                                                            dispatch(searchDiseasesAction(val));
                                                                        }
                                                                    }}
                                                                    isDisabled={props.showConsultLoading}
                                                                    onChange={(vals) => {
                                                                        setConsultation({...consultation, diagnostic: vals ? vals.map(val => {
                                                                            return {code: val.value, name: val.label}
                                                                        }) : []});
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>}
                                                    </div>

                                                    <h5 className='mt-4 text-primary'>Ordonance</h5>
                                                    <div className="border-top mt-2 mb-4"></div>
                                                    <div className='row justify-content-between align-items-center'>
                                                        <div className="col-12 mb-2">
                                                            <div className='form-group'>
                                                                <label className='mb-1 '>
                                                                    <strong>{t('common.search-drug')}</strong>
                                                                </label>
                                                                <Select
                                                                    className="select-search"
                                                                    options={props.drugList?.map(elt => {return {...elt, label: elt.name, value: elt.code}})}
                                                                    placeholder="Saisir un mot clé pour trouver le médicament"
                                                                    search
                                                                    isMulti
                                                                    isDisabled={props.showConsultLoading}
                                                                    onInputChange={(val) => {
                                                                        console.log(val);
                                                                        if(val.length >= 1){
                                                                            dispatch(searchDrugsAction(val));
                                                                        }
                                                                    }}
                                                                    onChange={(vals) => {
                                                                        setConsultation({...consultation, drugs:vals ? vals.map((val, i) => {
                                                                            let res = {
                                                                                active: i===vals.length - 1,
                                                                                id: val.id,
                                                                                code: val.code,
                                                                                name: val.name,
                                                                                posologie: [],
                                                                                coverage: val.isExclu,
                                                                                unit: val.unit,
                                                                                quantity: 1
                                                                            }
                                                                            return res;
                                                                        }) : []});
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        
                                                        {consultation.drugs && <div className='col-12'>
                                                            <PerfectScrollbar
                                                                id="DZ_W_Todo2"
                                                                className="widget-media dz-scroll ps ps--active-y height300 text-center"
                                                            >
                                                                {consultation.drugs?.length === 0 && <span className='text-light'>Ajouter des médicaments...</span>}
                                                                {consultation.drugs?.length > 0 && 
                                                                    
                                                                    <div className="basic-list-group">
                                                                        <ListGroup>
                                                                            {consultation.drugs?.map((drug, j) => {
                                                                                return <ListGroup.Item
                                                                                    action
                                                                                    active={drug.active}
                                                                                    key={j}
                                                                                    className="mb-1 flex-column align-items-start"
                                                                                    style={{padding: "10px 20px", zIndex: "unset"}}
                                                                                    onClick={(e) => {
                                                                                        e.preventDefault();
                                                                                        setConsultation({...consultation, drugs: consultation.drugs.map(drg => {
                                                                                            drg.active = drg.code === drug.code;
                                                                                            return drg;
                                                                                        })});
                                                                                    }}
                                                                                    >
                                                                                    <h6 className={drug.active ? "text-white m-0" : "text-dark m-0"}>
                                                                                        {drug.name}
                                                                                        {drug.active ? 'active' : 'inactive'}
                                                                                        <small className={'text-' + (drug.coverage ? 'danger' : 'success')}>{drug.coverage ? '(Exclu)' : '(Couvert)'}</small>
                                                                                    </h6>
                                                                                    <div className="p-inputgroup" style={{width: '40%', height: '25px'}}>
                                                                                        <InputNumber 
                                                                                            placeholder="Price"
                                                                                            value={drug.quantity}
                                                                                            min={1}
                                                                                            disabled={props.showConsultLoading}
                                                                                            onChange={(e) => {
                                                                                                    setConsultation({...consultation, drugs: consultation.drugs.map(drg => { 
                                                                                                        if(drg.code === drug.code){
                                                                                                            drg.quantity = e.value
                                                                                                        }
                                                                                                        return drg;
                                                                                                    })});
                                                                                                }
                                                                                            } 
                                                                                        />
                                                                                        <span className="p-inputgroup-addon">{drug.unit}</span>
                                                                                    </div>
                                                                                    </ListGroup.Item>
                                                                                })
                                                                            }
                                                                        </ListGroup>
                                                                    </div>
                                                                }
                                                            </PerfectScrollbar>

                                                        </div>}

                                                        <div className="col-12 mb-2">
                                                            <div className='form-group'>
                                                                <label className='mb-1 '>
                                                                    <strong>Joindre l'ordonance</strong>
                                                                </label>
                                                                <div>
                                                                    <div className='input-group'>
                                                                        <div className='custom-file'>
                                                                            <input type="file" required className='custom-file-input' onChange={(e) => addOrdonance(e)} />
                                                                            <label className='custom-file-label'>{fileSrc ? <small>{fileSrc.name}</small> : 'Choisir'}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='row justify-content-end px-3 align-items-center'>
                                                        { props.showConsultLoading && 
                                                            <Button variant="primary" disabled>
                                                                <Spinner
                                                                    as="span"
                                                                    animation="grow"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                />{' '}
                                                                <span className="visually-hidden">{t('common.await-text')}</span>
                                                            </Button> }
                                                        { !props.showConsultLoading && 
                                                            <button className='btn btn-primary' disabled={noChange()} onClick={e => saveConsultation(e)}>Enregistrer</button>
                                                        }
                                                    </div>
                                                    
                                                </div>
                                            </TabPanel>

                                        </TabView>
                                    </form>
                                </Dialog>
                            </div>
                        </div>
                        <div className='bon-print col'>
                            <h2 className='text-center mb-5'>Bon de Pharmacie n°{search}</h2>

                            <h4 className='mt-2 text-dark text-start'>
                                <span style={{textDecoration: 'underline'}}>Patient:</span>
                                <span className='text-bold text-primary ml-2'>{focusBon.patientName}</span>
                            </h4>
                            <h4 className='mt-2 text-dark text-start'>
                                <span style={{textDecoration: 'underline'}}>{t('laboratory.prescriber')}:</span>
                                <span className='text-bold text-primary ml-2'>{focusBon.providerName}</span>
                            </h4>
                            <h4 className='mt-2 text-dark text-start'>
                                <span style={{textDecoration: 'underline'}}>Affection:</span>
                                <span className='text-bold text-primary ml-2'>{focusBon.codeAffection}</span>
                            </h4>
                            <div className="border-top my-2"></div>
                            
                            <div className="card">
                                <DataTable 
                                    value={prixPharmacie?.map(bon => {
                                        bon.deliveryDate = new Date(bon.deliveryDate);
                                        return bon;
                                    })} 
                                    showGridlines 
                                    tableStyle={{ minWidth: '50rem' }}
                                >
                                    <Column field="name" header="Médicament" style={{ minWidth: '200px' }}></Column>
                                    <Column field="qte" header="Quantite" style={{ minWidth: '200px' }}></Column>
                                    <Column field="unit" header="Unité" style={{ minWidth: '150px' }}></Column>
                                    <Column field="isDelivery" header="Livré" style={{ minWidth: '150px' }} body={statusDelBodyTemplate}></Column>
                                    <Column field="deliveryDate" header="Livré le" style={{ minWidth: '150px' }} body={dateBodyTemplate}></Column>
                                    <Column field="deliveryName" header="Livré par" style={{ minWidth: '150px' }}></Column>
                                </DataTable>
                            </div>
                        </div>
                    </> 
                    }
                </div>
            </div>
        </Fragment>
    )
};
const mapStateToProps = (state) => {
    return {
       bon: state.pharmacie.bon,
       consultationData: state.consultation.consultationByCode,
       beneficiaires: state.contrat.beneficiaires,
       diseases: state.consultation.diseases,
       drugList: state.consultation.drugs,
       actesConsult: state.provider.actesConsult,
       successMessage: state.pharmacie.successMessage,
       consultSuccessMessage: state.consultation.successMessage,
       errorMessage: state.pharmacie.errorMessage,
       showLoading: state.pharmacie.showLoading,
       currentUser: currentUser(state),
       ticketModDatas: state.facturation.ticketModDatas,
       showConsultLoading: state.consultation.showLoading
    };
};
export default withRouter(connect(mapStateToProps)(BonSearchForm));