import { HttpHeaders } from '@azure/storage-blob';
import axios from 'axios';
const headers = { 'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('userDetails'))?.token, 'Accept-Language': localStorage.getItem('currentLanguage')};

const consultationServiceRequest = axios.create({
  baseURL: process.env.REACT_APP_BASEURL_CONSULTATION_BACKEND,
  headers: headers
});

export const getBonByCode = (code) => consultationServiceRequest.get('/Examen/bon/'+code);
export const saveExamResults = (data) => consultationServiceRequest.post('/Examen/execute', data);
export const saveInterpretaionResults = (data) => consultationServiceRequest.post('/Examen/result', data);
export const getPatientResultsFiles = (id) => consultationServiceRequest.get('/Examen/beneficiairy/'+id);
