
import Webcam from "react-webcam";
import { connect, useDispatch } from 'react-redux';
import React, {useEffect} from "react";
import swal from "sweetalert";
import {
    loadingToggleAction, identificationAction,
} from '../../../store/actions/PatientAction';
import { Link, withRouter } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";
import { currentUser } from '../../../store/selectors/CurrentUserSelector';
import { useTranslation } from "react-i18next";

const PatientIdentify = (props) => {
    
    const webcamRef = React.useRef(null);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [imgSrc, setImgSrc] = React.useState(null);
    
    const capture = React.useCallback(() => {
        const imageBase64 = webcamRef.current.getScreenshot();
        if (imageBase64 != null) {
            setImgSrc(imageBase64);
            dispatch(loadingToggleAction(true));
            dispatch(identificationAction(imageBase64, props.history));
        }
    }, [webcamRef,setImgSrc]);
    const reset = () =>{
        setImgSrc("");
    }

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
          return false;
        }else{
          return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
      }
    
     const hasMenu = (menuCode) => {
          return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
      }

      useEffect(() => {
        if(hasMenu('MENIDENT') && canDo('MENIDENT', 'ACIDENTRF')){
            console.log('');
        }else{
            props.history.push("/provider-view/"+props.currentUser.providerId);
        }
    }, []);

    return (
        <>
            <div className="authincation h-100 p-meddle">
         <div className="container h-100">
            <div className="row justify-content-center h-100 align-items-center">
               <div className="col-md-6">
                  <div className="authincation-content">
                     <div className="row no-gutters">
                        <div className="col-xl-12">
                           <div className="auth-form">
                              <h4 className="text-center mb-4">
                                 {t('patient.authentication-patient')}
                              </h4>
                              <div className="justify-content-center rounded-circle">
                            {props.showLoading ?
                                <img className="mb-3 img-thumbnail"
                                src={imgSrc} alt="client screenshot"
                                />:
                                <Webcam
                                className="img-thumbnail"
                                audio={false}
                                ref={webcamRef}
                                height={"50%"}
                                width={"100%"}
                                screenshotFormat="image/jpeg"
                            />
                            }
                            { props.showLoading && 
                                <Button variant="primary" className="btn btn-primary btn-block mt-2" disabled>
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />{' '}
                                    <span className="visually-hidden">{t('patient.authentificate-await')}</span>
                                </Button> }
                            { !props.showLoading &&
                                <button type="submit" className="btn btn-primary btn-block mt-2" onClick={capture}>{t('patient.authenticate')}</button>
                            }

                        </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>

        </>
    )
};
const mapStateToProps = (state) => {
    return {
       showLoading: state.patient.showLoading,
       currentUser: currentUser(state)
    };
};
export default withRouter(connect(mapStateToProps)(PatientIdentify));