import React, { useState, useEffect } from "react";
import { connect, useDispatch } from 'react-redux';
import { getConsultationsAction, startLoader } from "../../../../store/actions/ConsultationActions";
import { currentUser } from "../../../../store/selectors/CurrentUserSelector";
import { Link, withRouter } from 'react-router-dom';
import { classNames } from 'primereact/utils';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { DataTableBase } from '../../DataTables/DataTableBase';
import { Modal } from 'react-bootstrap';
import HealthParamsModal from "./HealthParamsModal";
import { STATUSES } from '../../../globals';
import { useTranslation } from "react-i18next";

export const ConsultationList = (props) => {
    
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [filters, setFilters] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [healthModal, setHealthModal] = useState(false);
    const [currentConsultation, setCurrentConsultation] = useState({});

    const manageHealthParamsModal = () => {
        setHealthModal(false);
    };

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            beneficiaryName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            doctorName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            code: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            date: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            status: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            isHospitalisation: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        });
        setGlobalFilterValue("");
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <Button type="button" icon="pi pi-filter-slash" label="Réinitialiser" outlined onClick={clearFilter} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Rechercher" />
                </span>
            </div>
        );
    };

    const clearFilter = () => {
        initFilters();
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const setColumns = () => {
        return [
            {name: 'code', headerName: "Code", filterPlaceholder: t('common.filter-by-code'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
            {name: 'beneficiaryName', headerName: "Patient", filterPlaceholder: 'Filtrer par patient', style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
            {name: 'doctorName', headerName: t('common.doctor'), filterPlaceholder: 'Filtrer par médecin', style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
            {name: 'date', filterField: 'date', dataType: 'date', headerName: t('common.consultation-date'), filterPlaceholder: t('common.filter-by-consultation'), style: { minWidth: '6rem' }, filter: true, bodyTemplate: dateConsultationBodyTemplate, filterElement: dateFilterTemplate},
            {name: 'status', headerName: 'Statut', filterPlaceholder: t('common.status-filter-text'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: statusBodyTemplate, filterElement: statusFilterTemplate},
            {name: 'isHospitalisation', headerName: 'Hospitalisation', dataType: 'boolean', bodyClassName:"text-center", filterPlaceholder: t('common.filter-by-case'), style: { minWidth: '6rem' }, filter: true, bodyTemplate: statusHospiBodyTemplate, filterElement: statusHospiFilterTemplate},
            {name: 'actions', headerName: 'Actions', filterPlaceholder: '', style: { minWidth: '6rem'}, filter: false, bodyTemplate: getActionsTemplate},
        ]
    };

    const getActionsTemplate = (rowData) => {
        return <div style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
            {hasMenu('MENCONSULT') && canDo('MENCONSULT', 'ACVIEWCONSULT') && rowData.dossierConsultation && 
            <button className="btn btn-danger shadow btn-xs sharp mx-2"
                onClick={(e) => {
                    props.history.push('/consultation-details/'+rowData.dossierConsultation.id);
                }}
            >
                <i className="fa fa-eye"></i>
            </button>}
            {hasMenu('MENCONSULT') && canDo('MENCONSULT', 'ACMAKECONSULT') && (rowData.status !== STATUSES.exectuted && rowData.status !== STATUSES.expired && isDateArrived(rowData.date)) && 
            <button className="btn btn-primary shadow btn-xs sharp mx-2"
                onClick={(e) => {
                    props.history.push('/consultation/'+rowData.id+'/'+rowData.beneficiaryId+'/'+rowData.beneficiaryName);
                }}
            >
                <i className="fa fa-pencil"></i>
            </button>}
            {hasMenu('MENCONSULT') && canDo('MENCONSULT', 'ACUPDPARAMS') && !rowData.dossierConsultation && isDateArrived(rowData.date) && 
                <>
                    <button className="btn btn-warning shadow btn-xs sharp mx-2"
                        onClick={(e) => {
                            setHealthModal(true);
                            setCurrentConsultation(rowData);
                        }}
                    >
                        <i className="la la-heartbeat"></i>
                    </button>
                </>
            }
        </div>
    };

    const isDateArrived = (date) => {
        return new Date(date) <= new Date();
    }

    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={props.consultations.map(consultation => {
            return consultation.status
        }).reduce(function (acc, curr) {
            if (!acc.includes(curr))
                acc.push(curr);
            return acc;
        }, [])} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={statusItemTemplate} placeholder="Choisir" className="p-column-filter" showClear />;
    };

    const statusHospiFilterTemplate = (options) => {
        return (
            <div className="flex align-items-center gap-2">
                <label htmlFor="isClose-filter" className="font-bold">
                    Avec hospitalisation
                </label>
                <TriStateCheckbox inputId="isClose-filter" value={options.value} onChange={(e) => options.filterCallback(e.value)} />
            </div>
        );
    };

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" mask="99/99/9999" />;
    };

    const dateConsultationBodyTemplate = (rowData) => {
        return formatDate(rowData.date);
    };

    const statusBodyTemplate = (rowData) => {
        return <Tag value={t('statuses.'+rowData.status)} severity={getSeverity(rowData.status)} />;
    };

    const statusHospiBodyTemplate = (rowData) => {
        return <i className={classNames('pi', { 'text-green-500 pi-check-circle': rowData.isHospitalisation, 'text-red-500 pi-times-circle': !rowData.isHospitalisation })}></i>;
    };

    const statusItemTemplate = (option) => {
        return <Tag value={option} severity={getSeverity(option)} />;
    };

    const getSeverity = (status) => {
        switch (status) {
            case STATUSES.pending:
                return 'primary';
            
            case STATUSES.expired:
                return 'danger';

            case STATUSES.exectuted:
                return 'success';

            default:
                return 'warning';
        }
    };

    const formatDate = (value) => {
        return value.toLocaleDateString('fr-FR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
          return false;
        }else{
          return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
      }
    
     const hasMenu = (menuCode) => {
          return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
      }

     useEffect(() => {
        initFilters();
        if(hasMenu('MENCONSULT') && canDo('MENCONSULT', 'ACLISTCONSULT')){
            dispatch(startLoader());
            dispatch(getConsultationsAction(props.currentUser.providerId));
        }else{
            props.history.push('/access-denied');
        }
    }, []);

    return (
        <>
            <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
                <div className="mr-auto d-none d-lg-block">
                    <Link
                    to="/"
                    className="btn btn-primary btn-rounded"
                    >
                    + nouvelle consultation
                    </Link>
                </div>
            </div>
            <DataTableBase 
                data={props.consultations.map(facture => {
                    facture.date = new Date(facture.date);
                    return facture;
                })}
                emptyMessage="Aucune consultation trouvée" 
                filters={filters}
                globalFiltersFields={['beneficiaryName', 'doctorName', 'code', 'date', 'isHospitalisation', 'status']}
                header={renderHeader()}
                columns={setColumns()}
                rows={10} 
                loading={props.showLoading}
            />
            <Modal
                className="fade bd-example-modal-lg"
                show={healthModal}
                size="lg"
            >
                <HealthParamsModal 
                    showModal={healthModal}
                    consultation={currentConsultation}
                    currentUser={props.currentUser}
                    manageHealthParamsModal={manageHealthParamsModal}
                    history={props.history}
                />
            </Modal>
        </>
)
};

const mapStateToProps = (state) => {
    return {
        consultations: state.consultation.consultations,
        showLoading: state.consultation.showLoading,
        currentUser: currentUser(state)
    };
};

export default withRouter(connect(mapStateToProps)(ConsultationList));