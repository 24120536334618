// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";

import { getDatabase } from "firebase/database";

//import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {

  apiKey: "AIzaSyDR24QjADhtTvh7n291qm56JBBbF0RNDCs",

  authDomain: "activa-amp.firebaseapp.com",

  databaseURL: "https://activa-amp-default-rtdb.europe-west1.firebasedatabase.app",

  projectId: "activa-amp",

  storageBucket: "activa-amp.appspot.com",

  messagingSenderId: "847436345776",

  appId: "1:847436345776:web:0fbc6b7653c203efe52675",

  measurementId: "G-FNLRW8RM8V"

};


// Initialize Firebase

const app = initializeApp(firebaseConfig);

//const analytics = getAnalytics(app);

export const db = getDatabase(app);