import React, { Fragment, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import { Tab, Nav } from "react-bootstrap";
import { currentUser } from '../../../../store/selectors/CurrentUserSelector';
import Finances from './Finances';
import DossierPatient from './DossierPatient';
import Contacts from './Contacts';

const VoletAdminPane = (props) => {
    const {dossierDatas} = props;
    const { id } = useParams();
    const dispatch = useDispatch();
    
    const tabData = [
        {
          name: 'Dossier Patient',
          icon: 'user-alt',
          component: DossierPatient,
        },
        {
          name: 'Contacts',
          icon: 'address-card',
          component: Contacts,
        },
        {
          name: 'Historique Financier',
          icon: 'coins',
          component: Finances,
        },
      ];



    return (
        <Fragment>
            <div className='custom-tab-1'>
                <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                    <Nav as='ul' className='nav-tabs'>
                    {tabData.map((data, i) => (
                        <Nav.Item as='li' key={i}>
                        <Nav.Link eventKey={data.name.toLowerCase()}>
                            <i className={`la la-${data.icon} mr-2`} />
                            {data.name}
                        </Nav.Link>
                        </Nav.Item>
                    ))}
                    </Nav>
                    <Tab.Content className='pt-4'>
                    {tabData.map((data, i) => (
                        <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                            <data.component datas={dossierDatas} />
                        </Tab.Pane>
                    ))}
                    </Tab.Content>
                </Tab.Container>
            </div>
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        provider: state.provider.provider,
        currentUser: currentUser(state)
    };
};

export default connect(mapStateToProps)(VoletAdminPane);