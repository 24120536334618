import React, { Fragment, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link, useParams } from "react-router-dom";
import { Tab, Nav, ListGroup } from "react-bootstrap";
import { currentUser } from '../../../../store/selectors/CurrentUserSelector';
import { getClientConsultationsAction } from '../../../../store/actions/ConsultationActions';

const Contacts = (props) => {
    return (
        <Fragment>
            <div className="row">
                <div className='col-12 h300'>
                    <div className='card'>
                        <div className='card-header border-0 bg-primary'>
                            <h4 className='text-white d-flex align-items-center'>
                                <i className='la la-address-card mx-1' style={{fontSize: "25px"}}></i>
                                Contacts
                            </h4>
                        </div>
                        <div className='card-body px-5'>

                            <div className='row mt-4 mb-1'>
                                <div>
                                    <i className='la la-user mr-2 rounded-circle p-2 border-primary bg-primary text-white' style={{fontSize: "40px"}}></i>
                                </div>
                                <div className='col'>
                                    <h6 className='text-primary'>
                                        Médecin Traitant
                                    </h6>
                                    <b className='text-dark'>Dr Joseph Kondo</b>
                                </div>
                            </div>

                            <div className='row mt-4 mb-1'>
                                <div>
                                    <i className='la la-users mr-2 rounded-circle p-2 border-primary bg-primary text-white' style={{fontSize: "40px"}}></i>
                                </div>
                                <div className='col'>
                                    <h6 className='text-primary'>
                                        Liens Familiaux
                                    </h6>
                                    <b className='text-dark'>Patient Name 2 (Epouse)</b><br/>
                                    <b className='text-dark'>Patient Name 3 (Enfant)</b>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        currentUser: currentUser(state)
    };
};

export default connect(mapStateToProps)(Contacts);