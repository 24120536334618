import {
    START_LOADER_ACTION,
    GET_BON_CONFIRMED_ACTION,
    GET_BON_FAILED_ACTION,
    SEND_PROFORMA_CONFIRMED_ACTION,
    SEND_PROFORMA_FAILED_ACTION,
    EXECUTE_ACT_CONFIRMED_ACTION,
    EXECUTE_ACT_FAILED_ACTION
} from '../actions/OptiqueActions';

const initialState = {
    proformaData: {},
    errorMessage: '',
    successMessage: '',
    showLoading: false,
};

export const OptiqueReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_BON_CONFIRMED_ACTION:
            return {
                ...state,
                proformaData: action.payload,
                errorMessage: '',
                successMessage: 'proformaData gotten Successfully Completed',
                showLoading: false,
            };
        case GET_BON_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case SEND_PROFORMA_CONFIRMED_ACTION:
            return {
                ...state,
                subsRequests: action.payload,
                errorMessage: '',
                successMessage: 'Proforma data gotten Successfully Completed',
                showLoading: false,
            };
        case SEND_PROFORMA_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case EXECUTE_ACT_CONFIRMED_ACTION:
            return {
                ...state,
                errorMessage: '',
                successMessage: 'Act execution Successfully Completed',
                showLoading: false,
            };
        case EXECUTE_ACT_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case START_LOADER_ACTION:
            return {
                ...state,
                showLoading: true,
            };
        default:
            return state;
    };
};