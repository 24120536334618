import {
    REGISTER_CLIENT_CONFIRMED_ACTION,
    REGISTER_CLIENT_FAILED_ACTION,
    IDENTIFY_CLIENT_CONFIRMED_ACTION,
    IDENTIFY_CLIENT_FAILED_ACTION,
    LIST_CLIENT_CONFIRMED_ACTION,
    LIST_CLIENT_FAILED_ACTION,
    UPDATE_CLIENT_DATAS,
    START_LOADER_ACTION,
    CLIENT_REGISTER_CHECK,
    CLIENT_REGISTER_CHECK_FAILED,
    GET_CLIENT_CONFIRMED_ACTION,
    GET_CLIENT_FAILED_ACTION,
    SEARCH_CLIENT_CONFIRMED_ACTION
} from '../actions/ClientAuthActions';

const initialState = {
    client: {},
    clients: [],
    clientSearchResult: [],
    errorMessage: '',
    successMessage: '',
    showLoading: false,
    numAssu: null
};

export const ClientAuthReducer = (state = initialState, action) => {
    switch (action.type) {
        case REGISTER_CLIENT_CONFIRMED_ACTION:
            return {
                ...state,
                numAssu: action.payload,
                errorMessage: '',
                successMessage: 'Client Registry Successfully Completed',
                showLoading: false,
            };
        case REGISTER_CLIENT_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case IDENTIFY_CLIENT_CONFIRMED_ACTION:
            return {
                ...state,
                client: action.payload,
                errorMessage: '',
                successMessage: 'Client Identification Successfully Completed',
                showLoading: false,
            };
        case IDENTIFY_CLIENT_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case LIST_CLIENT_CONFIRMED_ACTION:
            return {
                ...state,
                clients: action.payload,
                errorMessage: '',
                successMessage: 'Client listing Successfully Completed',
                showLoading: false,
            };
        case LIST_CLIENT_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case UPDATE_CLIENT_DATAS:
            return {
                ...state,
                //client: action.payload
            };
        case START_LOADER_ACTION:
            return {
                ...state,
                showLoading: true,
            };
        case CLIENT_REGISTER_CHECK:
            return {
                ...state,
                errorMessage: '',
                successMessage: 'Client check Successfully Completed',
                showLoading: false,
            };
        case CLIENT_REGISTER_CHECK_FAILED:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case GET_CLIENT_CONFIRMED_ACTION:
            return {
                ...state,
                client: action.payload,
                errorMessage: '',
                successMessage: 'Client gotten Successfully',
                showLoading: false,
            };
        case GET_CLIENT_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case SEARCH_CLIENT_CONFIRMED_ACTION:
            return {
                ...state,
                clientSearchResult: action.payload,
                errorMessage: '',
                successMessage: 'Clients successfully found',
                showLoading: false,
            };
        default:
          return state;
    };
};