import {
    START_LOADER_ACTION,
    REGISTER_PROVIDER_BANK_FAILED_ACTION,
    REGISTER_PROVIDER_BANK_CONFIRMED_ACTION,
    GET_PROVIDER_DATA_CONFIRMED_ACTION,
    GET_PROVIDER_DATA_FAILED_ACTION,
    SEARCH_ACTES_CONFIRMED_ACTION,
    SEARCH_ACTES_FAILED_ACTION,
    SEARCH_ACTES_SPEC_CONFIRMED_ACTION,
    SEARCH_ACTES_SPEC_FAILED_ACTION,
    GET_PROVIDER_CONFIRMED_ACTION,
    GET_PROVIDER_FAILED_ACTION,
    GET_FACTURE_FAILED_ACTION,
    GET_FACTURE_CONFIRMED_ACTION,
    SEARCH_ACTES_DEP_KINE_CONFIRMED_ACTION,
    SEARCH_ACTES_DEP_KINE_FAILED_ACTION
} from '../actions/ProviderActions';

const initialState = {
    providers: [],
    provider: {},
    actes: [],
    actesSpec: [],
    depKine: [],
    facture: {},
    errorMessage: '',
    successMessage: '',
    showLoading: false,
};

export const ProviderReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PROVIDER_DATA_CONFIRMED_ACTION:
            return {
                ...state,
                provider: action.payload,
                errorMessage: '',
                successMessage: 'Provider gotten Successfully Completed',
                showLoading: false,
            };
        case GET_PROVIDER_DATA_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case REGISTER_PROVIDER_BANK_CONFIRMED_ACTION:
            return {
                ...state,
                errorMessage: '',
                successMessage: 'Provider Bank Registry Successfully Completed',
                showLoading: false,
            };
        case REGISTER_PROVIDER_BANK_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };

        case SEARCH_ACTES_CONFIRMED_ACTION:
            return {
                ...state,
                actes: action.payload,
                errorMessage: '',
                successMessage: 'ACts gotten Successfully Completed',
                showLoading: false,
            };
        case SEARCH_ACTES_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };

        case SEARCH_ACTES_SPEC_CONFIRMED_ACTION:
            return {
                ...state,
                actesSpec: action.payload,
                errorMessage: '',
                successMessage: 'ActsSpec gotten Successfully Completed',
                showLoading: false,
            };
        case SEARCH_ACTES_SPEC_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };

        case SEARCH_ACTES_DEP_KINE_CONFIRMED_ACTION:
            return {
                ...state,
                depKine: action.payload,
                errorMessage: '',
                successMessage: 'DepKine gotten Successfully Completed',
                showLoading: false,
            };
        case SEARCH_ACTES_DEP_KINE_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        
        case GET_PROVIDER_CONFIRMED_ACTION:
            return {
                ...state,
                providers: action.payload,
                errorMessage: '',
                successMessage: 'Provider Listing Successfully Completed',
                showLoading: false,
            };
        case GET_PROVIDER_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };

        case GET_FACTURE_CONFIRMED_ACTION:
            return {
                ...state,
                facture: action.payload,
                errorMessage: '',
                successMessage: 'Facture gotten Successfully Completed',
                showLoading: false,
            };
        case GET_FACTURE_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };

        case START_LOADER_ACTION:
            return {
                ...state,
                showLoading: true,
            };
        default:
            return state;
    };
};