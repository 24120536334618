import React from "react";
import { Modal, Button } from "react-bootstrap";
import { PrestationsTab } from "../../Patient/PrestationsTab";

const PrestationTabModal = (props) => {
    const {data, patientName, manageHealthParamsModal} = props;

    return (
        <>
        <Modal.Header>
            <Modal.Title className="text-center" >
                    Tableau de prestations du patient {' '}<br/>
                    <b className="text-primary" >{patientName}</b>
            </Modal.Title>
            <Button
                variant=""
                className="close"
                onClick={() => manageHealthParamsModal()}
            >
                <span>&times;</span>
            </Button>
        </Modal.Header>
        <Modal.Body>
            
            <div className="row">
                <div className="col-xl-12 col-xxl-12">
                    <div className="card">
                        <div className="card-body">
                            <PrestationsTab data={data}/>
                        </div>
                    </div>
                </div>
            </div>

        </Modal.Body>
    </>
   );
};

export default PrestationTabModal;
