import React from "react";
import { DataTable } from 'primereact/datatable';
import { InputSwitch } from 'primereact/inputswitch';
import { Column } from 'primereact/column';
        

export const DataTableBase = (props) => {
    const {data, emptyMessage, filters, globalFiltersFields, header, 
        columns, rows, loading, rowClass, size, rowClick, selectedData, 
        setSelectedDatas, isRowSelectable, rowGroupMode, groupRowsBy,
        rowGroupHeaderTemplate, tableStyle} = props;


    return (
        <DataTable 
            value={data} 
            paginator 
            size={size}
            //showGridlines
            rowClassName={rowClass} 
            tableStyle={tableStyle}
            rows={rows} 
            loading={loading} 
            dataKey="id" 
            filters={filters} 
            globalFilterFields={globalFiltersFields} 
            header={header}
            emptyMessage={emptyMessage}
            selectionMode={rowClick ? null : 'checkbox'} 
            selection={selectedData} 
            onSelectionChange={rowClick ? (e) => setSelectedDatas(e.value) : null}
            isDataSelectable={rowClick ? (e) => isRowSelectable(e.data) : null}
            rowGroupMode={rowGroupMode}
            groupRowsBy={groupRowsBy}
            rowGroupHeaderTemplate={rowGroupHeaderTemplate}
        >
            {
                columns.map((column, index) => {
                    return <Column 
                        key={index}
                        field={column.name} 
                        dataType={column.dataType}
                        header={column.headerName} 
                        filter={column.filter} 
                        filterField={column.filterField}
                        filterPlaceholder={column.filterPlaceholder} 
                        filterElement={column.filterElement}
                        style={column.style} 
                        body={column.bodyTemplate}
                        bodyClassName={column.bodyClassName}
                        selectionMode={column.selectionMode}
                        data-testid={"data-"+index}
                    />
                })
            }
        </DataTable>
    )
};

export default DataTableBase;