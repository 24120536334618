import React, { useState, useEffect } from "react";
import { connect, useDispatch } from 'react-redux';
import { getActesHospitalisationAction, startLoader, requestProlongHospiAction } from '../../../../store/actions/ConsultationActions';
import { Button, Modal, Spinner, Badge, Dropdown } from "react-bootstrap";
import Select from 'react-select';
import { calculationRembBaseAction } from "../../../../store/actions/FacturationAction";

export const ProlongHospiModal = (props) => {
    const dispatch = useDispatch();
    const {clientId, clientName, rembCalculDatas, currentUser, actesHospitalisation, bonId, manageProlongationModal, consultationId } = props;
    const [error, setError] = useState(null);
    const [acte, setActe] = useState({});
    const [duree, setDuree] = useState(3);
    const [price, setPrice] = useState(0);
    const [observations, setObservations] = useState("");

    

    const prolongationRequest = () => {
        /*if(Object.keys(acte).length === 0 ) {
            setError("Vous devez choisir un élément");
            return;
        }

        
        const data = {
            consultationId,
            hospitalisationBonId: bonId,
            code: acte.acte.code,
            nameFr: acte.acte.nameFr,
            nameEn: acte.acte.nameEn,
            isAccord: acte.acte.isAccord,
            isAlert: acte.acte.isAlert,
            isExclu: acte.acte.isExclu,
            id: acte.acte.id,
            quantite: duree,
            montant: acte.value,
            montantConvention: price,
            MontantTM: Math.min(rembCalculDatas.filter(fact => {return fact.index === 'hospitalisation'})[0]?.data.montantConvention, rembCalculDatas.filter(fact => {return fact.index === 'hospitalisation'})[0]?.data.montantPolice, price) === price ?
            (price - (price * (100 - rembCalculDatas.filter(fact => {return fact.index === 'hospitalisation'})[0]?.data.tm) / 100))
            : rembCalculDatas.filter(fact => {return fact.index === 'hospitalisation'})[0]?.data.montantPaye,
            montantpolice: rembCalculDatas.filter(fact => {return fact.index === 'hospitalisation'})[0]?.data.montantPolice
        };*/

        dispatch(startLoader());
        dispatch(requestProlongHospiAction(bonId, duree, observations));
        manageProlongationModal(false, true);

    }

    useEffect(() => {
        dispatch(getActesHospitalisationAction(currentUser.providerId));
    }, []);

    return (
            <>
                    <Modal.Header>
                        <Modal.Title className="text-center" >
                                 Prolongation d'hospitalisation{' '}<br/>
                                <b className="text-primary" >{clientName}</b>
                        </Modal.Title>
                        <Button
                            variant=""
                            className="close"
                            onClick={() => manageProlongationModal(false)}
                        >
                            <span>&times;</span>
                        </Button>
                    </Modal.Header>
                    <Modal.Body>
                        {/*<div className='row justify-content-between my-5'>
                            {rembCalculDatas.filter(fact => {return fact.index === 'hospitalisation'})[0]?.data.montantPolice > 0 && <>
                                <span className="font-weight-bold text-primary">Prix de la chambre: {Math.min(rembCalculDatas.filter(fact => {return fact.index === 'hospitalisation'})[0]?.data.montantConvention, rembCalculDatas.filter(fact => {return fact.index === 'hospitalisation'})[0]?.data.montantPolice, price) * duree}</span>
                                <span className="font-weight-bold text-primary">Ticket modérateur: {100 - rembCalculDatas.filter(fact => {return fact.index === 'hospitalisation'})[0]?.data.tm} %</span>
                            </>}
                            {rembCalculDatas.filter(fact => {return fact.index === 'hospitalisation'})[0]?.data.montantPolice === 0 && 
                                <h6 className='text-danger'>!!! Ce patient n'est pas couvert par l'assurance pour cet examen !!!</h6>
                            }
                            <span className="font-weight-bold text-warning">Montant à payer par le patient: 
                                {Math.min(rembCalculDatas.filter(fact => {return fact.index === 'hospitalisation'})[0]?.data.montantConvention, rembCalculDatas.filter(fact => {return fact.index === 'hospitalisation'})[0]?.data.montantPolice, price) === price ?
                                (price - (price * (100 - rembCalculDatas.filter(fact => {return fact.index === 'hospitalisation'})[0]?.data.tm) / 100)) * duree
                                : rembCalculDatas.filter(fact => {return fact.index === 'hospitalisation'})[0]?.data.montantPaye 
                                * duree}
                            </span>
                        </div>*/}
                        
                        { error && <small className="text-danger">{{error}}</small> }

                        <form onSubmit={(e) => e.preventDefault()}>
                            {/*<div className="form-group">
                                <Select
                                    className="select-search"
                                    options={actesHospitalisation.map(elt => {return {label: (elt.acte.nameFr+' - '+(elt.value * elt.acte.coefLettre)), value: elt.id, rootValue: elt.acte.id, price: (elt.value * elt.acte.coefLettre)}})}
                                    placeholder="Choisir"
                                    search
                                    isRequired
                                    onChange={(val) => {
                                        console.log("Inside the wrong event listener");
                                        setActe(actesHospitalisation.filter(elt => elt.id === val.value)[0]);
                                        setPrice(val.price);
                                        let data = {
                                            providerId: currentUser.providerId,
                                            patientId: clientId,
                                            acteId: val.rootValue
                                        };
                                        dispatch(calculationRembBaseAction(data, 'hospitalisation'));
                                    }}
                                />
                            </div>*/}
                            <div className='form-group col-12'>
                                <label className='mb-1 '>
                                    <strong>Renseignement clinique<span className='text-danger'>*</span></strong>
                                </label>
                                <textarea
                                    value={observations}
                                    className='form-control'
                                    rows='4'
                                    id='Observations'
                                    onChange={e => setObservations(e.target.value)}
                                ></textarea>
                            </div>
                            <div className='form-group col-12'>
                                <label className='mb-1 '>
                                    <strong>Durée en jours</strong>
                                </label>
                                <input
                                    value={duree}
                                    type='number'
                                    min='1'
                                    max='3'
                                    width={"50px"}
                                    className='form-control input-group-prepend'
                                    onChange={e => {
                                        setDuree(e.target.value);
                                    }}
                                />
                            </div>
                        </form>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="danger light"
                            onClick={() => manageProlongationModal(false, false)}
                        >
                            Annuler
                        </Button>
                        <Button
                            variant=""
                            type="button"
                            className="btn btn-primary"
                            //disabled={(Object.keys(acte).length === 0)}
                            onClick={() => prolongationRequest()}
                        >
                            Soumettre
                        </Button>
                    </Modal.Footer>
            </>
    )
};

const mapStateToProps = (state) => {
    return {
        drugList: state.consultation.drugs,
        actesHospitalisation: state.consultation.actesHospitalisation,
        rembCalculDatas: state.facturation.rembCalculDatas,
    };
};

export default connect(mapStateToProps)(ProlongHospiModal);