import {format} from 'date-fns';
import { Button } from 'react-bootstrap';
export const COLUMNS = [
	{
		Header : 'Id',
		Footer : 'Id',
		accessor: 'id',
	},
	{
		Header : 'Examen',
		Footer : 'Examen',
		accessor: 'examen',
	},
	{
		Header : 'Résultats',
		Footer : 'Résultats',
		Cell: ({ value }) => {
			return <Button
					as='a'
					variant='warning light'
					href={value}
					target="_blank"
					className='btn-card mt-3 mr-3'
					>
						<i className="la la-file-download"></i>
					</Button>
		},
		accessor: 'result_file',
	},
	{
		Header : 'Envoyé le',
		Footer : 'Envoyé le',
		Cell: ({ value }) => {return format(new Date(value), 'dd/MM/yyyy')},
		accessor: 'send_at',
	},
	{
		Header : 'Effectué par',
		Footer : 'Effectué par',
		accessor: 'send_by',
	},
];