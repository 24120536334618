import React, {useState} from "react";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { DataTableBase } from '../../DataTables/DataTableBase';
import { Button as ButtonBootstrap } from 'react-bootstrap';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { STATUSES } from '../../../globals';
import { useTranslation } from "react-i18next";
        

export const MedicamentsTable = (props) => {
    
    const { t } = useTranslation();
    const [filters, setFilters] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [showCommentModal, setShowCommentModal] = useState(false);
    const [targetExam, setTargetExam] = useState(null);

    const {data, respondDemande} = props;

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            'medicament.name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            quantite: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            'medicament.unit': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            deliveryName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            deliveryDate: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            isDelivery: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        });
        setGlobalFilterValue("");
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <Button type="button" icon="pi pi-filter-slash" label="Réinitialiser" outlined onClick={clearFilter} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Rechercher" />
                </span>
            </div>
        );
    };

    const clearFilter = () => {
        initFilters();
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const setColumns = () => {
        return [
            {name: 'medicament.name', headerName: "Médicament", filterPlaceholder: 'Filtrer par médicament', style: { minWidth: '12rem' }, filter: true, bodyTemplate: medicamentNameBodyTemplate},
            {name: 'quantite', dataType: 'numeric', headerName: 'Quantité', filterPlaceholder: 'Filtrer par montant', style: { minWidth: '6rem' }, filter: true, bodyTemplate: null},
            {name: 'medicament.unit', headerName: "Unité", filterPlaceholder: 'Filtrer par médicament', style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
            {name: 'deliveryName', headerName: "Centre exécutant", filterPlaceholder: 'Filtrer par centre exécutant', style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
            {name: 'deliveryDate', filterField: 'date', dataType: 'date', headerName: 'Exécuté le', filterPlaceholder: 'Filtrer par date d\'exécution', style: { minWidth: '6rem' }, filter: true, bodyTemplate: dateBodyTemplate, filterElement: dateFilterTemplate},
            {name: 'isDelivery', headerName: 'Statut', dataType: 'boolean', filterPlaceholder: 'Filtrer par statut', style: { minWidth: '6rem' }, filter: true, bodyTemplate: statusDelBodyTemplate, filterElement: statusDelFilterTemplate},
            {name: 'actions', headerName: 'Actions', filterPlaceholder: '', style: { minWidth: '6rem'}, filter: false, bodyTemplate: getActionsTemplate},
        ]
    };

    const getActionsTemplate = (rowData) => {
        return <div style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
            {rowData.drugSubtitution.length > 0 && rowData.drugSubtitution[rowData.drugSubtitution.length - 1]?.status === STATUSES.pending && 
                <>
                    <ButtonBootstrap
                        as='a'
                        variant='success light'
                        target="_blank"
                        className='btn-card mr-3'
                        onClick={(e) => respondDemande(true, rowData.drugSubtitution[rowData.drugSubtitution.length - 1]?.id)}
                    >
                        <i className="fa fa-check"></i>
                    </ButtonBootstrap>
                    
                    <ButtonBootstrap
                        as='a'
                        variant='danger light'
                        href='#'
                        className='btn-card'
                        onClick={(e) => respondDemande(false, rowData.drugSubtitution[rowData.drugSubtitution.length - 1]?.id)}
                    >
                        <i className="fa fa-close"></i>
                    </ButtonBootstrap>
                </>
            }
        </div>
    };

    const statusDelFilterTemplate = (options) => {
        return (
            <div className="flex align-items-center gap-2">
                <label htmlFor="isClose-filter" className="font-bold">
                    Livré
                </label>
                <TriStateCheckbox inputId="isClose-filter" value={options.value} onChange={(e) => options.filterCallback(e.value)} />
            </div>
        );
    };

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" mask="99/99/9999" />;
    };

    const dateBodyTemplate = (rowData) => {
        return formatDate(rowData.date);
    };

    const medicamentNameBodyTemplate = (rowData) => {
        return <>
            <span>{rowData.medicament.name}</span><br/>
            {rowData.drugSubtitution.length > 0 && rowData.drugSubtitution[rowData.drugSubtitution.length - 1]?.status === STATUSES.pending && 
                <>
                    <small className='text-white'>
                        Proposition de substitution en attente avec {' '}
                        {rowData.drugSubtitution[rowData.drugSubtitution.length - 1]?.newMedicament?.name}
                    </small>
                </>
            }
        </>
    };

    const statusDelBodyTemplate = (rowData) => {
        return <Tag value={rowData.isDelivery ? 'Livré' : "Non Livré"} severity={getSeverity(rowData.isDelivery)} />;
    };

    const getSeverity = (status) => {
        return status ? 'success' : 'danger'
    };

    const formatDate = (value) => {
        return value ? value.toLocaleDateString('fr-FR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        }) : '';
    };

    return (
        <>
            <DataTableBase 
                data={data.map(bon => {
                    bon.deliveryDate = new Date(bon.deliveryDate);
                    return bon;
                })}
                size="small"
                emptyMessage="Aucun médicament trouvé" 
                filters={filters}
                globalFiltersFields={['medicament.name', 'quantite', 'medicament.unit', 'deliveryName', 'deliveryDate', 'isDelivery']}
                header={renderHeader()}
                columns={setColumns()}
                rows={10} 
                loading={props.showLoading}
                rowClass={(rowData) => {
                    return {
                        'bg-warning': rowData.drugSubtitution.length > 0 && rowData.drugSubtitution[rowData.drugSubtitution.length - 1]?.status === STATUSES.pending
                    };
                }}
            />
        </>
    )
};

export default MedicamentsTable;