import React, { Fragment, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useParams, withRouter } from "react-router-dom";
import { Tab, Nav } from "react-bootstrap";
import { currentUser } from '../../../store/selectors/CurrentUserSelector';
import { getClientMedicalFileAction, startLoader } from '../../../store/actions/ConsultationActions';
import VoletAdminPane from './VoletAdmin/VoletAdminPane';
import VoletMedialPane from './VoletMedical/VoletMedialPane';
import task6 from "../../../images/task/img6.jpg";
import "./DossierMedical.css";

const DossierMedicalPane = (props) => {
    const { showLoading, medicalFile, patientResults } = props;
    const { id } = useParams();
    const dispatch = useDispatch();
    
    const tabData = [
        {
          name: 'Volet Medical',
          icon: 'stethoscope',
          component: VoletMedialPane,
        },
        {
          name: 'Volet Administratif',
          icon: 'user-injured',
          component: VoletAdminPane,
        },
    ];

    const getAge = (dateString) => {
        if(dateString) {
            let today = new Date();
            let birthDate = new Date(dateString);
            let age = today.getFullYear() - birthDate.getFullYear();
            let m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
                return age+" ans"
            }else{
                return age+" ans et "+m+" mois"
            }
        }else{
            return ""
        }
    }

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
          return false;
        }else{
          return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
      }
    
     const hasMenu = (menuCode) => {
          return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
      }


    useEffect(() => {
        if(hasMenu('MENCONSULT') && canDo('MENCONSULT', 'ACVIEWMEDFOLD')){
            dispatch(startLoader());
            dispatch(getClientMedicalFileAction(id));
        }else{
            props.history.push('/access-denied');
        }
    }, []);

    return (
        <Fragment>
            { showLoading && 
            <div id="preloader">
                <div className="sk-three-bounce">
                    <div className="sk-child sk-bounce1"></div>
                    <div className="sk-child sk-bounce2"></div>
                    <div className="sk-child sk-bounce3"></div>
                </div>
            </div> }
            { !showLoading && 
            <>
                <div className="bg-white p-5">
                    <div className="media d-sm-flex d-block justify-content-end text-center text-sm-left mb-2">
                        <img
                        alt="widget"
                        className="rounded mr-sm-4 mr-0"
                        width={130}
                        src={task6}
                        />
                        <div className="media-body align-items-center">
                            <div className="d-sm-flex d-block justify-content-between my-3 my-sm-0">
                                <div>
                                    <h3 className="fs-22 text-black font-w600 mb-0">
                                        {medicalFile.beneficiaire?.firstName+" "+medicalFile.beneficiaire?.lastName}
                                    </h3>
                                    <h5 className="my-2 my-sm-2 text-danger">
                                        <b>{getAge(medicalFile.beneficiaire?.birthdate)}</b>
                                    </h5>
                                    <small className="mb-2 mb-sm-2 text-info">
                                        <small><b>{medicalFile.beneficiaire?.sexe}</b></small>
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='default-tab'>
                    {Object.keys(medicalFile).length > 0 && <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                        <Nav as='ul' className='nav-tabs justify-content-space-evenly header-nav' style={{width: "100%"}}>
                        {tabData.map((data, i) => (
                            <Nav.Item as='li' key={i} style={{width: "50%"}}>
                            <Nav.Link eventKey={data.name.toLowerCase()} className="text-center">
                                <i className={`la la-${data.icon} mr-2`} />
                                {data.name}
                            </Nav.Link>
                            </Nav.Item>
                        ))}
                        </Nav>
                        <Tab.Content className='pt-4'>
                        {tabData.map((data, i) => (
                            <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                                <data.component dossierDatas={medicalFile} />
                            </Tab.Pane>
                        ))}
                        </Tab.Content>
                    </Tab.Container>}
                    </div>
                </div>
            </>
            }
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        medicalFile: state.consultation.medicalFile,
        showLoading: state.consultation.showLoading,
        currentUser: currentUser(state)
    };
};

export default withRouter(connect(mapStateToProps)(DossierMedicalPane));