import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { DatePicker, DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { getFacturesAction, startLoader, requestPaymentAction, getFacturesImpayesAction, createBordereauAction } from "../../../store/actions/FacturationAction";
import { currentUser } from "../../../store/selectors/CurrentUserSelector";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { useTranslation } from "react-i18next";
import './BordereauForm.css';

export const BordereauForm = (props) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    
    const [dateMin, setDateMin] = useState(props.dateMin);
    const [dateMax, setDateMax] = useState(props.dateMax);
    const [choosenBills, setChoosenBills] = useState(props.factures);
    const [expandedRows, setExpandedRows] = useState(null);

    const searchFactures = (min, max) => {
         dispatch(startLoader());
         dispatch(getFacturesImpayesAction(props.currentUser.providerId, min, max));
    }

    useEffect(() => {
        console.log("hello");
        setChoosenBills(props.factures);
    }, [props.factures]);

    const facturePatientDetails = (facture) => {
        return [];
    }

    const createBordereau = () => {
        let data = {
            factureId: choosenBills.map(elt => elt.id),
            prestataireName: props.currentUser.provider.providerName,
            prestataireId: props.currentUser.providerId
        }
        console.log(data);
        dispatch(startLoader());
        dispatch(createBordereauAction(data, props.currentUser.providerId, dateMin, dateMax));
    }

    const formatDate = (value) => {
        return value.toLocaleDateString('fr-FR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    const rowExpansionTemplate = (data) => {
        console.log(data);
        return (
            <div>
                <DataTable 
                    dataKey="id"
                    showGridlines 
                    value={data.facturePrestataireDetails.map(elt => {
                        elt.createdAt = formatDate(new Date(elt.createdAt));
                        return elt;
                    })}
                >
                    {/*<Column header="Cathégorie" field="type"></Column>*/}
                    <Column header="Acte/Médicament" field="libelle"></Column>
                    <Column header="Date" field="createdAt"></Column>
                    <Column header="Montant" field="priceToPayByActiva"></Column>
                </DataTable>
            </div>
        );
    };

    const headerGroup = (
        <Row>
            <Column header="Nomination" field="name" />
            <Column header="Assuré" field="patientName" />
            <Column header="Date" field="createdAt" />
            <Column header="Montant" field="montantTotal" />
        </Row>
    );

    const allowExpansion = (rowData) => {
        console.log(rowData);
        return rowData.facturePrestataireDetails && rowData.facturePrestataireDetails.length > 0;
    };

    const onRowExpand = (event) => {
        console.log(event);
    };

    const onRowCollapse = (event) => {
        console.log(event);
    };


    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser?.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
          return false;
        }else{
          return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
    }
    
    const hasMenu = (menuCode) => {
        return props.currentUser?.menus?.filter(menu => menu.code === menuCode).length > 0;
    }

    return (
        <Fragment>
            <div className="row justify-content-end">
                <div className="col-lg-3 mb-2">
                    <div className="form-group">
                        <label className='mb-1 '>
                            <strong>Date minimum</strong>
                        </label>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                autoOk
                                label=""
                                clearable
                                format="dd/MM/yyyy"
                                disableFuture
                                value={dateMin}
                                onChange={(val) => {
                                    setDateMin(val);
                                    searchFactures(val, dateMax);
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                </div>
                <div className="col-lg-3 mb-2">
                    <div className="form-group">
                        <label className='mb-1 '>
                            <strong>Date maximum</strong>
                        </label>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                autoOk
                                label=""
                                clearable
                                format="dd/MM/yyyy"
                                disableFuture
                                value={dateMax}
                                onChange={(val) => {
                                    setDateMax(val);
                                    searchFactures(dateMin, val);
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                </div>
            </div>

            <div className="controls row justify-content-between align-items-center">
                {choosenBills.length < props.factures.length && <button onClick={e => setChoosenBills(props.factures)} className='col-3 btn btn-primary-outline'>
                    Sélectionner tout
                </button>}
                {choosenBills.length === props.factures.length && <button onClick={e => setChoosenBills([])} className='col-3 btn btn-danger-outline'>
                    Désélectionner tout
                </button>}

                <b>Total: {choosenBills.reduce((sum, val) => sum + val.montantTotal, 0)}</b>
                
                <button disabled={choosenBills.length === 0} onClick={e => createBordereau()} className='col-3 btn btn-success'>
                    Créer le bodereau
                </button>
            </div>

            <div className="factures py-2">
                {/*{props.factures.map(facture => {
                    return <div className="row justify-content-between py-4 pl-2">
                        <div className='col-12 custom-control custom-checkbox checkbox-danger'>
                            <input
                                type='checkbox'
                                checked={choosenBills.find(elt => elt.id === facture.id)}
                                onChange={(e) => 
                                    e.target.checked ? 
                                    setChoosenBills([...choosenBills, facture]) :
                                    setChoosenBills(choosenBills.filter(bill => bill.id !== facture.id))
                                }
                                className='custom-control-input'
                                id={'customCheckBox'+facture.id}
                                required
                            />
                            <label
                                className='custom-control-label'
                                htmlFor={'customCheckBox'+facture.id}
                            >
                                {facture.name} - {facture.patientName} <span class="text-bold">total: {facture.montantTotal}</span>
                            </label>
                        </div>
                        {choosenBills.find(elt => elt.id === facture.id) && <>
                            {facture.facturePrestataireDetails.map(detail => {
                                return <div className="col-4 border border-primary facture-details">
                                    <h4>{detail.libelle} - {formatDate(new Date(detail.dateExecution))}</h4>
                                    <div className="actes">
                                        <h5 class="text-primary">{detail.priceToPayByActiva}</h5>
                                    </div>
                                </div>
                            })}
                        </>}
                    </div>
                })} */}

                <>
                    {props.factures.length === 0 && <span>
                        Aucune facture disponible pour cette période.
                    </span>}
                    {props.factures.length > 0 && <div className='results-section'>
                        <DataTable 
                            value={props.factures.map(elt => {
                                elt.dateFacturation = formatDate(new Date(elt.dateFacturation));
                                return elt;
                            })} 
                            dataKey="id"
                            showGridlines 
                            tableStyle={{ minWidth: '50rem' }}
                            isDataSelectable={(e) => {return true}}
                            onSelectionChange={(e) => {
                                setChoosenBills(props.factures.filter(bill => e.value.includes(bill)));
                            }}
                            selectionMode={'checkbox'} 
                            selection={choosenBills} 
                            expandedRows={expandedRows}
                            onRowExpand={onRowExpand} 
                            onRowCollapse={onRowCollapse} 
                            onRowToggle={(e) => {
                                console.log(e);
                                setExpandedRows(e.data);
                                /*setExpandedRows(e.facturePrestataireDetails.map(elt => {
                                    elt.createdAt = formatDate(new Date(elt.createdAt));
                                }));*/
                            }}
                            rowExpansionTemplate={rowExpansionTemplate}
                        >
                            <Column selectionMode={'multiple'} style={{ width: '3rem' }} />
                            <Column expander={allowExpansion} header="Détails" style={{ width: '5rem' }} />
                            <Column header="Nomination" field="name" />
                            <Column header="Assuré" field="patientName" />
                            <Column header="Date" field="dateFacturation" />
                            <Column header="Montant" field="montantTotal" />
                        </DataTable>
                    </div>}
                </>
            </div>
        </Fragment>
    )
};

const mapStateToProps = (state) => {
    return {
        factures: state.facturation.factures,
        factureImpayees: state.facturation.factureImpayees,
        showLoading: state.facturation.showLoading,
        currentUser: currentUser(state)
    };
};

export default connect(mapStateToProps)(BordereauForm);