import { connect, useDispatch } from 'react-redux';
import React, {Fragment, useEffect, useState, useRef} from "react";
import swal from "sweetalert";
import {
    loadingToggleAction, identificationAction,
} from '../../../store/actions/PatientAction';
import { Link, withRouter } from "react-router-dom";
import { Row, Col, Card, Accordion, Button, Spinner, Badge, Modal } from 'react-bootstrap'
import { getBonByCodeAction, sendProformaAction, startLoader, executeBonAction } from '../../../store/actions/OptiqueActions';
import { currentUser } from '../../../store/selectors/CurrentUserSelector';
import { useReactToPrint } from 'react-to-print';
import { STATUSES } from '../../globals';
import { useTranslation } from "react-i18next";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Select from 'react-select';

class FactureTemplate extends React.Component {
    
    
    render() {
        return (<div className="row">
                    <div className="col-lg-12">
                        <div className="card mt-3">
                        <div className="card-header">
                            {" "}
                            <strong>PROFORMA LUNETTES POUR {this.props.facture.beneficiaryName}</strong>{" "}
                            <strong>Prescription de {this.props.facture.prescription?.consultation?.doctorName}</strong>{" "}
                            <span className="float-right">
                                <strong>Status:</strong> 
                                <span>{this.props.t('statuses.'+this.props.facture.status)}</span>
                            </span>{" "}
                        </div>
                        {this.props.facture.motifExclusion && 
                            <p className='text-danger'>{this.props.facture.motifExclusion}</p>
                        }
                        <div className="card-body">
                            <div className="row mb-5">
                            <div className="mt-4 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                <h6>De:</h6>
                                <div>
                                {" "}
                                <strong>{this.props.currentUser.provider.providerName}</strong>{" "}
                                </div>
                                <div>{this.props.currentUser.username}</div>
                                <div>{this.props.currentUser.provider.city?.name}, {this.props.currentUser.provider.country?.name}</div>
                                <div>Email: {this.props.currentUser.provider.email}</div>
                                <div>Phone: +{this.props.currentUser.provider.phoneNumber}</div>
                            </div>
                            <div className="mt-4 col-xl-6 col-lg-6 col-md-6 col-sm-12 align-items-end justify-content-end">
                                <h6>À:</h6>
                                <div>
                                {" "}
                                <strong>Médecin conseil Activa Assurance</strong>{" "}
                                </div>
                                <div>Service Paiement</div>
                                <div>Rue Prince de Galles - Douala, Cameroun</div>
                                <div>Email: paiement@group-active.com</div>
                                <div>Phone: +237692880000</div>
                            </div>
                            </div>
                            <div className='row'>
                                <div className='col-4'>
                                    <b>Ecart Pupillaire: <span className='text-primary'>{this.props.facture.ecartPupillaire}</span></b>
                                </div>
                                <div className='col-4'>
                                    <b>Vision de loin(oeil gauche): <span className='text-primary'>{this.props.facture.vlog}</span></b>
                                </div>
                                <div className='col-4'>
                                    <b>Vision de loin(oeil droit): <span className='text-primary'>{this.props.facture.vlod}</span></b>
                                </div>
                                <div className='col-4'>
                                    <b>Vision de près(oeil gauche): <span className='text-primary'>{this.props.facture.vpog}</span></b>
                                </div>
                                <div className='col-4'>
                                    <b>Vision de près(oeil droit): <span className='text-primary'>{this.props.facture.vpod}</span></b>
                                </div>
                            </div>
                            <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th className="center">#</th>
                                        <th>Libelé</th>
                                        <th className="right">Prix</th>
                                        <th className="right">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.facture.opticBonDetails.map((data, i) => { 
                                        return (
                                            <tr>
                                                <td className="center">{i}</td>
                                                <td className="left strong">
                                                    {data.acteName}<br/>
                                                    {data.motifExclusion && <small className="text-danger">Exclu pour: {data.motifExclusion}</small>}<br/>
                                                </td>
                                                <td className="right">{data.amount}</td>
                                                <td className="center">{this.props.t('statuses.'+data.status)}</td>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                            </table>
                            </div>
                            <div className="row">
                            <div className="col-lg-4 col-sm-5"> </div>
                            <div className="col-lg-4 col-sm-5 ml-auto">
                                <table className="table table-clear">
                                <tbody>
                                    <tr>
                                        <td className="left">
                                            <strong>Total</strong>
                                        </td>
                                        <td className="right">{this.props.facture.amount}</td>
                                    </tr>
                                    <tr>
                                        <td className="left">
                                            <strong>Ticket Modérateur</strong>
                                        </td>
                                        <td className="right">{this.props.facture.tm} %</td>
                                    </tr>
                                    <tr>
                                    <td className="left">
                                        <strong>Montant à payer par le patient</strong>
                                    </td>
                                    <td className="right">
                                        <strong>{this.props.facture.amountTM}</strong>
                                    </td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
            </div>
        );
    };

}

const OpticBonSearchForm = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [search, setSearch] = useState("");
    const [currentKey, setCurrentKey] = useState('');
    const [opticDatas, setOpticDatas] = useState([]);
    const { proformaData, currentUser, history, errorMessage, successMessage, showLoading } = props;

    const montures = [
        {id: 1, name: "Monture Titane 400", prix: 100000},
        {id: 2, name: "Monture argentée", prix: 80000},
        {id: 3, name: "Monture classique", prix: 35000},
        {id: 4, name: "Monture plastique enfant", prix: 63000},
        {id: 5, name: "Monture Deep Link", prix: 44350},
        {id: 6, name: "Monture Delux", prix: 300000},
    ]

    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })

    const searchBon = (e) => {
        e.preventDefault();
        if(search.trim().length > 0) {
            console.log(search, currentUser.providerId);
            dispatch(startLoader());
            dispatch(getBonByCodeAction(search, currentUser.providerId));
        }
    }

    const submitProforma = () => {
        let data = {
            code: proformaData.opticBon.id,
            providerId: currentUser.providerId,
            providerName: currentUser.provider.providerName,
            oeilDroit: {
                prixActiva: parseFloat(opticDatas[0].prixActiva),
                prixPrestataire: parseFloat(opticDatas[0].prixPrestataire)
            },
            oeilGauche: {
                prixActiva: parseFloat(opticDatas[1].prixActiva),
                prixPrestataire: parseFloat(opticDatas[1].prixPrestataire)
            },
            traitement: {
                prixActiva: parseFloat(opticDatas[2].prixActiva),
                prixPrestataire: parseFloat(opticDatas[2].prixPrestataire)
            },
            monture: {
                prixActiva: parseFloat(opticDatas[4].prixActiva),
                prixPrestataire: parseFloat(opticDatas[4].prixPrestataire)
            },
        }
        dispatch(startLoader());
        dispatch(sendProformaAction(data));
    }

    const executer = () => {
        dispatch(startLoader());
        dispatch(executeBonAction(proformaData.opticBon.id));
    }

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
          return false;
        }else{
          return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
      }
    
    const hasMenu = (menuCode) => {
        return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
    }

    const prixPrestataireBodyTemplate = (rowData) => {
        return rowData.acte === "Total" ? 
            opticDatas.filter(val => (val.category !== 'Monture' && val.acte !== 'Total')).reduce((sum, val) => {
                return sum + (parseInt(val.prixPrestataire) || 0)
            }, 0) :
            <input type='number' 
                className='form-control'
                min="0" 
                disabled={rowData.acte === "Total"}
                onChange={e => {
                    setOpticDatas(opticDatas.map(elt => {
                        if(elt.acte === rowData.acte) {
                            elt.prixPrestataire = e.target.value
                        }
                        return elt;
                    }));
                }} 
                value={rowData.prixPrestataire}
            />;
    }

    const acteBodyTemplate = (rowData) => {
        return rowData.subDataVl ? 
                <>
                    <span>{rowData.acte}</span><br/>
                    <small className='text-primary'>De loin: {rowData.subDataVl}</small><br/>
                    <small className='text-primary'>De près: {rowData.subDataVp}</small>
                </> :
                rowData.acte;
        /*rowData.category === 'Monture' ?
            <Select
                className="select-search"
                options={montures.map(elt => {return {label: elt.name, value: elt.id, prix: elt.prix}})}
                value={montures.filter(elt => elt.id === rowData.acte).map(mont => {return {label: mont.name, value: mont.id, prix: mont.prix}})}
                placeholder="Choisir la monture"
                search
                menuPlacement="top"
                onChange={(val) => {
                    setOpticDatas(opticDatas.map(elt => {
                        if(elt.category === 'Monture') {
                            elt.acte = val.value
                            elt.prixActiva = val.prix
                        }
                        return elt;
                    }));
                }}
            /> : rowData.subData ? 
                    <>
                        <span>{rowData.acte}</span><br/>
                        <small className='text-primary'>{rowData.subData}</small>
                    </> :
                    rowData.acte;*/
    }

    useEffect(() => {
        if(hasMenu('MENOPTIC') && canDo('MENOPTIC', 'ACVIEWBONOPTIC')){
            console.log("About to call all the consultations");
        }else{
            props.history.push('/access-denied');
        }
    }, [proformaData]);

    useEffect(() => {
        if(Object.keys(proformaData).length > 0) {
            if(proformaData.opticBon.status === STATUSES.waitingAccordPreable || proformaData.opticBon.status === STATUSES.confirmed) {
                let vlod = proformaData.opticBon.prescriptionEyes.find(elt => elt.visionEye === 'VLOD');
                let vlog = proformaData.opticBon.prescriptionEyes.find(elt => elt.visionEye === 'VLOG');
                let vpod = proformaData.opticBon.prescriptionEyes.find(elt => elt.visionEye === 'VPOD');
                let indiceOdVl = vlod.sphere+'('+vlod.cylindre+' à '+vlod.axe+'°)';
                let indiceOgVl = vlog.sphere+'('+vlog.cylindre+' à '+vlog.axe+'°)';
                let indiceVp = vpod.sphere+' ADD';
                setOpticDatas([
                    {category: 'Verres', acte: 'Oeil Droit', subDataVl: indiceOdVl, subDataVp: indiceVp, prixActiva: proformaData.proformat.OeilDroit.PrixActiva || 0, prixPrestataire: proformaData.proformat.OeilDroit.PrixPrestataire},
                    {category: 'Verres', acte: 'Oeil Gauche', subDataVl: indiceOgVl, subDataVp: indiceVp, prixActiva: proformaData.proformat.OeilGauche.PrixActiva || 0, prixPrestataire: proformaData.proformat.OeilGauche.PrixPrestataire},
                    {category: 'Verres', acte: 'Traitement', subData: "Antireflets", prixActiva: proformaData.proformat.Traitement.PrixActiva || 0, prixPrestataire: proformaData.proformat.Traitement.PrixPrestataire},
                    {category: 'Verres', acte: 'Total', subData: null, prixActiva: proformaData.totalVerreActiva || 0, prixPrestataire: proformaData.totalVerrePrestataire, acteId: proformaData.opticBon?.opticBonDetails[0]?.id, acteName: proformaData.opticBon?.opticBonDetails[0]?.acteName, motifRejet: proformaData.opticBon?.opticBonDetails[0]?.motifExclusion, montantRejet: proformaData.opticBon?.opticBonDetails[0]?.amountExclu, isRejet: proformaData.opticBon?.opticBonDetails[0]?.amountExclu > 0},
                    {category: 'Monture', acte: 'Monture', subData: null, prixActiva: proformaData.proformat.Monture.PrixActiva || 0, prixPrestataire: proformaData.proformat.Monture.PrixPrestataire, acteId: proformaData.opticBon?.opticBonDetails[1]?.id, acteName: proformaData.opticBon?.opticBonDetails[1]?.acteName, motifRejet: proformaData.opticBon?.opticBonDetails[1]?.motifExclusion, montantRejet: proformaData.opticBon?.opticBonDetails[1]?.amountExclu, isRejet: proformaData.opticBon?.opticBonDetails[1]?.amountExclu > 0},
                ])
            }else{
                let vlod = proformaData.opticBon.prescriptionEyes.find(elt => elt.visionEye === 'VLOD');
                let vlog = proformaData.opticBon.prescriptionEyes.find(elt => elt.visionEye === 'VLOG');
                let vpod = proformaData.opticBon.prescriptionEyes.find(elt => elt.visionEye === 'VPOD');
                let indiceOdVl = vlod.sphere+'('+vlod.cylindre+' à '+vlod.axe+'°)';
                let indiceOgVl = vlog.sphere+'('+vlog.cylindre+' à '+vlog.axe+'°)';
                let indiceVp = vpod.sphere+' ADD';
                setOpticDatas([
                    {category: 'Verres', acte: 'Oeil Droit', subDataVl: indiceOdVl, subDataVp: indiceVp, prixActiva: proformaData.montantOD || 0, prixPrestataire: 0},
                    {category: 'Verres', acte: 'Oeil Gauche', subDataVl: indiceOgVl, subDataVp: indiceVp, prixActiva: proformaData.montantOG || 0, prixPrestataire: 0},
                    {category: 'Verres', acte: 'Traitement', subData: "Antireflets", prixActiva: proformaData.traitement || 0, prixPrestataire: 0},
                    {category: 'Verres', acte: 'Total', subData: null, prixActiva: proformaData.montantTotal || 0, prixPrestataire: 0},
                    {category: 'Monture', acte: 'Monture', subData: null, prixActiva: proformaData.montantMonture || 0, prixPrestataire: 0},
                ])
            }
        }
    }, [proformaData]);

    useEffect(() => {
        if(search.trim().length > 0) {
            dispatch(startLoader());
            dispatch(getBonByCodeAction(search, currentUser.providerId));
        }
    }, [successMessage, errorMessage]);
    

    return (
        <Fragment>
            <div className="bg-white p-5">
                <div className='row justify-content-center mt-4'>
                    <form onSubmit={(e) => searchBon(e)} className="d-flex">
                        <input type="search" placeholder='Code du Bon' required value={search} onChange={e => setSearch(e.target.value)} className='form-control input-default mb-2' /> 
                        
                        { showLoading && 
                            <div className='col-6'>
                                <Button variant="primary" disabled>
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />{' '}
                                    <span className="visually-hidden">Un instant...</span>
                                </Button>
                            </div> }
                        { !showLoading &&
                            <div className='col-4'>
                                <button type='submit' className='btn btn-primary btn-block'>
                                    <i className='la la-search'></i>
                                </button>
                            </div> }
                    </form>
                </div>
                {Object.keys(proformaData).length > 0 &&  proformaData.opticBon.status === STATUSES.exectuted && <p className='text-center text-warning'>
                    Ce bon a déjà été exécuté
                </p>}
                {Object.keys(proformaData).length > 0 &&  (proformaData.opticBon.status === STATUSES.waitingAccordPreable || proformaData.opticBon.status === STATUSES.confirmed) && <>
                    <h2 className='text-center mb-5'>PROFORMA LUNETTES DE VUE</h2>
                    <h4 className='text-center'>
                        <b className='text-warning text-center'>{t('statuses.'+proformaData.opticBon.status)}</b>
                    </h4>
                    {proformaData.motifRejet.length > 0 && <p className='text-center'>
                        <b className='text-danger text-center'>{proformaData.motifRejet}</b>
                    </p>}
                    <div className='row'>
                        <div className='col-6'>
                            <h4 className='mt-2 text-dark text-start'>
                                <span style={{textDecoration: 'underline'}}>Exécutant:</span>
                                <span className='text-bold text-primary ml-2'>{proformaData.proformat.ProviderName}</span>
                            </h4>
                            <h4 className='mt-2 text-dark text-start'>
                                <span style={{textDecoration: 'underline'}}>Date de soumission:</span>
                                <span className='text-bold text-primary ml-2'>{proformaData.createdAt}</span>
                            </h4>
                        </div>
                        <div className='col-6 text-right'>
                            <h4 className='mt-2 text-dark text-start'>
                                <span style={{textDecoration: 'underline'}}>Patient:</span>
                                <span className='text-bold text-primary ml-2'>{proformaData.opticBon.beneficiaryName}</span>
                            </h4>
                            <h4 className='mt-2 text-dark text-start'>
                                <span style={{textDecoration: 'underline'}}>Prescripteur:</span>
                                <span className='text-bold text-primary ml-2'>{proformaData.opticBon.providerName}</span>
                            </h4>
                        </div>
                    </div>
                    <div className="border-top my-2"></div>

                    <div className='row'>
                        <div className='form-group col-4'>
                            <label className='mb-1 '>
                                <strong>Ecart Pupillaire: </strong>
                                <span className='text-primary'>
                                    {proformaData.opticBonDetailCaracteristique.find(elt => elt.typeCaracteristique === 'ecartPupillaire')?.valeurCaracteristique}
                                </span>
                            </label>
                        </div>
                        <div className='form-group col-4'>
                            <label className='mb-1 '>
                                <strong>Type: </strong>
                                <span className='text-primary'>
                                    {proformaData.opticBonDetailCaracteristique.find(elt => elt.typeCaracteristique === 'typeVerre')?.valeurCaracteristique}
                                </span>
                            </label>
                        </div>
                        <div className='form-group col-4'>
                            <label className='mb-1 '>
                                <strong>Matière: </strong>
                                <span className='text-primary'>
                                    {proformaData.opticBonDetailCaracteristique.find(elt => elt.typeCaracteristique === 'baseVerre')?.valeurCaracteristique}
                                </span>
                            </label>
                        </div>
                        <div className='form-group col-4'>
                            <label className='mb-1 '>
                                <strong>Teinte: </strong>
                                <span className='text-primary'>
                                    {proformaData.opticBonDetailCaracteristique.find(elt => elt.typeCaracteristique === 'teinteVerre')?.valeurCaracteristique}
                                </span>
                            </label>
                        </div>
                        <div className='form-group col-4'>
                            <label className='mb-1 '>
                                <strong>Traitement: </strong>
                                <span className='text-primary'>
                                    {proformaData.opticBonDetailCaracteristique.find(elt => elt.typeCaracteristique === 'traimentVerre')?.valeurCaracteristique}
                                </span>
                            </label>
                        </div>
                        <div className='form-group col-4'>
                            <label className='mb-1 '>
                                <strong>Port de verres: </strong>
                                <span className='text-primary'>
                                    {proformaData.opticBonDetailCaracteristique.find(elt => elt.typeCaracteristique === 'portVerre')?.valeurCaracteristique}
                                </span>
                            </label>
                        </div>
                    </div>
                    <div className="card">
                        <DataTable value={opticDatas} showGridlines rowGroupMode="rowspan" groupRowsBy="category" tableStyle={{ minWidth: '50rem' }}>
                            <Column field="category" header="" style={{ minWidth: '200px' }}></Column>
                            <Column field="acte" header="" style={{ minWidth: '200px' }} body={acteBodyTemplate}></Column>
                            <Column field="prixActiva" header="Prix Activa" style={{ minWidth: '200px' }}></Column>
                            <Column field="prixPrestataire" header="Prix" style={{ minWidth: '150px' }}></Column>
                            <Column field="montantRejet" header="Montant exclu" style={{ minWidth: '150px' }}></Column>
                            <Column field="motifRejet" header="Motif exclusion" style={{ minWidth: '150px' }}></Column>
                        </DataTable>
                    </div>
                    <div className="col-lg-4 col-sm-5 ml-auto">
                        <table className="table table-clear">
                        <tbody>
                            <tr>
                                <td className="left">
                                    <strong>Montant Total</strong>
                                </td>
                                <td className="right">{proformaData.montantTotal}</td>
                            </tr>
                            <tr>
                                <td className="left">
                                    <strong>Ticket Modérateur</strong>
                                </td>
                                <td className="right">{proformaData.amountTM}</td>
                            </tr>
                            {proformaData.amountRejet > 0 && <tr>
                                <td className="left">
                                    <strong>Montant Rejeté</strong>
                                </td>
                                <td className="right">{proformaData.amountRejet}</td>
                            </tr>}
                            <tr>
                                <td className="left">
                                    <strong>Part assureur</strong>
                                </td>
                                <td className="right">{proformaData.montantTotal - proformaData.amountTM - proformaData.amountRejet}</td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    {hasMenu('MENOPTIC') && canDo('MENOPTIC', 'ACDELOPTIC') && proformaData.opticBon.status === STATUSES.confirmed && <div className='row justify-content-end'>
                        <div className='col-2'>
                        { showLoading && 
                            <div className='col-6'>
                                <Button variant="warning" disabled>
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />{' '}
                                    <span className="visually-hidden">Un instant...</span>
                                </Button>
                            </div> }
                        { !showLoading &&
                            <button onClick={e => executer()} type='button' className='btn btn-warning btn-block'>
                                Excécuter
                            </button> }
                        </div>
                    </div>}
                </>}
                {Object.keys(proformaData).length > 0 &&  proformaData.opticBon.status === STATUSES.pending  &&
                    <>
                        {/*<FactureTemplate facture={proformaData} currentUser={currentUser} ref={componentRef} t={t} />*/}
                        <h4 className='mt-2 text-dark text-start'>
                            <span style={{textDecoration: 'underline'}}>Patient:</span>
                            <span className='text-bold text-primary ml-2'>{proformaData.opticBon.beneficiaryName}</span>
                        </h4>
                        <h4 className='mt-2 text-dark text-start'>
                            <span style={{textDecoration: 'underline'}}>Prescripteur:</span>
                            <span className='text-bold text-primary ml-2'>{proformaData.opticBon.providerName}</span>
                        </h4>
                        <div className="border-top my-2"></div>

                        <div className='row'>
                            <div className='form-group col-4'>
                                <label className='mb-1 '>
                                    <strong>Ecart Pupillaire: </strong>
                                    <span className='text-primary'>
                                        {proformaData.opticBonDetailCaracteristique.find(elt => elt.typeCaracteristique === 'ecartPupillaire')?.valeurCaracteristique}
                                    </span>
                                </label>
                            </div>
                            <div className='form-group col-4'>
                                <label className='mb-1 '>
                                    <strong>Type: </strong>
                                    <span className='text-primary'>
                                        {proformaData.opticBonDetailCaracteristique.find(elt => elt.typeCaracteristique === 'typeVerre')?.valeurCaracteristique}
                                    </span>
                                </label>
                            </div>
                            <div className='form-group col-4'>
                                <label className='mb-1 '>
                                    <strong>Matière: </strong>
                                    <span className='text-primary'>
                                        {proformaData.opticBonDetailCaracteristique.find(elt => elt.typeCaracteristique === 'baseVerre')?.valeurCaracteristique}
                                    </span>
                                </label>
                            </div>
                            <div className='form-group col-4'>
                                <label className='mb-1 '>
                                    <strong>Teinte: </strong>
                                    <span className='text-primary'>
                                        {proformaData.opticBonDetailCaracteristique.find(elt => elt.typeCaracteristique === 'teinteVerre')?.valeurCaracteristique}
                                    </span>
                                </label>
                            </div>
                            <div className='form-group col-4'>
                                <label className='mb-1 '>
                                    <strong>Traitement: </strong>
                                    <span className='text-primary'>
                                        {proformaData.opticBonDetailCaracteristique.find(elt => elt.typeCaracteristique === 'traimentVerre')?.valeurCaracteristique}
                                    </span>
                                </label>
                            </div>
                            <div className='form-group col-4'>
                                <label className='mb-1 '>
                                    <strong>Port de verres: </strong>
                                    <span className='text-primary'>
                                        {proformaData.opticBonDetailCaracteristique.find(elt => elt.typeCaracteristique === 'portVerre')?.valeurCaracteristique}
                                    </span>
                                </label>
                            </div>
                        </div>
                        <div className="card">
                            <DataTable value={opticDatas} showGridlines rowGroupMode="rowspan" groupRowsBy="category" tableStyle={{ minWidth: '50rem' }}>
                                <Column field="category" header="" style={{ minWidth: '200px' }}></Column>
                                <Column field="acte" header="" style={{ minWidth: '200px' }} body={acteBodyTemplate}></Column>
                                <Column field="prixActiva" header="Tier Payant" style={{ minWidth: '200px' }}></Column>
                                <Column field="prixPrestataire" header="Prix" style={{ minWidth: '150px' }}  body={prixPrestataireBodyTemplate}></Column>
                            </DataTable>
                        </div>
                        {proformaData.opticBon.status === STATUSES.rejeted && <div>
                            <b class="text-red">*** Cette proforma n'a pas été validée par l'assureur! ***</b>
                        </div>}
                        {hasMenu('MENOPTIC') && canDo('MENOPTIC', 'ACSENDOPTICPROF') && proformaData.opticBon.status === STATUSES.pending && <div className='row justify-content-end'>
                            <div className='col-2'>
                            { showLoading && 
                                <div className='col-6'>
                                    <Button variant="primary" disabled>
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />{' '}
                                        <span className="visually-hidden">Un instant...</span>
                                    </Button>
                                </div> }
                            { !showLoading &&
                                <button onClick={e => submitProforma()} type='button' className='btn btn-primary btn-block'>
                                    Valider et soumettre
                                </button> }
                            </div>
                        </div>}
                    </>
                }
            </div>
        </Fragment>
    )
};
const mapStateToProps = (state) => {
    return {
       proformaData: state.optique.proformaData,
       successMessage: state.optique.successMessage,
       errorMessage: state.optique.errorMessage,
       showLoading: state.optique.showLoading,
       currentUser: currentUser(state)
    };
};
export default withRouter(connect(mapStateToProps)(OpticBonSearchForm));