import {
    LIST_COUNTRIES_CONFIRMED_ACTION,
    LIST_COUNTRIES_FAILED_ACTION,
    LIST_WORKINGFIELDS_CONFIRMED_ACTION,
    LIST_WORKINGFIELDS_FAILED_ACTION,
    LIST_PARAM_IDENT_CONFIRMED_ACTION,
    LIST_PARAM_IDENT_FAILED_ACTION,
} from '../actions/ParamerterAction';

const initialState = {
    countries: [
        {id: 1, name: "Cameroun", city: [{id: 1, name:"DOuala"}]}
    ],
    workingFields: [],
    identParams: [],
    errorMessage: '',
    successMessage: '',
    showLoading: false,
};

export const ParameterReducer = (state = initialState, action) => {
    switch (action.type) {
        case LIST_COUNTRIES_CONFIRMED_ACTION:
            return {
                ...state,
                countries: action.payload,
                errorMessage: '',
                successMessage: 'Countries Successfully listed',
                showLoading: false,
            };
        case LIST_COUNTRIES_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case LIST_WORKINGFIELDS_CONFIRMED_ACTION:
            return {
                ...state,
                workingFields: action.payload,
                errorMessage: '',
                successMessage: 'Working fields Successfully listed',
                showLoading: false,
            };
        case LIST_WORKINGFIELDS_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case LIST_PARAM_IDENT_CONFIRMED_ACTION:
            return {
                ...state,
                identParams: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case LIST_PARAM_IDENT_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        default:
          return state;
    };
};