import {ParametreMicroserviceApi} from './AxiosInstance';
import { errorToastr, successToastr } from '../Utils/ToastrMessage';

export async function  addCountry(data)  {
    return ParametreMicroserviceApi.post(
        `country`,
        data,
    );
}
export async function  addCity(data)  {
    return ParametreMicroserviceApi.post(
        `city`,
        data,
    );
}
export async function  addExclusion(data)  {
    return ParametreMicroserviceApi.post(
        `exclusions`,
        data,
    );
}
export async function  addProfession(data)  {
    return ParametreMicroserviceApi.post(
        `profession`,
        data,
    );
}
export async function  addActivite(data)  {
    return ParametreMicroserviceApi.post(
        `activity-area`,
        data,
    );
}
export async function  getParameter(data)  {
    return ParametreMicroserviceApi.get(
        `all`,
        data,
    );
}
export function formatPatient() {

}

export function formatError() {
    errorToastr("Erreur lors de l'enregistrement");
}
export function getDateError() {
    errorToastr("Une erreur est survenu");
}
export function saveSuccess() {
    successToastr("Enregistrement Reussi !");
}