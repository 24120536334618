import { getBonByCode, saveExamResults, saveInterpretaionResults, getPatientResultsFiles } from '../../services/LaboratoireService';
import uploadFiles from '../../services/BlobStorageService';
import { errorToastr, successToastr } from '../../Utils/ToastrMessage';


export const GET_BON_CONFIRMED_ACTION = '[getLabBon action] confirmed getLabBon';
export const GET_BON_FAILED_ACTION = '[getLabBon action] failed getLabBon';

export const DELIEVER_EXAMS_CONFIRMED_ACTION = '[deleieverExams action] confirmed deleieverExams';
export const DELIEVER_EXAMS_FAILED_ACTION = '[deleieverExams action] failed deleieverExams';

export const COMMENT_EXAMS_CONFIRMED_ACTION = '[commentExams action] confirmed commentExams';
export const COMMENT_EXAMS_FAILED_ACTION = '[commentExams action] failed commentExams';

export const GET_PATIENT_RESULTS_CONFIRMED_ACTION = '[getPatientResults action] confirmed getPatientResults';
export const GET_PATIENT_RESULTS_FAILED_ACTION = '[getPatientResults action] failed getPatientResults';

export const START_LOADER_ACTION = '[laboratoireLoader action] started laboratoireLoader';

var I18n = require('react-redux-i18n').I18n;

export const getBonByCodeAction = (code) => {
    console.log("Action is called");
    return (dispatch) => {
        getBonByCode(code)
        .then((response) => {
            console.log(response.data);
            dispatch(confirmedGetBonAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('labo.get_bon_error')+error.response?.data?.message);
            dispatch(getBonFailedAction(error.response?.data?.message));
        });
    };
};

export const delieverExamensAction = (data) => {
    console.log("Action is called");
    return async (dispatch) => {
        for (let i = 0; i<data.examen.length; i++){
            if(data.examen[i].examFile){
                let resImg = await uploadFiles([data.examen[i].examFile], 'amp-providers-container');
                delete data.examen[i].examFile;
                data.examen[i].resultUrl = resImg[0];
            }
        }
        
        saveExamResults(data)
        .then((response) => {
            console.log(response.data);
            successToastr(I18n.t('labo.deliever_exam_success'));
            dispatch(confirmedDelievryBonAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('labo.deliever_exam_error')+error.response?.data?.message);
            dispatch(delievryFailedAction(error.response?.data?.message));
        });
    };
};

export const commentExamensAction = (data) => {
    console.log("Action is called");
    return async (dispatch) => {
        saveInterpretaionResults(data)
        .then((response) => {
            console.log(response.data);
            successToastr(I18n.t('labo.comment_success'));
            dispatch(confirmedDelievryBonAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('labo.comment_error')+error.response?.data?.message);
            dispatch(delievryFailedAction(error.response?.data?.message));
        });
    };
};

export const getPatientResultsAction = (id) => {
    console.log("Action is called");
    return async (dispatch) => {
        getPatientResultsFiles(id)
        .then((response) => {
            console.log(response.data);
            dispatch(confirmedGetPatientResultsAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('labo.getPatientResult')+error.response?.data?.message);
            dispatch(getPatientResultsFailedAction(error.response?.data?.message));
        });
    };
};

export function confirmedGetBonAction(payload) {
    return {
        type: GET_BON_CONFIRMED_ACTION,
        payload,
    };
};

export function getBonFailedAction(payload) {
    return {
        type: GET_BON_FAILED_ACTION,
        payload,
    };
};

export function confirmedDelievryBonAction(payload) {
    return {
        type: DELIEVER_EXAMS_CONFIRMED_ACTION,
        payload,
    };
};

export function delievryFailedAction(payload) {
    return {
        type: DELIEVER_EXAMS_FAILED_ACTION,
        payload,
    };
};


export function commentFailedAction(payload) {
    return {
        type: COMMENT_EXAMS_FAILED_ACTION,
        payload,
    };
};

export function confirmedCommentBonAction(payload) {
    return {
        type: COMMENT_EXAMS_CONFIRMED_ACTION,
        payload,
    };
};

export function confirmedGetPatientResultsAction(payload) {
    return {
        type: GET_PATIENT_RESULTS_CONFIRMED_ACTION,
        payload,
    };
};

export function getPatientResultsFailedAction(payload) {
    return {
        type: GET_PATIENT_RESULTS_FAILED_ACTION,
        payload,
    };
};

export function startLoader() {
    console.log("the start loader function is activated");
    return {
        type: START_LOADER_ACTION
    };
};