import { getFacture, getFactures, getBordereauDetail, requestPayment, calculationRembBase, calculationTM,
getFactureImpayes, getBodereauxPrestataires, createBordereau, submitBordereau, validateBordereau,
unpaidBordereau, paidBordereau, onTreatmentBordereau, addFactureBordereau, removeFactureBordereau, calculationRembBaseHospi } from '../../services/FacturationService';
import { errorToastr, successToastr } from '../../Utils/ToastrMessage';


export const GET_FACTURES_CONFIRMED_ACTION = '[getFactures action] confirmed getFactures';
export const GET_FACTURES_FAILED_ACTION = '[getFactures action] failed getFactures';

export const GET_FACTURE_CONFIRMED_ACTION = '[getFacture action] confirmed getFacture';
export const GET_FACTURE_FAILED_ACTION = '[getFacture action] failed getFacture';


export const GET_FACTURES_IMPAYES_CONFIRMED_ACTION = '[getFactureImpayées action] confirmed getFactureImpayées';

export const GET_BORDEREAUX_CONFIRMED_ACTION = '[getBordereau action] confirmed getBordereau';

export const CREATE_BORDEREAU_CONFIRMED_ACTION = '[createBordereau action] confirmed createBordereau';

export const SUBMIT_BORDEREAU_CONFIRMED_ACTION = '[submitBordereau action] confirmed submitBordereau';

export const VALIDATE_BORDEREAU_CONFIRMED_ACTION = '[validateBordereau action] confirmed validateBordereau';

export const PAID_BORDEREAU_CONFIRMED_ACTION = '[getPaidBordereau action] confirmed getPaidBordereau';

export const UNPAID_BORDEREAU_CONFIRMED_ACTION = '[getUnpaidBordereau action] confirmed getUnpaidBordereau';

export const ON_TREATMENT_BORDEREAU_CONFIRMED_ACTION = '[onTreatmentBordereau action] confirmed onTreatmentBordereau';

export const REQUEST_PAYMENT_CONFIRMED_ACTION = '[requestPayment action] confirmed requestPayment';
export const REQUEST_PAYMENT_FAILED_ACTION = '[requestPayment action] failed requestPayment';

export const CALCULATE_REMB_BASE_CONFIRMED_ACTION = '[CalculateRembBase action] confirmed CalculateRembBase';
export const CALCULATE_REMB_BASE_FAILED_ACTION = '[CalculateRembBase action] failed CalculateRembBase';

export const CALCULATE_TM_CONFIRMED_ACTION = '[CalculateTM action] confirmed CalculateTM';
export const CALCULATE_TM_FAILED_ACTION = '[CalculateTM action] failed CalculateTM';

export const START_LOADER_ACTION = '[facturationLoader action] started facturationLoader';

var I18n = require('react-redux-i18n').I18n;

export const getFacturesAction = (providerId) => {
   console.log("Action is called");
   return (dispatch) => {
    getFactures(providerId)
       .then((response) => {
           console.log(response.data);
           dispatch(confirmedGetFacturesAction(response.data));
       })
       .catch((error) => {
           errorToastr(I18n.t('factures.getFactures.error')+error.response?.data?.message);
           dispatch(getFacturesFailedAction(error.response?.data?.message));
       });
   };
};

export const getFactureAction = (id) => {
   console.log("Action is called");
   return (dispatch) => {
    getBordereauDetail(id)
       .then((response) => {
           console.log(response.data);
           dispatch(confirmedGetFactureAction(response.data));
       })
       .catch((error) => {
           errorToastr(I18n.t('factures.getFactures.error_single')+error.response?.data?.message);
           dispatch(getFactureFailedAction(error.response?.data?.message));
           //history.goBack();
       });
   };
};


export const requestPaymentAction = (providerId, history) => {
   console.log("Action is called");
   return (dispatch) => {
    requestPayment(providerId)
       .then((response) => {
           console.log(response.data);
           successToastr(I18n.t('factures.requestPayment.success'));
           dispatch(confirmedRequestPaymentAction());
           /*if(response.data.id){
                history.push('/facturation-details/'+response.data.id);
           }*/
       })
       .catch((error) => {
           errorToastr(I18n.t('factures.requestPayment.error')+error.response?.data?.message);
           dispatch(requestPaymentFailedAction(error.response?.data?.message));
       });
   };
}

export const calculationRembBaseAction = (data, index) => {
    console.log("Action is called");
    return async (dispatch) => {
        calculationRembBase(data.providerId, data.patientId, data.acteId)
        .then((response) => {
            console.log(response.data);
            dispatch(confirmedCalculationRembBaseAction({data: response.data, index}));
        })
        .catch((error) => {
            errorToastr(I18n.t('factures.calculate.error_base_remb')+error.response?.data?.message);
            dispatch(updateCalculationRembBaseFailedAction(error.response?.data?.message));
        });
    };
 };

 export const calculationRembBaseHospiAction = (data, index) => {
    console.log("Action is called");
    return async (dispatch) => {
        calculationRembBaseHospi(data.providerId, data.patientId, data.acteId, data.montant)
        .then((response) => {
            console.log(response.data);
            dispatch(confirmedCalculationRembBaseAction({data: response.data, index}));
        })
        .catch((error) => {
            errorToastr(I18n.t('factures.calculate.error_base_remb')+error.response?.data?.message);
            dispatch(updateCalculationRembBaseFailedAction(error.response?.data?.message));
        });
    };
 };

 export const calculationTMAction = (patientId) => {
    console.log("Action is called");
    return async (dispatch) => {
        calculationTM(patientId)
        .then((response) => {
            console.log(response.data);
            dispatch(confirmedCalculationTMAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('factures.calculate.error_tm')+error.response?.data?.message);
            dispatch(updateCalculationTMFailedAction(error.response?.data?.message));
        });
    };
 };

 export const getFacturesImpayesAction = (providerId, dateDebut, dateFin) => {
    console.log("Action is called");
    return (dispatch) => {
        getFactureImpayes(providerId, dateDebut, dateFin)
        .then((response) => {
            console.log(response.data);
            dispatch(confirmedGetFactureImpayesAction(response.data, dateDebut, dateFin));
        })
        .catch((error) => {
            errorToastr(I18n.t('factures.getFactures.error')+error.response?.data?.message);
            dispatch(getFacturesFailedAction(error.response?.data?.message));
        });
    };
 };

 export const getBodereauxAction = (providerId) => {
    console.log("Action is called");
    return (dispatch) => {
        getBodereauxPrestataires(providerId)
        .then((response) => {
            console.log(response.data);
            dispatch(confirmedBordereauxAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('factures.getFactures.error')+error.response?.data?.message);
            dispatch(getFacturesFailedAction(error.response?.data?.message));
        });
    };
 };

 export const createBordereauAction = (data, providerId, dateMin, dateMax) => {
    console.log("Action is called");
    return (dispatch) => {
        createBordereau(data)
        .then((response) => {
            console.log(response.data);
            successToastr('Un bordereau a bien été créé. Veuillez le soumettre afin que le règlement technique puisse être fait.');
            dispatch(startLoader());
            dispatch(getFacturesImpayesAction(providerId, dateMin, dateMax));
            dispatch(getUnpaidBodereauxAction(providerId));
        })
        .catch((error) => {
            errorToastr(I18n.t('factures.getFactures.error')+error.response?.data?.message);
            dispatch(getFacturesFailedAction(error.response?.data?.message));
        });
    };
 };

 export const submitBordereauAction = (bordereauId, providerId) => {
    console.log("Action is called");
    return (dispatch) => {
        submitBordereau(bordereauId)
        .then((response) => {
            console.log(response.data);
            successToastr('Un bordereau a bien été soumis pour règlement technique.');
            dispatch(startLoader());
            dispatch(getUnpaidBodereauxAction(providerId));
            dispatch(getOnTreatmentBodereauxAction(providerId));
        })
        .catch((error) => {
            errorToastr(I18n.t('factures.getFactures.error')+error.response?.data?.message);
            dispatch(getFacturesFailedAction(error.response?.data?.message));
        });
    };
 };

 export const validateBordereauAction = (bordereauId, providerId) => {
    console.log("Action is called");
    return (dispatch) => {
        validateBordereau(bordereauId)
        .then((response) => {
            console.log(response.data);
            successToastr('La validation du bordereau a bien été enregistrée.');
            dispatch(startLoader());
            dispatch(getOnTreatmentBodereauxAction(providerId));
            dispatch(getPaidBodereauxAction(providerId));
        })
        .catch((error) => {
            errorToastr(I18n.t('factures.getFactures.error')+error.response?.data?.message);
            dispatch(getFacturesFailedAction(error.response?.data?.message));
        });
    };
 };

 export const addFactureBordereauAction = (bordereauId, data, providerId) => {
    console.log("Action is called");
    return (dispatch) => {
        addFactureBordereau(bordereauId, data)
        .then((response) => {
            console.log(response.data);
            successToastr('Le bordereau a bien été mis à jour.');
            dispatch(startLoader());
            dispatch(getUnpaidBodereauxAction(providerId));
        })
        .catch((error) => {
            errorToastr(I18n.t('factures.getFactures.error')+error.response?.data?.message);
            dispatch(getFacturesFailedAction(error.response?.data?.message));
        });
    };
 };

 export const removeBordereauAction = (bordereauId, data, providerId) => {
    console.log("Action is called");
    return (dispatch) => {
        validateBordereau(bordereauId, data)
        .then((response) => {
            console.log(response.data);
            successToastr('Le bordereau a bien été mis à jour.');
            dispatch(startLoader());
            dispatch(getUnpaidBodereauxAction(providerId));
        })
        .catch((error) => {
            errorToastr(I18n.t('factures.getFactures.error')+error.response?.data?.message);
            dispatch(getFacturesFailedAction(error.response?.data?.message));
        });
    };
 };

 export const getPaidBodereauxAction = (providerId) => {
    console.log("Action is called");
    return (dispatch) => {
        paidBordereau(providerId)
        .then((response) => {
            console.log(response.data);
            dispatch(confirmedPaidBordereauxAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('factures.getFactures.error')+error.response?.data?.message);
            dispatch(getFacturesFailedAction(error.response?.data?.message));
        });
    };
 };

 export const getUnpaidBodereauxAction = (providerId) => {
    console.log("Action is called");
    console.log(providerId);
    return (dispatch) => {
        unpaidBordereau(providerId)
        .then((response) => {
            console.log(response.data);
            dispatch(confirmedUnpaidBordereauxAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('factures.getFactures.error')+error.response?.data?.message);
            dispatch(getFacturesFailedAction(error.response?.data?.message));
        });
    };
 };

 export const getOnTreatmentBodereauxAction = (providerId) => {
    console.log("Action is called");
    return (dispatch) => {
        onTreatmentBordereau(providerId)
        .then((response) => {
            console.log(response.data);
            dispatch(confirmedOnTreatmentBordereauxAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('factures.getFactures.error')+error.response?.data?.message);
            dispatch(getFacturesFailedAction(error.response?.data?.message));
        });
    };
 };

 export function confirmedOnTreatmentBordereauxAction(payload) {
    return {
        type: ON_TREATMENT_BORDEREAU_CONFIRMED_ACTION,
        payload,
    };
 };

 export function confirmedUnpaidBordereauxAction(payload) {
    return {
        type: UNPAID_BORDEREAU_CONFIRMED_ACTION,
        payload,
    };
 };

 export function confirmedPaidBordereauxAction(payload) {
    return {
        type: PAID_BORDEREAU_CONFIRMED_ACTION,
        payload,
    };
 };

 export function confirmedBordereauxAction(payload) {
    return {
        type: GET_BORDEREAUX_CONFIRMED_ACTION,
        payload,
    };
 };

 export function confirmedGetFactureImpayesAction(data, dateDebut, dateFin) {
    return {
        type: GET_FACTURES_IMPAYES_CONFIRMED_ACTION,
        payload: {data, dateDebut, dateFin},
    };
 };

 export function confirmedCalculationTMAction(payload) {
    return {
        type: CALCULATE_TM_CONFIRMED_ACTION,
        payload,
    };
 };
 
 export function updateCalculationTMFailedAction(payload) {
    return {
        type: CALCULATE_TM_FAILED_ACTION,
        payload,
    };
 };

 export function confirmedCalculationRembBaseAction(payload) {
    return {
        type: CALCULATE_REMB_BASE_CONFIRMED_ACTION,
        payload,
    };
 };
 
 export function updateCalculationRembBaseFailedAction(payload) {
    return {
        type: CALCULATE_REMB_BASE_FAILED_ACTION,
        payload,
    };
 };


export function confirmedGetFacturesAction(payload) {
   return {
       type: GET_FACTURES_CONFIRMED_ACTION,
       payload,
   };
};

export function getFacturesFailedAction(payload) {
   return {
       type: GET_FACTURES_FAILED_ACTION,
       payload,
   };
};

export function confirmedGetFactureAction(payload) {
   return {
       type: GET_FACTURE_CONFIRMED_ACTION,
       payload,
   };
};

export function getFactureFailedAction(payload) {
   return {
       type: GET_FACTURE_FAILED_ACTION,
       payload,
   };
};

export function startLoader() {
   return {
       type: START_LOADER_ACTION
   };
};

export function confirmedRequestPaymentAction() {
   return {
       type: REQUEST_PAYMENT_CONFIRMED_ACTION
   };
};

export function requestPaymentFailedAction(payload) {
   return {
       type: REQUEST_PAYMENT_FAILED_ACTION,
       payload,
   };
};