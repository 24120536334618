import {format} from 'date-fns';
export const COLUMNS = [
	{
		Header : 'Id',
		Footer : 'Id',
		accessor: 'id',
	},
	{
		Header : 'Démarré le',
		Footer : 'Démarré le',
		Cell: ({ value }) => {return format(new Date(value), 'dd/MM/yyyy')},
		accessor: 'start_date',
	},
	{
		Header : 'Terminé le',
		Footer : 'Terminé le',
		Cell: ({ value }) => {return format(new Date(value), 'dd/MM/yyyy')},
		accessor: 'end_date',
	},
	{
		Header : 'Statut',
		Footer : 'Statut',
		accessor: 'status',
	},
	{
		Header : 'Designation',
		Footer : 'Designation',
		accessor: 'name',
	},
	{
		Header : 'Posologie',
		Footer : 'Posologie',
		accessor: 'posologie',
	},
];