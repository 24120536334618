import {
    GET_BENEF_CONFIRMED_ACTION,
    GET_BENEF_FAILED_ACTION,
    LOADING_TOGGLE_ACTION,
    GET_ASSUR_BENEF_CONFIRMED_ACTION,
    GET_ASSUR_BENEF_FAILED_ACTION,
    GET_SOUSCRPTS_CONFIRMED_ACTION,
    GET_SOUSCRIPTS_FAILED_ACTION,
    GET_BENEF_DATA_CONFIRMED_ACTION,
    GET_BENEF_DATA_FAILED_ACTION,
    LOADING_STOP_TOGGLE_ACTION
} from '../actions/ContratActions';

const initialState = {
    beneficiaires: [],
    beneficiaire: {},
    assureBenefs: [],
    souscripteurs: [],
    errorMessage: '',
    successMessage: '',
    showLoading: false,
};

export const ContratReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_BENEF_CONFIRMED_ACTION:
            return {
                ...state,
                beneficiaires: action.payload,
                errorMessage: '',
                successMessage: 'Notifications listing Successfully Completed',
                showLoading: false,
            };
        case GET_BENEF_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case GET_BENEF_DATA_CONFIRMED_ACTION:
            return {
                ...state,
                beneficiaire: action.payload,
                errorMessage: '',
                successMessage: 'Notifications listing Successfully Completed',
                showLoading: false,
            };
        case GET_BENEF_DATA_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case GET_ASSUR_BENEF_CONFIRMED_ACTION:
            return {
                ...state,
                assureBenefs: action.payload,
                errorMessage: '',
                successMessage: 'Notifications listing Successfully Completed',
                showLoading: false,
            };
        case GET_ASSUR_BENEF_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case GET_SOUSCRPTS_CONFIRMED_ACTION:
            return {
                ...state,
                souscripteurs: action.payload,
                errorMessage: '',
                successMessage: 'Notifications listing Successfully Completed',
                showLoading: false,
            };
        case GET_SOUSCRIPTS_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case LOADING_TOGGLE_ACTION:
            return {
                ...state,
                showLoading: true,
            };
        case LOADING_STOP_TOGGLE_ACTION:
            return {
                ...state,
                showLoading: false,
            };
        default:
          return state;
    };
};