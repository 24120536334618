import React, { Fragment, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams, withRouter } from "react-router-dom";
import { Tab, Nav } from "react-bootstrap";
import { Button, Spinner} from "react-bootstrap";
import { getConsultationDataAction, updateConsultationAction, startLoader } from '../../../../store/actions/ConsultationActions';
import { getProviderDataAction } from '../../../../store/actions/ProviderActions';
import { currentUser } from '../../../../store/selectors/CurrentUserSelector';
import ConsultationForm from './ConsultationFormSteps/ConsultationForm';
import DiagnosisForm from './ConsultationFormSteps/DiagnosisForm';
import ExamsForm from './ConsultationFormSteps/ExamsForm';
import SpecialActsForm from './ConsultationFormSteps/SpecialActsForm';
import PharmacyForm from './ConsultationFormSteps/PharmacyForm';
import HospitalisationForm from './ConsultationFormSteps/HospitalisationForm';
import OptiqueForm from './ConsultationFormSteps/OptiqueForm';
import KineForm from './ConsultationFormSteps/KineForm';
import { warningToastr } from '../../../../Utils/ToastrMessage';
import "./ConsultationPane.css";
import { useTranslation } from "react-i18next";


const ConsultationPane = (props) => {
    const { t } = useTranslation();
    const { id, clientId, clientName } = useParams();
    const dispatch = useDispatch();
    const cookedConsultation = JSON.parse(localStorage.getItem("consultation"));
    
    const [tabData, setTabData] = useState([
        {
          name: 'Consultation',
          icon: 'stethoscope',
          component: ConsultationForm,
        },
        {
          name: 'Diagnostic',
          icon: 'diagnoses',
          component: DiagnosisForm,
        },
        {
          name: 'Examens',
          icon: 'vials',
          component: ExamsForm,
        },
    
        {
          name: 'Ordonnance',
          icon: 'pills',
          component: PharmacyForm,
        },
    
        {
          name: 'Actes spécialisés',
          icon: 'user-nurse',
          component: SpecialActsForm,
        }
      ]);

      const [isHospitalisation, setIsHospitalisation] = useState((cookedConsultation && cookedConsultation.isHospitalisation) ? cookedConsultation.isHospitalisation : false);

    const setSendToHospi = (val) => {
        setIsHospitalisation(val);
        let consultation = JSON.parse(localStorage.getItem("consultation"));
        consultation.isHospitalisation = val;
        localStorage.setItem("consultation", JSON.stringify(consultation));
    }

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
          return false;
        }else{
          return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
      }
    
     const hasMenu = (menuCode) => {
          return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
      }

     useEffect(() => {
        if(hasMenu('MENCONSULT') && canDo('MENCONSULT', 'ACMAKECONSULT')){
            console.log("About to call all the consultations");
            //dispatch(getConsultationDataAction(id, props.history));
            dispatch(getProviderDataAction(props.currentUser.providerId));
            if(!cookedConsultation) {
                localStorage.setItem("consultation", JSON.stringify({}));
            }
            let addTabs = [];
            if(canDo('MENCONSULT', 'ACPRESCHOSPI')){
                addTabs = [...addTabs, {
                      name: 'Hospitalisation',
                      icon: 'procedures',
                      component: HospitalisationForm,
                    }];
            }
            if(canDo('MENCONSULT', 'ACPRESCOPTIQ')){
                addTabs = [...addTabs, {
                        name: 'Optique',
                        icon: 'eye',
                        component: OptiqueForm,
                    }];
            }
            if(canDo('MENCONSULT', 'ACPRESCKINE')){
                addTabs = [...addTabs, {
                        name: 'Kine',
                        icon: 'wheelchair',
                        component: KineForm,
                    }];
            }
            if(addTabs.length > 0){
                setTabData(
                    [...tabData, ...addTabs]
                )
            }
        }else{
            props.history.push('/access-denied');
        }
    }, []);

    const saveConsultation = (e) => {
        e.preventDefault();
        let consultationDatas = JSON.parse(localStorage.getItem("consultation"));
        console.log(consultationDatas);

        if(errorKine(consultationDatas)) {
            warningToastr("Veuillez correctement renseigner la prescription de kinésithérapie");
        }else if(errorDiagnostique(consultationDatas)){
            warningToastr("Vous ne pouvez pas enregistrer la consultation sans donner de diagnostic");
        }else if(errorConsult(consultationDatas)){
            warningToastr("Veuillez renseigner les informations de consultation(motif et observations)");
        }else{
            dispatch(startLoader());
            consultationDatas.bonId = id;
            dispatch(updateConsultationAction(consultationDatas, props.history));
        }
    }

    const errorKine = (data) => {
        return data.kine && (
            !data.kine.nombreSession ||
            !data.kine.frequence ||
            !data.kine.uniteFrequence ||
            !data.kine.typeOrdonance ||
            data.kine.bonDetails.find(elt => !elt.acteId)
        )
    }

    const errorConsult = (data) => {
        return !data.questions || data.questions && (
            !data.questions.motif ||
            !data.questions.observations
        )
    }

    const errorDiagnostique = (data) => {
        return !data.diagnostics || (data.diagnostics  && data.diagnostics.diagnostic.length === 0)
    }

    return (
        <Fragment>
            <div className="bg-white p-5">
                <div className="card-header">
                    <h4 className="card-title">Consultation de {clientName}</h4>
                </div>
                <div className='row justify-content-end mt-4 mb-2'>
                    { props.showLoading && 
                        <Button variant="primary" disabled>
                            <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />{' '}
                            <span className="visually-hidden">Un instant...</span>
                        </Button> }
                    { !props.showLoading &&
                        <div className='col-4'>
                            <button type='button' onClick={e => saveConsultation(e)} className='btn btn-primary btn-block'>
                            {t('consultation.save-consultation')}
                            </button>
                        </div> }
                </div>
                {/*<div className='d-flex justify-content-start'>

                    <div className='custom-control custom-checkbox mb-3 checkbox-primary'>
                        <input
                        type='checkbox'
                        checked={isHospitalisation}
                        onChange={(e) => setSendToHospi(e.target.checked)}
                        className='custom-control-input'
                        id='customCheckBox2'
                        required
                        />
                        <label
                        className='custom-control-label text-primary text-bold'
                        htmlFor='customCheckBox2'
                        >
                        Hospitaliser le patient
                        </label>
                    </div>
                    </div>*/}
                <div className='custom-tab-1'>
                    <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                        <Nav as='ul' className='nav-tabs'>
                        {tabData.map((data, i) => (
                            <Nav.Item as='li' key={i}>
                                <Nav.Link eventKey={data.name.toLowerCase()}>
                                    <i className={`la la-${data.icon} mr-2`} />
                                    {data.name}
                                </Nav.Link>
                            </Nav.Item>
                        ))}
                        </Nav>
                        <Tab.Content className='pt-4'>
                        {tabData.map((data, i) => (
                            <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                                <data.component provider={props.provider} clientId={clientId} clientName={clientName} currentUser={props.currentUser} />
                            </Tab.Pane>
                        ))}
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </div>
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        provider: state.provider.provider,
        showLoading: state.consultation.showLoading,
        consultation: state.consultation.consultation,
        showLoading: state.consultation.showLoading,
        currentUser: currentUser(state)
    };
};

export default withRouter(connect(mapStateToProps)(ConsultationPane));