export const STATUSES = {
    success: "202",
    failed: "500",
    resused: "401",
    cancel: "400",
    confirmed: "203",
    pending: "102",
    created: "201",
    exectuted: "206",
    hospitalisation: "100",
    nonPaye: "104",
    paye: "207",
    accepted: "205",
    enCoursPaiement: "103",
    delivered: "204",
    rejeted: "403",
    waitingAccordPreable: "106",
    expired: "405",
    increation: "110",
    invalidation: "109",
    inpayment: "108",
    intechnicalvalidation: "107",
    bodereauValidatedByProvider: "208",
    bordereauRegle: "209"
};