import axios from 'axios';
const headers = { 'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('userDetails'))?.token, 'Accept-Language': localStorage.getItem('currentLanguage')};

const provicerServiceRequest = axios.create({
  baseURL: process.env.REACT_APP_BASEURL_ADMIN_BACKEND,
  headers: headers,
});

export const getProvider = (id) => provicerServiceRequest.get('/Parameter/provider/'+id);

export const registerProviderBank = (data) => provicerServiceRequest.put('/Parameter/provider/payment-account', data);

export const registerProviderMobileMoney = (data) => provicerServiceRequest.post('/Parameter/provider/mobile-payment', data);

export const searchActes = (keyword) => provicerServiceRequest.get('/parameter/acte/actes/search?keyword='+keyword);

export const searchActesConsult = (keyword, providerId, type) => provicerServiceRequest.get('/parameter/acte/tarif', {
  params: {
    type,
    providerId,
    keyword
  }
});

export const getAllProviders = () => provicerServiceRequest.get('/Parameter/provider-doctor');

export const getFacturation = (data) => provicerServiceRequest.post('/parameter/facture', data);
