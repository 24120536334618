import React, { useState, useEffect } from 'react';
import { Badge, Dropdown, Button, ListGroup } from "react-bootstrap";
import DropdownMultiselect from 'react-multiselect-dropdown-bootstrap';
import PerfectScrollbar from "react-perfect-scrollbar";
import { connect, useDispatch } from 'react-redux';
import { getActesHospitalisationAction } from '../../../../../store/actions/ConsultationActions';
import { calculationRembBaseAction, calculationRembBaseHospiAction, startLoader } from "../../../../../store/actions/FacturationAction";
import { currentUser } from '../../../../../store/selectors/CurrentUserSelector';
import Select from 'react-select';
import { ProgressSpinner } from 'primereact/progressspinner';
import './ConsultationStyle.css';
import { useTranslation } from "react-i18next";

const HospitalisationForm = (props) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const consult = JSON.parse(localStorage.getItem("consultation"));
    const [chambre, setChambre] = useState((consult && consult.hospitalisation) ? consult.hospitalisation[0].id : "");
    const [duree, setDuree] = useState((consult && consult.hospitalisation) ? consult.hospitalisation[0].quantite : 3);
    const [price, setPrice] = useState((consult && consult.hospitalisation) ? consult.hospitalisation[0].price : 0);
    const [inHospi, setInHospi] = useState(consult && consult.hospitalisation)
    const {actesHospitalisation, clientId, clientName, currentUser, rembCalculDatas} = props;

    useEffect(() => {
        dispatch(getActesHospitalisationAction(currentUser.providerId));
    }, []);

    const saveUpdates = (value, type) => {
        console.log(value);

        if(type === "chambre") {
            let chmb = actesHospitalisation.filter(elt => elt.id === value)[0];
            console.log(chmb);
            let consultation = JSON.parse(localStorage.getItem("consultation"));
            let temp = consultation.hospitalisation ? consultation.hospitalisation[0] : null;
            consultation.hospitalisation = [
                temp ? 
                {
                    ...temp,
                    code: chmb.acte.code,
                    nameFr: chmb.acte.nameFr,
                    nameEn: chmb.acte.nameEn,
                    isAccord: chmb.acte.isAccord,
                    isAlert: chmb.acte.isAlert,
                    isExclu: chmb.acte.isExclu,
                    id: chmb.acte.id,
                    quantite: duree,
                    montant: chmb.value,
                    montantConvention: price,
                    MontantTM: Math.min(rembCalculDatas.filter(fact => {return fact.index === 'hospitalisation'})[0]?.data.montantConvention, rembCalculDatas.filter(fact => {return fact.index === 'hospitalisation'})[0]?.data.montantPolice, price) === price ?
                    (price - (price * (100 - rembCalculDatas.filter(fact => {return fact.index === 'hospitalisation'})[0]?.data.tm) / 100))
                    : rembCalculDatas.filter(fact => {return fact.index === 'hospitalisation'})[0]?.data.montantPaye,
                    montantpolice: rembCalculDatas.filter(fact => {return fact.index === 'hospitalisation'})[0]?.data.montantPolice
                } : 
                {
                    code: chmb.acte.code,
                    nameFr: chmb.acte.nameFr,
                    nameEn: chmb.acte.nameEn,
                    isAccord: chmb.acte.isAccord,
                    isAlert: chmb.acte.isAlert,
                    isExclu: chmb.acte.isExclu,
                    id: chmb.acte.id,
                    quantite: duree,
                    montant: chmb.value,
                    montantConvention: price,
                    MontantTM: Math.min(rembCalculDatas.filter(fact => {return fact.index === 'hospitalisation'})[0]?.data.montantConvention, rembCalculDatas.filter(fact => {return fact.index === 'hospitalisation'})[0]?.data.montantPolice, price) === price ?
                    (price - (price * (100 - rembCalculDatas.filter(fact => {return fact.index === 'hospitalisation'})[0]?.data.tm) / 100))
                    : rembCalculDatas.filter(fact => {return fact.index === 'hospitalisation'})[0]?.data.montantPaye,
                    montantpolice: rembCalculDatas.filter(fact => {return fact.index === 'hospitalisation'})[0]?.data.montantPolice
                }
            ]
            console.log(consultation.hospitalisation);
            localStorage.setItem("consultation", JSON.stringify(consultation));
        }else if(type === "duree"){
            let consultation = JSON.parse(localStorage.getItem("consultation"));
            let temp = consultation.hospitalisation ? consultation.hospitalisation[0] : null;
            consultation.hospitalisation = [
                temp ? 
                {
                    ...temp,
                    quantite: duree,
                } : 
                {
                    quantite: duree,
                }
            ]
            localStorage.setItem("consultation", JSON.stringify(consultation));
        }else{
            let consultation = JSON.parse(localStorage.getItem("consultation"));
            let temp = consultation.hospitalisation ? consultation.hospitalisation[0] : null;
            if(temp && !value){
                delete consultation['hospitalisation'];
            }
            localStorage.setItem("consultation", JSON.stringify(consultation));
        }
    }


    return (
      <>
        <div className='row'>
            <div className='col-xl-12 col-lg-12'>
                <div className='card'>
                    <div className='card-header'>
                        <h4 className='card-title'>Hospitalisation</h4>
                    </div>
                    <div className='card-body'>
                        <div className='basic-form'>
                            <form onSubmit={(e) => e.preventDefault()}>
                                <div className='form-group'>
                                    <div className='d-flex justify-content-start'>
                                        <div className='custom-control custom-checkbox mb-3 checkbox-danger'>
                                            <input
                                                type='checkbox'
                                                checked={inHospi}
                                                onChange={(e) => {
                                                    setInHospi(e.target.checked);
                                                    saveUpdates(e.target.checked, "inHospi");
                                                }}
                                                className='custom-control-input'
                                                id='customCheckBoxHospi1'
                                                required
                                            />
                                            <label
                                            className='custom-control-label'
                                            htmlFor='customCheckBoxHospi1'
                                            >
                                                {t('consultation.hospitalize-patient')}
                                            </label>
                                        </div>
                                    </div>
                                    <div className='row '>
                                        <div className='col-12'>
                                            {inHospi && <div className='row justify-content-between my-5'>
                                                {rembCalculDatas.filter(fact => {return fact.index === 'hospitalisation'})[0]?.data.montantPolice > 0 && <>
                                                    <span className="font-weight-bold text-primary">{t('consultation.price-chamber')} {price * duree}</span>
                                                    <span className="font-weight-bold text-primary">{t('consultation.moderator-fee')} {100 - rembCalculDatas.filter(fact => {return fact.index === 'hospitalisation'})[0]?.data.tm} %</span>
                                                </>}
                                                {rembCalculDatas.filter(fact => {return fact.index === 'hospitalisation'})[0]?.data.montantPolice === 0 && 
                                                    <h6 className='text-danger'>{rembCalculDatas.filter(fact => {return fact.index === 'hospitalisation'})[0]?.data.message}</h6>
                                                }
                                                <span className="font-weight-bold text-warning">{t('consultation.patient-amount-to-pay')} 
                                                    {Math.min(rembCalculDatas.filter(fact => {return fact.index === 'hospitalisation'})[0]?.data.montantConvention, rembCalculDatas.filter(fact => {return fact.index === 'hospitalisation'})[0]?.data.montantPolice, price) === price ?
                                                    (price * (100 - rembCalculDatas.filter(fact => {return fact.index === 'hospitalisation'})[0]?.data.tm) / 100) * duree
                                                    : rembCalculDatas.filter(fact => {return fact.index === 'hospitalisation'})[0]?.data.montantPaye 
                                                    * duree}
                                                </span>
                                                
                                                {props.loader && <div className="col-1">
                                                    <ProgressSpinner style={{width: '25px', height: '25'}} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                                                </div>}
                                            </div>}
                                            <div className='row'>
                                                <div className='form-group col-6'>
                                                    <label className='mb-1 '>
                                                        <strong>{t('consultation.duration-in-days')}</strong>
                                                    </label>
                                                    <input
                                                        value={duree}
                                                        type='number'
                                                        min='1'
                                                        max='3'
                                                        width={"50px"}
                                                        className='form-control input-group-prepend'
                                                        disabled={!inHospi}
                                                        onChange={e => {
                                                            setDuree(e.target.value);
                                                            saveUpdates(e.target.value, "duree");
                                                        }}
                                                    />

                                                </div>
                                                <div className='form-group col-6'>
                                                    <label className='mb-1 '>
                                                        <strong>{t('consultation.choose-room')}</strong>
                                                    </label>
                                                    <Select
                                                        className="select-search"
                                                        options={actesHospitalisation.map(act => {
                                                            return {label: (act.acte.nameFr+' - '+(act.value * act.acte.coefLettre)), value: act.id, rootValue: act.acte.id, price: (act.value * act.acte.coefLettre)}
                                                        })}
                                                        name="Type"
                                                        placeholder={t('consultation.choose-room')}
                                                        search
                                                        isDisabled={!inHospi}
                                                        onChange={(val) => {
                                                            setChambre(val.rootValue);
                                                            setPrice(val.price);
                                                            saveUpdates(val.value, "chambre");
                                                            let data = {
                                                                providerId: currentUser.providerId,
                                                                patientId: clientId,
                                                                acteId: val.rootValue,
                                                                montant: val.price
                                                            };
                                                            dispatch(startLoader());
                                                            dispatch(calculationRembBaseHospiAction(data, 'hospitalisation'));
                                                        }}
                                                    />

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </>
    )
}
const mapStateToProps = (state) => {
    return {
        actesHospitalisation: state.consultation.actesHospitalisation,
        rembCalculDatas: state.facturation.rembCalculDatas,
        loader: state.facturation.showLoading,
        currentUser: currentUser(state) 
    };
};

export default connect(mapStateToProps)(HospitalisationForm);
