import React, { useState, useEffect } from "react";
import { connect, useDispatch } from 'react-redux';
import { searchActesAction } from '../../../../store/actions/ProviderActions';
import { searchActesSpecAction } from '../../../../store/actions/ProviderActions';
import { searchDrugsAction, getActesHospitalisationAction, addActeDetailAction, changeHospiRoomAction, startLoader } from '../../../../store/actions/ConsultationActions';
import { addKineActeBonAction } from "../../../../store/actions/KineActions";
import { Button, Modal, Spinner, Badge, Dropdown } from "react-bootstrap";
import Select from 'react-select';
import DropdownMultiselect from 'react-multiselect-dropdown-bootstrap';
import { calculationRembBaseAction } from "../../../../store/actions/FacturationAction";
import incisiveTooth from '../../../../images/tooth/incisors-tooth.svg';
import canineTooth from '../../../../images/tooth/canine-tooth.svg';
import premolarTooth from '../../../../images/tooth/tooth.svg';
import molarTooth from '../../../../images/tooth/molar-tooth.svg';

export const AddActModal = (props) => {
    const dispatch = useDispatch();
    const { type, bonId, drugs, actes, actesHospitalisation, currentUser, manageAddActModal, 
        updateActDone, clientId, rembCalculDatas, consultationId, maxDays, isChange, t } = props;
    const [error, setError] = useState(null);
    const [acte, setActe] = useState({});
    const [drug, setDrug] = useState({});
    const [quantite, setQuantite] = useState(1);
    const [duree, setDuree] = useState(maxDays === 365 ? 3 : maxDays);
    const [price, setPrice] = useState(0);
    
    const [isKid, setIsKid] = useState(false);
    const [selectedTooth, setSelectedTooth] = useState([]);
    const [dents, setDents] = useState([]);
    
    const [bonKineDetails, setBonKineDetails] = useState([{acteName: '', acteCode: '', acteId: null, description: ''}]);
    const [descriptions, setDescriptions] = useState(['']);
    const [choosenActes, setChoosenActes] = useState([{acteName: '', acteCode: '', acteId: null}]);

    const adultTopDentiton = [
        {code: "18", name: molarTooth},
        {code: "17", name: molarTooth},
        {code: "16", name: molarTooth},
        {code: "15", name: premolarTooth},
        {code: "14", name: premolarTooth},
        {code: "13", name: canineTooth},
        {code: "12", name: incisiveTooth},
        {code: "11", name: incisiveTooth},
        {code: "21", name: incisiveTooth},
        {code: "22", name: incisiveTooth},
        {code: "23", name: canineTooth},
        {code: "24", name: premolarTooth},
        {code: "25", name: premolarTooth},
        {code: "26", name: molarTooth},
        {code: "27", name: molarTooth},
        {code: "28", name: molarTooth}
    ];

    const adultBottomDentiton = [
        {code: "48", name: molarTooth},
        {code: "47", name: molarTooth},
        {code: "46", name: molarTooth},
        {code: "45", name: premolarTooth},
        {code: "44", name: premolarTooth},
        {code: "43", name: canineTooth},
        {code: "42", name: incisiveTooth},
        {code: "41", name: incisiveTooth},
        {code: "31", name: incisiveTooth},
        {code: "32", name: incisiveTooth},
        {code: "33", name: canineTooth},
        {code: "34", name: premolarTooth},
        {code: "35", name: premolarTooth},
        {code: "36", name: molarTooth},
        {code: "37", name: molarTooth},
        {code: "38", name: molarTooth}
    ];

    const kidTopDentiton = [
        {code: "55", name: molarTooth},
        {code: "54", name: premolarTooth},
        {code: "53", name: canineTooth},
        {code: "52", name: incisiveTooth},
        {code: "51", name: incisiveTooth},
        {code: "61", name: incisiveTooth},
        {code: "62", name: incisiveTooth},
        {code: "63", name: canineTooth},
        {code: "64", name: premolarTooth},
        {code: "65", name: molarTooth},
    ];

    const kidBottomDentiton = [
        {code: "85", name: molarTooth},
        {code: "84", name: premolarTooth},
        {code: "83", name: canineTooth},
        {code: "82", name: incisiveTooth},
        {code: "81", name: incisiveTooth},
        {code: "71", name: incisiveTooth},
        {code: "72", name: incisiveTooth},
        {code: "73", name: canineTooth},
        {code: "74", name: premolarTooth},
        {code: "75", name: molarTooth},
    ];


    const units = [
        {value: "ml", label: "ml"},
        {value: "cl", label: "cl"},
        {value: "Cuillère à café", label: "Cuillère à café"},
        {value: "Cuillère à soupe", label: "Cuillère à soupe"},
        {value: "comprimé", label: "comprimé"},
        {value: "suppositoire", label: "suppositoire"},
        {value: "gouttes", label: "gouttes"},
    ]

    const frequency = [
        {value: "matin", label: "matin"},
        {value: "midi", label: "midi"},
        {value: "soir", label: "soir"},
        {value: "coucher", label: "coucher"},
        {value: "lever", label: "lever"},
        {value: "nuit", label: "nuit"},
        {value: "journée", label: "journée"},
        {value: "heure", label: "heure"},
        {value: "intervalle d'heure", label: "intervalle d'heure"},
    ];

    const durationUnits = [
        {value: "jour", label: "jour"},
        {value: "semaine", label: "semaine"},
        {value: "mois", label: "mois"},
    ];

    const meals = [
        {value: "", label: ""},
        {value: "avant", label: "avant"},
        {value: "pendant", label: "pendant"},
        {value: "après", label: "après"},
    ];

    const ways = [
        {value: "à boire", label: "à boire"},
        {value: "à avaler", label: "à avaler"},
        {value: "à aspirer par le nez", label: "à aspirer par le nez"},
        {value: "à appliquer sur la peau", label: "à appliquer sur la peau"},
        {value: "à appliquer dans l'oeil", label: "à appliquer dans l'oeil"},
        {value: "à appliquer sur la zone affectée", label: "à appliquer sur la zone affectée"},
        {value: "à injecter", label: "à injecter"},
        {value: "à goutter dans l'oeil", label: "à goutter dans l'oeil"},
        {value: "à goutter dans l'oreille", label: "à goutter dans l'oreille"},
        {value: "à insérer dans l'anus", label: "à insérer dans l'anus"}
    ];

    const [quantitePos, setQuantitePos] = useState(1);
    const [unit, setUnit] = useState(units[0].label);
    const [frequence, setFrequence] = useState(0);
    const [periode, setPeriode] = useState([frequency[0].label]);
    const [duration, setDuration] = useState(1);
    const [durationUnit, setDurationUnit] = useState(durationUnits[0].label);
    const [repas, setRepas] = useState(meals[0]);
    const [voie, setVoie] = useState(ways[0]);

    const [posologieString, setPosologieString] = useState('');
    const [posologie, setPosologie] = useState([]);

    const managePeriod = (val) => {
        console.log(val);
        if(val.includes("heure") || val.includes("intervalle d'heure")) {
            console.log("Inside this if condition");
            setPeriode([val[val.length - 1]]);
        }else{
            setPeriode(val);
            setFrequence(0);
        }
        console.log(periode);
    }

    const composePosologie = (pos) => {
        console.log(pos.periode);
        if(pos.periode.includes("heure")) {
            return pos.quantitePos+' '+pos.unit+' à '+(pos.frequence)+' '+pos.periode.toString()+', '+pos.voie+' ,'+(pos.repas ? pos.repas+' le repas ': '')+' durant '+pos.duration+' '+pos.durationUnit;
        }
        else if(pos.periode.includes("intervalle d'heure")) {
            return pos.quantitePos+' '+pos.unit+' toute les '+(pos.frequence)+' heures, '+pos.voie+' ,'+(pos.repas ? pos.repas+' le repas ': '')+' durant '+pos.duration+' '+pos.durationUnit;
        }
        else{
            return pos.quantitePos+' '+pos.unit+' tous les '+pos.periode.toString()+', '+pos.voie+' ,'+(pos.repas ? pos.repas+' le repas ': '')+' durant '+pos.duration+' '+pos.durationUnit;
        }
    }

    const addPosologie = (e) => {
        e.preventDefault();
        let drugPosologie = {
            quantitePos,
            unit,
            frequence,
            periode: periode.toString(),
            duration,
            durationUnit,
            repas: repas.value,
            voie: voie.value,
        };

        setPosologie([...posologie, drugPosologie]);

        if(posologieString && posologieString.trim().length > 0){
            setPosologieString(posologieString+', puis '+composePosologie(drugPosologie));
        }else{
            setPosologieString(composePosologie(drugPosologie));
        }

        setQuantitePos(1);
        setUnit(units[0].label);
        setFrequence(1);
        setPeriode([frequency[0].label]);
        setDuration(1);
        setDurationUnit(durationUnits[0].label);
        setRepas(meals[0]);
        setVoie(ways[0]);
    }


    const removePosologie = (e, posPosition) => {
        e.preventDefault();
        setPosologie(posologie.filter((pos, i) => {return i !== posPosition}));
        let posStrArr = posologieString.split(', puis ');
        posStrArr = posStrArr.filter((pos, i) => {return i !== posPosition});
        setPosologieString(posStrArr.toString());
    }

    const isDentiste = () => {
        return currentUser.profils.filter(profil => {return profil.code.includes("DEN")}).length > 0;
    }

    const addDent = (dentCode) => {
        setDents([...dents, dentCode]);
    }

    const removeDent = (dentCode) => {
        setDents(dents.filter(de => {return de !== dentCode}));
    }
    
    const addActeDetails = () => {
        if(Object.keys(acte).length === 0 && Object.keys(drug).length === 0 ) {
            setError("Vous devez choisir un élément");
            return;
        }

        dispatch(startLoader());

        let data = null;

        if(type === "actes spécialisés") {
            data = bonId ?
                {
                  acteSpecialiseBonId: bonId,
                  consultationId,
                  acteId: acte.id,
                  acteName: acte.nameFr,
                  acteCode: acte.code,
                  dents
                } : {
                  acteSpecialiseBonId: -1,
                  consultationId,
                  acteId: acte.id,
                  acteName: acte.nameFr,
                  acteCode: acte.code,
                  dents
                }

        }else if(type === "examens") {
            data = bonId ? {
                  examenBonId: bonId,
                  consultationId,
                  acteId: acte.id,
                  acteName: acte.nameFr,
                  acteCode: acte.code
                } : {
                  examenBonId: -1,
                  consultationId,
                  acteId: acte.id,
                  acteName: acte.nameFr,
                  acteCode: acte.code
                };

        }else if(type === "hospitalisation") {
            data = bonId ? {
                  hospitalisationBonId: bonId,
                  consultationId,
                  acteId: acte.acte.id,
                  acteName: acte.acte.nameFr,
                  acteCode: acte.acte.code,
                  quantite: duree,
                  montant: acte.value * acte.acte.coefLettre,
                  montantConvention: price,
                  MontantTM: Math.min(rembCalculDatas.filter(fact => {return fact.index === 'hospitalisation'})[0]?.data.montantConvention, rembCalculDatas.filter(fact => {return fact.index === 'hospitalisation'})[0]?.data.montantPolice, price) === price ?
                  (price * (100 - rembCalculDatas.filter(fact => {return fact.index === 'hospitalisation'})[0]?.data.tm) / 100)
                  : rembCalculDatas.filter(fact => {return fact.index === 'hospitalisation'})[0]?.data.montantPaye,
                  montantpolice: rembCalculDatas.filter(fact => {return fact.index === 'hospitalisation'})[0]?.data.montantPolice
                } : {
                    hospitalisationBonId: -1,
                    consultationId,
                    acteId: acte.acte.id,
                    acteName: acte.acte.nameFr,
                    acteCode: acte.acte.code,
                    quantite: duree,
                    montant: acte.value * acte.acte.coefLettre,
                    montantConvention: price,
                    MontantTM: Math.min(rembCalculDatas.filter(fact => {return fact.index === 'hospitalisation'})[0]?.data.montantConvention, rembCalculDatas.filter(fact => {return fact.index === 'hospitalisation'})[0]?.data.montantPolice, price) === price ?
                    (price * (100 - rembCalculDatas.filter(fact => {return fact.index === 'hospitalisation'})[0]?.data.tm) / 100)
                    : rembCalculDatas.filter(fact => {return fact.index === 'hospitalisation'})[0]?.data.montantPaye,
                    montantpolice: rembCalculDatas.filter(fact => {return fact.index === 'hospitalisation'})[0]?.data.montantPolice
                  }; 
        }else if(type === "kine"){
            data = bonId ? bonKineDetails.map(elt => {
                return {
                    ...elt,
                    consultationId,
                    kinesitherapieBonId: bonId
                }
            }) : bonKineDetails.map(elt => {
                return {
                    ...elt,
                    consultationId,
                    kinesitherapieBonId: -1
                }
            });
        }else{
            data = bonId ? {
                  pharmacieBonId: bonId,
                  consultationId,
                  medicamentId: drug.id,
                  medicamentCode: drug.code,
                  medicamentName: drug.name,
                  quantite,
                  montant: drug.amount,
                  posologie,
                  posologieString
                } : {
                  pharmacieBonId: -1,
                  consultationId,
                  consultationId,
                  medicamentId: drug.id,
                  medicamentCode: drug.code,
                  medicamentName: drug.name,
                  quantite,
                  montant: drug.amount,
                  posologie,
                  posologieString
                };
        }
        
        dispatch(startLoader());
        
        if(isChange) {
            dispatch(changeHospiRoomAction(data));
        }else{
            if(type === "kine") {
                dispatch(addKineActeBonAction(data));
            }else{
                dispatch(addActeDetailAction(type, data));
            }
        }
        manageAddActModal(false, true);

    }

    const removeLine = (e, index) => {
        e.preventDefault();
        setChoosenActes(choosenActes.filter((elt, i) => i !== index));
        setDescriptions(descriptions.filter((elt, i) => i !== index));
        setBonKineDetails(bonKineDetails.filter((elt, i) => i !== index));
    }

    const addLine = (e) => {
        e.preventDefault();
        setChoosenActes([...choosenActes, {
            acteName: '', 
            acteCode: '', 
            acteId: null, 
            description: ''
        }]);
        setDescriptions([...descriptions, '']);
        setBonKineDetails([...bonKineDetails, {
            acteName: '', 
            acteCode: '', 
            acteId: null, 
            description: ''
        }]);
    }

    useEffect(() => {
        dispatch(getActesHospitalisationAction(currentUser.providerId));
    }, []);

    return (
            <>
                    <Modal.Header>
                        <Modal.Title className="text-center" >
                                {type !== "hospitalisation" && <span>Prescription de {' '} {type}</span>}
                                {type === "hospitalisation" && <span>Changement de chambre</span>}
                        </Modal.Title>
                        <Button
                            variant=""
                            className="close"
                            onClick={() => manageAddActModal(false)}
                        >
                            <span>&times;</span>
                        </Button>
                    </Modal.Header>
                    <Modal.Body>
                        {type === "hospitalisation" && <div className='row justify-content-between my-5'>
                            {rembCalculDatas.filter(fact => {return fact.index === 'hospitalisation'})[0]?.data.montantPolice > 0 && <>
                                <span className="font-weight-bold text-primary">Prix de la chambre: {Math.min(rembCalculDatas.filter(fact => {return fact.index === 'hospitalisation'})[0]?.data.montantConvention, rembCalculDatas.filter(fact => {return fact.index === 'hospitalisation'})[0]?.data.montantPolice, price) * duree}</span>
                                <span className="font-weight-bold text-primary">Ticket modérateur: {100 - rembCalculDatas.filter(fact => {return fact.index === 'hospitalisation'})[0]?.data.tm} %</span>
                            </>}
                            {rembCalculDatas.filter(fact => {return fact.index === 'hospitalisation'})[0]?.data.montantPolice === 0 && 
                                <h6 className='text-danger'>!!! Ce patient n'est pas couvert par l'assurance pour cet examen !!!</h6>
                            }
                            <span className="font-weight-bold text-warning">Montant à payer par le patient: 
                                {Math.min(rembCalculDatas.filter(fact => {return fact.index === 'hospitalisation'})[0]?.data.montantConvention, rembCalculDatas.filter(fact => {return fact.index === 'hospitalisation'})[0]?.data.montantPolice, price) === price ?
                                (price - (price * (100 - rembCalculDatas.filter(fact => {return fact.index === 'hospitalisation'})[0]?.data.tm) / 100)) * duree
                                : rembCalculDatas.filter(fact => {return fact.index === 'hospitalisation'})[0]?.data.montantPaye 
                                * duree}
                            </span>
                        </div>}

                        <h5>Choisissez</h5>
                        
                        { error && <small className="text-danger">{{error}}</small> }


                        <form onSubmit={(e) => e.preventDefault()}>

                            <div className="form-group">
                                {type !== "kine" && <label className='mb-1 '>
                                    { type !== "medicament" &&
                                        <strong>Acte</strong>
                                    }
                                    { type === "medicament" &&
                                        <strong>Medicament</strong>
                                    }
                                </label>}
                                {type !== "medicament" && type !== "hospitalisation" && type !== "kine" && <Select
                                    className="select-search"
                                    options={actes.map(elt => {return {label: elt.nameFr, value: elt.id}})}
                                    placeholder="Choisir"
                                    search
                                    isRequired
                                    onInputChange={(val) => {
                                        console.log(val);
                                        if(val.length >= 3){
                                            dispatch(searchActesAction(val, currentUser.provider.id, type==="examens" ? "Examen" : "Acte spécialisé"));
                                        }
                                    }}
                                    onChange={(val) => {
                                        console.log("Inside the wrong event listener");
                                        setActe(actes.filter(elt => elt.id === val.value)[0]);
                                    }}
                                />}
                                {type === "hospitalisation" && <Select
                                    className="select-search"
                                    options={actesHospitalisation.map(elt => {return {label: (elt.acte.nameFr+' - '+(elt.value * elt.acte.coefLettre)), value: elt.id, rootValue: elt.acte.id, price: (elt.value * elt.acte.coefLettre)}})}
                                    placeholder="Choisir"
                                    search
                                    isRequired
                                    onChange={(val) => {
                                        console.log("Inside the wrong event listener");
                                        setActe(actesHospitalisation.filter(elt => elt.id === val.value)[0]);
                                        setPrice(val.price);
                                        let data = {
                                            providerId: currentUser.providerId,
                                            patientId: clientId,
                                            acteId: val.rootValue
                                        };
                                        dispatch(calculationRembBaseAction(data, 'hospitalisation'));
                                    }}
                                />}
                                {type === "medicament" && <Select
                                    className="select-search"
                                    options={drugs.map(elt => {return {label: elt.name, value: elt.id, amount: elt.amount}})}
                                    placeholder="Choisir"
                                    search
                                    isRequired
                                    onInputChange={(val) => {
                                        console.log(val);
                                        if(val.length >= 3){
                                            dispatch(searchDrugsAction(val));
                                        }
                                    }}
                                    onChange={(val) => {
                                        console.log("Inside the correct event listener");
                                        setDrug(drugs.filter(drg => drg.id === val.value)[0])
                                    }}
                                />}
                            </div>

                            {type === "hospitalisation" && <div className='form-group col-12'>
                                <label className='mb-1 '>
                                    <strong>Durée en jours</strong>
                                </label>
                                <input
                                    value={duree}
                                    type='number'
                                    min='1'
                                    max={maxDays}
                                    width={"50px"}
                                    className='form-control input-group-prepend'
                                    onChange={e => {
                                        setDuree(e.target.value);
                                    }}
                                />

                            </div>}

                            {type === "medicament" && 
                            <>
                                <div className="form-group">
                                    <label className='mb-1 '>
                                        <strong>Quantité(en {drug.unit})</strong>
                                    </label>
                                    <input
                                        type='number'
                                        value={quantite}
                                        required
                                        className='form-control'
                                        onChange={(e) => setQuantite(e.target.value)}
                                    />
                                </div>
                                
                                <h4>Posologie</h4>
                                <p className="mb-4">
                                    {posologie.map((pos, i) => {
                                        return <Badge variant="dark light mr-1 mb-1" key={i}>
                                            <span>{composePosologie(pos)}</span>
                                            <button type="button" className="btn btn-dark shadow btn-xs sharp mx-1"
                                                onClick={(e) => removePosologie(e, i)}
                                            >
                                                <i className="fa fa-close"></i>
                                            </button>
                                        </Badge>
                                    })
                                    }
                                </p>

                                <h4>Composer la posologie</h4>

                                <div className='row mt-1 px-3'>
                                    <Badge variant="dark light mr-1 mb-1">
                                        <span className='text-danger'>{composePosologie({quantitePos,unit,frequence,periode, duration,durationUnit,repas: repas.value,voie: voie.value,})}</span>
                                        <button type="button" className="btn btn-dark shadow btn-xs sharp mx-1"
                                            
                                        >
                                            <i className="fa fa-heart"></i>
                                        </button>
                                    </Badge>
                                </div>

                                <div className='row mt-1 mb-1'>
                                    <div className='col-4 mb-1'>
                                        <label className='mb-1 '>
                                            <strong>Qté</strong>
                                        </label>
                                        <div className='input-group'>
                                            <input
                                                value={quantitePos}
                                                type='number'
                                                min='0.1'
                                                width={"50px"}
                                                className='form-control input-group-prepend'
                                                onChange={e => setQuantitePos(e.target.value)}
                                            />
                                            
                                            <Dropdown className='input-group-prepend'>
                                                <Dropdown.Toggle
                                                    variant=''
                                                    className='btn btn-primary p-1 dropdown-toggle'
                                                    type='button'
                                                    data-toggle='dropdown'
                                                >
                                                    {unit}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className='dropdown-menu'>
                                                    { units.map(unit => { 
                                                        return <Dropdown.Item className='dropdown-item' to='#' onClick={(e) => setUnit(unit.value)}>{unit.label}</Dropdown.Item>
                                                        })
                                                    }
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>

                                    <div className='col-4 mb-1'>
                                        <label className='mb-1 '>
                                            <strong>Fréquence</strong>
                                        </label>
                                        <div className='input-group'>
                                            <input
                                                value={frequence}
                                                type='number'
                                                min='1'
                                                disabled={!periode.includes("heure") && !periode.includes("intervalle d'heure")}
                                                className='form-control input-default '
                                                onChange={e => setFrequence(e.target.value)}
                                            />
                                            
                                            <DropdownMultiselect
                                                className='input-group-prepend'
                                                selected={periode}
                                                buttonClass='btn btn-primary px-2 rounded-0'
                                                options={frequency.map(freq => {return freq.value})}
                                                handleOnChange={managePeriod}
                                            />
                                        </div>
                                    </div>

                                    <div className='col-4 mb-1'>
                                        <label className='mb-1 '>
                                            <strong>Durée</strong>
                                        </label>
                                        <div className='input-group'>
                                            <input
                                                value={duration}
                                                type='number'
                                                min='1'
                                                className='form-control input-default '
                                                onChange={e => setDuration(e.target.value)}
                                            />
                                            
                                            <Dropdown className='input-group-prepend'>
                                                <Dropdown.Toggle
                                                    variant=''
                                                    className='btn btn-primary p-1 dropdown-toggle'
                                                    type='button'
                                                    data-toggle='dropdown'
                                                >
                                                    {durationUnit}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className='dropdown-menu'>
                                                    { durationUnits.map(unit => { 
                                                        return <Dropdown.Item className='dropdown-item' to='#' onClick={(e) => setDurationUnit(unit.value)}>{unit.label}</Dropdown.Item>
                                                        })
                                                    }
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>

                                    <div className='col-4 mb-1'>
                                        <label className='mb-1 '>
                                            <strong>Repas</strong>
                                        </label>
                                        <Select
                                            selected={repas}
                                            onChange={setRepas}
                                            options={meals}
                                            style={{
                                            lineHeight: '40px',
                                            color: '#7e7e7e',
                                            paddingLeft: ' 15px',
                                            }}
                                        />
                                    </div>
                                    <div className='col-4 mb-1'>
                                        <label className='mb-1 '>
                                            <strong>Voie administration</strong>
                                        </label>
                                        <Select
                                            selected={voie}
                                            onChange={setVoie}
                                            options={ways}
                                            style={{
                                            lineHeight: '40px',
                                            color: '#7e7e7e',
                                            paddingLeft: ' 15px',
                                            }}
                                        />
                                    </div>
                                    
                                    <div className='col-1 mb-1'>
                                        <label className='mb-1 text-white'>
                                            <strong>Ajouter</strong>
                                        </label>
                                        <Button variant='primary' onClick={e => addPosologie(e)}>
                                            <i className='fa fa-check' />
                                        </Button>
                                    </div>
                                </div>
                            </>}
                            
                            {type === "actes spécialisés" && <>
                            {isDentiste() && <div className='row'>
                                <div className='col'>
                                    <h6 className='text-center'>Choisir la dent concernée</h6>
                                    <div className='row justify-content-center my-4'>
                                        <div className='form-group mb-0'>
                                            <label className='radio-inline mr-5'>
                                                <input type='radio' name='isKid' value="adulte" checked={!isKid} onChange={e => {
                                                    setIsKid(!e.target.checked);
                                                    setDents([]);
                                                }
                                                }/> Adulte
                                            </label>
                                            <label className='radio-inline'>
                                                <input type='radio' name='isKid' value="enfant" checked={isKid} onChange={e => {
                                                    setIsKid(e.target.checked);
                                                    setDents([]);
                                                }}/> Enfant
                                            </label>
                                        </div>
                                    </div>
                                    {/* Schéma Dentition */}
                                    <div className='d-flex flex-column align-items-center'>
                                        {/* Dentition supérieure */}
                                        <div className='d-flex sup-dental'>
                                            {isKid &&
                                                <>
                                                    {kidTopDentiton.map(tooth => {
                                                        return <button className={dents.includes(tooth.code) ? "btn btn-default d-flex flex-column align-items-center p-1 m-0 border border-warning" :  "btn btn-default d-flex flex-column align-items-center p-1 m-0"}
                                                                onClick={(e) => addDent(tooth.code)}
                                                                >
                                                                        <small className='font-weight-bold'>{tooth.code}</small>
                                                                        <img src={tooth.name} width="20" style={{transform: "rotate(180deg)"}} />
                                                                </button>
                                                    })}
                                                </> 
                                            }
                                            {!isKid &&
                                                <>
                                                    {adultTopDentiton.map(tooth => {
                                                        return <button className={dents.includes(tooth.code) ? "btn btn-default d-flex flex-column align-items-center p-1 m-0 border border-warning" :  "btn btn-default d-flex flex-column align-items-center p-1 m-0"}
                                                                onClick={(e) => addDent(tooth.code)}
                                                                >
                                                                        <small className='font-weight-bold'>{tooth.code}</small>
                                                                        <img src={tooth.name} width="20" style={{transform: "rotate(180deg)"}}/>
                                                                </button>
                                                    })}
                                                </> 
                                            }
                                        </div>
                                        {/* Dentition inférieure */}
                                        <div className='d-flex inf-dental'>
                                            {isKid &&
                                                <>
                                                    {kidBottomDentiton.map(tooth => {
                                                        return <button className={dents.includes(tooth.code) ? "btn btn-default d-flex flex-column align-items-center p-1 m-0 border border-warning" :  "btn btn-default d-flex flex-column align-items-center p-1 m-0"}
                                                                onClick={(e) => addDent(tooth.code)}
                                                                >
                                                                        <img src={tooth.name} width="20" />
                                                                        <small className='font-weight-bold'>{tooth.code}</small>
                                                                </button>
                                                    })}
                                                </> 
                                            }
                                            {!isKid &&
                                                <>
                                                    {adultBottomDentiton.map(tooth => {
                                                        return <button className={dents.includes(tooth.code) ? "btn btn-default d-flex flex-column align-items-center p-1 m-0 border border-warning" :  "btn btn-default d-flex flex-column align-items-center p-1 m-0"}
                                                                onClick={(e) => addDent(tooth.code)}
                                                                >
                                                                        <img src={tooth.name} width="20" />
                                                                        <small className='font-weight-bold'>{tooth.code}</small>
                                                                </button>
                                                    })}
                                                </> 
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {isDentiste() && <div className="row my-2">
                                {dents.map((dent, j) => {
                                    return <Badge variant="warning mr-1 mb-1" key={j}>
                                        <span>{dent}</span>
                                        <button type="button" className="btn btn-dark shadow btn-xs sharp mx-1"
                                            onClick={(e) => removeDent(dent)}
                                        >
                                            <i className="fa fa-close"></i>
                                        </button>
                                    </Badge>
                                })}
                            </div>}
                            </>}

                            {type === "kine" && <>
                                {bonKineDetails.map((actePrescrit, i) => {
                                    return <div className='row'>
                                        <div className='col-5'>
                                            <div className='form-group'>
                                                <label className='mb-1 '>
                                                    <strong>{t('kine.actes')}<span className='text-danger'>*</span></strong>
                                                </label>
                                                <Select
                                                    className="select-search"
                                                    options={props.actesKine.map(elt => {return {label: elt.nameFr, value: elt.code, id: elt.id}})}
                                                    value={{label: choosenActes[i].acteName, value: choosenActes[i].acteCode, id: choosenActes[i].acteId}}
                                                    placeholder={t('kine.search-acts')}
                                                    search
                                                    onInputChange={(val) => {
                                                        console.log(val);
                                                        if(val.length >= 1){
                                                            dispatch(searchActesSpecAction(val, props.currentUser.providerId, 'Acte spécialisé'));
                                                        }
                                                    }}
                                                    onChange={(val) => {
                                                        setChoosenActes(choosenActes.map((elt, k) => {
                                                            if(i === k) {
                                                                elt = {
                                                                    acteName: val.label,
                                                                    acteCode: val.value, 
                                                                    acteId: val.id
                                                                }
                                                            }
                                                            return elt;
                                                        }))
                                                        let temp = bonKineDetails;
                                                        temp[i] = {
                                                            acteName: val.label, 
                                                            acteCode: val.value, 
                                                            acteId: val.id,
                                                            description: temp[i].description
                                                        }; 
                                                        setBonKineDetails(temp); 
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-5' >
                                            <div className='form-group'>
                                                <label className='mb-1 '>
                                                    <strong>{t('kine.comment')}</strong>
                                                </label>
                                                <textarea
                                                    value={descriptions[i]}
                                                    rows={1}
                                                    className='form-control input-default '
                                                    onChange={e => {
                                                        setDescriptions(descriptions.map((elt, j) => {
                                                            if(i === j) {
                                                                elt = e.target.value
                                                            }
                                                            return elt;
                                                        }))
                                                        let temp = bonKineDetails;
                                                        temp[i].description = e.target.value;
                                                        setBonKineDetails(temp); 
                                                    }} 
                                                    placeholder="commentaire"
                                                />
                                            </div>
                                        </div>
                                        {(bonKineDetails.length > 1)  && <div className='col-1' style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                        }}>
                                            <button className='btn btn-outline-danger' onClick={e => removeLine(e, i)}>
                                                <i className='fa fa-trash'></i>
                                            </button>
                                        </div>}
                                        {(i === bonKineDetails.length - 1)  && <div className='col-1' style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                        }}>
                                            <button className='btn btn-outline-success' onClick={e => addLine(e)}>
                                                <i className='fa fa-plus'></i>
                                            </button>
                                        </div>}
                                    </div>
                                })}
                            </>}
                        </form>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="danger light"
                            onClick={() => manageAddActModal(false, false)}
                        >
                            Annuler
                        </Button>
                        
                        { props.showLoading && 
                            <Button variant="primary" disabled>
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />{' '}
                                <span className="visually-hidden">Un instant...</span>
                            </Button> }
                        { !props.showLoading &&
                            <Button
                                variant=""
                                type="button"
                                className="btn btn-primary"
                                disabled={(Object.keys(acte).length === 0 && Object.keys(drug).length === 0 )}
                                onClick={() => addActeDetails()}
                            >
                                Soumettre
                        </Button> }
                    </Modal.Footer>
            </>
    )
};

const mapStateToProps = (state) => {
    return {
        drugs: state.consultation.drugs,
        actes: state.provider.actes,
        actesHospitalisation: state.consultation.actesHospitalisation,
        actesKine: state.provider.actesSpec,
        updateActDone: state.consultation.updateActDone,
        rembCalculDatas: state.facturation.rembCalculDatas,
    };
};

export default connect(mapStateToProps)(AddActModal);