import {
    getBeneficiaires,
    getAssuresBeneficiaires,
    getSouscripteurs,
    getBeneficiaire
} from '../../services/ContratService';
import { errorToastr, successToastr } from '../../Utils/ToastrMessage';


export const GET_BENEF_CONFIRMED_ACTION = '[getBeneficiaires action] confirmed getBeneficiaires';
export const GET_BENEF_FAILED_ACTION = '[getBeneficiaires action] failed getBeneficiaires';

export const GET_ASSUR_BENEF_CONFIRMED_ACTION = '[getAssuresBeneficiaires action] confirmed getAssuresBeneficiaires';
export const GET_ASSUR_BENEF_FAILED_ACTION = '[getAssuresBeneficiaires action] failed getAssuresBeneficiaires';

export const GET_SOUSCRPTS_CONFIRMED_ACTION = '[getSouscripteurs action] confirmed getSouscripteurs';
export const GET_SOUSCRIPTS_FAILED_ACTION = '[getSouscripteurs action] failed getSouscripteurs';

export const GET_BENEF_DATA_CONFIRMED_ACTION = '[getBeneficiaire action] confirmed getBeneficiaire';
export const GET_BENEF_DATA_FAILED_ACTION = '[getBeneficiaire action] failed getBeneficiaire';

export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOADING_STOP_TOGGLE_ACTION = '[Stop action] toggle stop loading';

var I18n = require('react-redux-i18n').I18n;

export function getBeneficiairesAction(nomBenef, numPolice, numSouscrip) {
    return (dispatch) => {
        getBeneficiaires(nomBenef, numPolice, numSouscrip)
        .then((response) => {
            let data = response.data;
            dispatch(confirmedGetBenefAction(data));
        })
        .catch((error) => {
            errorToastr(I18n.t('contrat.getBenef.error')+error.response?.data?.message);
            dispatch(getBenefFailedAction(error.response?.data?.message));
        });
    };
}

export function getBeneficiaireAction(id, history) {
    return (dispatch) => {
        getBeneficiaire(id)
        .then((response) => {
            let data = response.data;
            console.log(data);
            dispatch(confirmedGetBenefDataAction(data));
        })
        .catch((error) => {
            errorToastr(I18n.t('contrat.getBenef.error')+error.response?.data?.message);
            dispatch(getBenefDataFailedAction(error.response?.data?.message));
            history.push('/client-search/');
        });
    };
}

export function getAssuresBeneficiairesAction(numContrat) {
    return (dispatch) => {
        getAssuresBeneficiaires(numContrat)
        .then((response) => {
            let data = response.data;
            dispatch(confirmedGetAssuresBenefAction(data));
        })
        .catch((error) => {
            errorToastr(I18n.t('contrat.getBenef.by_assu_error')+error.response?.data?.message);
            dispatch(getAssuresBenefFailedAction(error.response?.data?.message));
        });
    };
}

export function getSouscripteursAction() {
    return (dispatch) => {
        getSouscripteurs()
        .then((response) => {
            let data = response.data;
            dispatch(confirmedGetSouscripteursAction(data));
        })
        .catch((error) => {
            errorToastr(I18n.t('contrat.getSouscript.error')+error.response?.data?.message);
            dispatch(getSouscripteursFailedAction(error.response?.data?.message));
        });
    };
}

export function confirmedGetBenefAction(data) {
    return {
        type: GET_BENEF_CONFIRMED_ACTION,
        payload: data,
    };
}

export function getBenefFailedAction(data) {
    return {
        type: GET_BENEF_FAILED_ACTION,
        payload: data,
    };
}

export function confirmedGetBenefDataAction(data) {
    return {
        type: GET_BENEF_DATA_CONFIRMED_ACTION,
        payload: data,
    };
}

export function getBenefDataFailedAction(data) {
    return {
        type: GET_BENEF_DATA_FAILED_ACTION,
        payload: data,
    };
}

export function confirmedGetAssuresBenefAction(data) {
    return {
        type: GET_ASSUR_BENEF_CONFIRMED_ACTION,
        payload: data,
    };
}

export function getAssuresBenefFailedAction(data) {
    return {
        type: GET_ASSUR_BENEF_FAILED_ACTION,
        payload: data,
    };
}

export function confirmedGetSouscripteursAction(data) {
    return {
        type: GET_SOUSCRPTS_CONFIRMED_ACTION,
        payload: data,
    };
}

export function getSouscripteursFailedAction(data) {
    return {
        type: GET_SOUSCRIPTS_FAILED_ACTION,
        payload: data,
    };
}


export function loadingToggleAction() {
    return {
        type: LOADING_TOGGLE_ACTION
    };
}
export function loadingToggleActionStop() {
    return {
        type: LOADING_STOP_TOGGLE_ACTION
    };
}