/// Menu
import MetisMenu from "metismenujs";
import React, { Component } from "react";
import { connect } from 'react-redux';
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";

import { currentUser } from '../../../store/selectors/CurrentUserSelector';
import { withTranslation } from 'react-i18next';

class MM extends Component {
  
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

class SideBar extends Component {
  /// Open menu
  componentDidMount() {
    // sidebar open/close
    var btn = document.querySelector(".nav-control");
    var btnav = document.querySelector(".deznav");
    var aaa = document.querySelector("#main-wrapper");
    aaa.classList.add("menu-toggle");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }

    function addMenuToggle() {
      console.log("mouseover");
       
     // if(!aaa.classList.contains("menu-toggle")) {
        return aaa.classList.remove("menu-toggle");
     // }
    }

    function removeMenuToggle() {

     // if(aaa.classList.contains("menu-toggle")) {
        
      setTimeout(() => {
        aaa.classList.add("menu-toggle");
        console.log("mouseleave");
      },600);
      //}
    }

   btn.addEventListener("click", toggleFunc);
   // btnav.addEventListener("mouseover", addMenuToggle)
   // btnav.addEventListener("mouseleave", removeMenuToggle)
  }
  state = {
    loveEmoji: false,
  };

  canDo(menuCode, actionCode){
    let theMenu = this.props.currentUser.menus?.find(menu => menu.code === menuCode);
    if(!theMenu){
      return false;
    }else{
      return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
    }
  }

  hasMenu(menuCode) {
      return this.props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
  }

  hasRole(roleCode) {
    return this.props.currentUser.roles?.filter(role => role.code === roleCode).length > 0;
  }

  hasProfil(profilCode) {
    return this.props.currentUser.profils?.filter(role => role.code === profilCode).length > 0;
  }
  

  render() {
    /// Path
    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];

    /// Active menu
    let dashBoard = [
      ""
    ]

    /// Active menu
    let authentication = [
      "identification",
      "client-search",
      "assure-search"
    ]
    
    /// Active menu
    let account = [
      "provider-view",
    ];

    /// Active menu
    let consultations = [
      "consultation-list",
    ];

    /// Active menu
    let pharmacies = [
      "pharmacie/service",
    ];

    /// Active menu
    let laboratoires = [
      "laboratoire/service",
    ];

    /// Active menu
    let actesSpecialises = [
      "actes-specialises/service",
    ];

    /// Active menu
    let optique = [
      "optique/service",
    ];

    /// Active menu
    let rendezVous = [
      "appointments-list",
      "appointments"
    ];

    /// Active menu
    let facturation = [
      "facturation"
    ];
    
    /// Active menu
    let dashboard = [
      "dashboard"
    ];

    return (
      <div className="deznav">
        <PerfectScrollbar className="deznav-scroll">
          <MM className="metismenu" id="menu">
            {(this.hasMenu('MENIDENT') || this.hasMenu('MENENROLL')) && <li className={`${dashBoard.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon mm-collapsed" to="#" >
                <i className="la la-chart-bar"></i>
                <span className="nav-text">Dashboard</span>
              </Link>
              <ul className="mm-collapsed">
                <li>
                  <Link className={`${path === "dashboard" ? "mm-active" : ""}`} to={"/dashboard"} onClick={() => this.props.onClick()}>
                    <i className="la la-chalkboard"></i>
                    <span>Dashboard</span>
                  </Link>
                </li>
              </ul>
            </li>}
            {(this.hasMenu('MENIDENT') || this.hasMenu('MENENROLL')) && <li className={`${authentication.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" >
                <i className="la la-user-cog"></i>
                <span className="nav-text">{this.props.t('layout.authentication')}</span>
              </Link>
              <ul >
                {this.hasMenu('MENIDENT') && <li>
                  <Link className={`${path === "" ? "mm-active" : ""}`} to="" onClick={() => this.props.onClick3()}>
                    <i className="la la-address-card"></i>
                    <span >{this.props.t('layout.patient-identification')}</span>
                  </Link>
                </li>}
                {this.hasMenu('MENENROLL') && <li>
                  <Link className={`${path === "client-search" ? "mm-active" : ""}`} to={"/client-search/"} onClick={() => this.props.onClick()}>
                    <i className="la la-address-card"></i>
                    <span >{this.props.t('layout.patient-enrollment')}</span>
                  </Link>
                </li>}
                {this.hasMenu('MENENROLL') && <li>
                  <Link className={`${path === "assure-search" ? "mm-active" : ""}`} onClick={() => this.props.onClick()} to="/assure-search">
                    <i className="la la-user-injured"></i>
                    <span className="nav-text">Assurés enrôlés</span>
                  </Link>
                </li>}
              </ul>
            </li>}
            <li className={`${account.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" >
                <i className="la la-user"></i>
                <span className="nav-text">{this.props.t('layout.account')}</span>
              </Link>
              <ul >
                <li>
                  <Link className={`${path === "provider-view" ? "mm-active" : ""}`} onClick={() => this.props.onClick()} to={"/provider-view/"+this.props.currentUser.providerId}>
                    <i className="la la-hospital"></i>
                    <span>{this.props.t('layout.establishment')}</span>
                  </Link>

                </li>
              </ul>
            </li>
            {this.hasMenu('MENCONSULT') && <li className={`${consultations.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" >
                <i className="la la-stethoscope"></i>
                <span className="nav-text">Consultations</span>
              </Link>
              <ul >
                <li>
                  <Link className={`${path === "consultation-list" ? "mm-active" : ""}`} onClick={() => this.props.onClick()} to={"/consultation-list"}>
                    <i className="la la-notes-medical"></i>
                    <span>{this.props.t('layout.my-consultations')}</span>
                  </Link>

                </li>
              </ul>
            </li>}
            {this.hasMenu('MENPHARM') && <li className={`${pharmacies.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" >
                <i className="la la-tablets"></i>
                <span className="nav-text">{this.props.t('layout.pharmacy')}</span>
              </Link>
              <ul >
                <li>
                  <Link className={`${path === "pharmacie/service" ? "mm-active" : ""}`} onClick={() => this.props.onClick()} to={"/pharmacie/service"}>
                    <i className="la la-pills"></i>
                    <span>Service</span>
                  </Link>

                </li>
              </ul>
            </li>}
            {this.hasMenu('MENLAB') && <li className={`${laboratoires.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" >
                <i className="la la-flask"></i>
                <span className="nav-text">{this.props.t('layout.laboratory')}</span>
              </Link>
              <ul >
                <li>
                  <Link className={`${path === "laboratoire/service" ? "mm-active" : ""}`} onClick={() => this.props.onClick()} to={"/laboratoire/service"}>
                    <i className="la la-vial"></i>
                    <span>Service</span>
                  </Link>

                </li>
              </ul>
            </li>}
            {this.hasMenu('MENCONSULT') && <li className={`${actesSpecialises.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" >
                <i className="la la-user-nurse"></i>
                <span className="nav-text">{this.props.t('layout.specialized-acts')}</span>
              </Link>
              <ul >
                <li>
                  <Link className={`${path === "actes-specialises/service" ? "mm-active" : ""}`} onClick={() => this.props.onClick()} to={"/actes-specialises/service"}>
                    <i className="la la-user-md"></i>
                    <span>Service</span>
                  </Link>

                </li>
              </ul>
            </li>}
            {this.hasMenu('MENOPTIC') &&  <li className={`${optique.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" >
                <i className="la la-glasses"></i>
                <span className="nav-text">{this.props.t('layout.optics')}</span>
              </Link>
              <ul >
                <li>
                  <Link className={`${path === "optique/service" ? "mm-active" : ""}`} onClick={() => this.props.onClick()} to={"/optique/service"}>
                    <i className="la la-user-md"></i>
                    <span>Service</span>
                  </Link>

                </li>
              </ul>
            </li>}
            {this.hasMenu('MENKINE') &&  <li className={`${optique.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" >
                <i className="la la-wheelchair"></i>
                <span className="nav-text">{this.props.t('layout.kine')}</span>
              </Link>
              <ul >
                <li>
                  <Link className={`${path === "kine/service" ? "mm-active" : ""}`} onClick={() => this.props.onClick()} to={"/kine/service"}>
                    <i className="la la-user-md"></i>
                    <span>Service</span>
                  </Link>

                </li>
                <li>
                  <Link className={`${path === "kine/seances" ? "mm-active" : ""}`} onClick={() => this.props.onClick()} to={"/kine/seances"}>
                    <i className="la la-user-md"></i>
                    <span>Seances</span>
                  </Link>

                </li>
              </ul>
            </li>}
            
            {this.hasMenu('MENCONSULT') && <li className={`${rendezVous.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" >
                <i className="la la-calendar-check"></i>
                <span className="nav-text">{this.props.t('layout.appointments')}</span>
              </Link>
              <ul >
                <li>
                  <Link className={`${path === "appointments-list" ? "mm-active" : ""}`} onClick={() => this.props.onClick()} to={"/appointments-list"}>
                    <i className="la la-calendar-check"></i>
                    <span >{this.props.t('layout.all-appointments')}</span>
                  </Link>
                </li>
                <li>
                  <Link className={`${path === "appointments" ? "mm-active" : ""}`} onClick={() => this.props.onClick()} to={"/appointments"}>
                    <i className="la la-calendar-check"></i>
                    <span>{this.props.t('layout.my-appointments')}</span>
                  </Link>
                </li>
              </ul>
            </li>}

            {this.hasMenu('MENFACTURE') && <li className={`${facturation.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" >
                <i className="la la-file-invoice-dollar"></i>
                <span className="nav-text">{this.props.t('layout.billing')}</span>
              </Link>
              <ul >
                <li>
                  <Link className={`${path === "facturation" ? "mm-active" : ""}`} onClick={() => this.props.onClick()} to={"/facturation"}>
                    <i className="la la-file-invoice"></i>
                    <span>{this.props.t('layout.history')}</span>
                  </Link>
                </li>
              </ul>
            </li>}
          </MM>
        </PerfectScrollbar>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
      currentUser: currentUser(state),
      authData: state.auth.auth,
  };
};

export default withTranslation()(connect(mapStateToProps)(SideBar));
