import { connect, useDispatch } from 'react-redux';
import React, {Fragment, useEffect, useState, useRef} from "react";
import { withRouter } from "react-router-dom";
import { Button, Spinner } from 'react-bootstrap'
import { getBonByCodeAction, sendProformaAction, startLoader, executeBonAction } from '../../../store/actions/KineActions';
import { currentUser } from '../../../store/selectors/CurrentUserSelector';
import { useReactToPrint } from 'react-to-print';
import { STATUSES } from '../../globals';
import { useTranslation } from "react-i18next";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const KineBonSearchForm = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [search, setSearch] = useState("");
    const [currentKey, setCurrentKey] = useState('');
    const [opticDatas, setOpticDatas] = useState([]);
    const { proformaData, currentUser, history, errorMessage, successMessage, showLoading } = props;

    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })

    const searchBon = (e) => {
        e.preventDefault();
        if(search.trim().length > 0) {
            console.log(search, currentUser.providerId);
            dispatch(startLoader());
            dispatch(getBonByCodeAction(search, currentUser.providerId));
        }
    }

    const submitProforma = () => {
        let data = {
            kinesitherapieBonId: proformaData.kinesitherapieBon?.id,
            providerId: currentUser.providerId,
            providerName: currentUser.provider.providerName,
            priceActiva: opticDatas.find(elt => elt.acte === 'Total').prixActiva,
            priceConvention: opticDatas.filter(val => val.acte !== 'Total').reduce((sum, val) => {
                return sum + (parseInt(val.prixPrestataire) || 0)
            }, 0) * proformaData.kinesitherapieBon?.nombreSession,
            bonDetails: opticDatas.filter(elt => elt.acte !== 'Total').map(detail => {
                return {
                    kinesitherapieBonDetailId: detail.bonDetailId,
                    acteId: detail.id,
                    acteName: detail.acte,
                    acteCode: detail.code,
                    priceActiva: detail.prixActiva,
                    priceConvention: parseInt(detail.prixPrestataire)
                }
            }),
        }
        dispatch(startLoader());
        dispatch(sendProformaAction(data));
    }

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
          return false;
        }else{
          return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
      }
    
    const hasMenu = (menuCode) => {
        return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
    }

    const prixPrestataireBodyTemplate = (rowData) => {
        return rowData.acte === "Total" ? 
            opticDatas.filter(val => val.acte !== 'Total').reduce((sum, val) => {
                return sum + (parseInt(val.prixPrestataire) || 0)
            }, 0) * proformaData.kinesitherapieBon?.nombreSession :
            <input type='number' 
                className='form-control'
                min="0" 
                disabled={rowData.acte === "Total"}
                onChange={e => {
                    setOpticDatas(opticDatas.map(elt => {
                        if(elt.acte === rowData.acte) {
                            elt.prixPrestataire = e.target.value
                        }
                        return elt;
                    }));
                }} 
                value={rowData.prixPrestataire}
            />;
    }

    const acteBodyTemplate = (rowData) => {
        return rowData.subData ? 
                <>
                    <span>{rowData.acte}</span><br/>
                    <small className='text-primary'>{rowData.subData}</small>
                </> :
                rowData.acte;
    }

    useEffect(() => {
        if(hasMenu('MENKINE') && canDo('MENKINE', 'ACVIEWBONKINE')){
            console.log("About to call all the consultations");
        }else{
            props.history.push('/access-denied');
        }
    }, [proformaData]);

    useEffect(() => {
        if(Object.keys(proformaData).length > 0) {
            if(proformaData.status === STATUSES.waitingAccordPreable || proformaData.status === STATUSES.confirmed) {
                setOpticDatas([...proformaData.kineProformatDetail?.map(elt => {
                    return {
                        bonDetailId: elt.kinesitherapieBonDetailId,
                        acte: elt.acteName,
                        id: elt.acteId,
                        code: elt.acteCode,
                        subData: elt.description,
                        prixActiva: elt.priceActiva || 0, 
                        prixPrestataire: elt.priceConvention || 0,
                        motifRejet: elt.motifRejet, 
                        montantRejet: elt.amountRejet, 
                        isRejet: elt.amountRejet > 0
                    }
                }), {
                    acte: 'Total',
                    subData: null,
                    prixActiva: proformaData.priceActiva || 0, 
                    prixPrestataire: proformaData.kineProformatDetail?.reduce((sum, elt) => sum + elt.priceConvention, 0) * proformaData.kinesitherapieBon?.nombreSession || 0,
                    motifRejet: proformaData.motifRejet, 
                    montantRejet: proformaData.amountRejet || 0, 
                    isRejet: proformaData.amountRejet > 0
                }])
            }else{
                setOpticDatas([...proformaData.kineProformatDetail?.map(elt => {
                    return {
                        bonDetailId: elt.kinesitherapieBonDetailId,
                        acte: elt.acteName,
                        id: elt.acteId,
                        code: elt.acteCode,
                        subData: elt.description,
                        prixActiva: elt.priceActiva || 0, 
                        prixPrestataire: elt.priceConvention || 0,
                    }
                }), {
                    acte: 'Total',
                    subData: null,
                    prixActiva: proformaData.priceActiva || 0, 
                    prixPrestataire: proformaData.kineProformatDetail?.reduce((sum, elt) => sum + elt.priceConvention, 0) * proformaData.kinesitherapieBon?.nombreSession || 0
                }])
            }
        }
    }, [proformaData]);

    useEffect(() => {
        if(search.trim().length > 0) {
            dispatch(startLoader());
            dispatch(getBonByCodeAction(search, currentUser.providerId));
        }
    }, [successMessage]);
    

    return (
        <Fragment>
            <div className="bg-white p-5">
                <div className='row justify-content-center mt-4'>
                    <form onSubmit={(e) => searchBon(e)} className="d-flex">
                        <input type="search" placeholder='Code du Bon' required value={search} onChange={e => setSearch(e.target.value)} className='form-control input-default mb-2' /> 
                        
                        { showLoading && 
                            <div className='col-6'>
                                <Button variant="primary" disabled>
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />{' '}
                                    <span className="visually-hidden">Un instant...</span>
                                </Button>
                            </div> }
                        { !showLoading &&
                            <div className='col-4'>
                                <button type='submit' className='btn btn-primary btn-block'>
                                    <i className='la la-search'></i>
                                </button>
                            </div> }
                    </form>
                </div>
                {search.length !== 0 && Object.keys(proformaData).length === 0 && <p className='text-center text-warning'>
                    Aucun bon valide trouvé
                </p>}
                {Object.keys(proformaData).length > 0 &&  proformaData.status === STATUSES.exectuted && <p className='text-center text-warning'>
                    Ce bon a déjà été exécuté
                </p>}
                {Object.keys(proformaData).length > 0 &&  (proformaData.status === STATUSES.waitingAccordPreable || proformaData.status === STATUSES.confirmed  || proformaData.status === STATUSES.accepted || proformaData.status === STATUSES.rejeted) && <>
                    <h2 className='text-center mb-5'>PROFORMA SEANCES DE KINESITHERAPIE</h2>
                    <h4 className='text-center'>
                        <b className='text-warning text-center'>{t('statuses.'+proformaData.status)}</b>
                    </h4>
                    {proformaData.motifRejet?.length > 0 && <p className='text-center'>
                        <b className='text-danger text-center'>{proformaData.motifRejet}</b>
                    </p>}
                    <div className='row'>
                        <div className='col-6'>
                            <h4 className='mt-2 text-dark text-start'>
                                <span style={{textDecoration: 'underline'}}>Exécutant:</span>
                                <span className='text-bold text-primary ml-2'>{proformaData.ProviderName}</span>
                            </h4>
                            <h4 className='mt-2 text-dark text-start'>
                                <span style={{textDecoration: 'underline'}}>Date de soumission:</span>
                                <span className='text-bold text-primary ml-2'>{proformaData.createdAt}</span>
                            </h4>
                        </div>
                        <div className='col-6 text-right'>
                            <h4 className='mt-2 text-dark text-start'>
                                <span style={{textDecoration: 'underline'}}>Patient:</span>
                                <span className='text-bold text-primary ml-2'>{proformaData.patientName}</span>
                            </h4>
                            <h4 className='mt-2 text-dark text-start'>
                                <span style={{textDecoration: 'underline'}}>Prescripteur:</span>
                                <span className='text-bold text-primary ml-2'>{proformaData.prescripteurName}</span>
                            </h4>
                        </div>
                    </div>
                    <div className="border-top my-2"></div>

                    <div className='row'>
                        <div className='form-group col-4'>
                            <label className='mb-1 '>
                                <strong>Type: </strong>
                                <span className='text-primary'>
                                    {proformaData.kinesitherapieBon?.typeOrdonance}
                                </span>
                            </label>
                        </div>
                        <div className='form-group col-4'>
                            <label className='mb-1 '>
                                <strong>Nombre de séances: </strong>
                                <span className='text-primary'>
                                    {proformaData.kinesitherapieBon?.nombreSession}
                                </span>
                            </label>
                        </div>
                        <div className='form-group col-4'>
                            <label className='mb-1 '>
                                <strong>Fréquence: </strong>
                                <span className='text-primary'>
                                    {proformaData.kinesitherapieBon?.frequence}/{proformaData.kinesitherapieBon?.uniteFrequence}
                                </span>
                            </label>
                        </div>
                        <div className='form-group col-4'>
                            <label className='mb-1 '>
                                <strong>A domicile: </strong>
                                <span className='text-primary'>
                                    {proformaData.kinesitherapieBon?.isDomicile ? t('kine.yes') : t('kine.no')}
                                </span>
                            </label>
                        </div>
                    </div>
                    <div className="card">
                        <DataTable value={opticDatas} showGridlines tableStyle={{ minWidth: '50rem' }}>
                            <Column field="acte" header="" style={{ minWidth: '200px' }} body={acteBodyTemplate}></Column>
                            <Column field="prixActiva" header="Prix Activa" style={{ minWidth: '200px' }}></Column>
                            <Column field="prixPrestataire" header="Prix" style={{ minWidth: '150px' }}></Column>
                            <Column field="montantRejet" header="Montant exclu" style={{ minWidth: '150px' }}></Column>
                            <Column field="motifRejet" header="Motif exclusion" style={{ minWidth: '150px' }}></Column>
                        </DataTable>
                    </div>
                    <div className="col-lg-4 col-sm-5 ml-auto">
                        <table className="table table-clear">
                        <tbody>
                            <tr>
                                <td className="left">
                                    <strong>Montant Total</strong>
                                </td>
                                <td className="right">{proformaData.priceConvention}</td>
                            </tr>
                            <tr>
                                <td className="left">
                                    <strong>Ticket Modérateur</strong>
                                </td>
                                <td className="right">{proformaData.pricePatient}</td>
                            </tr>
                            {proformaData.amountRejet > 0 && <tr>
                                <td className="left">
                                    <strong>Montant Rejeté</strong>
                                </td>
                                <td className="right">{proformaData.amountRejet}</td>
                            </tr>}
                            <tr>
                                <td className="left">
                                    <strong>Part assureur</strong>
                                </td>
                                <td className="right">{proformaData.priceActiva - proformaData.amountRejet}</td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                </>}
                {Object.keys(proformaData).length > 0 && (!proformaData.status ||  proformaData.status === STATUSES.pending)  &&
                    <>
                        {/*<FactureTemplate facture={proformaData} currentUser={currentUser} ref={componentRef} t={t} />*/}
                        <h4 className='mt-2 text-dark text-start'>
                            <span style={{textDecoration: 'underline'}}>Patient:</span>
                            <span className='text-bold text-primary ml-2'>{proformaData.patientName}</span>
                        </h4>
                        <h4 className='mt-2 text-dark text-start'>
                            <span style={{textDecoration: 'underline'}}>Prescripteur:</span>
                            <span className='text-bold text-primary ml-2'>{proformaData.prescripteurName}</span>
                        </h4>
                        <div className="border-top my-2"></div>

                        <div className='row'>
                            <div className='form-group col-4'>
                                <label className='mb-1 '>
                                    <strong>Type: </strong>
                                    <span className='text-primary'>
                                        {proformaData.kinesitherapieBon?.typeOrdonance}
                                    </span>
                                </label>
                            </div>
                            <div className='form-group col-4'>
                                <label className='mb-1 '>
                                    <strong>Nombre de séances: </strong>
                                    <span className='text-primary'>
                                        {proformaData.kinesitherapieBon?.nombreSession}
                                    </span>
                                </label>
                            </div>
                            <div className='form-group col-4'>
                                <label className='mb-1 '>
                                    <strong>Fréquence: </strong>
                                    <span className='text-primary'>
                                        {proformaData.kinesitherapieBon?.frequence}/{proformaData.kinesitherapieBon?.uniteFrequence}
                                    </span>
                                </label>
                            </div>
                            <div className='form-group col-4'>
                                <label className='mb-1 '>
                                    <strong>A domicile: </strong>
                                    <span className='text-primary'>
                                        {proformaData.kinesitherapieBon?.isDomicile ? t('kine.yes') : t('kine.no')}
                                    </span>
                                </label>
                            </div>
                        </div>
                        <div className="card">
                            <DataTable value={opticDatas} showGridlines tableStyle={{ minWidth: '50rem' }}>
                                <Column field="acte" header="" style={{ minWidth: '200px' }} body={acteBodyTemplate}></Column>
                                <Column field="prixActiva" header="Tier Payant" style={{ minWidth: '200px' }}></Column>
                                <Column field="prixPrestataire" header="Prix" style={{ minWidth: '150px' }}  body={prixPrestataireBodyTemplate}></Column>
                            </DataTable>
                        </div>
                        {proformaData.status === STATUSES.rejeted && <div>
                            <b class="text-red">*** Cette proforma n'a pas été validée par l'assureur! ***</b>
                        </div>}
                        {hasMenu('MENKINE') && canDo('MENKINE', 'ACSENDKINEPROF') && (!proformaData.status ||  proformaData.status === STATUSES.pending) && <div className='row justify-content-end'>
                            <div className='col-2'>
                            { showLoading && 
                                <div className='col-6'>
                                    <Button variant="primary" disabled>
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />{' '}
                                        <span className="visually-hidden">Un instant...</span>
                                    </Button>
                                </div> }
                            { !showLoading &&
                                <button onClick={e => submitProforma()} type='button' className='btn btn-primary btn-block'>
                                    Valider et soumettre
                                </button> }
                            </div>
                        </div>}
                    </>
                }
            </div>
        </Fragment>
    )
};
const mapStateToProps = (state) => {
    return {
       proformaData: state.kine.proformaData,
       successMessage: state.kine.successMessage,
       errorMessage: state.kine.errorMessage,
       showLoading: state.kine.showLoading,
       currentUser: currentUser(state)
    };
};
export default withRouter(connect(mapStateToProps)(KineBonSearchForm));