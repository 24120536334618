import axios from 'axios';

export const appkey = process.env.REACT_APP_FACIAL_XAPI_KEY;
//export const baseURL = "http://127.0.0.1:5000/api/v2";
export const baseURL = process.env.REACT_APP_BASEURL_FACIAL;
export const ParametreBaseURL = process.env.REACT_APP_BASEURL_ADMIN_BACKEND+"/parameter";
export const ClientBaseURL = process.env.REACT_APP_BASEURL_CLIENT_BACKEND;

export const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASEURL_FACIAL,
});

export const faceApiForUrl = axios.create({
    baseURL: baseURL,
    timeout: 50000,
    headers: {
        "x-api-key": appkey,
        "Content-Type": "multipart/form-data",
    },
    mimeType: "multipart/form-data",
    contentType: false,
    params: {

    }
});
export const faceApiForUpload = axios.create({
    baseURL: baseURL,
    timeout: 50000,
    headers: {
        "x-api-key": appkey,
        "Content-Type": "multipart/form-data",
    },
    params: {
    }
});
export const clientMicroserviceApi = axios.create({
    baseURL: baseURL,
    timeout: 50000,
    headers: {
        'Ocp-Apim-Subscription-Key': '73333733a76c424b9e88cf044edb9892',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('userDetails'))?.token
    },
    params: {
    }
});

export const ParametreMicroserviceApi = axios.create({
    baseURL: ParametreBaseURL,
    timeout: 50000,
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('userDetails'))?.token
    },
    params: {
    }
});

export const localClientMicroserviceApi = axios.create({
    baseURL: ClientBaseURL,
    timeout: 50000,
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('userDetails'))?.token
    },
    params: {
    }
});
